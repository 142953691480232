import { FunctionComponent, useEffect, useState } from "react";

import {
  defaultPurchaseValueV2,
  PurchaseVoucherModelV2,
} from "../../models/purchase_model";
import { UTCToLocalDate } from "../../../../utils/date_util";
import moment from "moment";
import {
  InwardModel,
  ItemMovementModelV2,
} from "../../../inventory/models/inventory_voucher";
import {
  ModalAlignment,
  ModalType,
  useModal,
} from "../../../../context_providers/modal/modal_context";

import Icon from "@mdi/react";
import { mdiAttachment, mdiChevronRight, mdiRefresh } from "@mdi/js";

import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { useHistory, useParams } from "react-router-dom";
import PartySearchField from "../../parties/party_search_field";
import DrawerWidget from "../../../../context_providers/modal/drawer_widget";
import {
  AttachmentModel,
  PurchaseOrderModelV2,
} from "../../../purchase_order/model/purchase_order_model";
import { formToObject } from "../../../../utils/formDataToJson";

import { currencies } from "../../models/party_model";
import ItemRowV2 from "./components/item_row_v2";
import WarehouseSearchField from "../../../inventory/warehouse/warehouse_search_field";
import {
  GetInwardsFromPO,
  GetPurchaseOrder,
  GetVoucherAttachments,
} from "./service/inward_repo";
import LoadingWidget from "../../../../context_providers/modal/loader";
import InwardAttachmentViewSection from "./components/inward_attachment_view_widget";
import PurchaseVoucherQRCodeModal from "./components/purchase_voucher_item_qrcode_modal";
import ModalDialog from "../../../../ui/new_components/common/modal";

interface PurchaseVoucherProps {
  location?: any;
}

const PurchaseVoucherDetail: FunctionComponent<PurchaseVoucherProps> = (
  props
) => {
  const { id } = useParams<{ id: string }>();
  const navigation = useHistory();
  const query = new URLSearchParams(props.location.search);
  const po_no = query.get("po_no");
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const { showModal } = useModal();

  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const [poData, setPoData] = useState<PurchaseOrderModelV2>();
  const [voucherData, setVoucherData] = useState<PurchaseVoucherModelV2>(
    defaultPurchaseValueV2
  );
  const [existingItems, setExistingItems] = useState<InwardModel[]>([]);
  const [newItems, setNewItems] = useState<ItemMovementModelV2[]>([]);
  const [showAttachment, setShowAttachment] = useState(false);

  const [showQRCodeModal, setQRCodeModal] = useState<boolean>(false);

  const [attachmentKey, setAttachmentKey] = useState(Math.random() * 10000);
  const [voucherAttachments, setVoucherAttachments] = useState<
    AttachmentModel[]
  >([]);

  useEffect(() => {
    if (po_no) {
      getPoData(po_no);
    }
  }, []);

  // Fetch PO data and project data
  const getPoData = async (id: string) => {
    setLoading(true);
    try {
      const res = await GetPurchaseOrder(id);
      if (res.success) {
        if (!res.data.vendor_currency) {
          res.data.vendor_currency = currencies.rupee;
          res.data.vendor_currency_to_inr = 1;
        }

        console.log(res.data.party.id);

        setVoucherData((o) => ({
          ...o,

          party_id: res.data.party.id,
          party_detail: res.data.party,
          warehouse: res.data.warehouse,
          warehouse_id: res.data.warehouse_id,
          order_detail: {
            ...(o.order_detail || {}), // Ensure order_detail exists
            order_id: "",
            payment_terms: "",
            other_ref: "",
            delivery_terms: "",
            po_no: res.data.po_no,
          },
        }));

        setPoData(res.data);
        setLoading(false);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({
        type: ToastType.error,
        text: "An error occurred while fetching PO data.",
      });
    } finally {
      setLoading(false);
    }
  };

  const getInwards = async (po_no: string) => {
    setLoading(true);
    try {
      const res = await GetInwardsFromPO(po_no);

      if (res.success) {
        setExistingItems(res.data);
        setLoading(false);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({
        type: ToastType.error,
        text: "An error occurred while fetching Inward data.",
      });
    } finally {
      setLoading(false);
    }
  };

  const getVoucherAttachments = async (voucher_id: string) => {
    setLoading(true);
    try {
      const res = await GetVoucherAttachments(voucher_id);
      setVoucherAttachments(res.data.attachments);
      if (res.success) {
        setLoading(false);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({
        type: ToastType.error,
        text: "An error occurred while fetching Inward data.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (poData) {
      getInwards(poData.po_no);
    }

    if (id) {
      getVoucherAttachments(id);
    }
  }, [poData]);

  return (
    <>
      {showQRCodeModal && (
        <ModalDialog
          onClose={() => setQRCodeModal(false)}
          show={showQRCodeModal}
          title="GRN Item QR Codes"
        >
          <div style={{ height: "85vh", width: "50vw" }} className="p-5 ">
            <PurchaseVoucherQRCodeModal data={existingItems} />
          </div>
        </ModalDialog>
      )}
      {showAttachment && (
        <DrawerWidget
          data={{
            id: 1,
            title: "Attachments",
            type: ModalType.drawer,
            alignment: ModalAlignment.right,
            container: (
              <InwardAttachmentViewSection
                key={attachmentKey}
                attachments={voucherAttachments}
                onSelect={(att: AttachmentModel) => {
                  let url = att.url;
                  console.log("Selected URL", url);
                  window.open(url, "_blank", "noopener,noreferrer");
                }}
              />
            ),
          }}
          onClose={function (): void {
            setShowAttachment(false);
          }}
        ></DrawerWidget>
      )}
      {id && (
        <div className="fixed bottom-3 right-3">
          <button
            title="Attachments"
            // to={`${window.document.location.pathname}/comment`}
            onClick={() => setShowAttachment(true)}
            className="  rounded-full p-2  hover:scale-105 transform duration-150 bg-green-400 text-white   shadow cursor-pointer flex items-center justify-center"
          >
            <Icon
              path={mdiAttachment}
              size={1}
              className="hover:scale-105 transform -rotate-45"
            ></Icon>
          </button>
        </div>
      )}
      {showOrderDetail && (
        <DrawerWidget
          data={{
            id: 1,
            title: "Order detail",
            type: ModalType.drawer,
            alignment: ModalAlignment.right,
            container: (
              <>
                <div className="p-5 text-xs">
                  <div className="flex flex-col items-start  w-full">
                    <h2 className="text-sm font-semibold">Party</h2>
                    <PartySearchField
                      // show_add={true}
                      value={poData?.party}
                      onSelect={(d) => {
                        setVoucherData((o) => ({
                          ...o,
                          party_detail: d,
                        }));
                      }}
                    />
                  </div>

                  <div className="grid grid-cols-2  my-2 gap-1">
                    <div className=" font-semibold text-gray-500">Party</div>
                    <div className="">: {poData?.party?.name}</div>
                    <div className=" font-semibold text-gray-500">Address</div>
                    <div className="">
                      : {poData?.party?.mail_detail.address.city},
                      {poData?.party?.mail_detail.address.state},
                      {poData?.party?.mail_detail.address.country}-
                      {poData?.party?.mail_detail.address.pincode},
                    </div>
                    <div className=" font-semibold text-gray-500">
                      Contact name
                    </div>
                    <div className="">
                      : {poData?.party?.mail_detail.contact_name}
                    </div>
                    <div className=" font-semibold text-gray-500">
                      Contact mobile
                    </div>
                    <div className="">
                      : {poData?.party?.mail_detail.contact_mobile}
                    </div>
                    <div className=" font-semibold text-gray-500">
                      Contact email
                    </div>
                    <div className="">
                      : {poData?.party?.mail_detail.contact_email}
                    </div>
                  </div>

                  <hr />
                  <h2 className="text-sm font-semibold">Order details</h2>
                  <div className="grid grid-cols-1  my-2 gap-1">
                    <div className="grid grid-cols-4 items-center">
                      <label
                        htmlFor=""
                        className=" font-semibold text-gray-500"
                      >
                        Order ref
                      </label>{" "}
                      <input
                        type="text"
                        className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                        value={voucherData.order_detail?.order_id}
                        onChange={(e) => {
                          setVoucherData((o) => ({
                            ...o,
                            order_detail: {
                              ...o.order_detail!,
                              order_id: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center">
                      <label
                        htmlFor=""
                        className=" font-semibold text-gray-500"
                      >
                        Delivery terms
                      </label>{" "}
                      <textarea
                        className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                        value={voucherData.order_detail?.delivery_terms}
                        onChange={(e) => {
                          setVoucherData((o) => ({
                            ...o,
                            order_detail: {
                              ...o.order_detail!,
                              delivery_terms: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center">
                      <label
                        htmlFor=""
                        className=" font-semibold text-gray-500"
                      >
                        Payment terms
                      </label>{" "}
                      <textarea
                        className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                        value={voucherData.order_detail?.payment_terms}
                        onChange={(e) => {
                          setVoucherData((o) => ({
                            ...o,
                            order_detail: {
                              ...o.order_detail!,
                              payment_terms: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            ),
          }}
          onClose={function (): void {
            setShowOrderDetail(false);
          }}
        ></DrawerWidget>
      )}
      <div className=" mt-2 px-6">
        <div className="flex items-center justify-between mb-2">
          {/* <div className="font-bold text-2xl text-gray-500 border-l-4 border-myPrimaryColor pl-2">
            Goods Received Note
          </div> */}
          <div className="text-xl font-bold border-l-4 border-myPrimaryColor p-2">
            Goods Received Note <span className="text-sm">#{id}</span>
          </div>
          <div className="flex gap-2 items-center">
            <label htmlFor="" className="text-sm font-semibold text-gray-500">
              Date
            </label>{" "}
            <input
              disabled={true}
              type="date"
              className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
              value={
                voucherData.date
                  ? moment(voucherData.date).format("YYYY-MM-DD")
                  : ""
              }
              onChange={(e) => {
                const date = UTCToLocalDate(e.target.value)!;
                setVoucherData((o) => ({
                  ...o,
                  date: date,
                }));
              }}
            />
          </div>
        </div>
        <div className="bg-white rounded-lg p-3">
          <div className="grid grid-cols-2 gap-x-12 items-center justify-start w-1/2">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const d = formToObject(new FormData(e.currentTarget));
                if (d["po_no"]) {
                  if (existingItems!.length > 0) {
                    const confirm = window.confirm(
                      "Are you sure to refresh po data?"
                    );
                    if (!confirm) {
                      return;
                    }
                  }
                  getPoData(d["po_no"]);
                }
              }}
              className="grid grid-cols-1 items-center"
            >
              <label
                htmlFor="invoice_no"
                className="text-sm font-semibold text-gray-500"
              >
                PO number
              </label>

              <div className="flex gap-1 col-span-3 items-center">
                <input
                  disabled={true}
                  value={voucherData.order_detail?.po_no}
                  onChange={(e) => {
                    setVoucherData((o) => ({
                      ...o,
                      order_detail: {
                        ...o.order_detail!,
                        po_no: e.target.value,
                      },
                    }));
                  }}
                  required
                  placeholder="Po number"
                  name="po_no"
                  type="text"
                  className="focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                />
                <button
                  title="Fetch po details"
                  className="rounded-full p-1 border"
                >
                  <Icon
                    path={mdiRefresh}
                    size={1}
                    className="hover:scale-110 transform"
                  ></Icon>
                </button>
              </div>
            </form>
            <div>
              <div className="grid grid-cols-1 items-center">
                <label
                  htmlFor=""
                  className="text-sm font-semibold text-gray-500"
                >
                  Due Date
                </label>{" "}
                <input
                  type="date"
                  disabled={true}
                  className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
                  value={
                    voucherData.due_date
                      ? moment(voucherData.due_date).format("YYYY-MM-DD")
                      : "--"
                  }
                  onChange={(e) => {
                    const date = UTCToLocalDate(e.target.value)!;
                    setVoucherData((o) => ({
                      ...o,
                      due_date: date,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-x-12 mt-3 items-center justify-start">
            <div className="grid grid-cols-1 items-center">
              <label htmlFor="" className="text-sm font-semibold text-gray-500">
                Vendor
              </label>
              <div className="col-span-3">
                <PartySearchField
                  disabled={true}
                  key={Math.random() * 10000}
                  value={poData?.party ? poData.party : undefined}
                  onSelect={(d) => {
                    setVoucherData((o) => ({
                      ...o,
                      // party_detail: party,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 items-center">
              <label htmlFor="" className="text-sm font-semibold text-gray-500">
                Warehouse
              </label>
              <div className="col-span-3">
                <WarehouseSearchField
                  disabled={true}
                  key={Math.random() * 10000}
                  value={
                    voucherData?.warehouse ? voucherData.warehouse : undefined
                  }
                  onSelect={(d) => {
                    setVoucherData((o) => ({
                      ...o,
                      // party_detail: party,
                      warehouse: d,
                      warehouse_id: d.id,
                    }));
                  }}
                />
              </div>
            </div>
          </div>{" "}
          {/* <div className="flex justify-end">
            <div
              onClick={() => {
                setShowOrderDetail(true);
              }}
              className="flex gap-5 border rounded items-center px-2 py-1  text-sm cursor-pointer"
            >
              <p>Order details</p>
              <Icon path={mdiChevronRight} className="w-4 h-4" />
            </div>
          </div> */}
        </div>
        <div className="relative">
          <div className="mt-3 mb-3 border-b border-gray-300 text-sm font-bold">
            Item details
          </div>

          {/* Table grid */}
          <div className="flex flex-col gap-1 bg-white  overflow-auto h-72 rounded">
            <div className="grid grid-cols-9 bg-myPrimaryColor text-white font-semibold text-sm text-center rounded-t">
              <div className="py-1 px-2 col-span-1">S.No</div>
              <div className="py-1 px-2 col-span-1">Item Code</div>
              <div className="py-1 px-2 col-span-1">Item</div>
              <div className="py-1 px-2 col-span-1">Unit Measure</div>
              <div className="py-1 px-2 col-span-1">Total PO Qty</div>
              <div className="py-1 px-2 col-span-1">
                Previously Received QTY
              </div>
              <div className="py-1 px-2 col-span-1">Received QTY</div>
              <div className="py-1 px-2 col-span-1">Unique IDs</div>
              <div className="py-1 px-2 col-span-1">Actions</div>
            </div>

            {existingItems?.map((item, i) => (
              <ItemRowV2
                max_qty={
                  voucherData.order_detail?.po_no ? item.unit_no : undefined
                }
                index={i + 1}
                key={item.id}
                onDelete={(d) => {
                  // setVoucherData((o) => {
                  //   const items = [...(o.items || [])].filter(
                  //     (i) => i.id !== d.id
                  //   );
                  //   let sub_total = items.reduce(
                  //     (pv, val) => pv + val.price * val.bill_unit_no,
                  //     0
                  //   );
                  // });
                }}
                onSubmit={function (data: ItemMovementModelV2): void {
                  setNewItems((o) => {
                    let updatedList: ItemMovementModelV2[] = [...newItems];
                    console.log("Updated List initial", updatedList);
                    const existingItemIndex = newItems.findIndex(
                      (it: ItemMovementModelV2) =>
                        it.item?.item_code === data.item?.item_code
                    );

                    console.log("Existing Item index", existingItemIndex);

                    // there is an existingItem in the list which we need to replace
                    if (existingItemIndex !== -1) {
                      updatedList[existingItemIndex] = data;
                    }
                    // we dont have any same item exisiting we need to add this in our updated list
                    else {
                      console.log("Data", data);
                      console.log("Updated List existing", o);
                      updatedList = [...(o || []), data];
                    }

                    updatedList = updatedList.filter(
                      (item) => item.received_unit_no !== 0
                    );

                    return updatedList;
                  });
                }}
                item={item}
                edit={false}
                disabled={true}
              />
            ))}
            {/* No addition is supported */}
          </div>
        </div>
      </div>
      <div className=" flex justify-end px-6 py-2">
        <button
          type="button"
          onClick={() => setQRCodeModal(true)}
          className="px-10 py-1 rounded-md bg-myPrimaryColor text-white text-sm "
        >
          QR Code
        </button>
      </div>{" "}
      <div className=" flex justify-end px-6 py-2">
        <button
          type="button"
          onClick={() => {
            navigation.goBack();
          }}
          className="px-10 py-1 rounded-md bg-white text-black text-sm "
        >
          Quit
        </button>
      </div>
      {loading && <LoadingWidget />}
    </>
  );
};

export default PurchaseVoucherDetail;
