import React, { useCallback, useEffect, useState } from "react";
import LoadingWidget from "../../context_providers/modal/loader";
import moment from "moment";
import { UTCToLocalDate } from "../../utils/date_util";
import { mdiArrowTopRight, mdiClose, mdiExport, mdiRefresh } from "@mdi/js";
import Icon from "@mdi/react";
import {
  POReportDefaultFilter,
  POReportFilterModel,
  POReportModel,
} from "./models/filter_model";
import { debounce } from "lodash";
import { exportPOReportData, getPoReportData } from "./service/po_report_repo";
import { ToastType, useToast } from "../../context_providers/toast/toast_ctx";
import Pagination from "../../ui/pages/content_panel/ui/new_components/common/pagination";
import {
  ModalType,
  useModal,
} from "../../context_providers/modal/modal_context";

const PurchaseOrderReportListScreen = () => {
  const [filter, setFilter] = useState<POReportFilterModel>(
    POReportDefaultFilter()
  );
  const { showToast } = useToast();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<POReportModel[]>([]);

  const exportData = async () => {
    setLoading(true);
    try {
      filter.all = true;
      const res = await exportPOReportData(filter);

      // Read response as text (CSV content)
      const csvText = res;
      console.log(csvText);
      // Generate a filename
      let downloadFilename = "export.csv";

      if (!res.ok) {
        return showToast({ type: ToastType.error, text: res.statusText });
      }

      const header = res.headers.get("Content-Disposition");
      const parts = header?.split(";");
      if (parts) {
        downloadFilename = parts[1].split("=")[1];
        downloadFilename = downloadFilename.substring(
          1,
          downloadFilename.length - 1
        );
      }
      const blob = await res.blob();

      const fileUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", downloadFilename ?? `export.csv`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    } catch (error: any) {
      console.log(error);
      showToast({ type: ToastType.error, text: "Download failed" });
    }
    setLoading(false);
  };

  const fetchData = async (prop: POReportFilterModel) => {
    setLoading(true);
    try {
      const res = await getPoReportData(prop);
      if (res.success) {
        setData(res.result.result);
        if (res.result.metadata) {
          setTotal(res.result.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce((prop: POReportFilterModel) => {
      if (prop) fetchData(prop);
      else setData([]);
    }, 100),
    []
  );

  useEffect(() => {
    debouncedHandler(filter);
  }, [filter]);

  return (
    <>
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section id="top_section" className="mb-4">
          <div className="flex justify-between items-end my-1 flex-wrap">
            <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
              <p className=" font-bold border-l-4 border-myPrimaryColor pl-2">
                Purchase Orders Report
              </p>
              <button title="Refersh" onClick={() => debouncedHandler(filter)}>
                <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
              </button>
            </h1>
            <div
              onClick={exportData}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiExport} className="h-4 w-4"></Icon>Export
            </div>
          </div>

          <div className="py-2">
            <div className="text-sm flex items-center gap-2">
              <div className="flex items-center  gap-1">
                <label htmlFor="fromDate" className="">
                  From
                </label>
                <input
                  type="date"
                  name=""
                  id="fromDate"
                  className="p-1 rounded focus:outline-none"
                  value={
                    filter.fromDate
                      ? moment(filter.fromDate).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const now = UTCToLocalDate(e.target.value)!;
                    const fromDate = new Date(
                      new Date(now).setHours(0, 0, 0, 0)
                    );
                    setFilter((o) => ({ ...o, fromDate, page: 1 }));
                  }}
                />
              </div>
              <div className="flex items-center  gap-1">
                <label htmlFor="toDate" className="">
                  To
                </label>
                <input
                  type="date"
                  name=""
                  id="toDate"
                  className="p-1 rounded focus:outline-none "
                  value={
                    filter.toDate
                      ? moment(filter.toDate).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const now = UTCToLocalDate(e.target.value)!;
                    const toDate = new Date(
                      new Date(now).setHours(23, 59, 59, 999)
                    );
                    setFilter((o) => ({ ...o, toDate, page: 1 }));
                  }}
                />
              </div>
              {(filter.fromDate || filter.toDate) && (
                <button
                  title="Clear dates"
                  onClick={() =>
                    setFilter((o) => ({
                      ...o,
                      fromDate: null,
                      toDate: null,
                    }))
                  }
                  className="flex gap-1 items-center cursor-pointer rounded p-1 border  r hover:scale-105 duration-200     transform"
                >
                  <Icon path={mdiClose} size={0.7}></Icon>
                </button>
              )}
            </div>
          </div>
        </section>
        <section className="flex-grow flex flex-col">
          <div className="my-2 border flex-grow overflow-auto">
            <table className="w-full text-xs divide-y divide-gray-200 relative">
              <thead className="text-xs py-2 sticky -top-1 bg-white z-10">
                <tr className="border">
                  <th className="px-2 py-2 text-left">PO No.</th>
                  <th className="px-2 py-2 text-left">PO Date</th>
                  <th className="px-2 py-2 text-left">PO Amount</th>
                  <th className="px-2 py-2 text-left">Attachment Category</th>
                  <th className="px-2 py-2 text-left">Attachment</th>
                  <th className="px-2 py-2 text-left">Attachment Date</th>
                  <th className="px-2 py-2 text-left">Attachment Time</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((item, i) => {
                    return (
                      <tr className="border text-center" key={i}>
                        {/* PO No */}
                        <td className="px-3 py-2 text-left">
                          <div
                            className="py-3 items-center cursor-pointer text-blue-600 hover:underline"
                            role="button"
                            onClick={() => console.log("PO No")}
                          >
                            {item.po_no ?? "--"}
                          </div>
                        </td>

                        {/* PO Date (Only Date) */}
                        <td className="px-3 py-2 text-left">
                          {item.date
                            ? moment(item.date).format("DD-MM-YYYY")
                            : "--"}
                        </td>

                        {/* PO Amount */}
                        <td className="px-3 py-2 text-left">
                          {item.total ?? "--"}
                        </td>

                        {/* Attachment Category */}
                        <td className="px-3 py-2 text-left">
                          {item.attachment?.category ?? "--"}
                        </td>

                        {/* Attachment URL (Clickable Link) */}
                        <td className="px-3 py-2 text-left">
                          {item.attachment?.url ? (
                            <a
                              href={item.attachment.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 underline hover:text-blue-700"
                            >
                              Link
                            </a>
                          ) : (
                            "--"
                          )}
                        </td>

                        {/* Attachment Date (Only Date) */}
                        <td className="px-3 py-2 text-left">
                          {item.attachment?.created_at
                            ? moment(item.attachment.created_at).format(
                                "DD-MM-YYYY"
                              )
                            : "--"}
                        </td>

                        {/* Attachment Time (Only Time) */}
                        <td className="px-3 py-2 text-left">
                          {item.attachment?.created_at
                            ? moment(item.attachment.created_at).format(
                                "HH:mm:ss"
                              )
                            : "--"}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className="h-40 flex items-center justify-center">
                        <h5 className="text-gray-400 text-2xl">
                          No Data found!
                        </h5>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="mt-3">
            <Pagination
              itemCount={filter.count}
              page={filter.page}
              total={total}
              count={filter.count}
              onChange={(page) => setFilter((o) => ({ ...o, page }))}
            />
          </div>
        </section>
      </div>
      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default PurchaseOrderReportListScreen;
