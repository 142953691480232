import { mdiCheck, mdiClose, mdiPencil, mdiDelete, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  itemMovementDefaultValue,
  StockItemMovementModel,
  UniqueProductModel,
} from "../../../models/stock_transfer_model";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { ItemModelV2 } from "../../../models/item_model";
import { fetchGet } from "../../../../../service/api_client";
import WarehouseSearchField from "../../../warehouse/warehouse_search_field";
import {
  WAREHOUSE_TYPE,
  WarehouseModel,
} from "../../../models/warehouse_model";
import ItemSearchField from "../../../items/item_search_field";
import NewItemSearchField from "../../../items/item_search_new";
import ModalDialog from "../../../../../ui/new_components/common/modal";
import { uniqueId } from "lodash";
import { generate_id } from "../../../journals/destination_stock_voucher/components/item_row_status";

interface ItemRowProps {
  component?: StockItemMovementModel;
  onSubmit: (data: StockItemMovementModel) => void;
  edit: boolean;
  disabled: boolean;
  onDelete?: (i: StockItemMovementModel) => void;
  existingItems: StockItemMovementModel[];
  from_warehouse: WarehouseModel;
}

const ItemRowStockTransfer: FunctionComponent<ItemRowProps> = (props) => {
  const [data, setData] = useState<StockItemMovementModel>({
    ...itemMovementDefaultValue,
  });
  const [inventory, setInventory] = useState<any[]>([]); // Separate inventory state
  // const [selectedItem, setSelectedItem] = useState<string>();
  const { showToast } = useToast();
  const { edit, onSubmit, existingItems } = props;
  const [editable, setEditable] = useState(false);
  const [addProduct, setAddProduct] = useState(false);
  const [check, setMdiCheck] = useState(true);
  const [, setLoading] = useState(false);
  const [, setHasStock] = useState(false);
  const [totalUnits, setTotalUnits] = useState(0);
  const [prevUnits, setPrevUnits] = useState(0);
  const location = useLocation();
  const isCreatePath = location.pathname.endsWith("/create");
  console.log(existingItems, "existingItems");
  useEffect(() => {
    setEditable(edit);
    if (props.component) {
      setData(props.component);
    } else {
      setData({ ...itemMovementDefaultValue });
    }
  }, [edit, props.component]);

  useEffect(() => {
    if (props.component) {
      setData((prevData) => ({
        ...prevData,
        ...props.component,
      }));
    }
  }, [props.component]);

  // const [rows, setRows] = useState(initialRows);//use data.uniqueIds instead of separate state

  // const handleAdd = () => {
  //   const newRow = {
  //     id: `${data.item_code}-${rows.length + 1}`,
  //     unique_id:data.uniqueIds?.unique_id,
  //     item_name: data.item?.name,
  //   };
  //   setData([...rows, newRow]);
  // };

  // // Delete row
  // const handleDelete = (id: any) => {
  //   const updatedRows = rows.filter((row) => row.id !== id);
  //   setData(updatedRows);
  // };

  const [initialRows, setInitialRows] = useState<
    { id: string; unique_id: string; item_name: string }[]
  >([]);

  useEffect(() => {
    // Find the existing item in the list
    const existingItem = existingItems.find((item) => item.id === data.id);

    // Get the existing unit_no, defaulting to 0 if not found
    const existingUnitNo = existingItem ? Number(existingItem.unit_no) || 0 : 0;

    // Update state with the existing unit_no
    setPrevUnits(existingUnitNo);
  }, [existingItems, data.id]); // Track changes in existingItems and data.id

  useEffect(() => {
    if (!data.unit_no) return; // Ensure unit_no is valid
    if (
      data.item?.subcategory !== "Finished Goods" &&
      data.item?.subcategory !== "IT and Electronic Hardware"
    ) {
      return;
    }
    if (!data.unit_no) return; // Ensure unit_no is valid

    const rows = Array.from({ length: data.unit_no }, (_, index) => {
      const existingUniqueId = data.uniqueIds?.[index]?.unique_id || ""; // Get existing unique_id if present

      return {
        id: `${"UN"}-${generate_id(8,"numeric")}`, // Unique ID generation
        unique_id: existingUniqueId,
        item_name: data.item?.name || "",
      };
    });

    setInitialRows(rows);
    setData((prevData) => ({ ...prevData, uniqueIds: rows }));
  }, [data.unit_no]); // Depend on data.unit_no to update rows dynamically

  // Use data.uniqueIds as the source of truth
  const handleInputChange = (index: number, value: string) => {
    const updatedUniqueIds: UniqueProductModel[] = Array.isArray(data.uniqueIds)
      ? [...data.uniqueIds]
      : [];
    updatedUniqueIds[index].unique_id = value;
    setData({ ...data, uniqueIds: updatedUniqueIds });
  };

  // Add new row
  const handleAdd = () => {
    const newRow: UniqueProductModel = {
      unique_id: "",
      item_name: data.item?.name || "",
    };

    setData({
      ...data,
      uniqueIds: [...(data.uniqueIds || []), newRow],
    });
  };

  // Delete row
  const handleDelete = (index: number) => {
    const updatedUniqueIds = [...(data.uniqueIds || [])];
    updatedUniqueIds.splice(index, 1);
    setData({ ...data, uniqueIds: updatedUniqueIds });
  };

  //   const isDuplicate = (item: ItemModelV2, warehouse: any) => {
  //     return existingItems.some(
  //       (existing) =>
  //         existing.item_id === item.id &&
  //         existing.warehouse.id === warehouse.id &&
  //         check
  //     );
  //   };

  const handleWarehouseSelect = async (warehouse: WarehouseModel) => {
    if (!data.item?.name) {
      showToast({
        type: ToastType.warning,
        text: "Please select an item first.",
      });
      return null; // Return null to indicate failure
    }

    setData((prevData) => ({
      ...prevData,
      warehouse,
      unit_no: 0, // Reset units
    }));

    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/items/${data.item?.id}/value?warehouse_id=${warehouse.id}`;
    setLoading(true);

    try {
      const res = await fetchGet(url);
      console.log("result:", res.data);

      if (res.success) {
        const { item_data } = res.data;
        const inwards = item_data[0]?.inwards?.items || [];
        const outwards = item_data[0]?.outwards?.items || [];

        const totalUnits =
          (item_data[0]?.inwards?.total_units || 0) -
          (item_data[0]?.outwards?.total_units || 0);
        setTotalUnits(totalUnits + prevUnits);
        // console.log(
        //   "total units:",
        //   totalUnits,
        //   "inwards:",
        //   inwards,
        //   "outwards:",
        //   outwards
        // );

        if (inwards.length === 0) {
          showToast({
            type: ToastType.warning,
            text: "No available stock for this item.",
          });
          setHasStock(false);
          return null;
        }

        const updatedInventory = [
          {
            warehouse,
            inwards,
            outwards,
          },
        ];

        setInventory(updatedInventory); // Update state
        setHasStock(true);

        return updatedInventory; // Return inventory for immediate use
      } else {
        showToast({ type: ToastType.error, text: res.error });
        return null;
      }
    } catch (error) {
      showToast({
        type: ToastType.error,
        text: "An error occurred while fetching warehouse stock value",
      });
      return null;
    } finally {
      setLoading(false);
    }
  };

  const updatePriceBasedOnUnits = async (requestedUnits: number) => {
    const updatedInventory = await handleWarehouseSelect(props.from_warehouse);

    if (!props.from_warehouse || !data.item || !updatedInventory) return;

    console.log("Updated Inventory:", updatedInventory);
    const warehouseData = updatedInventory.find(
      (w) => w.warehouse.id === props.from_warehouse?.id
    );

    if (!warehouseData) {
      showToast({
        type: ToastType.warning,
        text: "No stock available in the selected warehouse.",
      });
      setHasStock(false);
      return;
    }

    const { inwards, outwards } = warehouseData;

    // Calculate available units by deducting outwards from inwards
    let cumulativeInwards = inwards.map((item: any) => ({
      ...item,
      remaining: item.unit_no,
    }));

    for (const out of outwards) {
      let unitsToDeduct = out.unit_no;
      for (const inItem of cumulativeInwards) {
        if (unitsToDeduct <= 0) break;
        if (inItem.remaining > 0) {
          const unitsToRemove = Math.min(unitsToDeduct, inItem.remaining);
          inItem.remaining -= unitsToRemove;
          unitsToDeduct -= unitsToRemove;
        }
      }
    }

    let remainingUnits = requestedUnits;

    for (const item of cumulativeInwards) {
      if (remainingUnits <= 0) break;
      if (item.remaining > 0) {
        const unitsToAllocate = Math.min(remainingUnits, item.remaining);
        remainingUnits -= unitsToAllocate;
      }
    }

    if (remainingUnits > 0 + prevUnits) {
      showToast({
        type: ToastType.warning,
        text: "Requested units exceed available stock.",
      });
      return;
    }

    setData((prevData) => ({
      ...prevData,
      unit_no: requestedUnits,
    }));

    setHasStock(true);
  };

  console.log("out od total:", totalUnits);
  const onDone = () => {
    if (
      data.item?.subcategory !== "Finished Goods" &&
      data.item?.subcategory !== "IT and Electronic Hardware"
    ) {
      delete data.uniqueIds;
    } else if (data.uniqueIds) {
      console.log("ENTERED");
      data.uniqueIds.forEach((object) => {
        if (object.unique_id === "" || object.unique_id === undefined) {
          showToast({
            type: ToastType.error,
            text: "Please enter a valid Item ID",
          });
          return;
        }
      });
    }

    if (!data.item || !data.unit_no) {
      showToast({
        type: ToastType.warning,
        text: "Please select Item, Warehouse, and Quantity",
      });
      return;
    }
    console.log("data getting ", data);
    onSubmit(data);
  };

  return (
    <div>
      {setAddProduct && (
        <ModalDialog
          show={addProduct}
          title={"Product Details"}
          onClose={() => {
            setAddProduct(false);
            setData((prevData) => ({ ...prevData, uniqueIds: initialRows }));
          }}
        >
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <div className="flex justify-between">
              <h2 className="text-md font-semibold mb-4">Add Product List</h2>
              <div className="">
                <button className="bg-green-600 text-white p-1 rounded-md">
                  Upload
                </button>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full border border-gray-300">
                {/* Table Head */}
                <thead className="bg-gray-200">
                  <tr>
                    <th className="p-2 border">Unique Id</th>
                    <th className="p-2 border">Item Name</th>
                    <th className="p-2 border">Actions</th>
                  </tr>
                </thead>

                {/* Table Body */}
                <tbody>
                  {/* {rows.map((row: any, index: any) => ( */}
                  {(data.uniqueIds || []).map((row: any, index) => (
                    <tr key={row.id} className="text-center">
                      <td className="p-2 border">
                        <input
                          type="text"
                          value={row.unique_id || ""}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                          className="p-1 border rounded w-full focus:outline-none"
                          placeholder="Enter Unique Id"
                        />
                      </td>
                      {/* <td className="p-2 border">{row.name}</td>
                      <td className="p-2 border">
                        <div
                          onClick={() => handleDelete(row.id)}
                          className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200 cursor-pointer inline-block"
                        >
                          <Icon path={mdiDelete} className="h-4 w-4" />
                        </div>
                      </td> */}
                      <td className="p-2 border">{row.item_name}</td>
                      <td className="p-2 border">
                        <div
                          onClick={() => handleDelete(index)}
                          className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200 cursor-pointer inline-block"
                        >
                          <Icon path={mdiDelete} className="h-4 w-4" />
                        </div>
                      </td>
                    </tr>
                  ))}

                  {/* Add New Row Button */}
                  <tr>
                    <td colSpan={3} className="p-2 border text-center">
                      <div
                        onClick={handleAdd}
                        className="bg-blue-100 p-1 rounded text-blue-800 hover:scale-110 transform duration-200 cursor-pointer inline-flex items-center space-x-1"
                      >
                        <Icon path={mdiPlus} className="h-4 w-4" />
                        <span>Add Row</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ModalDialog>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onDone();
        }}
        className="bg-white p-1"
      >
        <div className="grid grid-cols-7 gap-1 items-center">
          <div>
            <input
              className="text-left p-1 focus:outline-none border rounded w-full"
              placeholder="item-code"
              disabled
              value={data.item_code}
              readOnly
              // onChange={(e) => {
              //   setData((prev) => ({
              //     ...prev,
              //     item_code: e.target.value,
              //   }));
              // }}
            />
          </div>
          <div className="col-span-2">
            {/* <ItemSearchField
            defaulValue={data.item}
            onSubmit={(item: ItemModelV2) => {
              setSelectedItem(item);
              setData((prevData) => ({
                ...prevData,
                item,
                item_code: item.item_code,
              }));
            }}
          /> */}

            <NewItemSearchField
              disabled={!editable || props.disabled}
              value={data.item}
              onSelect={(d: ItemModelV2) => {
                // setSelectedItem(data.item);
                setData((o) => ({
                  ...o,
                  item: d,
                  item_id: d.id,
                  item_code: d.item_code,
                }));
              }}
            />
          </div>
          <div>
            <input
              className="text-left p-1 focus:outline-none border rounded w-full"
              placeholder="unit"
              disabled={!editable || props.disabled}
              value={data.unit_measure}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  unit_measure: e.target.value,
                }));
              }}
            />
          </div>
          {/* <div>
          <WarehouseSearchField
            type={WAREHOUSE_TYPE.internal}
            disabled={!editable || props.disabled}
            value={data.from_warehouse}
            onSelect={handleWarehouseSelect}
          />
        </div> */}
          <div>
            <input
              value={data.unit_no || ""}
              disabled={!editable || props.disabled}
              onChange={(e) => {
                const unitNo = Number(e.target.value) || 0;
                setData((prevData) => ({
                  ...prevData,
                  unit_no: unitNo,
                }));
                if (props.from_warehouse) {
                  updatePriceBasedOnUnits(unitNo);
                }
              }}
              // disabled={!editable || props.disabled}
              type="text"
              className="text-left p-1 focus:outline-none border rounded w-full"
            />
            <span className="text-xs text-gray-500">
              Avail Units:{totalUnits}
            </span>
          </div>
          <div>
            {data.item?.type === "Product" &&
            (data.item?.subcategory === "Finished Goods" ||
              data.item?.subcategory === "IT and Electronic Hardware") ? (
              <div
                onClick={() => {
                  setAddProduct(true);
                }}
                className="text-left p-1 focus:outline-none border rounded text-blue-800 w-full cursor-pointer"
              >
                <div className="flex items-center space-x-2">
                  <Icon path={mdiPlus} className="h-6 w-4" />
                  <span>
                    {(data.uniqueIds?.length ?? 0) > 0
                      ? `${data.uniqueIds?.length ?? 0} items added`
                      : "Add/Upload"}
                  </span>
                </div>
              </div>
            ) : (
              <input
                className="text-left p-1 focus:outline-none border rounded w-full"
                placeholder="unique"
                // value={}
                onClick={() => {}}
              />
            )}
          </div>

          <div className="flex gap-2 items-center justify-center">
            {props.component ? (
              !props.disabled && (
                <div className="flex justify-center gap-2">
                  {editable ? (
                    <>
                      <div
                        onClick={() => {
                          if (!window.confirm("Are you sure!")) return;
                          onDone();
                          setEditable(false);
                        }}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiCheck} className="h-4 w-4" />
                      </div>
                      <div
                        onClick={() => {
                          setData(props.component || itemMovementDefaultValue);
                          setEditable(false);
                        }}
                        className="bg-gray-100 p-1 rounded text-gray-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiClose} className="h-4 w-4" />
                      </div>
                    </>
                  ) : (
                    (data.voucher_id || isCreatePath) && (
                      <div
                        onClick={() => {
                          setEditable(true);
                          setMdiCheck(false);
                        }}
                        className="bg-blue-100 p-1 rounded text-blue-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiPencil} className="h-4 w-4" />
                      </div>
                    )
                  )}
                  {data.voucher_id && (
                    <div
                      onClick={() =>
                        props.onDelete && props.onDelete(props.component!)
                      }
                      className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                    >
                      <Icon path={mdiDelete} className="h-4 w-4" />
                    </div>
                  )}
                </div>
              )
            ) : (
              <div
                onClick={() => {
                  setEditable(false);
                  onDone();
                }}
                className="bg-blue-100 p-1 rounded text-blue-800 hover:scale-110 transform duration-200"
              >
                <Icon path={mdiPlus} className="h-4 w-4" />
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ItemRowStockTransfer;
