import { RouteParam } from "../../../routes";
import { ACTION, MODULE } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import StockOutwardCreate from "../stock_outward/stock_outward_create";
import StockOutwardListScreen from "../stock_outward/stock_outward_list";
import StockOutwardView from "../stock_outward/stock_outward_view";

const stock_outward_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_outward,
    component: StockOutwardListScreen,
    module: MODULE.stock_outward,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_outward + "/create",
    component: StockOutwardCreate,
    module: MODULE.stock_outward,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_outward + "/view/:id",
    component: StockOutwardView,
    module: MODULE.stock_outward,
    main_module: MODULE.procurement_panel,
    action: [ACTION.READ],
    operator: "or"
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_outward + "/update/:id",
    component: StockOutwardCreate,
    module: MODULE.stock_outward,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  }

];

export default stock_outward_routes;
