import { FunctionComponent, useState, useEffect } from "react";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";

import ItemSearchFieldV2 from "../../../../purchase_order/purchase_order_create/components/item_search_widget";
import {
  inwardDefaultValueV2,
  InwardModel,
  itemMovementDefaultValueV2,
  ItemMovementModelV2,
} from "../../../../inventory/models/inventory_voucher";
import { parseNumber } from "../../../../../utils/import_export_parser";
import Icon from "@mdi/react";
import { mdiCheck, mdiClose, mdiDelete, mdiPencil, mdiPlus } from "@mdi/js";
import { ItemType } from "../../../../../procurement/masters/master_items/models/item_model";
import ModalDialog from "../../../../../ui/new_components/common/modal";
import { UniqueProductModel } from "../../../../inventory/models/stock_transfer_model";
import { uniqueId } from "lodash";

interface ItemRowProps {
  max_qty?: number;

  index: number;
  item?: InwardModel;
  onSubmit: (data: ItemMovementModelV2) => void;
  edit: boolean;
  disabled: boolean;
  onDelete?: (i: ItemMovementModelV2) => void;
}

const ItemRowV2: FunctionComponent<ItemRowProps> = (props) => {
  // const { showModal, onClose } = useModal();
  const [existingItem, setExistingItem] = useState<InwardModel>({
    ...inwardDefaultValueV2,
  });
  const [newItem, setNewItem] = useState<ItemMovementModelV2>({
    ...itemMovementDefaultValueV2,
  });
  const { showToast } = useToast();
  // const [showMore, setShowMore] = useState(false);
  const { edit, onSubmit } = props;

  const [addProduct, setAddProduct] = useState(false);

  const [editable, setEditable] = useState(false);

  const [initialRows, setInitialRows] = useState<
    { id: string; unique_id: string; item_name: string }[]
  >([]);

  useEffect(() => {
    if (!newItem.received_unit_no) return; // Ensure unit_no is valid
    if (
      newItem.item?.subcategory !== "Finished Goods" &&
      newItem.item?.subcategory !== "IT and Electronic Hardware"
    ) {
      return;
    }
    const rows = Array.from(
      { length: newItem.received_unit_no },
      (_, index) => {
        const existingUniqueId = newItem.uniqueIds?.[index]?.unique_id || ""; // Get existing unique_id if present

        return {
          id: `${newItem.item?.id}-${index + 1}`, // Unique ID generation
          unique_id: existingUniqueId,
          item_name: newItem.item?.name || "",
        };
      }
    );

    setInitialRows(rows);
    setExistingItem((prev) => ({
      ...prev,
      uniqueIds: rows,
    }));
    setNewItem((prevData) => ({ ...prevData, uniqueIds: rows }));
  }, [newItem.received_unit_no]); // Depend on data.unit_no to update rows dynamically

  useEffect(() => {
    setEditable(edit);
  }, [edit]);

  const onDone = () => {
    if (!existingItem.item) {
      showToast({
        type: ToastType.warning,
        text: "Please select Item, Qty and price",
      });
      return;
    }

    if (
      newItem.item?.subcategory === "Finished Goods" ||
      newItem.item?.subcategory === "IT and Electronic Hardware"
    ) {
      delete newItem.uniqueIds;
    } else if (newItem.uniqueIds) {
      newItem.uniqueIds.forEach((object) => {
        if (object.unique_id === "" || object.unique_id === undefined) {
          showToast({
            type: ToastType.error,
            text: "Please enter a valid Item ID",
          });
          return;
        }
      });
    }

    // because we need to create a new Voucher
    newItem.voucher_id = "";

    if (
      newItem.received_unit_no + existingItem.total_received_qty >
      existingItem.total_ordered_qty
    ) {
      showToast({
        text: "Recieved Items are exceeding Total Ordered Quantity",
        type: ToastType.error,
      });
      return;
    }

    onSubmit(newItem);
  };

  useEffect(() => {
    setExistingItem({ ...(props.item || inwardDefaultValueV2) });
    // New item will be the same as existing item but with changed recieved unit number
    // and a changed Voucher Id and Voucher number
    setNewItem({ ...(props.item || itemMovementDefaultValueV2) });
  }, [props.item]);

  useEffect(() => {
    console.log("New Items", newItem);
  }, [newItem]);

  const handleInputChange = (index: number, value: string) => {
    console.log(index, value);

    const updatedUniqueIds: UniqueProductModel[] = Array.isArray(
      existingItem.uniqueIds
    )
      ? [...existingItem.uniqueIds]
      : [];
    updatedUniqueIds[index].unique_id = value;
    setNewItem({ ...newItem, uniqueIds: updatedUniqueIds });
  };

  const handleAdd = () => {
    const newRow: UniqueProductModel = {
      unique_id: "",
      item_name: existingItem.item?.name || "",
    };
    console.log("Added in new Item");
    setNewItem({
      ...newItem,
      uniqueIds: [...(newItem.uniqueIds || []), newRow],
    });

    console.log("Added in existing Item");
    setExistingItem({
      ...existingItem,
      uniqueIds: [...(existingItem.uniqueIds || []), newRow],
    });
  };

  // Delete row
  const handleDelete = (index: number) => {
    const updatedUniqueIds = [...(existingItem.uniqueIds || [])];
    updatedUniqueIds.splice(index, 1);
    setExistingItem({ ...existingItem, uniqueIds: updatedUniqueIds });
    setNewItem({ ...existingItem, uniqueIds: updatedUniqueIds });
  };

  return (
    <>
      <div className="p-1">
        {editable && setAddProduct && (
          <ModalDialog
            show={addProduct}
            title={"Product Details"}
            onClose={() => {
              setAddProduct(false);
              // setNewItem((prevData) => ({
              //   ...prevData,
              //   uniqueIds: initialRows,
              // }));
            }}
          >
            <div className="bg-white p-6 rounded-md shadow-lg w-96">
              <div className="flex justify-between">
                <h2 className="text-md font-semibold mb-4">Add Product List</h2>
                <div className="">
                  <button className="bg-green-600 text-white p-1 rounded-md">
                    Upload
                  </button>
                </div>
              </div>
              <div className="overflow-x-auto">
                <table className="w-full border border-gray-300">
                  {/* Table Head */}
                  <thead className="bg-gray-200">
                    <tr>
                      <th className="p-2 border">Unique Id</th>
                      <th className="p-2 border">Item Name</th>
                      <th className="p-2 border">Actions</th>
                    </tr>
                  </thead>

                  {/* Table Body */}
                  <tbody>
                    {/* {rows.map((row: any, index: any) => ( */}
                    {(newItem.uniqueIds || []).map((row: any, index) => (
                      <tr key={row.id} className="text-center">
                        <td className="p-2 border">
                          <input
                            type="text"
                            value={row.unique_id || ""}
                            onChange={(e) => {
                              handleInputChange(index, e.target.value);
                            }}
                            className="p-1 border rounded w-full focus:outline-none"
                            placeholder="Enter Unique Id"
                          />
                        </td>
                        {/* <td className="p-2 border">{row.name}</td>
                                  <td className="p-2 border">
                                    <div
                                      onClick={() => handleDelete(row.id)}
                                      className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200 cursor-pointer inline-block"
                                    >
                                      <Icon path={mdiDelete} className="h-4 w-4" />
                                    </div>
                                  </td> */}
                        <td className="p-2 border">{row.item_name}</td>
                        <td className="p-2 border">
                          <div
                            onClick={() => handleDelete(index)}
                            className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200 cursor-pointer inline-block"
                          >
                            <Icon path={mdiDelete} className="h-4 w-4" />
                          </div>
                        </td>
                      </tr>
                    ))}

                    {/* Add New Row Button */}
                    <tr>
                      <td colSpan={3} className="p-2 border text-center">
                        <div
                          onClick={handleAdd}
                          className="bg-blue-100 p-1 rounded text-blue-800 hover:scale-110 transform duration-200 cursor-pointer inline-flex items-center space-x-1"
                        >
                          <Icon path={mdiPlus} className="h-4 w-4" />
                          <span>Add Row</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ModalDialog>
        )}
        <div className="grid grid-cols-9 gap-x-2 text-sm px-2 items-center border-b py-1">
          {/* Index */}
          {props.index}
          {/* Item Code Input */}
          <input
            disabled={true}
            value={existingItem?.item?.item_code || ""}
            type="text"
            className="text-center p-1 focus:outline-none border rounded w-full"
          />
          {/* Item Name */}{" "}
          <ItemSearchFieldV2
            disabled={true}
            value={existingItem?.item}
            onSelect={(d) => {
              //   if (d.type !== ItemType.service) setSelectedItem(d);
              //   else {
              //     setData((o) => ({ ...o, item: d, item_id: d.id }));
              //   }

              console.log("Selected");
            }}
          />
          {/* Unit measure */}
          <input
            disabled={true}
            value={existingItem?.item?.unit?.symbol || ""}
            type="text"
            className="text-right p-1 focus:outline-none border rounded w-full"
          />
          {/* Total PO Quantity */}
          <input
            disabled={true}
            value={existingItem?.total_ordered_qty || ""}
            type="text"
            className="text-right p-1 focus:outline-none border rounded w-full"
          />
          {/* Previously recieved Quantity */}
          <input
            disabled={true}
            value={existingItem?.total_received_qty || 0}
            type="text"
            className="text-right p-1 focus:outline-none border rounded w-full"
          />
          {/* Recieved Quantity */}
          <input
            disabled={editable === false ? true : false}
            type="number"
            className="text-right p-1 focus:outline-none border rounded w-full"
            value={newItem?.received_unit_no || ""}
            onChange={(e) => {
              setNewItem({
                ...newItem,
                received_unit_no: parseNumber(e.target.value) ?? 0,
              });
            }}
          />
          {/* Unique ID  */}
          <div>
            {existingItem.item?.type === "Product" &&
            (existingItem.item?.subcategory === "Finished Goods" ||
              existingItem.item?.subcategory ===
                "IT and Electronic Hardware") ? (
              <div
                onClick={() => {
                  setAddProduct(true);
                }}
                className="text-left p-1 focus:outline-none border rounded text-blue-800 w-full cursor-pointer"
              >
                <div className="flex items-center space-x-2">
                  <Icon path={mdiPlus} className="h-6 w-4" />
                  <span>
                    {(existingItem.uniqueIds?.length ?? 0) > 0
                      ? `${existingItem.uniqueIds?.length ?? 0} items added`
                      : "Add/Upload"}
                  </span>
                </div>
              </div>
            ) : // <input
            //   className="text-left p-1 focus:outline-none border rounded w-full"
            //   placeholder="unique"
            //   // value={}
            //   onClick={() => {}}
            // />
            null}
          </div>
          {props.item ? (
            !props.disabled && (
              <>
                <div className="flex justify-center gap-1 items-end">
                  {editable ? (
                    <>
                      <div
                        onClick={() => {
                          if (!window.confirm("Are you sure !")) return;
                          onDone();
                          setEditable(false);
                        }}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                      </div>
                      <div
                        onClick={() => {
                          setExistingItem(props.item!);
                          setEditable(false);
                        }}
                        className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiClose} className="h-4 w-4"></Icon>
                      </div>
                    </>
                  ) : (
                    <div
                      onClick={() => setEditable(true)}
                      className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                    >
                      <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                    </div>
                  )}
                </div>
              </>
            )
          ) : (
            <button
              onClick={onDone}
              className="flex gap-1 text-white text-sm bg-myPrimaryColor rounded-full px-2 py-1  "
            >
              <Icon path={mdiPlus} className="h-5 w-5 "></Icon> Add
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ItemRowV2;
