import {
  mdiAttachment,
  mdiChevronLeft,
  mdiHistory,
  mdiInformationOutline,
  mdiPrinter,
} from "@mdi/js";
import throttle from "lodash/throttle";
import Icon from "@mdi/react";
import axios from "axios";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../../context_providers/auth/authProvider";
import DrawerWidget from "../../../context_providers/modal/drawer_widget";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  ModalType,
  ModalAlignment,
  useModal,
} from "../../../context_providers/modal/modal_context";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import {
  fetchPut,
  fetchPost,
  fetchGet,
  fetchDelete,
  toQueryString,
  getHeaders,
} from "../../../service/api_client";

import { UTCToLocalDate } from "../../../utils/date_util";
import { ACTION, MODULE, file_dir } from "../../../utils/enums";
import { currencyFormat } from "../../../utils/orders_utils";

import { tax_type } from "../../accounts/models/ledger_model";
import {
  currencies,
  default_mail_detail,
} from "../../accounts/models/party_model";
import PartySearchField from "../../accounts/parties/party_search_field";
import WarehouseSearchField from "../../inventory/warehouse/warehouse_search_field";
import {
  AttachmentModel,
  auto_approval_amount,
  PoItem,
  po_approval_status,
  po_status,
  PurchaseOrderModel,
  getAprrovalLevel,
  getFileType,
  PurchaseOrderModelV2,
  PoItemV2,
  po_default_value_v2,
} from "../model/purchase_order_model";

import PoSnapSection from "./components/po_snaps";

import {
  CategoryOfExpenseOptions,
  PaymentModeOptions,
  PaymentRequestModel,
  payment_request_default,
} from "../model/payment_request_model";
import RequiredMark from "../../../ui/new_components/common/required_mark";
import PoCreateBottomSheetWidget from "./components/bottom_sheet";

import DrawerModal, {
  DrawerModalLoaction,
} from "../../../ui/new_components/common/drawer_modal";
import {
  createPaymentRequestPayment,
  getExchangeRate,
  getProcurementAttachmentPRESignedURL,
  postProcurementAttachmentS3,
} from "./service/purchase_order_repository";
import { usePurchaseOrder } from "../contexts/po-creation-context";
import KazamGSTSearchField from "./components/GSTSearchField";
import PoItemRowV2 from "./components/item_row_v2";
import {
  createPurchaseOrderRepo,
  saveDraftPurchaseOrderRepo,
} from "./service/po_service";
import { Project } from "../../inventory/models/project_model";

interface PurchaseOrderCreateScreenProps {}

const PurchaseOrderCreateScreen: FunctionComponent<
  PurchaseOrderCreateScreenProps
> = (props) => {
  const navigation = useHistory();
  const { user, isAuthorised } = useAuth();
  const { purchaseOrder, setPurchaseOrder, setDefaultProject } =
    usePurchaseOrder();
  const { id } = useParams<{ id: string }>();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order`;
  const [loading, setLoading] = useState(false);
  const [showCreatePaymentRequestModal, setShowCreatePaymentRequestModal] =
    useState(false);
  const { showToast } = useToast();
  const [project, setProject] = useState<Project>();

  const [prvData, setPrvData] = useState<PurchaseOrderModelV2>({
    ...po_default_value_v2,
    shipping_profile: {
      ...default_mail_detail,
      contact_name: user?.first_name + " " + (user?.last_name || ""),
      contact_mobile: user?.mobile || "",
      contact_email: user?.email || "",
    },
  });
  const [addNewId, setAddNewId] = useState(Math.random() * 10000);
  const { showModal } = useModal();
  const [disabled, setDisabled] = useState();
  const [percent, setPercent] = useState<number>(0);
  const [amount, setAmount] = useState<any>();
  const [invoiceAmount, setInvoiceAmount] = useState<any>();
  const [advancePaymentFileList, setAdvancePaymentFileList] = useState<File[]>(
    []
  );
  const [againstInvoiceFileList, setAgainstInvoiceFileList] = useState<File[]>(
    []
  );
  const [paymentRequestType, setPaymentRequestType] = useState("advance"); // Track selected payment type
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequestModel>({
    ...payment_request_default,
  });
  // Reset form function
  const resetForm = () => {
    setPaymentRequestType("advance");
    setPaymentRequest(payment_request_default);
    setAdvancePaymentFileList([]);
    setAgainstInvoiceFileList([]);
    setShowCreatePaymentRequestModal(false);
  };

  const handleCancelPaymentRequest = async () => {
    resetForm();
  };

  const handlePaymentAmount = (e: any) => {
    const amount = Number(e.target.value);
    if (!paymentRequest?.invoice_amount) {
      showToast({
        type: ToastType.error,
        text: "Please enter invoice amount first",
      });
      return;
    }

    setPaymentRequest({
      ...paymentRequest,
      payment_amount: amount,
    });
  };

  // Handle create button logic based on selected payment type
  const handleCreatePaymentRequest = async () => {
    console.log("Payment Request Type:", paymentRequestType);
    // we are getting paymentRequestType from default values
    if (paymentRequestType === "advance") {
      console.log("Payment Request Type Advance:", amount);
      if (amount < paymentRequest.payment_amount) {
        showToast({
          type: ToastType.error,
          text: "Amount should not be greater than total amount: " + amount,
        });
        return;
      }
      if (!paymentRequest.payment_amount || !paymentRequest.percentage) {
        return showToast({
          type: ToastType.error,
          text: "Please enter all values!",
        });
      }
      if ((paymentRequest?.pi_amount ?? 0) > 0) {
        if ((paymentRequest?.pi_amount ?? 0) < paymentRequest?.payment_amount) {
          showToast({
            type: ToastType.error,
            text: "Payment amount cannot be more than pi amount.",
          });
          return;
        }
      }
      setPaymentRequest({
        ...paymentRequest,
        type: "ADVANCE",
      });

      paymentRequest.po_no = purchaseOrder.po_no;
      paymentRequest.vendor = purchaseOrder.party?.name;
      paymentRequest.project = purchaseOrder.project;
      paymentRequest.po_date = purchaseOrder.created_at;
      paymentRequest.po_approval = purchaseOrder.approval_status;
      paymentRequest.po_id = purchaseOrder.id;
      setLoading(true);

      // Call the url list for the signed URL
      const pre_signed_url_list = await getProcurementAttachmentPRESignedURL(
        advancePaymentFileList
      );
      if (!pre_signed_url_list) {
        showToast({
          type: ToastType.error,
          text: "Error in getting signed URL",
        });
        return;
      }
      try {
        for (let i = 0; i < pre_signed_url_list.length; i++) {
          const [signed_url, file, attachment] = pre_signed_url_list[i];
          let res = await postProcurementAttachmentS3(
            signed_url,
            file,
            attachment
          );
          if (!res) {
            showToast({
              type: ToastType.error,
              text: "Error in uploading attachments",
            });
            return;
          }
        }
        let newAttachments = [
          ...pre_signed_url_list.map(([, , attachment]) => attachment),
        ];
        const updatedState = {
          ...paymentRequest,
          attachments: [...paymentRequest.attachments, ...newAttachments],
        };
        // Call the API to create the Advance Payment Request
        const create_payment_request_advance_payment_res =
          await createPaymentRequestPayment(updatedState);
        setPaymentRequest(updatedState);
        setLoading(false);
        if (create_payment_request_advance_payment_res.success) {
          showToast({
            type: ToastType.success,
            text: create_payment_request_advance_payment_res.message,
          });
          resetForm();
        } else {
          showToast({
            type: ToastType.error,
            text: create_payment_request_advance_payment_res.error,
          });
        }
      } catch (error: any) {
        console.log("Error in uploading attachments", error);
        showToast({
          type: ToastType.error,
          text: error.message,
        });
      }
      setShowCreatePaymentRequestModal(false);
      setLoading(false);
      resetForm();
    } else {
      if (
        !paymentRequest.invoice_no ||
        !paymentRequest.payment_amount ||
        !paymentRequest.invoice_date ||
        !paymentRequest.attachments
      ) {
        return showToast({
          type: ToastType.error,
          text: "Please enter all values!",
        });
      }
      if (
        invoiceAmount < paymentRequest.payment_amount ||
        (paymentRequest?.invoice_amount ?? 0) < paymentRequest?.payment_amount
      ) {
        showToast({
          type: ToastType.error,
          text:
            "Payment Amount cannot be greater than invoice amount!, available amount is: " +
            paymentRequest?.invoice_amount,
        });
        return;
      }

      setPaymentRequest({
        ...paymentRequest,
        type: "AGAINST INVOICE", // Update type to "AGAINST INVOICE"
      });

      console.log("Payment Request After Update:", paymentRequest);

      paymentRequest.po_no = purchaseOrder.po_no;
      paymentRequest.vendor = purchaseOrder.party?.name;
      paymentRequest.project = purchaseOrder.project;
      paymentRequest.po_date = purchaseOrder.created_at;
      paymentRequest.po_approval = purchaseOrder.approval_status;
      paymentRequest.po_id = purchaseOrder.id;
      console.log("paymentRequestAgainstInvoice FILES", againstInvoiceFileList);
      // Call the url list for the signed URL
      setLoading(true);
      const against_invoice_pre_signed_urls =
        await getProcurementAttachmentPRESignedURL(againstInvoiceFileList);
      if (!against_invoice_pre_signed_urls) {
        showToast({
          type: ToastType.error,
          text: "Error in getting signed URL",
        });
        return;
      }
      try {
        for (let i = 0; i < against_invoice_pre_signed_urls.length; i++) {
          const [signed_url, file, attachment] =
            against_invoice_pre_signed_urls[i];
          let res = await postProcurementAttachmentS3(
            signed_url,
            file,
            attachment
          );
          if (!res) {
            showToast({
              type: ToastType.error,
              text: "Error in uploading attachments",
            });
            return;
          }
        }

        let newAttachments = [
          ...against_invoice_pre_signed_urls.map(
            ([, , attachment]) => attachment
          ),
        ];

        const againstInvoiceUpdatedState = {
          ...paymentRequest,
          attachments: [...paymentRequest.attachments, ...newAttachments],
        };
        const create_payment_request_against_invoice_res =
          await createPaymentRequestPayment(againstInvoiceUpdatedState);
        setPaymentRequest(againstInvoiceUpdatedState);
        if (create_payment_request_against_invoice_res.success) {
          showToast({
            type: ToastType.success,
            text: create_payment_request_against_invoice_res.message,
          });
          resetForm();
        } else {
          showToast({
            type: ToastType.error,
            text: create_payment_request_against_invoice_res.error,
          });
        }
        setLoading(false);
      } catch (error: any) {
        console.log("Error in uploading attachments", error);
        showToast({
          type: ToastType.error,
          text: error.message,
        });
      }
      resetForm();
    }
  };

  useEffect(() => {
    if (disabled === undefined) {
      let tmpDisabled =
        purchaseOrder.id &&
        purchaseOrder.approval_status === po_approval_status.approved &&
        purchaseOrder.approved_by;

      if (tmpDisabled) {
        const authorised = isAuthorised({
          action: [ACTION.UPDATE, ACTION.CREATE, ACTION.READ],
          module: MODULE.purchase_order_approval,
        });
        if (authorised) {
          tmpDisabled = false;
        }
      }
      setDisabled(tmpDisabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrder]);

  const saveDraft = async () => {
    setLoading(true);

    try {
      // const formData = new FormData();
      // console.log("Purchase Order", purchaseOrder);
      // formData.append("purchaseOrder", JSON.stringify(purchaseOrder));

      // const res = await fetchPost(saveDraftUrl, formData, {
      //   "Content-Type": "multipart/form-data",
      // });

      const res = await saveDraftPurchaseOrderRepo(purchaseOrder);

      if (res.success) {
        setPurchaseOrder(res.data);
        showToast({
          type: ToastType.success,
          text: "Draft saved successfully!",
        });
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({ type: ToastType.error, text: "Failed to save draft." });
    } finally {
      setLoading(false);
      navigation.goBack();
    }
  };

  const submit = async () => {
    // if (!purchaseOrder.party || purchaseOrder.party_id === "") {
    //   return showToast({
    //     type: ToastType.error,
    //     text: "Please Select a Vendor",
    //   });
    // }
    if (
      !purchaseOrder.vendor_currency ||
      !purchaseOrder.vendor_currency_to_inr
    ) {
      return showToast({
        type: ToastType.error,
        text: "Select vendor currency and exchange rate",
      });
    }

    if (purchaseOrder.bill_to.gst_number === "") {
      return showToast({
        type: ToastType.error,
        text: "Please fill in GST number in billing details",
      });
    }

    if (!purchaseOrder.warehouse.id) {
      return showToast({
        type: ToastType.error,
        text: "Select warehouse",
      });
    }
    if (!purchaseOrder.items.length) {
      return showToast({
        type: ToastType.error,
        text: "Select items",
      });
    }
    // if (!purchaseOrder.shipping_method) {
    //   return showToast({
    //     type: ToastType.error,
    //     text: "select shipping method",
    //   });
    // }
    // if (!purchaseOrder.payment_mode) {
    //   return showToast({
    //     type: ToastType.error,
    //     text: "Select payment mode",
    //   });
    // }
    // if (!purchaseOrder.status) {
    //   return showToast({
    //     type: ToastType.error,
    //     text: "select status",
    //   });
    // }

    // This calculation should be done on Backend

    // const total = purchaseOrder.total * purchaseOrder.vendor_currency_to_inr;
    // const bal =
    //   (project?.amount || 0) -
    //   ((project?.spent || 0) -
    //     (purchaseOrder.project_id === purchaseOrder.project_id
    //       ? purchaseOrder.total
    //       : 0));
    // if (total > bal) {
    //   return showToast({
    //     type: ToastType.error,
    //     text: "Total amount should not exceed the project balance amount.",
    //   });
    // }

    if (!window.confirm("Are you sure ?")) return;

    // We shifted to item wise
    // purchaseOrder.project_id = project?.id;
    // purchaseOrder.project = project?.name;
    setLoading(true);
    const formData = new FormData();
    formData.append("purchaseOrder", JSON.stringify(purchaseOrder));
    const res = await createPurchaseOrderRepo(purchaseOrder);
    if (res.success) {
      purchaseOrder.project_id = project?.id;
      purchaseOrder.project = project?.name;
      setPurchaseOrder(res.data);
      showToast({ type: ToastType.success, text: res.message });
      navigation.goBack();
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  useEffect(() => {
    if (id) getData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrencyExchangeRate = async () => {
    try {
      setLoading(true);
      const formattedDate = new Date().toISOString().split("T")[0]; // "YYYY-MM-DD"

      const exchangeRate = await getExchangeRate({
        from: "USD",
        to: "INR",
        date: formattedDate,
      });

      if (exchangeRate !== null) {
        console.log(`Exchange Rate: 1 INR = ${exchangeRate} USD`);
      } else {
        console.log("Exchange rate could not be retrieved.");
      }

      setPurchaseOrder((o) => ({
        ...o,
        vendor_currency_to_inr: exchangeRate ?? 0,
      }));
    } catch (error) {
      console.error("Error getting exchange rate:", error);
    } finally {
      setLoading(false);
    }
  };

  const getData = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      if (!res.data.vendor_currency) {
        res.data.vendor_currency = currencies.rupee;
        res.data.vendor_currency_to_inr = 1;
      }

      if (res.data.items === undefined || res.data.items === null) {
        res.data.items = [];
      }

      setPurchaseOrder({ ...res.data });
      setPrvData({ ...res.data });
      if (res.data.items[0].project) {
        setDefaultProject(res.data.items[0].project);
      }
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };

  useEffect(() => {
    purchaseOrder.subtotal = 0;
    purchaseOrder.sgst = 0;
    purchaseOrder.cgst = 0;
    purchaseOrder.igst = 0;

    if (Array.isArray(purchaseOrder.items) && purchaseOrder.items.length > 0) {
      purchaseOrder.items.forEach((item) => {
        const itemTotal = item.unit_no * item.price;
        purchaseOrder.subtotal += itemTotal;

        let gstRate = item.item.tax_detail?.gst_rate || 0;

        const taxAmount = gstRate ? (itemTotal * gstRate) / 100 : 0;
        console.log(taxAmount, "taxAmt");
        const billToState = purchaseOrder.bill_to?.address?.state || "";
        const partyState =
          purchaseOrder.party?.mail_detail?.address?.state || "";

        const isInterStateTransaction =
          billToState !== "" &&
          partyState !== "" &&
          billToState.toLowerCase() !== partyState.toLowerCase();

        if (isInterStateTransaction) {
          if (purchaseOrder.vendor_currency.short_name !== "INR") {
            purchaseOrder.igst +=
              taxAmount / purchaseOrder.vendor_currency_to_inr;
          } else {
            purchaseOrder.igst += taxAmount;
          }
        } else {
          const sgst = taxAmount / 2.0;
          const cgst = taxAmount / 2.0;

          console.log(sgst, cgst, "data");
          if (purchaseOrder.vendor_currency.short_name !== "INR") {
            purchaseOrder.sgst += sgst / purchaseOrder.vendor_currency_to_inr;
            purchaseOrder.cgst += cgst / purchaseOrder.vendor_currency_to_inr;
          } else {
            purchaseOrder.sgst += sgst;
            purchaseOrder.cgst += cgst;
          }
        }
      });
    }

    // const taxes = purchaseOrder.taxes.map((t, i) => {
    //   // if (data.entry_type === voucherEntryType.Invoice)
    //   if (t.ledger?.tax_detail?.type === tax_type.gst) {
    //     const tax = getItemTaxAmount({
    //       entry_type: "Invoice",
    //       items: purchaseOrder.items || [],
    //       ledgerTaxDetail: t.ledger!.tax_detail!,
    //       extras: purchaseOrder.extras,
    //     });
    //     console.log({ tax });

    //     t.amount = tax || 0;
    //   }
    //   return { ...t };
    // });
    // let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
    // data.tax = tax;
    // data.taxes = taxes;
    purchaseOrder.total =
      purchaseOrder.subtotal +
      (purchaseOrder.cgst ?? 0) +
      (purchaseOrder.sgst ?? 0) +
      (purchaseOrder.igst ?? 0) +
      (purchaseOrder.paid ?? 0); //rethink about it..

    // // data.total = data.subtotal + data.tax + data.extra - data.discount;
    // // if (data.total <= auto_approval_amount) {

    // // }
    // // else

    // // if (data.total > oldTotal && data.total > auto_approval_amount) {
    // //   data.approval_required = true;
    // //   data.approval_status = po_approval_status.pending;
    // // } else {
    // //   data.approval_required = data.total > auto_approval_amount;
    // //   data.approval_status = po_approval_status.approved;
    // // }
    if (purchaseOrder.total <= auto_approval_amount) {
      // no approval required
      purchaseOrder.approval_required = false;
      purchaseOrder.approval_status = po_approval_status.approved;
    } else {
      // approval required
      if (prvData.approval_status === po_approval_status.approved) {
        // already approved
        if (purchaseOrder.total > prvData.total) {
          //new total > old total
          purchaseOrder.approval_required = true;
          purchaseOrder.approval_status = po_approval_status.pending;
        } else {
          purchaseOrder.approval_required = prvData.approval_required;
          purchaseOrder.approval_status = prvData.approval_status;
        }
      } else {
        // already not approved
        purchaseOrder.approval_required = prvData.approval_required;
        purchaseOrder.approval_status = prvData.approval_status;
      }
    }

    purchaseOrder.approval_level = getAprrovalLevel(purchaseOrder.total);
    setPurchaseOrder({ ...purchaseOrder });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    purchaseOrder.items,
    purchaseOrder.vendor_currency,
    purchaseOrder.vendor_currency_to_inr,
    purchaseOrder.bill_to,
    purchaseOrder.party,
  ]);

  useEffect(() => {
    purchaseOrder.total =
      purchaseOrder.subtotal +
      purchaseOrder.cgst -
      purchaseOrder.sgst -
      (purchaseOrder.paid ?? 0);
    // // if (data.total <= auto_approval_amount) {
    // //   data.approval_required = false;
    // //   data.approval_status = po_approval_status.approved;
    // // } else if (data.total > oldTotal) {
    // //   data.approval_required = true;
    // //   data.approval_status = po_approval_status.pending;
    // // }
    // if (data.total > oldTotal && data.total > auto_approval_amount) {
    //   data.approval_required = true;
    //   data.approval_status = po_approval_status.pending;
    // } else {
    //   data.approval_required = data.total > auto_approval_amount;
    //   data.approval_status = po_approval_status.approved;
    // }

    if (purchaseOrder.total <= auto_approval_amount) {
      // no approval required
      console.log(" no approval required");

      purchaseOrder.approval_required = false;
      purchaseOrder.approval_status = po_approval_status.approved;
    } else {
      // approval required
      console.log("approval required");

      if (prvData.approval_status === po_approval_status.approved) {
        // already approved
        console.log("already approved");

        if (purchaseOrder.total > prvData.total) {
          //new total > old total
          console.log("new total > old total");

          purchaseOrder.approval_required = true;
          purchaseOrder.approval_status = po_approval_status.pending;
        } else {
          purchaseOrder.approval_required = prvData.approval_required;
          purchaseOrder.approval_status = prvData.approval_status;
        }
      } else {
        // already not approved
        console.log("already not approved");

        purchaseOrder.approval_required = prvData.approval_required;
        purchaseOrder.approval_status = prvData.approval_status;
      }
    }
    setPurchaseOrder({ ...purchaseOrder });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    prvData,
    purchaseOrder.taxes,
    purchaseOrder.discounts,
    purchaseOrder.discounts,
  ]);

  // const [showAttachment, setShowAttachment] = useState(false);
  const history = useHistory();
  const [attachmentKey, setAttachmentKey] = useState(Math.random() * 10000);
  const [showSnaps, setShowSnaps] = useState(false);
  const existingItems = purchaseOrder.items || [];

  useEffect(() => {
    if (paymentRequestType === "advance") {
      setPaymentRequest({
        ...paymentRequest,
        type: "ADVANCE",
      });
    } else {
      setPaymentRequest({
        ...paymentRequest,
        type: "AGAINST INVOICE",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentRequestType]); // This will log every time paymentRequest changes

  const handleInvoiceAmount = throttle(async (e: any) => {
    const amount = Number(e.target.value);
    setPaymentRequest({
      ...paymentRequest,
      invoice_amount: amount,
    });
    // Trigger validation and API logic
    await getInvoiceAmount(amount);
  }, 0);

  const getInvoiceAmount = throttle(async (amount: number) => {
    try {
      // Fetch the current total percentage from the backend
      const res = await fetchGet(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/advance_payment_request/get-percentage/${purchaseOrder.po_no}`
      );
      const po_total =
        purchaseOrder?.subtotal +
          (purchaseOrder.cgst ?? 0) +
          (purchaseOrder.sgst ?? 0) +
          (purchaseOrder.igst ?? 0) || 0;

      setInvoiceAmount((po_total ?? 0) - res.totalAmount);
      if (res.success) {
        const totalAmount = res.totalAmount;
        if (po_total - totalAmount < amount) {
          showToast({
            type: ToastType.error,
            text: "Please enter correct amount",
          });
          return;
        }
        setPaymentRequest({
          ...paymentRequest,
          invoice_amount: amount,
        });
      }
    } catch (error: any) {
      console.error("Error fetching percentage:", error);
      showToast({
        type: ToastType.error,
        text: error.message || "An unexpected error occurred.",
      });
    }
  }, 0);

  const handlePercentageChange = throttle((e: any) => {
    const percentage = Number(e.target.value);

    // Update state immediately for responsive input
    setPaymentRequest((prev) => ({
      ...prev,
      payment_amount: 0,
      percentage: percentage,
    }));

    // Trigger validation and API logic
    getPaymentAmount(percentage);
  }, 0);

  const getPaymentAmount = throttle(async (percentage: number) => {
    if (percentage < 0) {
      showToast({
        type: ToastType.error,
        text: "Please enter a valid percentage.",
      });
      return;
    }

    try {
      // Fetch the current total percentage from the backend
      const res = await fetchGet(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/advance_payment_request/get-percentage/${purchaseOrder.po_no}`
      );
      if (res.success) {
        const po_total =
          purchaseOrder?.subtotal +
            (purchaseOrder.cgst ?? 0) +
            (purchaseOrder.sgst ?? 0) +
            (purchaseOrder.igst ?? 0) || 0;
        const calculatedPercentage = (res.totalAmount / (po_total || 1)) * 100;
        // const totalPercentageUsed = res.totalPercentage - calculatedPercentage || 0; // Backend response
        const availablePercentage = 100 - calculatedPercentage;
        setPercent(availablePercentage);
        if (percentage > availablePercentage) {
          showToast({
            type: ToastType.error,
            text: `Percentage exceeds available limit. Available: ${availablePercentage}%`,
          });
          return;
        }
        // const availablePercent = 100 - totalPercentageUsed;
        const calculatedAmount = (percentage * po_total) / 100;

        if (calculatedAmount > po_total) {
          showToast({
            type: ToastType.error,
            text: "Amount should not be greater than total amount.",
          });
          return;
        }

        // Update the payment amount in the state
        setPaymentRequest((prev) => ({
          ...prev,
          // percentage:availablePercent,
          payment_amount: calculatedAmount,
        }));
      } else {
        showToast({
          type: ToastType.error,
          text: res.message || "Failed to fetch percentage data.",
        });
      }
    } catch (error: any) {
      console.error("Error fetching percentage:", error);
      showToast({
        type: ToastType.error,
        text: error.message || "An unexpected error occurred.",
      });
    }
  }, 0);

  const handleAmountChange = throttle((e: any) => {
    const amount = Number(e.target.value);

    // Update state immediately for responsive input
    setPaymentRequest((prev) => ({
      ...prev,
      payment_amount: amount,
      percentage: 0,
    }));

    // Trigger validation and API logic
    getPercentage(amount);
  }, 0);

  const getPercentage = throttle(async (amount: number) => {
    if (amount < 0) {
      showToast({
        type: ToastType.error,
        text: "Amount cannot be negative.",
      });
      return;
    }

    try {
      const res = await fetchGet(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/advance_payment_request/get-percentage/${purchaseOrder.po_no}`
      );
      if (res.success) {
        const po_total =
          purchaseOrder?.subtotal +
            (purchaseOrder.cgst ?? 0) +
            (purchaseOrder.sgst ?? 0) +
            (purchaseOrder.igst ?? 0) || 0;
        const totalAmount = res.totalAmount || 0;
        console.log("totalAmount", totalAmount);
        const availableAmount = po_total - totalAmount;
        setAmount(availableAmount);

        if (amount > availableAmount) {
          showToast({
            type: ToastType.error,
            text: `Amount exceeds available limit. Available: ${availableAmount}.`,
          });
          return;
        }
        const calculatedPercentage = purchaseOrder
          ? (amount / (po_total || 1)) * 100
          : 0;
        // const calculatedPercentage = (amount / (data.total - data.paid)) * 100;
        if (calculatedPercentage > 100) {
          showToast({
            type: ToastType.error,
            text: "Calculated percentage exceeds 100%.",
          });
          return;
        }

        setPaymentRequest((prev) => ({
          ...prev,
          percentage: calculatedPercentage,
        }));
      } else {
        showToast({
          type: ToastType.error,
          text: res.message || "Failed to fetch amount data.",
        });
      }
    } catch (error: any) {
      console.error("Error fetching amount:", error);
      showToast({
        type: ToastType.error,
        text: error.message || "An unexpected error occurred.",
      });
    }
  }, 0);

  const onSelectAttchment = (d: AttachmentModel) => {
    window.open(d.url, "_blank");
  };

  useEffect(() => {
    if (purchaseOrder.vendor_currency) {
      getCurrencyExchangeRate();
    }
  }, [purchaseOrder.vendor_currency]);
  useEffect(() => {
    if (purchaseOrder.id && !project && purchaseOrder.project_id) {
      getProjectData(purchaseOrder.project_id);
    }
    console.log("Data", purchaseOrder);
  }, [purchaseOrder]);

  const getProjectData = async (id: string) => {
    setLoading(true);

    const res = await fetchGet(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/project/${id}`
    );
    if (res.success) {
      setProject({ ...res.data });
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  // const handleCreatePaymentRequest = async () => {
  //   // validate

  //   if (paymentRequest.amount < 1 || !paymentRequest.due_date) {
  //     return showToast({
  //       type: ToastType.error,
  //       text: "Please enter all values!",
  //     });
  //   }
  //   const bal = data.total - data.paid;
  //   if (paymentRequest.amount > bal) {
  //     return showToast({
  //       type: ToastType.error,
  //       text: `Amount should not be greater than ${bal}`,
  //     });
  //   }
  //   paymentRequest.po_no = data.po_no;
  //   let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/payment_request`;
  //   setLoading(true);
  //   const res = await fetchPost(url, paymentRequest);
  //   if (res.success) {
  //     setShowCreatePaymentRequestModal(false);
  //     setPaymentRequest({ ...payment_request_default });
  //     showToast({ type: ToastType.success, text: res.message });
  //   } else showToast({ type: ToastType.error, text: res.error });
  //   setLoading(false);
  // };

  // const showLog = () => {
  //   showModal({
  //     title: "Logs",
  //     type: ModalType.drawer,
  //     alignment: ModalAlignment.right,
  //     container: (id) => (
  //       <ActionHistory po_no={purchaseOrder.po_no} data={purchaseOrder} />
  //     ),
  //   });
  // };

  return (
    <>
      {showCreatePaymentRequestModal && (
        <DrawerModal
          show={showCreatePaymentRequestModal}
          title={"Create Payment Request"}
          onClose={resetForm}
          location={DrawerModalLoaction.right} // or whatever location you are using for the DrawerModal
        >
          <div className="flex flex-col gap-3 w-auto h-auto p-5">
            {/* Tabs to select payment type */}
            <div className="flex gap-4 mb-4">
              <button
                onClick={() => setPaymentRequestType("advance")}
                className={`${
                  paymentRequestType === "advance"
                    ? "bg-myPrimaryColor text-white"
                    : "bg-gray-100"
                } px-4 py-2 rounded-md`}
              >
                Advance Payment
              </button>
              <button
                onClick={() => setPaymentRequestType("invoice")}
                className={`${
                  paymentRequestType === "invoice"
                    ? "bg-myPrimaryColor text-white"
                    : "bg-gray-100"
                } px-4 py-2 rounded-md`}
              >
                Against Invoice
              </button>
            </div>

            {/* Render form based on selected payment type */}
            {paymentRequestType === "advance" && (
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-5 w-auto">
                  {/* First Row */}
                  <div className="flex flex-row gap-5 w-full">
                    <div className="flex flex-col gap-1 w-full">
                      <label htmlFor="" className="text-xs text-gray-500">
                        Percentage
                      </label>
                      <input
                        type="number"
                        className="border px-2 py-1 rounded-md w-full"
                        value={paymentRequest.percentage || ""}
                        onChange={handlePercentageChange}
                        // disabled={paymentRequestAdvance.payment_amount !== undefined}
                      />
                      <span className="font-light">
                        Available Percent is:{percent}
                      </span>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                      <label htmlFor="" className="text-xs text-gray-500">
                        Payment Amount *
                      </label>
                      <input
                        type="number"
                        className="border px-2 py-1 rounded-md w-full"
                        value={paymentRequest.payment_amount || ""}
                        onChange={handleAmountChange}
                        // disabled={paymentRequestAdvance.percentage !== undefined}
                      />
                      <span className="font-light">
                        Available amount is:{amount}
                      </span>
                    </div>
                  </div>
                  {console.log("selected date", paymentRequest.due_date)}
                  {/* Second Row */}
                  <div className="flex flex-row gap-5 w-full">
                    <div className="flex flex-col gap-1 w-full">
                      <label htmlFor="" className="text-xs text-gray-500">
                        Due Date
                      </label>
                      <input
                        type="date"
                        required
                        value={
                          paymentRequest?.due_date
                            ? moment(paymentRequest?.due_date).format(
                                "YYYY-MM-DD"
                              ) // Format to YYYY-MM-DD for input
                            : "" // Empty by default, so no date is selected initially
                        }
                        min={moment().format("YYYY-MM-DD")} // Prevent selection of past dates
                        className="w-full px-3 py-2 border rounded-md"
                        onChange={(e) => {
                          const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                          const date = selectedDate
                            ? new Date(
                                new Date(selectedDate).setHours(0, 0, 0, 0)
                              ) // Set the time to 00:00:00
                            : undefined; // If no date is selected, reset the value

                          // Update the state with the selected date
                          setPaymentRequest((pv) => ({
                            ...pv,
                            due_date: date!, // Save the selected date in state
                          }));
                        }}
                      />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                      <label htmlFor="" className="text-xs text-gray-500">
                        PI Number
                      </label>
                      <input
                        type="text"
                        className="border px-2 py-1 rounded-md w-full"
                        value={paymentRequest.pi_no}
                        onChange={(e) =>
                          setPaymentRequest({
                            ...paymentRequest,
                            pi_no: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  {/* Third Row */}
                  <div className="flex flex-row gap-5 w-full">
                    <div className="flex flex-col gap-1 w-full">
                      <label htmlFor="" className="text-xs text-gray-500">
                        PI Date
                      </label>
                      <input
                        type="date"
                        className="border px-7 py-1 rounded-md w-full"
                        value={
                          paymentRequest?.pi_date
                            ? moment(paymentRequest?.pi_date).format(
                                "YYYY-MM-DD"
                              )
                            : ""
                        }
                        onChange={(e) => {
                          const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                          const date = selectedDate
                            ? new Date(
                                new Date(selectedDate).setHours(0, 0, 0, 0)
                              ) // Convert to a date object
                            : undefined; // Reset if no date is selected

                          // Update the paymentRequest state with the selected date
                          setPaymentRequest((pv) => ({
                            ...pv,
                            pi_date: date!, // Save the selected date in state
                          }));
                        }}
                      />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                      <label htmlFor="" className="text-xs text-gray-500">
                        PI Amount
                      </label>
                      <input
                        type="number"
                        className="border px-2 py-1 rounded-md w-full"
                        value={paymentRequest.pi_amount}
                        onChange={(e) =>
                          setPaymentRequest({
                            ...paymentRequest,
                            pi_amount: +e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-1 w-full">
                  <label htmlFor="" className="text-xs text-gray-500">
                    Payment Mode
                  </label>
                  <select
                    id="payment_mode"
                    className="border px-2 py-1 rounded-md w-full"
                    value={paymentRequest.payment_mode} // Ensure default is "raw_material"
                    onChange={(e) =>
                      setPaymentRequest({
                        ...paymentRequest,
                        payment_mode: e.target.value,
                      })
                    }
                  >
                    <option value="" hidden>
                      Select
                    </option>
                    {Object.values(PaymentModeOptions).map((c, i) => (
                      <option value={c} key={i}>
                        {c}
                      </option>
                    ))}
                    {/* Add more options as needed */}
                  </select>
                </div>

                {/* Attach Files Section */}

                <div className="flex flex-col gap-1">
                  <label htmlFor="" className="text-xs text-gray-500">
                    Attach Files
                  </label>

                  <input
                    type="file"
                    multiple
                    className="border px-2 py-1 rounded-md"
                    // onChange={handleFileChange} // Handle file selection
                    onChange={(e) => {
                      if (e.target.files) {
                        const newFiles = Array.from(e.target.files);
                        console.log(newFiles);
                        setAdvancePaymentFileList((prevFiles) => [
                          ...prevFiles,
                          ...newFiles,
                        ]);
                        console.log(
                          "advance payment fileList",
                          advancePaymentFileList
                        );
                      }
                    }}
                  />
                  <div className="w-full mt-2 mx-auto">
                    {advancePaymentFileList.length > 0 &&
                      advancePaymentFileList.map(
                        (file: File, index: number) => (
                          <ul className="py-2 m-2 flex justify-around rounded-md text-black border-2">
                            <div className="">
                              <li
                                key={index}
                                className="flex items-center gap-2"
                              >
                                {file ? (
                                  // Check if the file has a URL (for image/PDF preview)
                                  file.type === "image" ? (
                                    <img
                                      src={file.name}
                                      alt={file.name}
                                      className="w-16 h-16 object-cover"
                                    />
                                  ) : file.type === "pdf" ? (
                                    <embed
                                      src={file.name}
                                      type="application/pdf"
                                      className="w-16 h-16"
                                    />
                                  ) : (
                                    <span>{file.name}</span> // For other file types, display file name
                                  )
                                ) : (
                                  // If file doesn't have a URL, just display file name
                                  <span>{"No file selected"}</span>
                                )}
                              </li>
                            </div>
                            <div>
                              <button
                                type="button"
                                className="text-red-500"
                                onClick={() => {
                                  setAdvancePaymentFileList((prevFiles) =>
                                    prevFiles.filter(
                                      (_, currIndex) => currIndex !== index
                                    )
                                  );
                                }} // Remove the attachment
                              >
                                Remove
                              </button>
                            </div>
                          </ul>
                        )
                      )}
                  </div>
                  {/* Display attached files */}
                  {/* <div className="mt-2 mx-auto">
                    {paymentRequestAdvance.attachments.length > 0 && (
                      <ul>
                        {paymentRequestAdvance.attachments.map(
                          (file: any, index: number) => (
                            <li key={index} className="flex items-center gap-2">
                              {file?.url ? (
                                // Check if the file has a URL (for image/PDF preview)
                                file.type === "image" ? (
                                  <img
                                    src={file.url}
                                    alt={file.name}
                                    className="w-16 h-16 object-cover"
                                  />
                                ) : file.type === "pdf" ? (
                                  <embed
                                    src={file.url}
                                    type="application/pdf"
                                    className="w-16 h-16"
                                  />
                                ) : (
                                  <span>{file.name}</span> // For other file types, display file name
                                )
                              ) : (
                                // If file doesn't have a URL, just display file name
                                <span>{file?.name}</span>
                              )}

                              
                              <button
                                type="button"
                                className="text-red-500"
                                onClick={() => handleRemoveAttachment(index)} // Remove the attachment
                              >
                                Remove
                              </button>
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </div> */}
                </div>

                {/* Additional Notes Text Area */}
                <div className="flex flex-col gap-1">
                  <label htmlFor="" className="text-xs text-gray-500">
                    Additional Notes
                  </label>
                  <textarea
                    className="border px-2 py-1 rounded-md"
                    value={paymentRequest.notes}
                    onChange={(e) =>
                      setPaymentRequest({
                        ...paymentRequest,
                        notes: e.target.value,
                      })
                    }
                    placeholder="Enter any additional notes here"
                  />
                </div>
                <div className="flex flex-row gap-4 justify-between my-5">
                  <div className="align-center justify-center flex">
                    <button
                      onClick={handleCancelPaymentRequest}
                      className="bg-white px-24 py-2 rounded-md text-black ml-auto border-2"
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="bg-red-400">
                    <button
                      onClick={handleCreatePaymentRequest}
                      className="bg-myPrimaryColor px-24 py-2 rounded-md text-white ml-auto border-2"
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            )}

            {paymentRequestType === "invoice" && (
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-5 w-auto">
                  {/* First Row */}
                  <div className="flex flex-row gap-5 w-full">
                    <div className="flex flex-col gap-1 w-full">
                      <label htmlFor="" className="text-xs text-gray-500">
                        Invoice Number *
                      </label>
                      <input
                        type="text"
                        className="border px-2 py-1 rounded-md"
                        value={paymentRequest.invoice_no}
                        onChange={(e) =>
                          setPaymentRequest({
                            ...paymentRequest,
                            invoice_no: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                      <label htmlFor="" className="text-xs text-gray-500">
                        Invoice Date *
                      </label>
                      <input
                        type="date"
                        className="border px-7 py-1 rounded-md w-full"
                        value={
                          paymentRequest?.invoice_date
                            ? moment(paymentRequest?.invoice_date).format(
                                "YYYY-MM-DD"
                              )
                            : ""
                        }
                        onChange={(e) => {
                          const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                          const date = selectedDate
                            ? new Date(
                                new Date(selectedDate).setHours(0, 0, 0, 0)
                              ) // Convert to a date object
                            : undefined; // Reset if no date is selected

                          // Update the paymentRequest state with the selected date
                          setPaymentRequest((pv) => ({
                            ...pv,
                            invoice_date: date!, // Save the selected date in state
                          }));
                        }}
                      />
                    </div>
                  </div>

                  {/* Second Row */}
                  <div className="flex flex-row gap-5 w-full">
                    <div className="flex flex-col gap-1 w-full">
                      <label htmlFor="" className="text-xs text-gray-500">
                        Invoice Amount *
                      </label>
                      {/* <input
                        type="number"
                        placeholder="INR 0"
                        className="border px-2 py-1 rounded-md"
                        value={paymentRequestAgainstInvoice.invoice_amount}
                        onChange={(e) =>
                          setPaymentRequestAgainstInvoice({
                            ...paymentRequestAgainstInvoice,
                            invoice_amount: +e.target.value,
                          })
                        }
                      /> */}
                      <input
                        type="number"
                        className="border px-2 py-1 rounded-md w-full"
                        value={paymentRequest.invoice_amount || ""}
                        onChange={handleInvoiceAmount}
                        // disabled={paymentRequestAdvance.percentage !== undefined}
                      />
                      <span className="font-light">
                        Available amount is:{invoiceAmount}
                      </span>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                      <label htmlFor="" className="text-xs text-gray-500">
                        Payment Amount *
                      </label>
                      <input
                        type="number"
                        placeholder="INR 0"
                        className="border px-2 py-1 rounded-md w-full"
                        value={paymentRequest.payment_amount || ""}
                        onChange={handlePaymentAmount}
                        // onChange={(e) =>
                        //   setPaymentRequestAgainstInvoice({
                        //     ...paymentRequestAgainstInvoice,
                        //     payment_amount: +e.target.value,
                        //   })
                        // }
                      />
                    </div>
                  </div>

                  {/* Third Row */}
                  <div className="flex flex-row gap-5 w-full">
                    <div className="flex flex-col gap-1 w-full">
                      <label htmlFor="" className="text-xs text-gray-500">
                        Category of Expense
                      </label>
                      <select
                        id="category_of_expence"
                        className="border px-2 py-1 rounded-md w-full"
                        value={paymentRequest.category_of_expence} // Ensure default is "raw_material"
                        onChange={(e) =>
                          setPaymentRequest({
                            ...paymentRequest,
                            category_of_expence: e.target.value,
                          })
                        }
                      >
                        <option value="" hidden>
                          Select
                        </option>
                        {Object.values(CategoryOfExpenseOptions).map((c, i) => (
                          <option value={c} key={i}>
                            {c}
                          </option>
                        ))}
                        {/* Add more options as needed */}
                      </select>
                    </div>

                    <div className="flex flex-col gap-1 w-full">
                      <label htmlFor="" className="text-xs text-gray-500">
                        No. of Installations
                      </label>
                      <input
                        type="number"
                        className="border px-2 py-1 rounded-md w-full"
                        value={paymentRequest.no_of_intallations}
                        onChange={(e) =>
                          setPaymentRequest({
                            ...paymentRequest,
                            no_of_intallations: +e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="flex flex-row gap-5 w-full">
                    <div className="flex flex-col gap-1 w-full">
                      <label htmlFor="" className="text-xs text-gray-500">
                        Due Date
                      </label>
                      <input
                        type="date"
                        name=""
                        id="dueDateInvoice"
                        value={
                          paymentRequest?.due_date
                            ? moment(paymentRequest?.due_date).format(
                                "YYYY-MM-DD"
                              )
                            : ""
                        }
                        min={moment().format("YYYY-MM-DD")} // Prevent selection of past dates
                        className="w-full px-3 py-2 border rounded-md"
                        onChange={(e) => {
                          const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                          const date = selectedDate
                            ? new Date(
                                new Date(selectedDate).setHours(0, 0, 0, 0)
                              ) // Convert to a date object
                            : undefined; // Reset if no date is selected

                          // Update the paymentRequest state with the selected date
                          setPaymentRequest((pv) => ({
                            ...pv,
                            due_date: date!, // Save the selected date in state
                          }));
                        }}
                      />
                    </div>

                    <div className="flex flex-col gap-1 w-full">
                      <label htmlFor="" className="text-xs text-gray-500">
                        Payment Mode
                      </label>
                      <select
                        id="payment_mode"
                        className="border px-2 py-1 rounded-md w-full"
                        value={paymentRequest.payment_mode} // Ensure default is "raw_material"
                        onChange={(e) =>
                          setPaymentRequest({
                            ...paymentRequest,
                            payment_mode: e.target.value,
                          })
                        }
                      >
                        <option value="" hidden>
                          Select
                        </option>
                        {Object.values(PaymentModeOptions).map((c, i) => (
                          <option value={c} key={i}>
                            {c}
                          </option>
                        ))}
                        {/* Add more options as needed */}
                      </select>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className="text-xs text-gray-500">
                      Customer Location
                    </label>
                    <input
                      type="text"
                      placeholder="Write address here.."
                      className="border px-2 py-1 rounded-md w-full"
                      value={paymentRequest.customer_location}
                      onChange={(e) =>
                        setPaymentRequest({
                          ...paymentRequest,
                          customer_location: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                {/* Attach Files Section */}
                <div className="flex flex-col gap-1">
                  <label htmlFor="" className="text-xs text-gray-500">
                    Attach Files *
                  </label>
                  <input
                    type="file"
                    multiple
                    className="border px-2 py-1 rounded-md"
                    onChange={(e) => {
                      if (e.target.files) {
                        const newFiles = Array.from(e.target.files);
                        console.log(newFiles);
                        setAgainstInvoiceFileList((prevFiles) => [
                          ...prevFiles,
                          ...newFiles,
                        ]);
                        console.log(
                          "against invoice fileList",
                          againstInvoiceFileList
                        );
                      }
                    }}
                  />
                  {/* Display attached files */}
                  <div className="w-full mt-2 mx-auto">
                    {againstInvoiceFileList.length > 0 &&
                      againstInvoiceFileList.map(
                        (file: File, index: number) => (
                          <ul className="py-2 flex justify-around rounded-md text-black border-2">
                            <div className="">
                              <li
                                key={index}
                                className="flex items-center gap-2"
                              >
                                {file ? (
                                  // Check if the file has a URL (for image/PDF preview)
                                  file.type === "image" ? (
                                    <img
                                      src={file.name}
                                      alt={file.name}
                                      className="w-16 h-16 object-cover"
                                    />
                                  ) : file.type === "pdf" ? (
                                    <embed
                                      src={file.name}
                                      type="application/pdf"
                                      className="w-16 h-16"
                                    />
                                  ) : (
                                    <span>{file.name}</span> // For other file types, display file name
                                  )
                                ) : (
                                  // If file doesn't have a URL, just display file name
                                  <span>{"No file selected"}</span>
                                )}
                              </li>
                            </div>
                            <div>
                              <button
                                type="button"
                                className="text-red-500"
                                onClick={() => {
                                  setAgainstInvoiceFileList((prevFiles) =>
                                    prevFiles.filter(
                                      (_, currIndex) => currIndex !== index
                                    )
                                  );
                                }} // Remove the attachment
                              >
                                Remove
                              </button>
                            </div>
                          </ul>
                        )
                      )}
                  </div>
                </div>

                {/* Additional Notes Text Area */}
                <div className="flex flex-col gap-1">
                  <label htmlFor="" className="text-xs text-gray-500">
                    Additional Notes
                  </label>
                  <textarea
                    className="border px-2 py-1 rounded-md"
                    value={paymentRequest.notes}
                    onChange={(e) =>
                      setPaymentRequest({
                        ...paymentRequest,
                        notes: e.target.value,
                      })
                    }
                    placeholder="Enter any additional notes here"
                  />
                </div>
                <div className="flex flex-row gap-4 justify-between my-5">
                  <div className="align-center justify-center flex">
                    <button
                      onClick={handleCancelPaymentRequest}
                      className="bg-white px-24 py-2 rounded-md text-black ml-auto border-2"
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="bg-red-400">
                    <button
                      onClick={handleCreatePaymentRequest}
                      className="bg-myPrimaryColor px-24 py-2 rounded-md text-white ml-auto border-2"
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            )}
            {/* <div className="flex flex-row gap-4 justify-between mt-5">
              <div className="align-center justify-center flex">
                <button
                  onClick={handleCancelPaymentRequest}
                  className="bg-white px-24 py-2 rounded-md text-black ml-auto border-2"
                >
                  Cancel
                </button>
              </div>
              <div className="bg-red-400">
                <button
                  onClick={handleCreatePaymentRequest}
                  className="bg-myPrimaryColor px-24 py-2 rounded-md text-white ml-auto border-2"
                >
                  Create
                </button>
              </div>
            </div> */}
          </div>
        </DrawerModal>
      )}
      {showSnaps && purchaseOrder.id && (
        <DrawerWidget
          data={{
            id: 1,
            title: "Purchase order prints",
            type: ModalType.drawer,
            alignment: ModalAlignment.right,
            container: <PoSnapSection po_no={purchaseOrder.po_no} />,
          }}
          onClose={function (): void {
            setShowSnaps(false);
          }}
        ></DrawerWidget>
      )}

      <div className="relative bg-myBgcolor h-screen px-2 md:px-5  overflow-auto">
        <div className="flex items-center justify-between py-2 sticky top-0 bg-myBgcolor">
          <div className="flex flex-row">
            <button
              className="  flex  items-center p-2  hover:text-gray-700  font-bold text-lg"
              onClick={() => {
                history.goBack();
              }}
            >
              <Icon path={mdiChevronLeft} className="h-6 w-6 font-bold"></Icon>{" "}
              Back
            </button>
            <div className="text-xl font-bold border-l-4 border-myPrimaryColor p-2">
              Purchase Order{" "}
              <span className="text-sm">#{purchaseOrder.po_no}</span>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex gap-2 items-center">
              <label htmlFor="" className="text-sm font-semibold text-gray-500">
                Date
              </label>
              <input
                type="date"
                className="focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
                value={
                  purchaseOrder.date
                    ? moment(purchaseOrder.date).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  const date = UTCToLocalDate(e.target.value);
                  if (date) {
                    setPurchaseOrder((prev: PurchaseOrderModelV2) => ({
                      ...prev,
                      date,
                    }));
                  }
                }}
              />
            </div>
            {purchaseOrder.id && (
              <button
                title="Logs"
                // to={`${window.document.location.pathname}/log`}
                onClick={() => {
                  // showLog()
                }}
                className="mx-2 rounded-full p-2 hover:scale-105 transform duration-150    bg-myPrimaryColor text-white   shadow cursor-pointer flex items-center justify-center"
              >
                <Icon path={mdiHistory} className="h-6 w-6  "></Icon>
              </button>
            )}
          </div>
        </div>
        {purchaseOrder.approval_status === po_approval_status.rejected &&
          purchaseOrder.commets.length > 0 && (
            <p>
              <b>Reason of rejection :</b>
              {purchaseOrder.commets[purchaseOrder.commets.length - 1].comment}
            </p>
          )}

        <div className="bg-white rounded-lg px-3 py-5 ">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 ">
            <div className="">
              <div className="">
                <label htmlFor="" className="text-sm font-semibold  ">
                  Vendor <RequiredMark />
                </label>{" "}
                <PartySearchField
                  // show_add={true}
                  disabled={disabled}
                  placeholder="Vendor"
                  value={purchaseOrder.party ? purchaseOrder.party : undefined}
                  onSelect={(d) => {
                    setPurchaseOrder((o: PurchaseOrderModelV2) => {
                      const t = {
                        ...o,
                        party_id: d.id,
                        party: d,

                        party_mail_detail: d.mail_detail,
                      };

                      if (d.currency) {
                        t.vendor_currency = d.currency;
                      }
                      return t;
                    });
                  }}
                />
                <div className="">
                  <label className="">Vendor Registered Office</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Enter Registerd Address"
                    value={purchaseOrder.party.mail_detail.address.line1}
                    onChange={(e) => {
                      setPurchaseOrder((o) => ({
                        ...o,
                        party: {
                          ...o.party,
                          mail_detail: {
                            ...o.party.mail_detail,
                            address: {
                              ...o.party.mail_detail.address,
                              line1: e.target.value,
                            },
                          },
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">Line 2</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Enter Line 2"
                    value={purchaseOrder.party.mail_detail.address.line2}
                    onChange={(e) => {
                      setPurchaseOrder((o) => ({
                        ...o,
                        party: {
                          ...o.party,
                          mail_detail: {
                            ...o.party.mail_detail,
                            address: {
                              ...o.party.mail_detail.address,
                              line2: e.target.value,
                            },
                          },
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">Country</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Enter Country"
                    value={purchaseOrder.party.mail_detail.address.country}
                    onChange={(e) => {
                      setPurchaseOrder((o) => ({
                        ...o,
                        party: {
                          ...o.party,
                          mail_detail: {
                            ...o.party.mail_detail,
                            address: {
                              ...o.party.mail_detail.address,
                              country: e.target.value,
                            },
                          },
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="">
                <label className="">State</label>
                <input
                  disabled={true}
                  type="text"
                  placeholder="Enter State"
                  value={purchaseOrder.party.mail_detail.address.state}
                  onChange={(e) => {
                    setPurchaseOrder((o) => ({
                      ...o,
                      party: {
                        ...o.party,
                        mail_detail: {
                          ...o.party.mail_detail,
                          address: {
                            ...o.party.mail_detail.address,
                            state: e.target.value,
                          },
                        },
                      },
                    }));
                  }}
                  className="p-1 focus:outline-none border rounded w-full "
                />
              </div>
              <div className="">
                <label className="">City</label>
                <input
                  disabled={true}
                  type="text"
                  placeholder="Enter City"
                  value={purchaseOrder.party.mail_detail.address.city}
                  onChange={(e) => {
                    setPurchaseOrder((o) => ({
                      ...o,
                      party: {
                        ...o.party,
                        mail_detail: {
                          ...o.party.mail_detail,
                          address: {
                            ...o.party.mail_detail.address,
                            city: e.target.value,
                          },
                        },
                      },
                    }));
                  }}
                  className="p-1 focus:outline-none border rounded w-full "
                />
              </div>
              <div className="">
                <label className="">Pincode</label>
                <input
                  disabled={true}
                  type="text"
                  placeholder="Enter Pincode"
                  value={purchaseOrder.party.mail_detail.address.pincode}
                  onChange={(e) => {
                    setPurchaseOrder((o) => ({
                      ...o,
                      party: {
                        ...o.party,
                        mail_detail: {
                          ...o.party.mail_detail,
                          address: {
                            ...o.party.mail_detail.address,
                            pincode: e.target.value,
                          },
                        },
                      },
                    }));
                  }}
                  className="p-1 focus:outline-none border rounded w-full "
                />
              </div>
            </div>
            <div>
              <div className="">
                <label className="">GST Reg</label>
                <input
                  disabled={true}
                  type="text"
                  placeholder="Enter GST Reg"
                  value={purchaseOrder.party.tax_detail.reg_type ?? "--"}
                  onChange={(e) => {
                    setPurchaseOrder((o) => ({
                      ...o,
                      party: {
                        ...o.party,
                        tax_detail: {
                          ...o.party.tax_detail,
                          // address: {
                          //   ...o.party.mail_detail.address,
                          //   pincode: e.target.value,
                          // },
                          // reg_type: e.target.value as Taxt,
                        },
                      },
                    }));
                  }}
                  className="p-1 focus:outline-none border rounded w-full "
                />
              </div>
              <div className="">
                <label className="">GST No</label>
                <input
                  disabled={true}
                  type="text"
                  placeholder="Enter GST Reg"
                  value={purchaseOrder.party.tax_detail.gst_no[0] ?? "--"}
                  onChange={(e) => {}}
                  className="p-1 focus:outline-none border rounded w-full "
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-10">
          {" "}
          <div className="bg-white rounded-lg px-3 py-5 mt-2">
            <h1>Bill To</h1>
            <div className="grid grid-cols-2 text-sm m-2 gap-1">
              <div>
                <div>
                  <label htmlFor="" className="text-sm font-semibold  ">
                    Name
                  </label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Name"
                    value={purchaseOrder.bill_to?.name ?? "--"}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">Address</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Enter Registerd Address"
                    value={purchaseOrder.bill_to?.address.line1 ?? "--"}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">Line 2</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Enter Line 2"
                    value={purchaseOrder.bill_to?.address.line2 ?? "--"}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">Country</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Enter Country"
                    value={purchaseOrder.bill_to?.address.country ?? "--"}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
              </div>
              <div>
                {" "}
                <div className="">
                  <label className="">GST No</label>
                  <KazamGSTSearchField
                    disabled={disabled}
                    placeholder={"GST Number"}
                    value={
                      purchaseOrder.bill_to?.id
                        ? purchaseOrder.bill_to
                        : undefined
                    }
                    onSelect={(d) => {
                      setPurchaseOrder((prev: PurchaseOrderModelV2) => ({
                        ...prev,
                        bill_to: d,
                      }));
                    }}
                  />
                </div>
                <div className="">
                  <label className="">State</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="State"
                    value={purchaseOrder.bill_to?.address.state ?? "--"}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">City</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="City"
                    value={purchaseOrder.bill_to?.address.city ?? "--"}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">Pincode</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Pincode"
                    value={purchaseOrder.bill_to?.address.pincode ?? "--"}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg px-3 py-5 mt-2">
            <h1>Shipping Profile</h1>
            <div className="grid grid-cols-2 text-sm m-2 gap-1">
              <div>
                {" "}
                <label htmlFor="" className="text-sm font-semibold  ">
                  Ship to
                </label>
                <WarehouseSearchField
                  // type={WAREHOUSE_TYPE.internal}
                  disabled={disabled}
                  placeholder="Warehouse"
                  value={
                    purchaseOrder.warehouse.id
                      ? purchaseOrder.warehouse
                      : undefined
                  }
                  onSelect={(d) => {
                    setPurchaseOrder((prev: PurchaseOrderModelV2) => ({
                      ...prev,
                      warehouse: d,
                      warehouse_id: d.id,
                      shipping_profile: {
                        ...prev.shipping_profile, // Ensure we keep existing data
                        contact_name: d.spoc?.contact_name ?? "--",
                        contact_mobile: d.spoc?.contact_mobile ?? "--",
                        contact_email: d.spoc?.contact_email ?? "--",

                        address: d.address!,
                      },
                    }));
                  }}
                />
                <div className="">
                  <label className="">Address</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Address"
                    value={purchaseOrder.shipping_profile.address.line1}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">Line 2</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Line 2"
                    value={purchaseOrder.shipping_profile.address.line2}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">Country</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Country"
                    value={purchaseOrder.shipping_profile.address.country}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
              </div>
              <div>
                {" "}
                <div className="">
                  <label className="">State</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="State"
                    value={purchaseOrder.shipping_profile.address.state}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">City</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="City"
                    value={purchaseOrder.shipping_profile.address.city}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">Pincode</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Pincode"
                    value={purchaseOrder.shipping_profile.address.pincode}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/3">
          <div className="">Currency</div>
          <div className="">
            <select
              disabled={disabled}
              required
              className="p-1 focus:outline-none border rounded  w-full"
              id="currency"
              name="currency"
              value={
                purchaseOrder.vendor_currency &&
                JSON.stringify(purchaseOrder.vendor_currency)
              }
              onChange={(e) => {
                setPurchaseOrder((o: PurchaseOrderModelV2) => {
                  const updatedOrder = {
                    ...o,
                    vendor_currency: JSON.parse(e.target.value),
                  };
                  return updatedOrder;
                });
              }}
            >
              <option value="" hidden>
                select
              </option>
              {Object.values(currencies).map((currency: any) => (
                <option value={JSON.stringify(currency)}>
                  {currency.formal_name}({currency.symbol})
                </option>
              ))}
            </select>
          </div>
          <div className="">Currency Exchange rate({"\u20b9"})</div>
          <div className="">
            <input
              disabled={disabled}
              type="number"
              placeholder="Exchange rate of inr"
              value={purchaseOrder.vendor_currency_to_inr}
              onChange={(e) => {
                setPurchaseOrder((o: PurchaseOrderModelV2) => ({
                  ...o,
                  vendor_currency_to_inr: Number(e.target.value),
                }));
              }}
              className="p-1 focus:outline-none border rounded  w-full"
            />
            <div className="flex gap-1 text-gray-500 text-xs mt-2 items-center">
              <Icon path={mdiInformationOutline} size={0.8}></Icon>
              INR Per {purchaseOrder.vendor_currency.short_name} Eg: INR 70 =
              USD 1 then enter 70
            </div>{" "}
          </div>
        </div>
        <div className="my-5">
          <h3 className="text-sm font-bold">Items</h3>
          <div
            className="bg-white rounded flex flex-col gap-1 border   "
            // style={{ height: "75vh" }}
          >
            <div className="grid grid-cols-12 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
              <div className="col-span-1">Item Code</div>
              <div className="col-span-1">Item</div>
              <div className="col-span-1">Project Name</div>
              <div className="col-span-1">HSN/SAC</div>
              <div className="col-span-1">Unit</div>
              <div className="col-span-1">Qty</div>
              <div className="col-span-1">Price</div>
              <div className="col-span-1">CGST</div>
              <div className="col-span-1">SGST</div>
              <div className="col-span-1">IGST</div>
              <div className="col-span-1">Amount</div>
              <div className="col-span-1">Actions</div>
            </div>

            <div className="flex flex-col gap-1 px-1  text-sm overflow-auto h-72">
              {purchaseOrder.items.map((item, i) => {
                return (
                  <PoItemRowV2
                    currency={purchaseOrder.vendor_currency}
                    disabled={disabled || false}
                    key={item.id}
                    data={item}
                    onDelete={(d) => {
                      setPurchaseOrder((o) => ({
                        ...o,
                        items: [...(o.items || [])].filter(
                          (i) => i.id !== d.id
                        ),
                      }));
                    }}
                    onSubmit={function (data: PoItemV2): void {
                      setPurchaseOrder((o: PurchaseOrderModelV2) => ({
                        ...o,
                        items: [
                          ...(o.items || []).map((it) => {
                            if (it.id === data.id) return data;
                            return it;
                          }),
                        ],
                      }));
                    }}
                    edit={false}
                    existingItems={existingItems}
                  />
                );
              })}
              {!disabled && (
                <PoItemRowV2
                  currency={purchaseOrder.vendor_currency}
                  key={addNewId}
                  onSubmit={function (purchaseOrder: PoItemV2): void {
                    purchaseOrder.id = (Math.random() * 1000).toString();
                    setPurchaseOrder((o: PurchaseOrderModelV2) => ({
                      ...o,
                      items: [...(o.items || []), purchaseOrder],
                    }));
                    setAddNewId(Math.random() * 1000);
                  }}
                  edit={true}
                  disabled={false}
                  existingItems={existingItems}
                />
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 md:divide-x-2  text-sm  bg-white rounded-lg p-3  ">
          <div className=" flex flex-col gap-1 md:pr-5 ">
            <div className="grid grid-cols-1  md:grid-cols-4   dark: items-center">
              <label htmlFor="delivery_date">Delivery Date *</label>
              <input
                // disabled={disabled}
                value={
                  purchaseOrder.delivery_date
                    ? moment(purchaseOrder.delivery_date).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  setPurchaseOrder((o: any) => ({
                    ...o,
                    delivery_date: UTCToLocalDate(e.target.value)!,
                  }));
                }}
                type="date"
                name=""
                id="delivery_date"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              ></input>
            </div>
            <div className="grid grid-cols-1  md:grid-cols-4  items-start">
              <label htmlFor="ship_term">Delivery terms</label>
              <textarea
                disabled={disabled}
                rows={3}
                value={purchaseOrder.ship_term}
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/[\n\r]/g, ""); // Remove newlines
                  setPurchaseOrder((o: any) => ({
                    ...o,
                    ship_term: sanitizedValue,
                  }));
                }}
                name=""
                id="ship_term"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              ></textarea>
            </div>
            <div className="grid grid-cols-1  md:grid-cols-4  items-start">
              <label htmlFor="payment_term">Payment terms</label>
              <textarea
                // disabled={disabled}
                rows={3}
                value={purchaseOrder.payment_term}
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/[\n\r]/g, ""); // Remove newlines
                  setPurchaseOrder((o: any) => ({
                    ...o,
                    payment_term: sanitizedValue,
                  }));
                }}
                name=""
                id="payment_term"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              ></textarea>
            </div>
          </div>

          <div className=" flex flex-col gap-1 md:pl-5 ">
            <div className="flex justify-between">
              <div className="">Subtotal</div>
              <div className="">
                {currencyFormat(
                  purchaseOrder.subtotal,
                  purchaseOrder.vendor_currency.short_name
                )}
              </div>
            </div>{" "}
            <div className="flex justify-between ">
              <div className="flex  gap-2 items-end ">
                <label htmlFor="tax_desc">CGST </label>{" "}
              </div>

              <div className="">
                +{" "}
                {currencyFormat(
                  purchaseOrder.cgst,
                  purchaseOrder.vendor_currency.short_name
                )}
              </div>
            </div>
            <div className="flex justify-between ">
              <div className="flex  gap-2 items-end ">
                <label htmlFor="tax_desc">SGST </label>{" "}
              </div>

              <div className="">
                +{" "}
                {currencyFormat(
                  purchaseOrder.sgst,
                  purchaseOrder.vendor_currency.short_name
                )}
              </div>
            </div>
            <div className="flex justify-between ">
              <div className="flex  gap-2 items-end ">
                <label htmlFor="tax_desc">IGST</label>{" "}
              </div>

              <div className="">
                +{" "}
                {currencyFormat(
                  purchaseOrder.igst,
                  purchaseOrder.vendor_currency.short_name
                )}
              </div>
            </div>
            <hr />
            <div className="flex justify-between font-bold ">
              <p>Total</p>{" "}
              <div className="">
                {currencyFormat(
                  purchaseOrder.subtotal +
                    (purchaseOrder?.cgst +
                      purchaseOrder?.sgst +
                      purchaseOrder?.igst),
                  purchaseOrder.vendor_currency.short_name
                )}
              </div>
            </div>
            <hr />
            {/* <div className="flex justify-between font-bold ">
              <p>Paid</p>{" "}
              <div className="">
                {currencyFormat(
                  purchaseOrder.paid,
                  purchaseOrder.vendor_currency.short_name
                )}
              </div>
            </div> */}
            <hr />
            {/* <div className="flex justify-between font-bold ">
              <p>Balance</p>{" "}
              <div className="">
                {currencyFormat(
                  purchaseOrder.total,
                  purchaseOrder.vendor_currency.short_name
                )}
              </div>
            </div> */}
            {purchaseOrder.vendor_currency.short_name !==
              currencies.rupee.short_name && (
              <div className="flex justify-between font-bold ">
                <p>Total in INR</p>{" "}
                <div className="">
                  {currencyFormat(
                    purchaseOrder.total * purchaseOrder.vendor_currency_to_inr
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="sticky bottom-0 right-0 mt-3 w-full rounded-t-md bg-white shadow-sm py-3 px-5 border">
          <h3 className="text-sm font-semibold">Approval status</h3>
          <PoCreateBottomSheetWidget
            data={purchaseOrder}
            onSubmit={submit}
            onSaveDraft={saveDraft}
          />
        </div>
      </div>
      {id && (
        <div className="fixed flex flex-col gap-2 bottom-3 right-3">
          {purchaseOrder.approval_level ===
            purchaseOrder.current_approval_level &&
            (purchaseOrder.current_approval_count ?? 0) >=
              (purchaseOrder.current_approval_required_count ?? 0) && (
              <button
                title="print"
                // to={`${window.document.location.pathname}/comment`}
                onClick={() => setShowSnaps(true)}
                className="  rounded-full p-2  hover:scale-105 transform duration-150 bg-green-400 text-white   shadow cursor-pointer flex items-center justify-center"
              >
                <Icon
                  path={mdiPrinter}
                  size={1}
                  className="hover:scale-105 transform  "
                ></Icon>
              </button>
            )}
        </div>
      )}
      {loading && <LoadingWidget />}
    </>
  );
};

export default PurchaseOrderCreateScreen;
