import { mdiClose, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import { useOrderCreateCtx } from "../../../../../context_providers/order_create_context";
import { useOrderDetailCtx } from "../../../../../context_providers/order_detail_context";
import { OrderExtraModal } from "../../../../../service/models/orders/order_excess_model";
import OrderItemModel, {
  OrderItemModelV2,
} from "../../../../../service/models/orders/order_item_model";

import { MODULE, ACTION } from "../../../../../utils/enums";

import ExtraRowWidgetV2 from "./extra_row_v2";

interface ExtraSectionWidgetProps {}

const ExtraSectionWidget: FunctionComponent<ExtraSectionWidgetProps> = () => {
  const { user, isAuthorised, logout } = useAuth();

  const { order, setOrder, onExtraAdded, onExtraDeleted, onExtraUpdated } =
    useOrderDetailCtx();

  const [addNew, setAddNew] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [formId, setFormId] = useState(0);
  useEffect(() => {
    setDisabled(
      !isAuthorised({ module: MODULE.order, action: [ACTION.UPDATE] })
    );
  }, []);

  return (
    <>
      <div id="extra-section" className="flex justify-between items-end my-1">
        <h3 className="font-semibold text-gray-500">Extras</h3>
        <div className="flex justify-end   text-sm items-center ">
          {addNew ? (
            <div className="flex gap-2">
              <div
                onClick={() => {
                  setAddNew(false);
                }}
                className="flex gap-1 items-center cursor-pointer rounded p-1 bg-red-100 hover:scale-105 duration-200 hover:text-red-600 hover:bg-red-200 text-red-400  transform"
              >
                <Icon path={mdiClose} className="h-4 w-4"></Icon>Cancel
              </div>
            </div>
          ) : (
            !disabled && (
              <div
                onClick={() => {
                  setAddNew(true);
                }}
                className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
              >
                <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
              </div>
            )
          )}
        </div>
      </div>
      <div className="bg-white rounded-md shadow p-3 my-2 text-sm">
        <div className="grid grid-cols-4 gap-3 ">
          <h4 className="font-semibold">
            <span className="text-base text-red-500">*</span>Item Code
          </h4>
          <h4 className="font-semibold">
            <span className="text-base text-red-500">*</span>Item
          </h4>

          <h4 className="font-semibold ">
            <span className="text-base text-red-500">*</span>Unit
          </h4>
          <h4 className="font-semibold ">Actions</h4>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {order.excessData?.map((extra, i) => (
          <ExtraRowWidgetV2
            disabled={disabled}
            edit={false}
            key={extra.id}
            extra={extra}
            onDelete={onExtraDeleted}
            onSubmit={onExtraUpdated}
          />
        ))}

        {addNew && !disabled && (
          <div className="   border-dashed  border-2 rounded-md border-green-300 p-2">
            <ExtraRowWidgetV2
              disabled={disabled}
              key={formId}
              edit={true}
              // form_id="extra_form"
              onSubmit={(data: OrderItemModelV2) => {
                onExtraAdded(data);
                setFormId(Math.random());
              }}
            />
            <div className="flex justify-between">
              <p className="text-sm italic text-gray-400">
                Please select category,description and amount then hit + to add
                extra.
              </p>
            </div>
            {/* <div className="flex justify-center my-3">
              <button
                form="extra_form"
                className="bg-myPrimaryColor rounded-full p-2 hover:scale-110 transform duration-300"
              >
                <Icon path={mdiPlus} className="h-5 w-5 text-white"></Icon>
              </button>
            </div> */}
          </div>
        )}
      </div>
    </>
  );
};

export default ExtraSectionWidget;
