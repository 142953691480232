import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import { debounce } from "lodash";
import {
  mdiArrowBottomLeft,
  mdiArrowTopRight,
  mdiClose,
  mdiMenuDown,
  mdiMenuUp,
  mdiPlus,
  mdiRefresh,
} from "@mdi/js";
import moment from "moment";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  toQueryString,
} from "../../../service/api_client";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import LoadingWidget from "../../../context_providers/modal/loader";
import SearchFieldWidget from "../../../ui/new_components/search_field";
import { UTCToLocalDate } from "../../../utils/date_util";
import { urls } from "../../../utils/urls";
import FilterDropDown from "../../../ui/new_components/common/filter_drop_down";
import {
  OutwardSource,
  OutwardType,
  StockOutwardModal,
} from "../models/stock_transfer_model";
import Pagination from "../../../ui/pages/content_panel/ui/new_components/common/pagination";

interface StockOutwardListScreenProps {}

interface StockOutwardFilter {
  outward_type?: string[];
  outward_source?: string[];
  outward_date?: Date;
  warehouse?: string;
  outward_id?: string;
  order_id?: string;
  page: number;
  count: number;
  sort_order: string;
  all?: any;
  fromDate?: Date;
  toDate?: Date;
}

const defaultFilter = (): StockOutwardFilter => {
  // const now = new Date();
  // const start_date = new Date(new Date(now).setDate(now.getDate() - 30));
  // const end_date = now;
  return {
    page: 1,
    count: 100,
    sort_order: "desc",
    // fromDate: start_date,
    // toDate: end_date,
  };
};

const StockOutwardListScreen: FunctionComponent<
  StockOutwardListScreenProps
> = () => {
  const navigation = useHistory();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/journal/stock_outward`;
  const { showToast } = useToast();
  const [page, setPage] = useState(1);
  const [count] = useState(50);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<StockOutwardModal[]>([]);
  // const [fromDate, setFromDate] = useState(new Date());
  // const [toDate, setToDate] = useState(new Date());
  const [filter, setFilter] = useState<StockOutwardFilter>(defaultFilter());
  // const [showImport, setShowImport] = useState(false);
  // const [showAttachment, setShowAttachment] = useState<StockOutwardModal>();
  // const [attachmentKey, setAttachmentKey] = useState(Math.random() * 10000);
  const [selectedItems, setSelectedItems] = useState<StockOutwardModal[]>([]);
  // const [showExport, setShowExport] = useState(false);
  // const [ShowHistory, setShowHistory] = useState<any>();
  const [filterCollaped, setFilterCollapsed] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandler = useCallback(
    debounce((prop: StockOutwardFilter) => {
      if (prop) fetchData(prop);
      else setData([]);
    }, 300),
    []
  );
  useEffect(() => {
    debouncedHandler(filter);
  }, [filter, debouncedHandler]);
  console.log("filter:", filter);

  const fetchData = async (prop: StockOutwardFilter) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        console.log(res.data);
        setData(res?.data?.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    } finally {
      setLoading(false);
    }
  };

  const ExportData = async () => {
    try {
      const a = document.createElement("a");
      a.target = "_blank";
      a.href = `${
        process.env.REACT_APP_BACKEND_BASE_URL
      }/api/v3/erp/inventory/journal/sender_transfer/export${toQueryString({
        query: JSON.stringify({
          ...filter,
        }),
      })}`;

      // Delay the trigger to allow UI updates (if necessary)
      setTimeout(() => {
        setLoading(false);
        showToast({ type: ToastType.success, text: "Download started!" });
        a.click(); // Trigger the download
      }, 1000);
    } catch (error) {
      setLoading(false); // Make sure loading is set to false in case of an error
      showToast({ type: ToastType.error, text: "Failed to start download." });
      console.error("Download error:", error);
    }
  };

  const handleOnEdit = () => {
    if (selectedItems.length > 1) {
      showToast({
        type: ToastType.error,
        text: "Please select one item to edit",
      });
    } else if (selectedItems.length === 0) {
      showToast({
        type: ToastType.error,
        text: "Please select an item to edit",
      });
    } else {
      navigation.push(urls.stock_outward + "/update/" + selectedItems[0]!.id);
    }
  };

  const onSelect = (d: StockOutwardModal) => {
    navigation.push(urls.stock_outward + "/view/" + d.id);
  };

  const onDelete = async (d: StockOutwardModal) => {
    const promptText = window.prompt("Type 'delete' to confirm.");
    if (!promptText || promptText.trim() !== "delete") {
      return;
    }
    setLoading(true);
    const res = await fetchDelete(url + "/" + d.id);
    if (res.success) {
      await fetchData({ ...filter });
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  return (
    <>
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section
          id="top_section"
          className="flex justify-between items-end my-1"
        >
          <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
            Stock Outward
            <button
              title="Refersh"
              onClick={() => debouncedHandler({ ...filter })}
            >
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>

          <div className="flex flex-wrap text-sm items-center gap-2 ">
            <div className="text-sm flex flex-wrap items-center gap-2 m-3">
              <div className="flex items-center  gap-1">
                <label htmlFor="fromDate" className="">
                  From
                </label>
                <input
                  type="date"
                  id="fromDate"
                  className="p-1 rounded focus:outline-none"
                  value={
                    filter.fromDate
                      ? moment(filter.fromDate).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const selectedDate = UTCToLocalDate(e.target.value);
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      fromDate: selectedDate,
                    }));
                  }}
                />
              </div>
              <div className="flex items-center gap-1">
                <label htmlFor="toDate" className="">
                  To
                </label>
                <input
                  type="date"
                  id="toDate"
                  className="p-1 rounded focus:outline-none "
                  value={
                    filter.toDate
                      ? moment(filter.toDate).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const selectedDate = UTCToLocalDate(e.target.value);
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      toDate: selectedDate,
                    }));
                  }}
                />
                {(filter.fromDate || filter.toDate) && (
                  <button
                    title="Clear dates"
                    onClick={() =>
                      setFilter((prevFilter) => ({
                        ...prevFilter,
                        fromDate: undefined,
                        toDate: undefined,
                      }))
                    }
                    className="flex gap-1 items-center cursor-pointer rounded p-1 border r hover:scale-105 duration-200     transform"
                  >
                    <Icon path={mdiClose} size={0.7}></Icon>
                  </button>
                )}
              </div>
            </div>
            <div
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
              onClick={() => navigation.push(urls.stock_outward + "/create")}
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>Create
            </div>
            <div
              onClick={handleOnEdit}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200 text-white transform"
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>Update
            </div>
            <div>
              <button
                onClick={() => ExportData()}
                className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white flex gap-2 items-center"
              >
                <Icon path={mdiArrowTopRight} size="15"></Icon> Export
              </button>
            </div>

            <div className="flex gap-2">
              <h6 className="font-normal">Filters</h6>
              <div
                onClick={() => setFilterCollapsed(!filterCollaped)}
                className="flex   items-center justify-center bg-gray-200 p-1 rounded-full hover:bg-gray-800 hover:text-white"
              >
                <Icon
                  path={filterCollaped ? mdiMenuDown : mdiMenuUp}
                  className="w-4 h-4"
                />
              </div>
            </div>
          </div>
        </section>{" "}
        {!filterCollaped && (
          <div className="grid grid-cols-2 gap-6 w-1/2 m-2">
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">Outward ID</label>
              <SearchFieldWidget
                defaulValue={filter?.outward_id}
                onClear={() => {
                  setFilter({
                    ...filter,
                    outward_id: undefined, // Fixed indentation
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    outward_id: val, // Fixed indentation
                  });
                  setPage(1);
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">Order ID</label>
              <SearchFieldWidget
                defaulValue={filter?.order_id}
                onClear={() => {
                  setFilter({
                    ...filter,
                    order_id: undefined, // Fixed indentation
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    order_id: val, // Fixed indentation
                  });
                  setPage(1);
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">
                From Warehouse
              </label>
              <SearchFieldWidget
                defaulValue={filter?.warehouse}
                onClear={() => {
                  setFilter({
                    ...filter,
                    warehouse: undefined, // Fixed indentation
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    warehouse: val, // Fixed indentation
                  });
                  setPage(1);
                }}
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">
                {" "}
                Outward Type
              </label>
              <FilterDropDown
                placeholder="outward_type"
                onClearAll={() =>
                  setFilter({
                    ...filter,
                    outward_type: undefined, // Fixed indentation
                  })
                }
                onDelete={(type) => {
                  setFilter({
                    ...filter,
                    outward_type: filter?.outward_type?.filter(
                      (f: any) => f !== type
                    ),
                  });
                }}
                onSelect={(type) => {
                  setFilter((old) => ({
                    ...old,
                    outward_type: [...(old?.outward_type ?? []), type],
                  }));
                }}
                selectedOptions={filter?.outward_type ?? []}
                options={Object.values(OutwardType).map((t) => ({
                  label: t,
                  value: t,
                }))}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">
                {" "}
                Outward Source
              </label>
              <FilterDropDown
                placeholder="outward_source"
                onClearAll={() =>
                  setFilter({
                    ...filter,
                    outward_source: undefined, // Fixed indentation
                  })
                }
                onDelete={(type) => {
                  setFilter({
                    ...filter,
                    outward_source: filter?.outward_source?.filter(
                      (f: any) => f !== type
                    ),
                  });
                }}
                onSelect={(type) => {
                  setFilter((old) => ({
                    ...old,
                    outward_source: [...(old?.outward_source ?? []), type],
                  }));
                }}
                selectedOptions={filter?.outward_source ?? []}
                options={Object.values(OutwardSource).map((t) => ({
                  label: t,
                  value: t,
                }))}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">Outward Date</label>
              <input
                type="date"
                id="outward_date"
                className="p-1 rounded focus:outline-none"
                value={
                  filter.outward_date
                    ? moment(filter.outward_date).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  const selectedDate = UTCToLocalDate(e.target.value);
                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    outward_date: selectedDate,
                  }));
                }}
              />
            </div>
          </div>
        )}
        <div className="my-2 border overflow-auto" style={{ height: "80%" }}>
          <table className="w-full     text-xs divide-y divide-gray-200 relative ">
            <thead className="  text-xs   py-2 sticky -top-1 bg-white z-10">
              <tr className="border ">
                <th>
                  <div className="">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      checked={
                        selectedItems.length === data.length ? true : false
                      }
                      // disabled={checker && !maker ? false : true}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedItems([...data]);
                        } else {
                          setSelectedItems([]);
                        }
                        console.log(e);
                      }}
                    />
                  </div>
                </th>
                {/* <th className="px-2 py-2">Actions</th> */}
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Outward ID
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Stock Outward Type
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Stock Outward Date
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Warehouse
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">Items</th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Order ID
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Source
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  In-transit ID
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Created At
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="">
              {data?.length > 0 ? (
                data.map((data, i) => {
                  const isSelected = selectedItems.includes(data);
                  return (
                    <tr className="border text-center " key={i}>
                      <td>
                        <div className="p-2">
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            checked={isSelected}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedItems((prev) => [...prev, data]); // Add the item to selected items
                              } else {
                                setSelectedItems((prev) =>
                                  prev.filter(
                                    (selectedItem) => selectedItem !== data
                                  )
                                ); // Remove the item from selected items
                              }
                            }}
                          />
                        </div>
                      </td>
                      {/* <td>
                                  <div className="flex gap-2 justify-center">
                                    {" "}
                                    <div
                                      className="cursor-pointer"
                                      onClick={() => {
                                        setShowHistory(cost.id);
                                      }}
                                    >
                                      <Icon path={mdiHistory} size="16"></Icon>
                                    </div>
                                  </div>
                                </td> */}
                      <div
                        className="py-3 items-center cursor-pointer text-blue-600 hover:underline"
                        role="button"
                        onClick={() => onSelect(data)}
                      >
                        <td className="px-6 py-3 whitespace-nowrap text-left">
                          {data.id}
                        </td>
                      </div>
                      <td className="px-6 py-3 whitespace-nowrap text-left">
                        {data?.outward_type}
                      </td>

                      <td className="px-6 py-3 whitespace-nowrap  text-left">
                        {moment(data.outward_date).format("DD-MM-YYYY")}
                      </td>

                      <td className="px-6 py-3 whitespace-nowrap text-left">
                        {data?.warehouse?.name}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-left">
                        {/* {data?.items?.length} */}
                        <a
                          href={urls.stock_outward + "/view/" + data.id}
                          className="px-2 text-left text-blue-400 hover:text-blue-500  h-full w-full whitespace-pre  "
                        >
                          {`${data?.items[0]?.item?.name} ${
                            data?.items?.length > 1
                              ? `... and ${data?.items?.length - 1} more`
                              : ""
                          }`}
                        </a>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-left">
                        {data?.order_id}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-left">
                        {data?.source}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-left">
                        {data?.inTransit_id}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-left">
                        {moment(data.created_at).format("DD-MM-YYYY")}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-left">
                        {data?.status ?? " "}
                      </td>
                      {/* <td className="px-6 py-3 whitespace-nowrap text-left">
                        <button
                          onClick={() => setShowAttachment(data)}
                          className="relative text-sm font-semibold text-blue-400 hover:text-blue-500 whitespace-nowrap   flex items-center gap-1 px-2 h-full w-full"
                        >
                          View
                          <div>
                            (
                            {data?.attachments?.length
                              ? data?.attachments?.length
                              : 0}
                            )
                          </div>
                        </button>
                      </td> */}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={13}>
                    <div className="h-56 text-center p-5 my-20">
                      <h5 className="text-gray-400 text-2xl">No Data found!</h5>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-3">
          <Pagination
            itemCount={count}
            page={filter.page}
            total={total}
            count={filter.count}
            onChange={(page) => setFilter((o) => ({ ...o, page }))}
          />
        </div>
      </div>

      {loading && <LoadingWidget />}
    </>
  );
};

export default StockOutwardListScreen;
