import { mdiSortAscending, mdiSortDescending } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { Tooltip } from "@material-ui/core";
import Pagination from "../../ui/new_components/common/pagination";
import { FieldType } from "./create_view/form_field";
import { PurchaseOrderModel } from "../../erp/purchase_order/model/purchase_order_model";
export interface NewTableHeaderProps {
  title?: string;
  colspan?: number;
  rowspan?: number;
  align?: "left" | "right" | "center" | "justify" | "char";
  type: FieldType;
  key: string;
  sort_order?: "asc" | "desc";
  onClick?: (data: { sort: string; sort_order: string }) => void;
  value?: string;
  stickyColumn?: boolean;
  left?: string | number;
  right?: string | number;
  builder?: () => JSX.Element;
}
export interface NewTableRow<T> {
  stickyRow?: boolean;
  top?: string | number;
  bottom?: string | number;
  data: T;
  onClick?: (data: T) => void;
  cells: {
    maxLines?: number;
    title?: string;
    colspan?: number;
    rowspan?: number;
    value?: string | number | Date;
    align?: "left" | "right" | "center" | "justify" | "char";
    builder?: () => JSX.Element;
  }[];
}
interface NewTableViewProps<T extends { id?: string }> {
  show_index?: boolean;
  loading?: boolean;
  show_pagination?: boolean;
  lazy_loading?: boolean;
  infinite_Scroll?: boolean;
  page?: number;
  total?: number;
  count?: number;
  itemCount?: number;
  onShowHistory: (data: T) => void;
  onPageChange?: (p: number) => void;
  headers: NewTableHeaderProps[];
  stickyHeader?: boolean;
  rows: NewTableRow<T>[];
}

const NewTableView = <T extends { id?: string }>({
  headers,
  rows,
  page,
  count,
  itemCount,
  total,
  stickyHeader,
  onPageChange,
  onShowHistory,
  loading,
  show_pagination,
  lazy_loading,
  infinite_Scroll,
  show_index,
}: NewTableViewProps<T>): JSX.Element => {
  const [offSet, setOffSet] = useState(0);
  // const NewTableView = <T extends { id?: string }>({
  //   headers,
  //   rows,
  // }: PropsWithChildren<TableViewProps<T>>) => {

  useEffect(() => {
    const container = document.getElementById("page_container");
    const top_section = document.getElementById("top_section");
    const table = document.getElementById("table-container");
    if (container && top_section)
      table!.style.height = `${
        container!.clientHeight - 16 - top_section!.clientHeight
      }px`;
    if (page && count) setOffSet(page > 0 ? (page - 1) * count : 0);
  }, []);

  return (
    <section
      id="table-container"
      className="flex flex-col justify-between items-center w-full  bg-white border rounded-lg"
    >
      <div
        id="scroll_listener"
        className=" overflow-auto h-full  w-full"
        style={{ height: "95%" }}
      >
        <table className=" divide-y divide-gray-200 w-full  ">
          <thead className={` ${stickyHeader && " sticky  -top-1 "} z-10 `}>
            <tr
              className={`${
                stickyHeader && " sticky  -top-0.5 "
              } text-sm bg-white shadow whitespace-nowrap rounded-lg`}
            >
              {show_index && (
                <th className={` mx-5 pb-2 font-semibold px-5 border-r-2 w-8 `}>
                  <div className="  py-1      ">Sl</div>
                </th>
              )}

              {headers.map((header, i) =>
                header.builder ? (
                  header.builder()
                ) : (
                  <th
                    align={header.align}
                    colSpan={header.colspan || 1}
                    rowSpan={header.rowspan || 1}
                    style={
                      header.stickyColumn
                        ? {
                            left: header.left,
                            right: header.right,
                          }
                        : {}
                    }
                    title={header.title}
                    className={` ${
                      header.stickyColumn && " sticky "
                    }   pb-2 font-semibold px-5 border-r-2  overflow-hidden  `}
                    key={i}
                  >
                    <div className="  p-1 flex justify-between  items-center gap-1 ">
                      <div
                        title={header.title}
                        className={`flex flex-col gap-5 ${
                          header.align == "center"
                            ? "text-center"
                            : header.align == "right"
                            ? "text-right"
                            : "text-left"
                        } `}
                      >
                        <h3>{header.value ?? "---"}</h3>
                      </div>
                      {header.onClick && (
                        <div
                          onClick={() => {
                            if (header.onClick)
                              header.onClick({
                                sort: header.key,
                                sort_order:
                                  header.sort_order == "asc" ? "desc" : "asc",
                              });
                          }}
                          className=""
                          title={
                            header.sort_order == "asc"
                              ? "Ascending"
                              : "Descending"
                          }
                        >
                          <Icon
                            path={
                              header.sort_order == "asc"
                                ? mdiSortAscending
                                : mdiSortDescending
                            }
                            className="h-4 w-5"
                          ></Icon>
                        </div>
                      )}
                    </div>
                  </th>
                )
              )}
              {/* {(onEdit || onDelete) && (
                <th className={`   pb-2 font-semibold  overflow-hidden  `}>
                  <div className="   py-1 flex justify-center  items-center gap-1 ">
                    Action
                  </div>
                </th>
              )} */}
            </tr>
          </thead>

          <tbody className=" divide-y  divide-gray-200 max-h-96 mx-2 text-xs items-start">
            {rows.length > 0 ? (
              rows.map((row, i) => (
                <tr
                  style={
                    row.stickyRow
                      ? {
                          top: row.top,
                          bottom: row.bottom,
                        }
                      : {}
                  }
                  onClick={
                    row.onClick ? () => row.onClick!(row.data) : undefined
                  }
                  key={i}
                  className={`sticky-row   ${row.stickyRow && "sticky"}  ${
                    row.onClick ? " cursor-pointer  " : ""
                  }   bg-white shadow p-1  hover:bg-gray-200  `}
                >
                  <td className="pt-3 px-5 border-r-2">
                    <Tooltip title="History" className="">
                      <svg
                        onClick={() => onShowHistory(row.data)}
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4  cursor-pointer hover:text-red-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </Tooltip>
                  </td>
                  {/* {show_index && (
                    <td
                      className={`p-1    text-right w-min whitespace-nowrap    sticky-field  py-1 `}
                    >
                      <div className={``}>{offSet + (i + 1)}</div>
                    </td>
                  )} */}
                  {row.cells.map((cell, j) => {
                    if (cell.builder) {
                      return (
                        <td
                          // style={{
                          //   ...(headers[j].stickyColumn
                          //     ? {
                          //         left: headers[j].left,
                          //         right: headers[j].right,
                          //       }
                          //     : {}),
                          //   ...(cell.maxLines
                          //     ? {
                          //         overflow: "hidden",
                          //         display: "-webkit-box",
                          //         WebkitBoxOrient: "vertical",
                          //         WebkitLineClamp: cell.maxLines,
                          //       }
                          //     : {}),
                          // }}
                          style={
                            headers[j].stickyColumn
                              ? {
                                  left: headers[j].left,
                                  right: headers[j].right,
                                }
                              : {}
                          }
                          title={cell.title}
                          align={cell.align}
                          colSpan={cell.colspan || 1}
                          rowSpan={cell.rowspan || 1}
                          key={j}
                          className={` ${
                            (headers[j].stickyColumn || row.stickyRow) &&
                            "   sticky"
                          } p-1   sticky-field max-w-xs px-5   border-r-2    overflow-hidden overflow-ellipsis    `}
                        >
                          {cell.builder()}
                        </td>
                      );
                    }

                    if (headers[j].type === FieldType.date){
                      console.log("date")
                      return (
                        <td
                          style={
                            headers[j].stickyColumn
                              ? {
                                  left: headers[j].left,
                                  right: headers[j].right,
                                }
                              : {}
                          }
                          align={cell.align}
                          colSpan={cell.colspan || 1}
                          rowSpan={cell.rowspan || 1}
                          key={j}
                          className={`p-1 text-center whitespace-nowrap px-5  border-r-2 sticky-field ${
                            (headers[j].stickyColumn || row.stickyRow) &&
                            "  sticky"
                          } ${row.onClick ? " cursor-pointer  " : ""}`}
                        >
                          <div
                            className={`${
                              cell.align == "center"
                                ? "text-center"
                                : cell.align == "right"
                                ? "text-right"
                                : "text-left"
                            }`}
                          >
                           
                            {cell &&
                            moment(cell.value, moment.ISO_8601, true).isValid()
                              ? moment(cell.value).format("DD-MM-YYYY")
                              : cell.value}
                          </div>
                        </td>
                      );
                    }
                    // if (headers[j].type === FieldType.number){
                    //   console.log("number")
                    //   return (
                    //     <td
                    //       style={{
                    //         ...(headers[j].stickyColumn
                    //           ? {
                    //               left: headers[j].left,
                    //               right: headers[j].right,
                    //             }
                    //           : {}),
                    //       }}
                    //       title={cell.title}
                    //       align={cell.align}
                    //       colSpan={cell.colspan || 1}
                    //       rowSpan={cell.rowspan || 1}
                    //       key={j}
                    //       className={` ${
                    //         (headers[j].stickyColumn || row.stickyRow) &&
                    //         "   sticky"
                    //       } p-1   sticky-field max-w-xs     ${
                    //         headers[j].type == FieldType.number
                    //           ? " text-right "
                    //           : " text-center "
                    //       } overflow-hidden overflow-ellipsis px-5 border-r-2 ${
                    //         row.onClick ? " cursor-pointer  " : ""
                    //       }`}
                    //     >
                    //       <div
                    //         style={{
                    //           overflow: "hidden",
                    //           display: "-webkit-box",
                    //           WebkitBoxOrient: "vertical",
                    //           WebkitLineClamp: cell.maxLines,
                    //         }}
                    //         className={` overflow-ellipsis  ${
                    //           cell.align == "center"
                    //             ? "text-center"
                    //             : cell.align == "right"
                    //             ? "text-right"
                    //             : "text-left"
                    //         }`}
                    //       >
                    //         {cell.value ?? "--"}
                    //       </div>
                    //     </td>
                    //   );
                    // }
                    // if (headers[j].type === FieldType.currency)
                    //   return (
                    //     <td
                    //       // onClick={onClick ? () => onClick!(row.data) : undefined}
                    //       colSpan={headers[j].colspan || 1}
                    //       key={j}
                    //       className={`p-1 whitespace-nowrap border sticky-field   ${
                    //         headers[j].type == FieldType.currency
                    //           ? " text-right "
                    //           : " text-center "
                    //       } overflow-hidden  ${
                    //         onClick ? " cursor-pointer  " : ""
                    //       }`}
                    //     >
                    //       <div className={``}>{currencyFormat(cell) }</div>
                    //     </td>
                    //   );

                    return (
                      <td
                        style={{
                          ...(headers[j].stickyColumn
                            ? {
                                left: headers[j].left,
                                right: headers[j].right,
                              }
                            : {}),
                        }}
                        title={cell.title}
                        align={cell.align}
                        colSpan={cell.colspan || 1}
                        rowSpan={cell.rowspan || 1}
                        key={j}
                        className={` ${
                          (headers[j].stickyColumn || row.stickyRow) &&
                          "   sticky"
                        } p-1   sticky-field max-w-xs     ${
                          headers[j].type == FieldType.currency
                            ? " text-right "
                            : " text-center "
                        } overflow-hidden overflow-ellipsis px-5 border-r-2 ${
                          row.onClick ? " cursor-pointer  " : ""
                        }`}
                      >
                        <div
                          style={{
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: cell.maxLines,
                          }}
                          className={` overflow-ellipsis  ${
                            cell.align == "center"
                              ? "text-center"
                              : cell.align == "right"
                              ? "text-right"
                              : "text-left"
                          }`}
                        >
                          {cell.value ?? "--"}
                        </div>
                      </td>
                    );
                    
                    
                  })}
                  {/* {(onEdit || onDelete) && (
                  <td>
                    <div className="flex justify-center gap-5">
                      <button
                        onClick={onEdit ? () => onEdit!(row.data) : undefined}
                        className="text-sm font-semibold text-blue-300 flex items-center gap-1 hover:text-blue-500"
                      >
                        <Icon path={mdiPencil} className="w-4 h-4"></Icon>
                        Edit
                      </button>
                      <button
                        onClick={
                          onClick ? () => onDelete!(row.data) : undefined
                        }
                        className="text-sm font-semibold text-red-300 flex items-center gap-1 hover:text-red-500"
                      >
                        {" "}
                        <Icon path={mdiDelete} className="w-4 h-4"></Icon>
                        Delete
                      </button>
                    </div>
                  </td>  
                  )} */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={headers.length + (show_index ? 1 : 0)}>
                  <div className="h-full w-full flex justify-center items-center">
                    <div className="p-10">No Records Found !</div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {show_pagination && (
        <div className="px-1 w-full">
          <Pagination
            page={page ?? 1}
            total={total || 0}
            count={count ?? 0}
            itemCount={itemCount || 0}
            onChange={onPageChange ? onPageChange : (v) => {}}
          />
        </div>
      )}
    </section>
  );
};

export default NewTableView;
