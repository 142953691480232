import debounce from "lodash.debounce";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ProcurementProjectModel } from "../model/project_model";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { fetchGet, toQueryString } from "../../../../service/api_client";
import AutoCompleteField from "../../../../ui/new_components/common/autocomplete";
import ModalDialog from "../../../../ui/new_components/common/modal";

interface ProjectSearchFieldProps {
  onSelect: (data: ProcurementProjectModel) => void;
  disabled?: boolean;
  placeholder?: string;
  value?: ProcurementProjectModel;
}

const ProjectSearchField: FunctionComponent<ProjectSearchFieldProps> = (
  props
) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/project`;
  const { showToast } = useToast();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [addNew, setAddNew] = useState(false);
  const [data, setData] = useState<ProcurementProjectModel[] | undefined>();

  const getData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
  }) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce((query) => {
      if (query) getData({ search: query, page: 1, count: 10, all: true });
      else setData(undefined);
    }, 500),
    []
  );

  useEffect(() => {
    if (search && edit) {
      if (props.value && props.value.name === search) return;
      debouncedHandler(search);
    }
  }, [search, edit, debouncedHandler, props.value]);

  useEffect(() => {
    setSearch(props.value?.name ?? "");
  }, [props.value]);

  return (
    <div className="relative flex items-center w-full">
      {edit ? (
        <AutoCompleteField
          loading={loading}
          disabled={props.disabled}
          onAddNew={() => setAddNew(true)}
          value={search}
          placeholder={props.placeholder ?? "Item Search"}
          onSelect={(val) => {
            setEdit(false);
            setSearch(val.value.name);
            props.onSelect(val.value);
          }}
          suggestions={data?.map((d) => ({
            label: (
              <div className="text-xs truncate">
                <b>{d.name}</b>
              </div>
            ),
            value: d,
          }))}
          onChange={setSearch}
        />
      ) : (
        <div
          className={`text-sm px-3 py-2 h-8 rounded border cursor-pointer select-none ${
            search ? "" : "text-gray-400"
          } ${props.disabled ? "bg-gray-100 cursor-not-allowed" : "bg-white"}`}
          onClick={() => {
            if (!props.disabled) {
              setEdit(true);
            }
          }}
          style={{ overflow: "hidden", textOverflow: "ellipsis" }} // Ensures the field does not exceed its container
        >
          <span className="truncate">
            {search || props.placeholder || "Search"}
          </span>
        </div>
      )}
      {addNew && (
        <ModalDialog
          onClose={() => setAddNew(false)}
          show={addNew}
          title={"Add Item"}
        >
          <div
            className="overflow-auto"
            style={{ maxHeight: "80vh", maxWidth: "80vw" }}
          >
            {/* <ProjectCreateScreen
              onClose={(d) => {
                if (d) {
                  setEdit(false);
                  setSearch(d.name);
                  props.onSelect(d);
                }
                setAddNew(false);
              }}
            /> */}
          </div>
        </ModalDialog>
      )}
    </div>
  );
};

export default ProjectSearchField;
