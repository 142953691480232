import moment from "moment";
import { FunctionComponent } from "react";
import { useOrderCreateCtx } from "../../../../../context_providers/order_create_context";
import { UTCToLocalDate } from "../../../../../utils/date_util";
import {
  LeadSource,
  orderCategories,
  orderSegment,
  OrderStatus,
  OrderType,
  remarks,
  sale_type,
} from "../../../../../utils/enums";
import EmployeeSearchWidget from "../../../../new_components/emp_search_widget";

interface OrderDetailSectionProps {}

const OrderDetailSection: FunctionComponent<OrderDetailSectionProps> = () => {
  const { order, setOrder, discoms, subscriptionReq, setSubscriptionReq } =
    useOrderCreateCtx();

  return (
    <>
      <h3 className="font-semibold mt-5">Order Details</h3>
      <div className=" bg-white shadow rounded-md text-sm p-5">
        {/* <div className=" flex items-center gap-3 my-2">
          <label htmlFor="demo">Demo Order</label>
          <input
            name=""
            id="demo"
            type="checkbox"
            className=" p-2 rounded bg-gray-100 focus:outline-none"
            checked={order.demo}
            onChange={(e) =>
              setOrder((o) => ({ ...o!, demo: e.target.checked }))
            }
          ></input>
        </div> */}
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-x-10 gap-y-5">
          <div className="grid grid-cols-1   items-center">
            <label htmlFor="order_id">
              <span className="text-base text-red-500">*</span> Order ID
            </label>
            <input
              disabled={true}
              type="text"
              id="order_id"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none "
              value={order.order_id}
            />
          </div>
          <div className="grid grid-cols-1 items-center">
            <label htmlFor="order_date">
              {" "}
              <span className="text-base text-red-500">*</span>Date
            </label>
            <input
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              id="order_date"
              type="datetime-local"
              name="orderDate"
              required
              value={moment(order.order_date).format("YYYY-MM-DD HH:mm:ss")}
              onChange={(e) => {
                const date = new Date(e.target.value);

                setOrder((o) => ({
                  ...o!,
                  order_date: date,
                }));
              }}
            />
          </div>
          <div className="grid grid-cols-1 items-center">
            <label htmlFor="type">
              {" "}
              <span className="text-base text-red-500">*</span>Type
            </label>
            <select
              id="type"
              name="type"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              required
              value={order.type}
              onChange={(e) => {
                setOrder((o) => ({ ...o!, type: e.target.value }));
              }}
            >
              {Object.values(OrderType).map((type, i) => (
                <option value={type} key={i}>
                  {type}
                </option>
              ))}
            </select>
          </div>{" "}
          <div className="grid grid-cols-1 items-center">
            <label htmlFor="status">
              {" "}
              <span className="text-base text-red-500">*</span>Status
            </label>
            <select
              name=""
              id="status"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              value={order.status}
              onChange={(e) => {
                setOrder((o) => ({ ...o!, status: e.target.value }));
              }}
            >
              <option value="" hidden>
                Select ...
              </option>
              {Object.values(OrderStatus).map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="grid grid-cols-1 items-center">
            <label htmlFor="sales_person">
              {" "}
              <span className="text-base text-red-500">*</span>Sales person
            </label>
            <div className="col-span-2  rounded bg-gray-100 focus:outline-none">
              <EmployeeSearchWidget
                withBtn={false}
                disabled={false}
                placeholder="Select"
                emp={
                  order.sales_person_id
                    ? {
                        uid: order.sales_person_id!,
                        name: order.sales_person_name!,
                      }
                    : undefined
                }
                onSelect={(emp) => {
                  const name = (emp.first_name + " " + emp.last_name).trim();
                  setOrder((o) => ({
                    ...o!,
                    sales_person_id: emp.uid,
                    sales_person_name: name,
                  }));
                }}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 items-center">
            <label htmlFor="rm1">
              {" "}
              <span className="text-base text-red-500">*</span>Relationship Mgr
              1
            </label>
            <div className="col-span-2  rounded bg-gray-100 focus:outline-none">
              <EmployeeSearchWidget
                withBtn={false}
                disabled={false}
                placeholder="Select"
                emp={
                  order.rm_uid
                    ? {
                        uid: order.rm_uid!,
                        name: order.rm_name!,
                      }
                    : undefined
                }
                onSelect={(emp) => {
                  const name = (emp.first_name + " " + emp.last_name).trim();
                  setOrder((o) => ({
                    ...o!,
                    rm_uid: emp.uid,
                    rm_name: name,
                  }));
                }}
              />
            </div>{" "}
          </div>
          {/* <div className="grid grid-cols-1 items-center">
            <label htmlFor="rm2">Relationship Mgr 2</label>
            <div className="col-span-2  rounded bg-gray-100 focus:outline-none">
              <EmployeeSearchWidget
                withBtn={false}
                disabled={false}
                // emp={}
                placeholder="Select"
                emp={
                  order.rm2_uid
                    ? {
                        uid: order.rm2_uid!,
                        name: order.rm2_name!,
                      }
                    : undefined
                }
                onSelect={(emp) => {
                  const name = (emp.first_name + " " + emp.last_name).trim();
                  setOrder((o) => ({
                    ...o!,
                    rm2_uid: emp.uid,
                    rm2_name: name,
                  }));
                }}
              />
            </div>
          </div>{" "} */}
          <div className="grid grid-cols-1 items-center">
            <label htmlFor="category">Category</label>
            <select
              name=""
              id="category"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              value={order.category}
              onChange={(e) => {
                setOrder((o) => ({ ...o!, category: e.target.value }));
              }}
            >
              <option value="" hidden>
                Select ...
              </option>
              {orderCategories.map((src, i) => (
                <option value={src} key={i}>
                  {src}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="grid grid-cols-1 items-center">
            <label htmlFor="segment">
              <span className="text-base text-red-500">*</span>Lead segment
            </label>
            <select
              name=""
              id="segment"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              value={order.segment}
              onChange={(e) => {
                setOrder((o) => ({ ...o!, segment: e.target.value }));
              }}
            >
              <option value="" hidden>
                Select ...
              </option>
              {orderSegment.map((src, i) => (
                <option value={src} key={i}>
                  {src}
                </option>
              ))}
            </select>
          </div>{" "} */}
          {/* <div className="grid grid-cols-1 items-center">
            <label htmlFor="lead_source">
              {" "}
              <span className="text-base text-red-500">*</span>Lead Source
            </label>
            <select
              name=""
              id="lead_source"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              value={order.source}
              onChange={(e) => {
                setOrder((o) => ({ ...o!, source: e.target.value }));
              }}
            >
              <option value="" hidden>
                Select ...
              </option>
              {LeadSource.sort().map((src, i) => (
                <option value={src} key={i}>
                  {src}
                </option>
              ))}
            </select>
          </div> */}
          <div className="grid grid-cols-1 items-center">
            <label htmlFor="status">
              <span className="text-base text-red-500">*</span>Subscription
              applicable
            </label>
            <select
              name=""
              id="status"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              value={order.monthly_recurring_fee}
              onChange={(e) => {
                setSubscriptionReq(e.target.value === "Yes");
                setOrder((o) => ({
                  ...o!,
                  monthly_recurring_fee: e.target.value,
                }));
              }}
            >
              <option value="" hidden>
                Select ...
              </option>
              {["Yes", "No"].map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="grid grid-cols-1 items-center">
            <label htmlFor="sale_type">
              <span className="text-base text-red-500">*</span>Sales type
            </label>
            <select
              name=""
              id="sale_type"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              value={order.sale_type}
              onChange={(e) => {
                setOrder((o) => ({ ...o!, sale_type: e.target.value }));
              }}
            >
              <option value="" hidden>
                Select ...
              </option>
              {sale_type.sort().map((src, i) => (
                <option value={src} key={i}>
                  {src}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <h3 className="font-semibold mt-5">Remarks</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 bg-white shadow rounded-md text-sm p-5 gap-x-10 gap-y-5 ">
        {/* <div className="grid grid-cols-1 items-center">
          <label htmlFor="discom">Discom</label>
          <select
            name=""
            id="discom"
            className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
            value={order.discom}
            onChange={(e) => {
              setOrder((o) => ({ ...o!, discom: e.target.value }));
            }}
          >
            <option value="" hidden>
              Select ...
            </option>
            {discoms.map((d, i) => (
              <option value={d.short_name} key={i}>
                {`${d.short_name}(${d.state})`}
              </option>
            ))}
          </select>
        </div> */}
        {/* <div className="grid grid-cols-1 items-center">
          <label htmlFor="meter">Meter Type</label>
          <select
            name=""
            id="meter"
            className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
            value={order.meter_type}
            onChange={(e) => {
              setOrder((o) => ({ ...o!, meter_type: e.target.value }));
            }}
          >
            <option value="" hidden>
              Select ...
            </option>
            {[
              "Domestic Residential",
              "Domestic staircase",
              "Domestic lighting",
              "NDLS",
              "CGHS",
              "EV meter",
            ].map((src, i) => (
              <option value={src} key={i}>
                {src}
              </option>
            ))}
          </select>
        </div> */}
        {/* <div className="grid grid-cols-1 items-center">
          <label htmlFor="load">Load Sanction (KW)</label>
          <input
            type="text"
            id="load"
            className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none "
            value={order.load_sanction}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = parseFloat(e.target.value);
              }
              setOrder((o) => ({ ...o!, load_sanction: v }));
            }}
          />
        </div> */}
        {/* <div className="grid grid-cols-1 items-center">
          <label htmlFor="pay_remark">Payment Collection Remark</label>
          <select
            name=""
            id="pay_remark"
            className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
            value={order.remark}
            onChange={(e) => {
              setOrder((o) => ({ ...o!, remark: e.target.value }));
            }}
          >
            <option value="" hidden>
              Select ...
            </option>
            {remarks.sort().map((src, i) => (
              <option value={src} key={i}>
                {src}
              </option>
            ))}
          </select>
        </div> */}
        {/* <div className="grid grid-cols-1 items-center">
          <label htmlFor="pay_date">Expected Payment Date</label>
          <input
            className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
            id="pay_date"
            type="date"
            name="payment_date"
            value={
              order.expected_pay_date &&
              moment(order.expected_pay_date).format("YYYY-MM-DD")
            }
            onChange={(e) => {
              const date = UTCToLocalDate(new Date(e.target.value));
              setOrder((o) => ({
                ...o!,
                expected_pay_date: date,
              }));
            }}
          />
        </div> */}
        {/* <div className="grid grid-cols-1 items-center">
          <label htmlFor="reg_doc">Registered Document</label>
          <select
            // required={order.type === OrderType.DELHI_TENDOR}
            name=""
            id="reg_doc"
            className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
            value={order.registration_doc_issued}
            onChange={(e) => {
              setOrder((o) => ({
                ...o!,
                registration_doc_issued: e.target.value,
              }));
            }}
          >
            <option value="" hidden>
              Select ...
            </option>
            {["Yes", "No"].map((src, i) => (
              <option value={src} key={i}>
                {src}
              </option>
            ))}
          </select>
        </div> */}
        {/* <div className="grid grid-cols-1 items-center">
          <label htmlFor="reg_device">Registered Device</label>
          <input
            // required={order.type === OrderType.DELHI_TENDOR}
            name=""
            id="reg_device"
            type="text"
            className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
            value={order.no_device_registered}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = parseInt(e.target.value);
              }
              setOrder((o) => ({ ...o!, no_device_registered: v }));
            }}
          ></input>
        </div> */}
        <div className="">
          <div className=" mb-1 font-semibold text-xs">Survey date</div>
          <input
            className={`w-full text-xs  px-3 py-2  bg-gray-100 rounded focus:outline-none`}
            id="survey_date"
            type="date"
            name="survey_date"
            required
            value={
              order.survey_date &&
              moment(order.survey_date).format("YYYY-MM-DD HH:mm:ss")
            }
            onChange={(e) => {
              const date = e.target.value
                ? UTCToLocalDate(e.target.value)
                : undefined;

              setOrder((o) => ({
                ...o!,
                survey_date: date,
              }));
            }}
          />
        </div>
        <div className="">
          <div className=" mb-1 font-semibold text-xs">SLD & BOQ date</div>
          <input
            className={`w-full text-xs  px-3 py-2  bg-gray-100 rounded focus:outline-none`}
            id="survey_date"
            type="date"
            name="survey_date"
            required
            value={
              order.sld_and_boq_date &&
              moment(order.sld_and_boq_date).format("YYYY-MM-DD HH:mm:ss")
            }
            onChange={(e) => {
              const date = e.target.value
                ? UTCToLocalDate(e.target.value)
                : undefined;

              setOrder((o) => ({
                ...o!,
                sld_and_boq_date: date,
              }));
            }}
          />
        </div>
        <div className="">
          <div className=" mb-1 font-semibold text-xs">
            SLD & BOQ approval date
          </div>
          <input
            className={`w-full text-xs  px-3 py-2  bg-gray-100 rounded focus:outline-none`}
            id="survey_date"
            type="date"
            name="survey_date"
            required
            value={
              order.sld_and_boq_approval_date &&
              moment(order.sld_and_boq_approval_date).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            }
            onChange={(e) => {
              const date = e.target.value
                ? UTCToLocalDate(e.target.value)
                : undefined;

              setOrder((o) => ({
                ...o!,
                sld_and_boq_approval_date: date,
              }));
            }}
          />
        </div>
        <div className="">
          <div className=" mb-1 font-semibold text-xs">Installation date</div>
          <input
            className={`w-full text-xs  px-3 py-2  bg-gray-100 rounded focus:outline-none`}
            id="survey_date"
            type="date"
            name="survey_date"
            required
            value={
              order.installation_date &&
              moment(order.installation_date).format("YYYY-MM-DD HH:mm:ss")
            }
            onChange={(e) => {
              const date = e.target.value
                ? UTCToLocalDate(e.target.value)
                : undefined;

              setOrder((o) => ({
                ...o!,
                installation_date: date,
              }));
            }}
          />
        </div>
        <div className="">
          <div className=" mb-1 font-semibold text-xs">Commission date</div>
          <input
            className={`w-full text-xs  px-3 py-2  bg-gray-100 rounded focus:outline-none`}
            id="survey_date"
            type="date"
            name="survey_date"
            required
            value={
              order.commission_date &&
              moment(order.commission_date).format("YYYY-MM-DD HH:mm:ss")
            }
            onChange={(e) => {
              const date = e.target.value
                ? UTCToLocalDate(e.target.value)
                : undefined;

              setOrder((o) => ({
                ...o!,
                commission_date: date,
              }));
            }}
          />
        </div>
        <div className="">
          <div className=" mb-1 font-semibold text-xs">Dealer code</div>
          <input
            className={`w-full text-xs  px-3 py-2  bg-gray-100 rounded focus:outline-none`}
            id="dealer_code"
            type="string"
            name="dealer_code"
            required
            value={order.dealer_code}
            onChange={(e) => {
              setOrder((o) => ({
                ...o!,
                dealer_code: e.target.value,
              }));
            }}
          />
        </div>
      </div>
    </>
  );
};

export default OrderDetailSection;
