import {
  mdiRefresh,
  mdiPlus,
  mdiImport,
  mdiArrowBottomLeft,
  mdiClose,
  mdiCommentBookmark,
  mdiHistory,
  mdiTrashCan,
  mdiPlusBox,
  mdiCloseBox,
} from "@mdi/js";
import Icon from "@mdi/react";
import axios, { CancelTokenSource } from "axios";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  useModal,
  ModalType,
} from "../../../context_providers/modal/modal_context";
import ModalWidget from "../../../context_providers/modal/modal_widget";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  toQueryString,
} from "../../../service/api_client";
import { urls } from "../../../utils/urls";
import { useAuth } from "../../../context_providers/auth/authProvider";
import { ACTION, MODULE } from "../../../utils/enums";
import Pagination from "../../../ui/new_components/common/pagination";
import { debounce, update } from "lodash";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../../ui/new_components/common/drawer_modal";
import {
  CostCenter,
  CostCenterFilterData,
  MasterCostCenterStatus,
} from "../models/cost_center_model";
import ImportWizard from "../items/components/import_comp";
import moment from "moment";
import {
  Project,
  ProjectCloseStatus,
  ProjectFilterData,
} from "../models/project_model";
import SearchFieldWidget from "../../../ui/new_components/search_field";
import FilterDropDown from "../../../ui/new_components/common/filter_drop_down";

interface ProjectScreenProps {}

const defaultFilter: CostCenterFilterData = {
  page: 1,
  count: 100,
};

const ProjectListScreen: FunctionComponent<ProjectScreenProps> = () => {
  const cc_url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/cost_center`;
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/project`;
  const { showModal, onClose } = useModal();

  const [filter, setFilter] = useState<ProjectFilterData>(defaultFilter);

  const { showToast } = useToast();
  const [view, setView] = useState(false);
  const [maker, setMaker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<Project[]>([]);
  const [costCenter, setCostCenter] = useState<CostCenter[]>([]);

  // const [state, setState] = useState ({
  //   checker:
  // })

  const { user, isAuthorised } = useAuth();
  const fetchData = async (prop: ProjectFilterData) => {
    setLoading(true);
    console.log("Props", prop);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
        setCount(res.data.metadata?.count);
        console.log("Res:", res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
        setLoading(false);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    }
  };

  const fetchDataCostCenter = async (prop: CostCenterFilterData) => {
    setLoading(true);
    console.log("Props", prop);
    try {
      const res = await fetchGet(cc_url + toQueryString(prop));
      if (res.success) {
        setCostCenter(res.data.data);
        setLoading(false);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    }
  };

  useEffect(() => {
    const maker_permission_result = isAuthorised({
      module: MODULE.project_master,
      action: ACTION.CREATE,
    });
    const checker_permission_result = isAuthorised({
      module: MODULE.project_master_maker,
      action: ACTION.READ,
    });
    setView(checker_permission_result);
    setMaker(maker_permission_result);
    console.log("Checker", checker_permission_result);
    console.log("Maker", maker_permission_result);
  }, [isAuthorised]); // Separate call for permission check

  const debouncedHandler = useCallback(
    debounce((prop: ProjectFilterData & CostCenterFilterData) => {
      if ("page" in prop) {
        fetchData(prop);
      } else {
        fetchDataCostCenter(prop);
      }
    }, 500),
    []
  );

  useEffect(() => {
    debouncedHandler(filter);
  }, [page, filter, debouncedHandler]);

  const ExportProjectListData = async () => {
    try {
      const a = document.createElement("a");
      a.target = "_blank";
      a.href = `${
        process.env.REACT_APP_BACKEND_BASE_URL
      }/api/v3/erp/inventory/project/export${toQueryString({
        query: JSON.stringify({
          ...filter,
        }),
      })}`;

      // Delay the trigger to allow UI updates (if necessary)
      setTimeout(() => {
        setLoading(false);
        showToast({ type: ToastType.success, text: "Download started!" });
        a.click(); // Trigger the download
      }, 1000);
    } catch (error) {
      setLoading(false); // Make sure loading is set to false in case of an error
      showToast({ type: ToastType.error, text: "Failed to start download." });
      console.error("Download error:", error);
    }
  };

  const navaigation = useHistory();
  const onSelect = (d: Project) => {
    navaigation.push(urls.procurement_project + "/view-project/" + d.id);
  };

  const handleOnEdit = () => {
    if (selectedItems.length > 1) {
      showToast({
        type: ToastType.error,
        text: "Please select one item to edit",
      });
    } else if (selectedItems.length === 0) {
      showToast({
        type: ToastType.error,
        text: "Please select an item to edit",
      });
    } else {
      navaigation.push(
        urls.procurement_project + "/update/" + selectedItems[0]!.id
      );
    }
  };

  const [selectedItems, setSelectedItems] = useState<Project[]>([]);

  return (
    <>
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section id="top_section" className=" ">
          <div className="flex justify-between items-end my-1">
            <h1 className="font-bold text-3xl text-gray-500 flex items-center gap-3">
              Project Master
              <button
                title="Refresh"
                onClick={() => fetchData({ page, count })}
              >
                <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
              </button>
            </h1>
            <div className="flex flex-wrap justify-start   text-sm items-center gap-4 ">
              {" "}
              <div className="flex items-center  gap-1">
                <label htmlFor="fromDate" className="">
                  From
                </label>
                <input
                  type="date"
                  id="fromDate"
                  className="p-1 rounded"
                  value={
                    filter.from ? moment(filter?.from).format("YYYY-MM-DD") : ""
                  }
                  onChange={(e) => {
                    const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                    const fromDate = selectedDate
                      ? new Date(new Date(selectedDate).setHours(0, 0, 0, 0)) // Convert to a date object
                      : undefined; // Reset if no date is selected

                    // Update the filter
                    setFilter({
                      ...filter,
                      from: fromDate,
                    });
                  }}
                />
              </div>
              <div className="flex items-center  gap-1">
                <label htmlFor="toDate" className="">
                  To
                </label>
                <input
                  type="date"
                  id="to_date"
                  className="p-1 rounded"
                  value={
                    filter.to ? moment(filter?.to).format("YYYY-MM-DD") : ""
                  }
                  onChange={(e) => {
                    const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                    const toDate = selectedDate
                      ? new Date(new Date(selectedDate).setHours(0, 0, 0, 0)) // Convert to a date object
                      : undefined; // Reset if no date is selected

                    // Update the filter
                    setFilter({
                      ...filter,
                      to: toDate,
                    });
                  }}
                />
              </div>
              {(filter.from || filter.to) && (
                <button
                  title="Clear dates"
                  onClick={() =>
                    setFilter((o) => ({
                      ...o,
                      from: undefined,
                      to: undefined,
                    }))
                  }
                  className="flex gap-1 items-center cursor-pointer rounded p-1 border  r hover:scale-105 duration-200     transform"
                >
                  <Icon path={mdiClose} size={0.7}></Icon>
                </button>
              )}
              <div
                onClick={() => {
                  ExportProjectListData();
                }}
                className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
              >
                <Icon path={mdiArrowBottomLeft} size={0.8} />
                Export
              </div>
              <>
                {maker && !view && (
                  <div
                    onClick={() => {
                      navaigation.push(urls.procurement_project + "/create");
                    }}
                    className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200 text-white transform"
                  >
                    <Icon path={mdiPlus} className="h-4 w-4"></Icon>Create
                  </div>
                )}
              </>
              <>
                {maker && !view && (
                  <div
                    onClick={handleOnEdit}
                    className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200 text-white transform"
                  >
                    <Icon path={mdiPlus} className="h-4 w-4"></Icon>Update
                  </div>
                )}
              </>
            </div>
          </div>
        </section>

        <div className="grid grid-cols-2 gap-6 w-1/2 m-2">
          <div className="flex flex-col gap-1">
            <label className="text-md pt-1">Project ID</label>
            <SearchFieldWidget
              defaulValue={filter?.id}
              onClear={() => {
                setFilter({
                  ...filter,
                  id: undefined, // Fixed indentation
                });
                setPage(1);
              }}
              onSubmit={(val) => {
                setFilter({
                  ...filter,
                  id: val, // Fixed indentation
                });
                setPage(1);
              }}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-md pt-1">Project Name</label>
            <SearchFieldWidget
              defaulValue={filter?.name}
              onClear={() => {
                setFilter({
                  ...filter,
                  name: undefined, // Fixed indentation
                });
                setPage(1);
              }}
              onSubmit={(val) => {
                setFilter({
                  ...filter,
                  name: val, // Fixed indentation
                });
                setPage(1);
              }}
            />
          </div>

          <div className="flex flex-col gap-1">
            <label className="text-md">Project Status</label>
            <FilterDropDown
              placeholder="Project Status"
              onClearAll={() =>
                setFilter({
                  ...filter,
                  projectStatus: undefined, // Fixed indentation
                })
              }
              onDelete={(type) => {
                setFilter({
                  ...filter,
                  projectStatus: filter?.projectStatus?.filter(
                    (f: any) => f !== type
                  ),
                });
              }}
              onSelect={(type) => {
                setFilter((old) => ({
                  ...old,
                  projectStatus: [...(old?.projectStatus ?? []), type],
                }));
              }}
              selectedOptions={filter?.projectStatus ?? []}
              options={Object.values(ProjectCloseStatus).map((t) => ({
                label: t,
                value: t,
              }))}
            />
          </div>
        </div>
        <div className="my-2 border overflow-auto" style={{ height: "80%" }}>
          <table className="w-full     text-xs divide-y divide-gray-200 relative ">
            <thead className="  text-xs  py-2 sticky -top-1 bg-white z-10">
              <tr className="border ">
                <th>
                  <div className="">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      checked={
                        selectedItems.length === data.length ? true : false
                      }
                      //   disabled={checker && !maker ? false : true}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedItems([...data]);
                        } else {
                          setSelectedItems([]);
                        }
                        console.log(e);
                      }}
                    />
                  </div>
                </th>
                {/* <th className="px-2 py-2">Actions</th> */}
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Project ID
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">Name</th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Description
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Cost Center
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Budget
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Additional Funds
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Total Budget
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">Spent</th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Balance
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Project Status
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Created By
                </th>
              </tr>
            </thead>
            <tbody className="">
              {data.length > 0 ? (
                data.map((project, i) => {
                  const isSelected = selectedItems.includes(project);
                  return (
                    <tr className="border text-center " key={i}>
                      <td>
                        <div className="p-2">
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            checked={isSelected}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedItems((prev) => [...prev, project]); // Add the item to selected items
                              } else {
                                setSelectedItems((prev) =>
                                  prev.filter(
                                    (selectedItem) => selectedItem !== project
                                  )
                                ); // Remove the item from selected items
                              }
                            }}
                          />
                        </div>
                      </td>

                      <div
                        className="py-3 items-center cursor-pointer text-blue-600 hover:underline"
                        role="button"
                        onClick={() => onSelect(project)}
                      >
                        <td className="px-3 py-3 whitespace-nowrap text-left">
                          {project.id}
                        </td>
                      </div>

                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {project.name}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {project.description}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {project.cost_center_category}
                      </td>

                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {project?.budget ?? "--"}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {project?.totalAdditionalFunds ?? "--"}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {project?.totalBudget ?? "--"}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {project.spent ?? "--"}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {project?.totalBudget - project?.spent}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {project.projectStatus ?? "--"}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {project.created_by_name ?? "--"}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={13}>
                    <div className="h-56 text-center p-5 my-20">
                      <h5 className="text-gray-400 text-2xl">No Data found!</h5>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-3">
          <Pagination
            itemCount={count}
            page={filter.page}
            total={total}
            count={filter.count}
            onChange={
              (page) => setFilter((o) => ({ ...o, page }))
              // () => console.log("Pagination")
            }
          />
        </div>
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default ProjectListScreen;
