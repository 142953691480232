import {
  mdiHome,
  mdiOfficeBuilding,
  mdiHomeCity,
  mdiAccount,
  mdiPhone,
  mdiEmail,
  mdiMapMarker,
  mdiCheck,
  mdiChevronLeft,
  mdiChevronRight,
  mdiRadioactive,
  mdiCheckCircle,
  mdiCheckboxBlankCircleOutline,
  mdiCheckCircleOutline,
  mdiCheckboxMarkedCircle,
  mdiCheckboxMarkedCircleOutline,
  mdiRadioboxBlank,
  mdiRadioboxMarked,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useOrderCreateCtx } from "../../../../../../context_providers/order_create_context";
import {
  ToastType,
  useToast,
} from "../../../../../../context_providers/toast/toast_ctx";
import { CustomerDetailModel } from "../../../../../../service/models/orders/customer_detail_model";
import { GetBillingProfilesOfUserApi } from "../../../../../../service/repos/customer/billing_profile_repo";
import { AddressType, OrderType } from "../../../../../../utils/enums";
import ModalDialog from "../../../../../new_components/common/modal";
import SearchableField from "../../../../../new_components/common/search_with_suggestion/search_with_suggestion_field";
import AddBillingProfileWidget from "./add_billing_profile_widget";

interface NewBillingProfileWidgetProps {}

const NewBillingProfileWidget: FunctionComponent<
  NewBillingProfileWidgetProps
> = () => {
  const { showToast } = useToast();
  const { order, user, billing, setBilling, deliveryProfile } =
    useOrderCreateCtx();

  const [same, setSame] = useState(true);
  const [addNew, setAddNew] = useState(false);
  const [edit, setEdit] = useState(false);
  const [customers, setCustomers] = useState<CustomerDetailModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const getCustomers = async () => {
    setLoading(true);
    const res = await GetBillingProfilesOfUserApi(user?.uid);

    if (res.success) {
      setCustomers(res.data.billing_profiles);
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  useEffect(() => {
    if (!customers.length && !same) getCustomers();
  }, [same]);
  // useEffect(() => {
  //   if (billing) getCustomers();
  // }, [billing]);
  useEffect(() => {
    if (billing) setCustomers((o) => [...o, billing]);
  }, [billing]);

  useEffect(() => {
    if (same && deliveryProfile) {
      setBilling(deliveryProfile);
    } else {
      setBilling(undefined);
    }
  }, [same, deliveryProfile]);
  const l = page * 2 - 1;
  const h = l + 2 - 1;
  console.log({ l, h });

  return (
    <>
      {addNew && (
        <ModalDialog
          title="Add Billing Address"
          show={addNew}
          onClose={() => setAddNew(false)}
        >
          <div className="p-5">
            <AddBillingProfileWidget
              user_id={user?.uid}
              onAdded={(data) => {
                setBilling(data);
                setAddNew(false);
                setCustomers((o) => [...o, data]);
                setPage(Math.ceil(customers.length / 2));
              }}
            />
          </div>
        </ModalDialog>
      )}

      <div className="flex justify-between gap-2 items-end my-2 mt-5 ">
        {/* <div className="flex  gap-5">
          <div
            className="flex justify-between items-end 
          "
          >
            <h3 className="font-semibold">
              {" "}
              <span className="text-base text-red-500">*</span>Delivery profile
            </h3>
          </div>
          <div className="flex  items-center gap-2 ">
            <input
              disabled={addNew || edit}
              checked={same}
              onChange={(e) => setSame(e.target.checked)}
              type="checkbox"
              name=""
              id="same_as_billing"
            />
            <label htmlFor="same_as_billing">Same as billing profile</label>
          </div>
        </div> */}

        <div className="flex items-center gap-1 mx-12 ">
          <button
            onClick={() => setSame((o) => !o)}
            className="flex gap-1 items-center"
          >
            <Icon
              className="text-teal"
              path={same ? mdiRadioboxMarked : mdiRadioboxBlank}
              size={0.8}
            />
            <p>Same as delivery address</p>
          </button>
        </div>
        <div className="flex gap-2  ">
          {edit && (
            <button
              disabled={same}
              onClick={() => {
                setEdit(false);
              }}
              className="cursor-pointer border  px-3 py-1 rounded border-red-400   text-red-400 font-semibold"
            >
              Cancel
            </button>
          )}

          {!same && (
            <button
              disabled={same}
              onClick={() => {
                if (!user) {
                  showToast({
                    type: ToastType.info,
                    text: "Please select customer ",
                  });
                  return;
                }
                setAddNew(true);
              }}
              className="cursor-pointer    text-white bg-myPrimaryColor px-3 py-1 rounded font-semibold"
            >
              Add new
            </button>
          )}
        </div>
      </div>

      {!same && (
        <div className="flex items-center gap-1">
          <button
            onClick={() => setPage((p) => p - 1)}
            className={`p-1  flex justify-center items-center bg-white shadow rounded-full ${
              page <= 1 && "invisible"
            }`}
          >
            <Icon path={mdiChevronLeft} className="h-10 w-10 text-gray-400" />
          </button>
          <div
            className="rounded-md text-sm flex w-full"
            style={{ minHeight: "120px" }}
          >
            <div className=" grid grid-cols-2 gap-5 w-full">
              {customers
                .filter((f, i) => {
                  return i >= l - 1 && i <= h - 1;
                })
                .map((customer, i) => (
                  <div
                    // style={{ width: "400px" }}
                    className={`cursor-pointer bg-white hover:bg-green-50 h-60 w-full p-5 rounded-lg border ${
                      billing?.id === customer.id
                        ? "border-green-400"
                        : "border-gray-200"
                    }`}
                    key={i}
                    onClick={() => {
                      setBilling(customer);
                      setEdit(false);
                    }}
                  >
                    <div className="flex gap-1 justify-between mb-2">
                      <input
                        style={{ accentColor: "green" }}
                        className="w-5 h-5"
                        type="radio"
                        name=""
                        id=""
                        checked={billing?.id === customer.id}
                      />

                      <p className="  rounded-3xl px-3 py-1   text-teal bg-green-50">
                        {customer.address.type}
                      </p>
                    </div>
                    <div className="flex gap-2 my-1">
                      {/* <span>
                    <Icon
                      path={mdiAccount}
                      className="h-4 w-4 text-gray-500"
                    ></Icon>
                  </span> */}
                      <p>
                        <b>
                          {customer.firstName} {customer.lastName}
                        </b>
                      </p>
                    </div>
                    <div className="flex gap-1 my-1">
                      {/* <Icon
                    path={mdiPhone}
                    className="h-4 w-4 text-gray-500"
                  ></Icon> */}

                      <p>
                        {customer.mobile} , {customer.alt_mobile}
                      </p>
                    </div>
                    <div className="flex gap-1 my-1">
                      {/* <Icon
                    path={mdiEmail}
                    className="h-4 w-4 text-gray-500"
                  ></Icon> */}
                      <p>{customer.email}</p>
                    </div>
                    <hr className="my-2" />
                    <div className="flex gap-1">
                      GST # :<p>{customer.gst ?? "--"}</p>
                    </div>
                    <div className="flex gap-1">
                      CA # :<p>{customer.ca ?? "--"}</p>
                    </div>
                    <hr className="my-2" />
                    <div className="flex gap-1 text-xs text-gray-500">
                      {/* <Icon
                    path={mdiMapMarker}
                    className="h-4 w-4 text-gray-500"
                  ></Icon> */}
                      <p className="h-8 overflow-hidden overflow-ellipsis">
                        {customer.address.line1},{customer.address.line2},
                        {customer.address.city},{customer.address.state},
                        {customer.address.country}-{customer.address.pincode}
                      </p>
                    </div>
                  </div>
                ))}
            </div>

            {/* {deliveryProfile && !edit ? (
          <div className="p-5">
            <div className="flex justify-end">
              <button
                disabled={same}
                onClick={() => setEdit(true)}
                className="cursor-pointer hover:scale-125 transform duration-300 text-blue-400 font-semibold"
              >
                Edit
              </button>
            </div>
            <div className="">
              <div className="flex gap-1">
                <span>
                  <Icon
                    path={
                      deliveryProfile.address.type === AddressType.HOME
                        ? mdiHome
                        : deliveryProfile.address.type === AddressType.OFFICE
                        ? mdiOfficeBuilding
                        : mdiHomeCity
                    }
                    className="h-4 w-4 text-gray-500"
                  ></Icon>
                </span>
                <p>
                  <b>{deliveryProfile.address.type}</b>
                </p>
              </div>
              <div className="flex gap-1">
                <span>
                  <Icon
                    path={
                      order.type === OrderType.B2C ||
                      order.type === OrderType.DELHI_TENDOR
                        ? mdiAccount
                        : mdiOfficeBuilding
                    }
                    className="h-4 w-4 text-gray-500"
                  ></Icon>
                </span>
                <p>
                  <b>
                    {deliveryProfile.firstName} {deliveryProfile.lastName}
                  </b>
                </p>
              </div>
              {(order.type === OrderType.B2B ||
                order.type === OrderType.PARTNER) && (
                <div className="flex gap-1">
                  <span>
                    <Icon
                      path={mdiAccount}
                      className="h-4 w-4 text-gray-500"
                    ></Icon>
                  </span>
                  <p>
                    <b>{deliveryProfile.poc}</b>
                  </p>
                </div>
              )}
              <div className="flex gap-1">
                <Icon path={mdiPhone} className="h-4 w-4 text-gray-500"></Icon>

                <p>
                  {deliveryProfile.mobile} , {deliveryProfile.alt_mobile}
                </p>
              </div>
              <div className="flex gap-1">
                <Icon path={mdiEmail} className="h-4 w-4 text-gray-500"></Icon>
                <p>{deliveryProfile.email}</p>
              </div>
              <hr />
              <div className="flex gap-1">
                GST # :<p>{deliveryProfile.gst ?? "--"}</p>
              </div>
              <div className="flex gap-1">
                CA # :<p>{deliveryProfile.ca ?? "--"}</p>
              </div>
              <hr className="my-2" />
              <div className="flex gap-1">
                <Icon
                  path={mdiMapMarker}
                  className="h-4 w-4 text-gray-500"
                ></Icon>
                <p className="">
                  {deliveryProfile.address.line1},
                  {deliveryProfile.address.line2},{deliveryProfile.address.city}
                  ,{deliveryProfile.address.state},
                  {deliveryProfile.address.country}-
                  {deliveryProfile.address.pincode}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="">
            <div className="flex flex-col divide-y max-h-96 overflow-auto">
              {customers.map((customer, i) => (
                <div
                  className="cursor-pointer hover:bg-blue-50 px-5 py-3"
                  key={i}
                  onClick={() => {
                    setDeliveryProfile(customer);
                    setEdit(false);
                  }}
                >
                  {" "}
                  {deliveryProfile?.id === customer.id && (
                    <div className="flex justify-end">
                      <div className=" flex gap-1 border rounded-3xl border-green-400  text-green-400 px-2 py-1 text-xs">
                        <Icon path={mdiCheck} className="h-4 w-4"></Icon>{" "}
                        Selected
                      </div>
                    </div>
                  )}
                  <div className="flex gap-1">
                    <span>
                      <Icon
                        path={
                          customer.address.type === AddressType.HOME
                            ? mdiHome
                            : customer.address.type === AddressType.OFFICE
                            ? mdiOfficeBuilding
                            : mdiHomeCity
                        }
                        className="h-4 w-4 text-gray-500"
                      ></Icon>
                    </span>
                    <p>
                      <b>{customer.address.type}</b>
                    </p>
                  </div>
                  <div className="flex gap-1">
                    <span>
                      <Icon
                        path={mdiAccount}
                        className="h-4 w-4 text-gray-500"
                      ></Icon>
                    </span>
                    <p>
                      <b>
                        {customer.firstName} {customer.lastName}
                      </b>
                    </p>
                  </div>
                  <div className="flex gap-1">
                    <Icon
                      path={mdiPhone}
                      className="h-4 w-4 text-gray-500"
                    ></Icon>

                    <p>
                      {customer.mobile} , {customer.alt_mobile}
                    </p>
                  </div>
                  <div className="flex gap-1">
                    <Icon
                      path={mdiEmail}
                      className="h-4 w-4 text-gray-500"
                    ></Icon>
                    <p>{customer.email}</p>
                  </div>
                  <hr />
                  <div className="flex gap-1">
                    GST # :<p>{customer.gst ?? "--"}</p>
                  </div>
                  <div className="flex gap-1">
                    CA # :<p>{customer.ca ?? "--"}</p>
                  </div>
                  <hr className="my-2" />
                  <div className="flex gap-1">
                    <Icon
                      path={mdiMapMarker}
                      className="h-4 w-4 text-gray-500"
                    ></Icon>
                    <p className="">
                      {customer.address.line1},{customer.address.line2},
                      {customer.address.city},{customer.address.state},
                      {customer.address.country}-{customer.address.pincode}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )} */}
          </div>
          <button
            onClick={() => setPage((p) => p + 1)}
            className={`p-1 flex justify-center items-center bg-white shadow rounded-full ${
              !(page < customers.length / 2) && "invisible"
            }`}
          >
            <Icon path={mdiChevronRight} className="h-10 w-10 text-gray-400" />
          </button>
        </div>
      )}
      {/* <p className="italic text-xs">
        Please select customer and then select delivery profile.
      </p> */}
    </>
  );
};

export default NewBillingProfileWidget;