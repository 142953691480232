/* eslint-disable react-hooks/exhaustive-deps */
import {
  mdiRefresh,
  mdiPlus,
  mdiExport,
  mdiMenuDown,
  mdiMenuUp,
  mdiClose,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { urls } from "../../../utils/urls";
import {
  defaultPartyFilter,
  PartyFilter,
  PartyModel,
  PartyStatus,
  PartyStatusMap,
  PartyType,
  taxRegType,
} from "../models/party_model";
import { useAuth } from "../../../context_providers/auth/authProvider";
import { ACTION, MODULE } from "../../../utils/enums";
import VendorSearchField from "./components/vendor_code_search_widget";
import { debounce } from "lodash";
import Pagination from "../../../ui/new_components/common/pagination";
import { ExportRepo, GetVendorRepo } from "./repo/parties_repo";
import { stateData } from "../../../utils/states_data";
import moment from "moment";

interface PartyListScreenProps {}

const PartyListScreen: FunctionComponent<PartyListScreenProps> = () => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<PartyModel[]>([]);
  const [isMaker, setIsMaker] = useState<boolean>(false);
  const [isChecker, setIsChecker] = useState<boolean>(false);
  const { user, isAuthorised } = useAuth();
  const [filter, setFilter] = useState<PartyFilter>(defaultPartyFilter);
  const [states, setStates] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<PartyModel[]>([]);
  const [filterCollaped, setFilterCollapsed] = useState(true);

  const fetchData = async (prop: PartyFilter) => {
    setLoading(true);
    try {
      const res = await GetVendorRepo(prop);
      console.log(res);
      if (res.success) {
        setData(res.data.result);
        console.log("vendor list:",data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  const exportData = async () => {
    setLoading(true);
    try {
      // const res = await fetch(url + "/export", {
      //   method: "post",
      //   body: JSON.stringify({ fields: exportFields }),
      //   headers: { ...getHeaders(), "Content-Type": "application/json" },
      //   credentials: "include",
      // });
      const res = await ExportRepo(filter);

      if (!res.ok) {
        return showToast({ type: ToastType.error, text: res.statusText });
      }

      const header = res.headers.get("Content-Disposition");
      const parts = header?.split(";");
      let downloadFilename = "";
      if (parts) {
        downloadFilename = parts[1].split("=")[1];
        downloadFilename = downloadFilename.substring(
          1,
          downloadFilename.length - 1
        );
      }
      const blob = await res.blob();

      const fileUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", downloadFilename ?? `export.csv`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log("Data", data);
  }, [data]);

  useEffect(() => {
    const checker_permission_result = isAuthorised({
      module: MODULE.master_vendor_checker,
      action: ACTION.READ,
    });
    const maker_permission_result = isAuthorised({
      module: MODULE.master_vendor_maker,
      action: ACTION.CREATE,
    });
    setIsChecker(checker_permission_result);
    setIsMaker(maker_permission_result);
    console.log("Checker", checker_permission_result);
    console.log("Maker", maker_permission_result);
  }, []);

  useEffect(() => {
    const allStates = stateData.map((state) => state.name);
    setStates(allStates);
  }, []);

  useEffect(() => {
    console.log("Filter", filter);
  }, [filter]);

  const debouncedHandler = useCallback(
    debounce((prop: PartyFilter) => {
      if (prop) fetchData(prop);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedHandler(filter);
  }, [page, filter, debouncedHandler]);

  const navaigation = useHistory();

  const onSelect = (d: PartyModel) => {
    if (isMaker) {
      if (
        d.status === PartyStatusMap.Approved ||
        d.status === PartyStatusMap.Rejected ||
        d.status === PartyStatusMap["Pending for Approval"] ||
        d.status === PartyStatusMap["Update Pending"]
      ) {
        navaigation.push(
          urls.procurement_masters_party + "/view-details/" + d.id
        );
      } else if (d.status === PartyStatusMap["Save Draft"]) {
        navaigation.push(urls.procurement_masters_party + "/create/" + d.id);
      } else
        navaigation.push(urls.procurement_masters_party + "/update/" + d.id);
    } else if (isChecker) {
      if (
        d.status === PartyStatusMap["Save Draft"] ||
        d.status === PartyStatusMap.Approved ||
        d.status === PartyStatusMap.Rejected
      ) {
        navaigation.push(
          urls.procurement_masters_party + "/view-details/" + d.id
        );
      } else {
        // Navigate to approve page
        navaigation.push(
          urls.procurement_masters_party + "/approve-reject/" + d.id
        );
      }
    }
  };

  function handleUpdate(): void {
    if (selectedItems.length > 1) {
      showToast({
        type: ToastType.error,
        text: "Please select one item to edit",
      });
    } else if (selectedItems.length === 0) {
      showToast({
        type: ToastType.error,
        text: "Please select an item to edit",
      });
    } else {
      if (selectedItems[0]?.status === PartyStatusMap["Save Draft"]) {
        navaigation.push(
          urls.procurement_masters_party + "/create/" + selectedItems[0]!.id
        );
      } else if (selectedItems[0]?.status !== PartyStatusMap.Approved) {
        navaigation.push(
          urls.procurement_masters_party + "/update/" + selectedItems[0]!.id
        );
      } else {
        showToast({
          type: ToastType.error,
          text: "Approved Item cannot be updated",
        });
      }
    }
  }

  return (
    <>
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section
          id="top_section"
          className="flex flex-wrap justify-between items-end my-1 "
        >
          <h1 className="font-bold text-2xl text-gray-500border-l-4 border-myPrimaryColor pl-2 flex items-center gap-3">
            Vendor
            <button title="Refresh" onClick={() => fetchData(filter)}>
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>

          <div className="flex justify-end text-sm items-center gap-2 m-2">
            <div className="flex items-center  gap-1">
              <label htmlFor="fromDate" className="">
                From
              </label>
              <input
                type="date"
                id="fromDate"
                className="p-1 rounded"
                value={
                  filter.from_date
                    ? moment(filter?.from_date).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                  const fromDate = selectedDate
                    ? new Date(new Date(selectedDate).setHours(0, 0, 0, 0)) // Convert to a date object
                    : undefined; // Reset if no date is selected

                  // Update the filter
                  setFilter({
                    ...filter,
                    from_date: fromDate,
                  });
                }}
              />

              <div className="flex items-center  gap-1">
                <label htmlFor="fromDate" className="">
                  To
                </label>
                <input
                  type="date"
                  id="toDate"
                  className="p-1 rounded"
                  value={
                    filter.to_date
                      ? moment(filter?.to_date).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                    const toDate = selectedDate
                      ? new Date(new Date(selectedDate).setHours(0, 0, 0, 0)) // Convert to a date object
                      : undefined; // Reset if no date is selected

                    // Update the filter
                    setFilter({
                      ...filter,
                      to_date: toDate,
                    });
                  }}
                />
              </div>
              {(filter.from_date || filter.to_date) && (
                <button
                  title="Clear dates"
                  onClick={() =>
                    setFilter((o) => ({
                      ...o,
                      from_date: undefined,
                      to_date: undefined,
                    }))
                  }
                  className="flex gap-1 items-center cursor-pointer rounded p-1 border  r hover:scale-105 duration-200     transform"
                >
                  <Icon path={mdiClose} size={0.7}></Icon>
                </button>
              )}
            </div>
            {isMaker && (
              <>
                <div
                  onClick={() => {
                    navaigation.push(
                      urls.procurement_masters_party + "/create"
                    );
                  }}
                  className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
                >
                  <Icon path={mdiPlus} className="h-4 w-4"></Icon>Create
                </div>
                <div
                  onClick={handleUpdate}
                  className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
                >
                  <Icon path={mdiPlus} className="h-4 w-4"></Icon>Update
                </div>
              </>
            )}

            <div
              onClick={exportData}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiExport} className="h-4 w-4"></Icon>Export
            </div>
            <div className="flex gap-2">
              <h6 className="font-normal">Filters</h6>
              <div
                onClick={() => setFilterCollapsed(!filterCollaped)}
                className="flex   items-center justify-center bg-gray-200 p-1 rounded-full hover:bg-gray-800 hover:text-white"
              >
                <Icon
                  path={filterCollaped ? mdiMenuDown : mdiMenuUp}
                  className="w-4 h-4"
                />
              </div>
            </div>
          </div>
        </section>
        {/* Filter Section */}
        {!filterCollaped && (
          <section>
            <div className="grid grid-cols-2 gap-6 w-1/2 m-2">
              <div className="">
                <label htmlFor="app_status" className="">
                  Vendor Code
                </label>
                <VendorSearchField
                  defaulValue={filter.id ?? ""}
                  onClear={() => {
                    setFilter((prevData) => ({
                      ...prevData,
                      id: "", // Directly updating item_code
                    }));
                  }}
                  onSubmit={(value: string) =>
                    setFilter((prevData) => ({
                      ...prevData,
                      id: value, // Directly updating item_code
                    }))
                  }
                />
              </div>
              <div className="">
                <label htmlFor="app_status" className="">
                  Vendor Name
                </label>
                <VendorSearchField
                  defaulValue={filter.name ?? ""}
                  onClear={() => {
                    setFilter((prevData) => ({
                      ...prevData,
                      name: "", // Directly updating item_code
                    }));
                  }}
                  onSubmit={(value: string) =>
                    setFilter((prevData) => ({
                      ...prevData,
                      name: value, // Directly updating item_code
                    }))
                  }
                />
              </div>
              <div className="">
                <label htmlFor="item_type" className="">
                  Vendor Type
                </label>
                <select
                  className="w-full p-2 bg-white rounded focus:outline-none"
                  name="item_type"
                  id="item_type"
                  value={filter.type}
                  onChange={(e) => {
                    setFilter((prevData) => ({
                      ...prevData,
                      type: e.target.value, // Directly updating type
                    }));
                  }}
                >
                  <option value="">select</option>
                  {PartyType.map((f: string) => (
                    <option key={f} value={f}>
                      {f}
                    </option>
                  ))}
                </select>
              </div>

              <div className="">
                <label htmlFor="item_type" className="">
                  GST Reg Type
                </label>
                <select
                  className="w-full p-2 bg-white rounded focus:outline-none"
                  name="reg_type"
                  id="reg_type"
                  value={filter.reg_type}
                  onChange={(e) => {
                    setFilter((prevData) => ({
                      ...prevData,
                      reg_type: e.target.value, // Directly updating type
                    }));
                  }}
                >
                  <option value="">Select</option>
                  {Object.values(taxRegType).map((c, i) => (
                    <option value={c} key={i}>
                      {c}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="sub_category" className="">
                  State
                </label>
                <select
                  className="w-full p-2 bg-white rounded focus:outline-none"
                  name="state"
                  id="state"
                  value={filter.state ?? ""}
                  onChange={(e) => {
                    // Handle the change event
                    setFilter((prevData) => ({
                      ...prevData,
                      state: e.target.value, // Update the sub_category in state
                    }));
                  }}
                >
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
              <div className="">
                <label htmlFor="app_status" className="">
                  City
                </label>

                <VendorSearchField
                  defaulValue={filter.id ?? ""}
                  onClear={() => {
                    setFilter((prevData) => ({
                      ...prevData,
                      city: "", // Directly updating item_code
                    }));
                  }}
                  onSubmit={(value: string) =>
                    setFilter((prevData) => ({
                      ...prevData,
                      city: value, // Directly updating item_code
                    }))
                  }
                />
              </div>
              <div>
                <label htmlFor="app_status">Approval Status</label>
                <select
                  className="w-full p-2 rounded focus:outline-none bg-white"
                  name="app_status"
                  id="app_status"
                  value={filter.status ?? ""}
                  onChange={(e) => {
                    setFilter((prevData) => ({
                      ...prevData,
                      status: e.target.value as PartyStatus, // Correctly updating status
                    }));
                  }}
                >
                  <option value="">Select</option>
                  {Object.entries(PartyStatusMap).map(([key, value]) => (
                    <option key={key} value={value}>
                      {key}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </section>
        )}
        <div className="my-2 border overflow-auto" style={{ height: "80%" }}>
          <table className="w-full     text-xs divide-y divide-gray-200 relative ">
            <thead className="  text-xs   py-2 sticky -top-1 bg-white z-10">
              <tr className="border ">
                <th>
                  <div className="">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      checked={
                        // false
                        selectedItems?.length === data?.length ? true : false
                      }
                      disabled={isChecker && !isMaker ? false : true}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedItems([...data]);
                        } else {
                          setSelectedItems([]);
                        }
                        console.log(e);
                      }}
                    />
                  </div>
                </th>
                <th className="px-2 py-2 text-left">Vendor Code</th>
                <th className="px-2 py-2 text-left">Vendor Name</th>
                <th className="px-2 py-2 text-left">Created At</th>
                <th className="px-2 py-2 text-left">Type</th>
                <th className="px-2 py-2 text-left">MSME Registration</th>
                <th className="px-2 py-2 text-left">GST Reg type</th>
                <th className="px-2 py-2 text-left">City</th>
                <th className="px-2 py-2 text-left">State</th>
                <th className="px-2 py-2 text-left">Status</th>
              </tr>
            </thead>
            <tbody className="">
              {data.length > 0 ? (
                data.map((item, i) => {
                  const isSelected = selectedItems.includes(item);
                  return (
                    <>
                      <tr className="border text-center " key={i}>
                        <td>
                          <div className="p-2">
                            <input
                              type="checkbox"
                              name=""
                              id=""
                              checked={isSelected}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedItems((prev) => [...prev, item]); // Add the item to selected items
                                } else {
                                  setSelectedItems((prev) =>
                                    prev.filter(
                                      (selectedItem) => selectedItem !== item
                                    )
                                  ); // Remove the item from selected items
                                }
                              }}
                            />
                          </div>
                        </td>

                        {/* Item code */}
                        <div
                          className="py-3 items-center cursor-pointer text-blue-600 hover:underline"
                          role="button"
                          onClick={() => onSelect(item)}
                        >
                          <td className="px-3 py-2 text-left">{item.id}</td>
                        </div>
                        {/* Item Name */}
                        <td className="px-3 py-2 text-left">{item.name}</td>
                        <td className="px-3 py-2 text-left">{moment(item.created_at).format("DD-MM-YYYY")}</td>
                        <td
                          className="px-3 py-2  text-left"
                          // onClick={() => setUpdateData(d)}
                        >
                          {item.type ?? "--"}
                        </td>
                        <td className="px-3 py-2 text-left">
                          {item.msme_registration?.msme_registration_number}
                        </td>
                        {/* TODO: MPN Field is there */}
                        <td className="px-3 py-2 text-left">
                          {item.tax_detail.reg_type ?? "--"}
                        </td>
                        <td className="px-3 py-2 text-left">
                          {item.mail_detail.address.city ?? "--"}
                        </td>
                        <td className="px-3 py-2 text-left">
                          {item.mail_detail.address.state ?? "--"}
                        </td>
                        <td className="px-3 py-2 text-left">
                          {item.status ?? "--"}
                        </td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={13}>
                    <div className="h-56 text-center p-5 my-20">
                      <h5 className="text-gray-400 text-2xl">No Data found!</h5>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-3">
          <Pagination
            itemCount={filter.count}
            page={filter.page}
            total={total}
            count={filter.count}
            onChange={
              (page) => setFilter((o) => ({ ...o, page }))
              // () => console.log("Pagination")
            }
          />
        </div>
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default PartyListScreen;
