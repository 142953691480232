import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import {
  InventoryImportData,
  InventoryModel,
} from "../../../../service/models/inventory/inventory_model";
import { GetInventoryListApi } from "../../../../service/repos/inventory_repo";
import { debounce } from "../../../../utils/debounce";
import AutoCompleteField from "../../../new_components/common/autocomplete";
import { uniqueId } from "lodash";
import { GetAvailableDevicesAPI } from "../../../../service/repos/order_repo";
import {
  AvailableDevices,
  ItemMovementModelV2,
} from "../../../../erp/inventory/models/inventory_voucher";

interface InventoryDropDownProps {
  onSelect: (val: { unique_id: string; name: string }) => void;
  selected: { unique_id: string; name: string }[];
  disabled?: boolean;
  status?: string[];
  product_id: string; // item_code
}

const InventoryDropDownV2: FunctionComponent<InventoryDropDownProps> = ({
  selected,
  onSelect,
  disabled = false,
  status,
  product_id,
}) => {
  const { showToast } = useToast();
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<
    { unique_id: string; name: string }[]
  >([]);
  const [loading, setLoading] = useState(false);
  // const [inventories, setInventories] = useState<InventoryModel[]>([]);
  const [inventories, setInventories] = useState<AvailableDevices[]>([]);
  const [search, setSearch] = useState<string>("");
  const searchInventory = async (search: string) => {
    setLoading(true);

    const res = await GetAvailableDevicesAPI({
      unique_id: search,
      item_code: product_id,
    });

    if (res.success) {
      setInventories(res.data);
    } else {
      setInventories([]);
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    if (search) {
      debounce(() => searchInventory(search));
    } else setInventories([]);
  }, [search]);
  useEffect(() => {
    setSelectedDeviceIds(selected);
  }, [selected]);
  return (
    <>
      <div className="flex gap-1 flex-col items-start flex-wrap">
        <label htmlFor="device">Devices ({selectedDeviceIds.length})</label>
        <AutoCompleteField
          disabled={disabled}
          loading={loading}
          value={search}
          placeholder={"Device"}
          onSelect={(selected_value) => {
            const device = selected_value.value;

            if (
              !device ||
              !device.uniqueIds.unique_id ||
              !device.uniqueIds.item_name
            ) {
              showToast({
                type: ToastType.error,
                text: "Invalid device selected",
              });
              return;
            }

            const notContain = selectedDeviceIds.every(
              (v) => v.unique_id !== device.uniqueIds.unique_id
            );

            if (notContain) {
              const newSelection = {
                unique_id: device.uniqueIds.unique_id,
                name: device.uniqueIds.item_name,
              };

              onSelect(newSelection);
              setSelectedDeviceIds((prev) => [...prev, newSelection]);
              setSearch("");
            } else {
              showToast({
                type: ToastType.warning,
                text: `${device.uniqueIds.unique_id} (${device.uniqueIds.item_name}) already selected`,
              });
            }
          }}
          suggestions={inventories.map((e) => ({
            label: (
              <div className="text-xs">
                <b> {e.item?.item_code} </b>
                <span style={{ fontSize: "10px" }}>({e.item?.name})</span>
              </div>
            ),

            value: e,
          }))}
          onChange={(str) => {
            setSearch(str);
          }}
        />
        <p className="text-xs text-blue-400">
          note : Please select device id from suggestions .
        </p>
      </div>
    </>
  );
};

export default InventoryDropDownV2;
