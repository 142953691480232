import { debounce } from "lodash";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../../../context_providers/toast/toast_ctx";
import AutoCompleteField from "../../../../../../ui/new_components/common/autocomplete";
import { GetWareHouseListApi } from "../../../../inventory_basket/components/warehouse_repo";
import {
  PartyFilter,
  PartyModel,
} from "../../../../../accounts/models/party_model";
import { GetVendorRepo } from "../../../../../accounts/parties/repo/parties_repo";

interface VendorMultiSelectProps {
  onSelect: (data: PartyModel) => void;
  onDelete: (uid: string) => void;
  selectedVendors: PartyModel[];
  placeholder?: string;
  disabled?: boolean;
  withBtn?: boolean;
}

const VendorMultiSelect: FunctionComponent<VendorMultiSelectProps> = ({
  onSelect,
  onDelete,
  selectedVendors: selectedWarehouses,
  placeholder,
  disabled = false,
}) => {
  const { showToast } = useToast();
  const [search, setSearch] = useState("");
  const [vendors, setVendors] = useState<PartyModel[]>([]);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit) setSearch(""); // Clear search when entering edit mode
  }, [edit]);

  const getVendors = async (prop: PartyFilter) => {
    setLoading(true);
    try {
      const res = await GetVendorRepo(prop);
      if (res.success) {
        setVendors(res.data.result);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce((query) => {
      let prop: PartyFilter = {
        id: "",
        type: "",
        gst_no: "",
        reg_type: "",
        name: query,
        page: 1,
        count: 20,
        all: true,
        city: "",
        state: "",
        status: "",
      };
      if (query) getVendors(prop);
    }, 500),
    []
  );

  useEffect(() => {
    if (search && edit) {
      debouncedHandler(search);
    }
  }, [search, edit, debouncedHandler]);

  return (
    <div className="flex flex-col gap-1 w-full">
      <div className="flex gap-1 items-center">
        {edit ? (
          <AutoCompleteField
            loading={loading}
            value={search}
            placeholder={placeholder ?? "Vendor"}
            onSelect={(val) => {
              setEdit(false);
              setSearch("");
              onSelect(val.value);
            }}
            suggestions={vendors.map((e) => ({
              label: (
                <div className="text-xs">
                  <b>{e.name}</b>
                </div>
              ),
              value: e,
            }))}
            onChange={(str) => {
              setSearch(str);
            }}
          />
        ) : (
          <div
            className="text-sm px-7 py-2 h-8 rounded text-left items-start bg-white focus:outline-none w-full border"
            onClick={() => {
              if (!disabled) {
                setEdit(true);
              }
            }}
          >
            {placeholder}
          </div>
        )}
      </div>

      {/* Render selected employees as pills */}
      <div className="flex flex-wrap gap-1 mt-2">
        {selectedWarehouses.map((warehouse) => (
          <span
            key={warehouse.id}
            className="bg-gray-200 text-xs py-1 px-2 rounded flex items-center"
          >
            {warehouse.name}
            <button
              onClick={() => onDelete(warehouse.id)}
              className="ml-1 text-red-500"
            >
              x
            </button>
          </span>
        ))}
      </div>
    </div>
  );
};

export default VendorMultiSelect;
