import {
  mdiBank,
  mdiCurrencyRupee,
  mdiEmailFast,
  mdiLocationEnter,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { fetchGet } from "../../../service/api_client";
import { ACTION, FileType, MODULE } from "../../../utils/enums";
import { updateObject } from "../../../utils/formDataToJson";
import { urls } from "../../../utils/urls";

import {
  CityType,
  CountryType,
  LegalEntityType,
  PartyModel,
  PartyStatusMap,
  PartyType,
  StateType,
  defaulPartyValue,
  taxRegType,
} from "../models/party_model";

import { getFileType } from "../../purchase_order/model/purchase_order_model";

import { dataTypes } from "../../../utils/import_export_parser";

import { VendorAttachmentsTypes } from "./enums/vendor_enums";
import { useAuth } from "../../../context_providers/auth/authProvider";
import { Country } from "country-state-city";
import { stateData } from "../../../utils/states_data";

interface VendorDetailScreenProps {
  onClose: (data?: PartyModel) => void;
  data?: PartyModel;
}

const VendorDetailScreen: FunctionComponent<VendorDetailScreenProps> = (
  props
) => {
  const { id } = useParams<{ id: string }>();
  const navigation = useHistory();
  const detail_page = useRouteMatch(
    urls.procurement_masters_party + "/view-details/:id"
  );
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/party`;
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [vendor, setVendor] = useState<PartyModel>({
    ...(props.data || defaulPartyValue),
  });

  // const [msmeFileSelection, setMsmeFileSelection] =
  //   useState<AttachmentModel[]>();
  // const [gstFileSelectionList, setGstFileSelectionList] = useState<
  //   AttachmentModel[]
  // >([]);

  const { user, isAuthorised } = useAuth();
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [isMaker, setIsMaker] = useState<boolean>(false);
  const [creating, setCreating] = useState(false);
  const [newGstNo, setNewGstNo] = useState<string>();

  const [countries, setCountries] = useState<CountryType[]>([]);

  // Selected values
  const [selectedCountry, setSelectedCountry] = useState<string>("");

  useEffect(() => {
    const allCountries = Country.getAllCountries();
    setCountries(allCountries);
  }, []);

  const handleChange = (e: any) => {
    const { value, name } = e.target;
    const type = e.target.getAttribute("data-type");
    const keys = name.split(".");
    const updatedValue = updateObject(vendor, keys, value, type);
    console.log(updatedValue);
    setVendor(updatedValue);
  };

  const handleAddGstNo = () => {
    if (newGstNo && !vendor.tax_detail.gst_no.includes(newGstNo)) {
      const updatedGstNoList = [...vendor.tax_detail.gst_no, newGstNo];
      setVendor({
        ...vendor,
        tax_detail: {
          ...vendor.tax_detail,
          gst_no: updatedGstNoList, // Add an empty string for a new input
        },
      });
      setNewGstNo(""); // Clear input after adding
    }
  };

  // Handles removing a GST number
  const removeGSTNumber = (index: number) => {
    const updatedGSTNumbers = vendor.tax_detail.gst_no.filter(
      (_, i) => i !== index
    );
    setVendor({
      ...vendor,
      tax_detail: {
        ...vendor.tax_detail,
        gst_no: updatedGSTNumbers,
      },
    });
  };

  useEffect(() => {
    let is_maker: Boolean = isAuthorised({
      module: MODULE.master_vendor_checker,
      action: ACTION.CREATE,
    });
    if (is_maker) {
      setIsMaker(true);
    } else {
      setIsMaker(false);
    }
    if (detail_page !== null) {
      setCanEdit(false);
    }

    console.log("canEdit", canEdit);
  }, []);

  useEffect(() => {
    if (id && detail_page) getData(id);
  }, []);
  const getData = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      setVendor(res.data);
      setSelectedCountry(res.data.mail_detail.address.country);
      if (res.data.status === PartyStatusMap.Approved) {
        setCanEdit(false);
      }
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  return (
    <>
      <section id="top_section" className="flex justify-between items-end ">
        <h1 className="m-4 border-l-4 border-myPrimaryColor pl-2 font-bold text-2xl text-gray-500border-l-4 flex items-center gap-3">
          Vendor Details
        </h1>
      </section>

      <form
        id="party_create_form"
        onSubmit={() => {
          console.log("Vendor Create");
        }}
        className="grid gap-2 p-5 "
      >
        <fieldset disabled={true}>
          <section className="flex flex-col w-1/4 gap-3 rounded-md">
            <div className="grid gap-1">
              <label htmlFor="name" className="text-sm">
                Vendor Name <RequiredMark />
              </label>
              <input
                required
                className="border rounded-md px-3 py-1 "
                type="text"
                data-type={dataTypes.string}
                id="name"
                name="name"
                value={vendor.name}
                onChange={handleChange}
              />
            </div>
            <div className="grid gap-1">
              <label htmlFor="type" className="text-sm">
                Vendor type <RequiredMark />
              </label>
              <select
                required
                className="border rounded-md px-3 py-1 capitalize"
                id="type"
                name="type"
                data-type={dataTypes.string}
                value={vendor.type}
                onChange={handleChange}
              >
                {" "}
                <option value=""> select</option>
                {PartyType.map((type: any) => (
                  <option value={type}>{type}</option>
                ))}
              </select>
            </div>
          </section>
          <section className="py-4">
            <h1 className="p-2 font-semibold flex gap-2 items-center bg-white rounded-t-md">
              <span className="text-blue-500">
                <Icon path={mdiEmailFast} size={0.8} />
              </span>
              Contact Details
            </h1>
            <div className="grid grid-flow-col grid-rows-1 sm:grid-rows-2 md:grid-rows-5 lg:grid-rows-5 gap-3 bg-white rounded-b-md p-2">
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_mobile" className="text-sm">
                  Contact No <RequiredMark />
                </label>
                <input
                  required
                  type="text"
                  data-type={dataTypes.string}
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.contact_mobile"
                  name="mail_detail.contact_mobile"
                  value={vendor.mail_detail.contact_mobile}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_name" className="text-sm">
                  Name of Contact Person <RequiredMark />
                </label>
                <input
                  required
                  type="text"
                  data-type={dataTypes.string}
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.contact_name"
                  name="mail_detail.contact_name"
                  value={vendor.mail_detail.contact_name}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_email" className="text-sm">
                  Email ID of Contact Person
                </label>
                <input
                  data-type={dataTypes.string}
                  type="email"
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.contact_email_contact_person"
                  name="mail_detail.contact_email_contact_person"
                  value={vendor.mail_detail.contact_email_contact_person}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_email" className="text-sm">
                  Email ID of CEO/Director
                </label>
                <input
                  data-type={dataTypes.string}
                  type="email"
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.contact_email_ceo_director"
                  name="mail_detail.contact_email_ceo_director"
                  value={vendor.mail_detail.contact_email_ceo_director}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_email" className="text-sm">
                  Email ID of Accounts SPOC
                </label>
                <input
                  data-type={dataTypes.string}
                  type="email"
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.contact_email_account_spoc"
                  name="mail_detail.contact_email_account_spoc"
                  value={vendor.mail_detail.contact_email_account_spoc}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_email" className="text-sm">
                  Email ID <RequiredMark />
                </label>
                <input
                  required
                  data-type={dataTypes.string}
                  type="email"
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.contact_email"
                  name="mail_detail.contact_email"
                  value={vendor.mail_detail.contact_email}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_email" className="text-sm">
                  Designation
                </label>
                <input
                  data-type={dataTypes.string}
                  type="email"
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.designation"
                  name="mail_detail.designation"
                  value={vendor.mail_detail.designation}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label
                  htmlFor="mail_detail.name_ceo_director"
                  className="text-sm"
                >
                  Name of CEO/Director
                </label>
                <input
                  data-type={dataTypes.string}
                  type="email"
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.name_ceo_director"
                  name="mail_detail.name_ceo_director"
                  value={vendor.mail_detail.name_ceo_director}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_email" className="text-sm">
                  Name of Accounts SPOC
                </label>
                <input
                  data-type={dataTypes.string}
                  type="email"
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.name_account_spoc"
                  name="mail_detail.name_account_spoc"
                  value={vendor.mail_detail.name_account_spoc}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_email" className="text-sm">
                  Website
                </label>
                <input
                  data-type={dataTypes.string}
                  type="email"
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.website"
                  name="mail_detail.website"
                  value={vendor.mail_detail.website}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_mobile" className="text-sm">
                  Mobile No. of contact person
                </label>
                <input
                  type="text"
                  data-type={dataTypes.string}
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.contact_mobile_contact_person"
                  name="mail_detail.contact_mobile_contact_person"
                  value={vendor.mail_detail.contact_mobile_contact_person}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_mobile" className="text-sm">
                  Contact Detail of CEO/Director
                </label>
                <input
                  type="text"
                  data-type={dataTypes.string}
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.contact_detail_ceo_director"
                  name="mail_detail.contact_detail_ceo_director"
                  value={vendor.mail_detail.contact_detail_ceo_director}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_mobile" className="text-sm">
                  Mobile No. of Accounts SPOC
                </label>
                <input
                  type="text"
                  data-type={dataTypes.string}
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.contact_mobile_accounts_spoc"
                  name="mail_detail.contact_mobile_accounts_spoc"
                  value={vendor.mail_detail.contact_mobile_accounts_spoc}
                  onChange={handleChange}
                />
              </div>
            </div>
          </section>

          <div className="grid grid-cols-1  md:grid-cols-2 gap-3">
            {/* Address Start */}
            <section className="py-4 bg-white rounded-md p-2 gap-2">
              <h1 className="mb-2 font-semibold flex gap-2 items-center">
                <span className="text-green-500">
                  <Icon path={mdiLocationEnter} size={0.7} />
                </span>
                Address Details
              </h1>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
                <div>
                  <div className="grid gap-1">
                    <label
                      htmlFor="mail_detail.address.line1"
                      className="text-sm"
                    >
                      Registered office address
                      <RequiredMark />
                    </label>
                    <input
                      required
                      type="text"
                      data-type={dataTypes.string}
                      className="border rounded-md px-3 py-1"
                      id="mail_detail.address.line1"
                      name="mail_detail.address.line1"
                      value={vendor.mail_detail.address.line1}
                      onChange={handleChange}
                      placeholder="Line 1"
                    />
                  </div>
                  <div className="grid gap-1">
                    <label
                      htmlFor="mail_detail.address.line2"
                      className="text-sm"
                    >
                      Line 2
                    </label>
                    <input
                      type="text"
                      data-type={dataTypes.string}
                      className="border rounded-md px-3 py-1"
                      id="mail_detail.address.line2"
                      name="mail_detail.address.line2"
                      value={vendor.mail_detail.address.line2}
                      onChange={handleChange}
                      placeholder="Line 2"
                    />
                  </div>
                  <div className="grid gap-1">
                    <label
                      htmlFor="mail_detail.address.line3"
                      className="text-sm"
                    >
                      Line 3
                    </label>
                    <input
                      type="text"
                      data-type={dataTypes.string}
                      className="border rounded-md px-3 py-1"
                      id="mail_detail.address.line3"
                      name="mail_detail.address.line3"
                      value={vendor.mail_detail.address.line3}
                      onChange={handleChange}
                      placeholder="Line 3"
                    />
                  </div>
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="country">
                      Country
                      <RequiredMark />
                    </label>
                    <select
                      id="mail_detail.address.country"
                      name="mail_detail.address.country"
                      className="p-1 rounded focus:outline-none w-full border"
                      value={vendor.mail_detail.address.country || ""}
                      onChange={(event) => {
                        const selectedCountryName = event.target.value;

                        if (selectedCountryName) {
                          setSelectedCountry(selectedCountryName);
                        }

                        setVendor((prev: any) => ({
                          ...prev,
                          mail_detail: {
                            ...prev.mail_detail,
                            address: {
                              ...prev.mail_detail.address,
                              country: selectedCountryName, // Store the country name in state
                            },
                          },
                        }));
                      }}
                    >
                      <option value="">Select Country</option>
                      {countries.map((country) => (
                        <option key={country.isoCode} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <div>
                    {selectedCountry === "India" ? (
                      <div className="grid gap-1">
                        <label
                          htmlFor="mail_detail.address.state"
                          className="text-sm"
                        >
                          State <RequiredMark />
                        </label>
                        <select
                          id="mail_detail.address.state"
                          name="mail_detail.address.state"
                          className="p-1 rounded focus:outline-none w-full border"
                          value={vendor.mail_detail.address.state} // Make sure it defaults to empty string if undefined
                          onChange={(newValue) => {
                            const selectedStateValue = (
                              newValue.target as HTMLSelectElement
                            ).value;

                            setVendor((prev: any) => ({
                              ...prev,
                              mail_detail: {
                                ...prev.mail_detail,
                                address: {
                                  ...prev.mail_detail.address,
                                  state: selectedStateValue
                                    ? selectedStateValue
                                    : "", // Update the country field in the vendor state
                                },
                              },
                            }));
                          }}
                        >
                          <option value="">Select State</option>
                          {stateData.map((state) => (
                            <option key={state.id} value={state.name}>
                              {state.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : (
                      <div className="grid gap-1">
                        <label
                          htmlFor="mail_detail.address.state"
                          className="text-sm"
                        >
                          State
                        </label>
                        <input
                          type="text"
                          data-type={dataTypes.string}
                          className="border rounded-md px-3 py-1"
                          id="mail_detail.address.state"
                          name="mail_detail.address.state"
                          value={vendor.mail_detail.address.state}
                          onChange={handleChange}
                          placeholder="State"
                        />
                      </div>
                    )}
                    <div className="grid gap-1">
                      <label
                        htmlFor="mail_detail.address.city"
                        className="text-sm"
                      >
                        City <RequiredMark />
                      </label>
                      <input
                        type="text"
                        data-type={dataTypes.string}
                        className="border rounded-md px-3 py-1"
                        id="mail_detail.address.city"
                        name="mail_detail.address.city"
                        value={vendor.mail_detail.address.city}
                        onChange={handleChange}
                        placeholder="City"
                      />
                    </div>
                    <div>
                      <div className="grid gap-1">
                        <label
                          htmlFor="mail_detail.address.pincode"
                          className="text-sm"
                        >
                          Postal code
                        </label>
                        <input
                          type="text"
                          data-type={dataTypes.string}
                          className="border rounded-md px-3 py-1"
                          id="mail_detail.address.pincode"
                          name="mail_detail.address.pincode"
                          value={vendor.mail_detail.address.pincode}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid gap-1">
                    <label
                      htmlFor="mail_detail.address.city"
                      className="text-sm"
                    >
                      City <RequiredMark />
                    </label>
                    <input
                      type="text"
                      data-type={dataTypes.string}
                      className="border rounded-md px-3 py-1"
                      id="mail_detail.address.city"
                      name="mail_detail.address.city"
                      value={vendor.mail_detail.address.city}
                      onChange={handleChange}
                      placeholder="Line 3"
                    />
                  </div>
                  <div>
                    <div className="grid gap-1">
                      <label
                        htmlFor="mail_detail.address.pincode"
                        className="text-sm"
                      >
                        Postal code
                      </label>
                      <input
                        type="text"
                        data-type={dataTypes.string}
                        className="border rounded-md px-3 py-1"
                        id="mail_detail.address.pincode"
                        name="mail_detail.address.pincode"
                        value={vendor.mail_detail.address.pincode}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Address End */}

            {/* MSME Start */}
            <section className="py-4 bg-white rounded-md p-2 gap-2">
              <div>
                <h1 className="mb-2 font-semibold flex gap-2 items-center">
                  <span className="text-green-500">
                    <Icon path={mdiLocationEnter} size={0.7} />
                  </span>
                  MSME Registration
                  <div className="flex flex-row gap-6">
                    <div className="flex gap-3 items-center">
                      <input
                        type={dataTypes.radio}
                        data-type={dataTypes.boolean}
                        id="msme_registration_applicable"
                        name="msme_registration_applicable"
                        value={String(
                          vendor.msme_registration_applicable === false
                        )}
                        checked={vendor.msme_registration_applicable === true}
                        onChange={handleChange}
                        disabled={false}
                      />
                      <label htmlFor="msme_registration">Yes</label>
                    </div>
                    <div className="flex gap-3 items-center">
                      <input
                        type={dataTypes.radio}
                        data-type={dataTypes.boolean}
                        id="msme_registration_applicable"
                        name="msme_registration_applicable"
                        value={String(
                          vendor.msme_registration_applicable === false
                        )}
                        checked={vendor.msme_registration_applicable === false}
                        onChange={handleChange}
                        disabled={false}
                      />
                      <label htmlFor="msme_registration">No</label>
                    </div>
                  </div>
                </h1>

                <div>
                  {vendor.msme_registration_applicable && (
                    <>
                      <div>
                        <div className="grid gap-1 m-2 text-sm">
                          <label
                            className="w-full"
                            htmlFor="msme_registration_applicable"
                          >
                            MSME Category
                            <RequiredMark />
                          </label>
                          <select
                            value={vendor.msme_registration.msme_category}
                            disabled={false}
                            onChange={handleChange}
                            name="msme_registration.msme_category"
                            id="msme_registration.msme_category"
                            className="p-1 rounded focus:outline-none w-full border"
                          >
                            <option value="default">
                              Select a MSME category
                            </option>
                            {["Micro", "Small", "Medium"].map((type, i) => (
                              <option value={type} key={i}>
                                {type.toUpperCase()}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="grid gap-1 m-2">
                          <label
                            htmlFor="msme_registration.msme_registration_number"
                            className="text-sm"
                          >
                            MSME Registration number <RequiredMark />
                          </label>
                          <input
                            required
                            type="text"
                            data-type={dataTypes.string}
                            className="border rounded-md px-3 py-1"
                            id="msme_registration.msme_registration_number"
                            name="msme_registration.msme_registration_number"
                            value={
                              vendor.msme_registration.msme_registration_number
                            }
                            onChange={handleChange}
                          />
                        </div>
                        <div className="grid gap-1 m-2"></div>
                      </div>
                      <div>
                        {vendor.attachments
                          .filter(
                            (fileObj) =>
                              fileObj.category === VendorAttachmentsTypes.msme
                          ) // Ensure type is valid
                          .map((fileObj) => (
                            <div
                              key={fileObj.id}
                              className="flex items-center justify-between p-3 border rounded-md"
                            >
                              <div className="flex items-center gap-3 m-4">
                                {getFileType(fileObj.type) ===
                                FileType.IMAGE ? (
                                  <img
                                    src={fileObj.url}
                                    alt="Attachment"
                                    className="w-16 h-16 object-cover"
                                  />
                                ) : (
                                  <a
                                    href={fileObj.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-sm"
                                  >
                                    {fileObj.url.split("/").pop()}
                                  </a>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </section>
            {/* MSME end */}
          </div>

          <div className="grid grid-cols-1  md:grid-cols-2 gap-3  ">
            <section className="py-4">
              <h1 className="p-2 font-semibold flex gap-2 items-center bg-white rounded-t-md">
                <span className="text-green-500">
                  <Icon path={mdiBank} size={0.7} />
                </span>
                Bank Details
              </h1>
              <div className="grid grid-cols-1  md:grid-cols-2 gap-3 bg-white rounded-b-md p-2">
                <div className="grid gap-0.5">
                  <label
                    htmlFor="bank_detail.account_holder_name"
                    className="text-sm"
                  >
                    Account holder name <RequiredMark />
                  </label>
                  <input
                    required
                    type="text"
                    data-type={dataTypes.string}
                    className="border rounded-md px-3 py-1"
                    id="bank_detail.account_holder_name"
                    name="bank_detail.account_holder_name"
                    value={vendor.bank_detail.account_holder_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="grid gap-0.5">
                  <label htmlFor="bank_detail.ac_no" className="text-sm">
                    Account no <RequiredMark />
                  </label>
                  <input
                    required
                    type="text"
                    data-type={dataTypes.string}
                    className="border rounded-md px-3 py-1"
                    id="bank_detail.ac_no"
                    name="bank_detail.ac_no"
                    value={vendor.bank_detail.ac_no}
                    onChange={handleChange}
                  />
                </div>
                <div className="grid gap-0.5">
                  <label htmlFor="bank_detail.bank_name" className="text-sm">
                    Bank Name <RequiredMark />
                  </label>
                  <input
                    required
                    type="text"
                    data-type={dataTypes.string}
                    className="border rounded-md px-3 py-1"
                    id="bank_detail.bank_name"
                    name="bank_detail.bank_name"
                    value={vendor.bank_detail.bank_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="grid gap-0.5">
                  <label htmlFor="bank_detail.bank_address" className="text-sm">
                    Bank Address
                  </label>
                  <textarea
                    data-type={dataTypes.string}
                    className="border rounded-md px-3 py-1"
                    id="bank_detail.bank_address"
                    name="bank_detail.bank_address"
                    value={vendor.bank_detail.bank_address}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  {vendor.mail_detail.address.country === "India" ? (
                    <div className="grid gap-0.5">
                      <label htmlFor="bank_detail.ifsc" className="text-sm">
                        IFSC <RequiredMark />
                      </label>
                      <input
                        required
                        type="text"
                        data-type={dataTypes.string}
                        className="border rounded-md px-3 py-1"
                        id="bank_detail.ifsc"
                        name="bank_detail.ifsc"
                        value={vendor.bank_detail.ifsc}
                        onChange={handleChange}
                      />
                    </div>
                  ) : (
                    <div className="grid gap-0.5">
                      <label
                        htmlFor="bank_detail.swift_code"
                        className="text-sm"
                      >
                        Swift code
                      </label>
                      <input
                        type="text"
                        data-type={dataTypes.string}
                        className="border rounded-md px-3 py-1"
                        id="bank_detail.swift_code"
                        name="bank_detail.swift_code"
                        value={vendor.bank_detail.swift_code}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                </div>
                <div className="grid gap-0.5">
                  <label htmlFor="bank_detail.bank_name" className="text-sm">
                    MICR Code
                  </label>
                  <input
                    type="text"
                    data-type={dataTypes.string}
                    className="border rounded-md px-3 py-1"
                    id="bank_detail.micr_code"
                    name="bank_detail.micr_code"
                    value={vendor.bank_detail.micr_code}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </section>
            <section className="my-4 bg-white rounded-md p-2">
              <h1 className="mb-2 font-semibold flex gap-2 items-center">
                <span className="text-green-500">
                  <Icon path={mdiCurrencyRupee} size={0.7} />
                </span>
                Details
              </h1>
              <div className="grid grid-cols-2 gap-2">
                <div className="grid gap-0.5">
                  <label htmlFor="tax_detail.pan" className="text-sm">
                    PAN <RequiredMark />
                  </label>
                  <input
                    required
                    type="text"
                    data-type={dataTypes.string}
                    className="border rounded-md px-3 py-1"
                    id="tax_detail.pan"
                    name="tax_detail.pan"
                    value={vendor.tax_detail.pan}
                    onChange={handleChange}
                  />
                </div>
                <div className="grid gap-0.5">
                  <label htmlFor="type" className="text-sm">
                    Legal Entity <RequiredMark />
                  </label>
                  <select
                    required
                    className="border rounded-md px-3 py-1 capitalize w-full truncate text-ellipsis"
                    id="legal_entity_type"
                    name="legal_entity_type"
                    data-type={dataTypes.string}
                    value={vendor.legal_entity_type}
                    // onChange={(e) => {
                    //   setVendor((o) => ({
                    //     ...o,
                    //     legal_entity_type: e.target.value, // Update state with selected value
                    //   }));
                    // }}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    {LegalEntityType.map((type: any) => (
                      <option key={type} value={type} className="truncate">
                        {type}
                      </option>
                    ))}
                  </select>
                </div>

                {vendor.legal_entity_type === "Sole Proprietorship" && (
                  <div className="grid gap-1 col-span-2">
                    <label
                      htmlFor="tax_detail.adhaar_number"
                      className="text-sm"
                    >
                      ADHAAR Number
                    </label>
                    <input
                      type="text"
                      data-type={dataTypes.string}
                      className="border rounded-md px-3 py-1 w-full"
                      id="adhaar_number"
                      name="adhaar_number"
                      value={vendor.adhaar_number}
                      onChange={handleChange}
                    />
                  </div>
                )}
                <div className="grid gap-0.5">
                  <label htmlFor="tax_detail.pan" className="text-sm">
                    CIN/LLPIN Number
                  </label>
                  <input
                    required
                    type="text"
                    data-type={dataTypes.string}
                    className="border rounded-md px-3 py-1"
                    id="cin_llpin_number"
                    name="cin_llpin_number"
                    value={vendor.cin_llpin_number}
                    onChange={handleChange}
                  />
                </div>

                <div className="grid gap-0.5">
                  <label htmlFor="date_of_commencement" className="text-sm">
                    Date of Commencement of Business
                  </label>
                  <input
                    required
                    type="date" // Changed from "text" to "date"
                    data-type={dataTypes.string}
                    className="border rounded-md px-3 py-1"
                    id="date_of_commencement"
                    name="date_of_commencement"
                    value={vendor.date_of_commencement}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </section>
          </div>

          <div className="grid grid-cols-1  md:grid-cols-2 gap-3  ">
            <section className="">
              <div className="bg-white rounded-md p-2">
                <h1 className="mb-2 font-semibold flex gap-2 items-center">
                  <span className="text-green-500">
                    <Icon path={mdiCurrencyRupee} size={0.7} />
                  </span>
                  GST Details
                </h1>
                <div className="gap-3 ">
                  <div className="grid gap-0.5">
                    <label htmlFor="tax_detail.reg_type" className="text-sm">
                      GST Registration Type
                    </label>
                    <select
                      id="tax_detail.reg_type"
                      name="tax_detail.reg_type"
                      className="border rounded-md px-3 py-1"
                      data-type={dataTypes.string}
                      value={vendor.tax_detail.reg_type}
                      onChange={handleChange}
                    >
                      <option value=" ">Select</option>
                      {Object.values(taxRegType).map((c, i) => (
                        <option value={c} key={i}>
                          {c}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="gap-1">
                    <label htmlFor="tax_detail.gst_no" className="text-sm">
                      GST Nos.
                    </label>
                    <div>
                      <div className="flex justify-between gap-2">
                        <input
                          type="text"
                          data-type={dataTypes.string}
                          id="tax_detail.gst_no"
                          name="tax_detail.gst_no"
                          className="border rounded-md px-3 py-1 flex-grow" // Add `flex-grow` to expand the input
                          value={newGstNo || ""}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setNewGstNo(e.target.value);
                          }}
                        />
                        <button
                          type="button"
                          onClick={handleAddGstNo}
                          className="px-3 py-1 bg-blue-500 text-white rounded-md"
                        >
                          Add
                        </button>
                      </div>

                      <div className="mt-2">
                        {vendor.tax_detail.gst_no.length > 0 && (
                          <ul className="list-disc pl-5">
                            {vendor.tax_detail.gst_no.map((gstNo, index) => (
                              <div className="">
                                <li
                                  key={index}
                                  className="flex justify-between items-center"
                                >
                                  <span>{gstNo}</span>
                                  <button
                                    type="button"
                                    onClick={() => removeGSTNumber(index)}
                                    className="text-red-500 hover:text-red-700"
                                  >
                                    Remove
                                  </button>
                                </li>
                              </div>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {vendor.attachments
                    .filter(
                      (fileObj) =>
                        fileObj.category === VendorAttachmentsTypes.gst
                    ) // Ensure type is valid
                    .map((fileObj) => (
                      <div
                        key={fileObj.id}
                        className="flex items-center justify-between p-3 border rounded-md"
                      >
                        <div className="flex items-center gap-3 m-4">
                          {getFileType(fileObj.type) === FileType.IMAGE ? (
                            <img
                              src={fileObj.url}
                              alt="Attachment"
                              className="w-16 h-16 object-cover"
                            />
                          ) : (
                            <a
                              href={fileObj.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-sm"
                            >
                              {fileObj.url}
                            </a>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </section>
            {vendor.attachments.some(
              (fileObj) => fileObj.category === VendorAttachmentsTypes.common
            ) && (
              <section className="py-4 bg-white rounded-md p-2">
                {vendor.attachments
                  .filter(
                    (fileObj) =>
                      fileObj.category === VendorAttachmentsTypes.common
                  )
                  .map((fileObj) => (
                    <div
                      key={fileObj.id}
                      className="flex items-center justify-between p-3 border rounded-md"
                    >
                      <div className="flex items-center gap-3 m-4">
                        {getFileType(fileObj.type) === FileType.IMAGE ? (
                          <img
                            src={fileObj.url}
                            alt="Attachment"
                            className="w-16 h-16 object-cover"
                          />
                        ) : (
                          <a
                            href={fileObj.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-sm"
                          >
                            {fileObj.url}
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
              </section>
            )}
          </div>
        </fieldset>
        <div className="flex flex-row justify-end gap-2 m-2">
          <div className="flex gap-2">
            <button
              onClick={() => {
                navigation.goBack();
              }}
              type="button"
              className="rounded-md border border-green-600 text-sm font-semibold bg-white text-black px-3 py-1"
            >
              Quit
            </button>
          </div>
        </div>
      </form>
      {creating && <LoadingWidget />}
    </>
  );
};

const RequiredMark = () => (
  <>
    <span className="text-red-500">*</span>
  </>
);

export default VendorDetailScreen;
