import { createContext, useContext, useState } from "react";
import {
  po_default_value,
  po_default_value_v2,
  PurchaseOrderModel,
  PurchaseOrderModelV2,
} from "../model/purchase_order_model";
import { default_mail_detail } from "../../accounts/models/party_model";
import { Project } from "../../inventory/models/project_model";

// Define Context type
interface PurchaseOrderContextType {
  defaultProject: Project | null;
  setDefaultProject: React.Dispatch<React.SetStateAction<Project | null>>;
  purchaseOrder: PurchaseOrderModelV2;
  setPurchaseOrder: React.Dispatch<React.SetStateAction<PurchaseOrderModelV2>>;
}

const PurchaseOrderContext = createContext<PurchaseOrderContextType | null>(
  null
);

export const PurchaseOrderProvider = ({
  children,
}: {
  children: React.ReactNode;
  // purchaseOrder?: PurchaseOrderModelV2;
}) => {
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrderModelV2>({
    ...po_default_value_v2,
    shipping_profile: {
      ...default_mail_detail,
    },
  });

  const [defaultProject, setDefaultProject] = useState<Project | null>(null);

  return (
    <PurchaseOrderContext.Provider
      value={{
        purchaseOrder,
        defaultProject,
        setPurchaseOrder,
        setDefaultProject,
      }}
    >
      {children}
    </PurchaseOrderContext.Provider>
  );
};

export const usePurchaseOrder = () => {
  const context = useContext(PurchaseOrderContext);
  if (!context) {
    throw new Error(
      "usePurchaseOrder must be used within a PurchaseOrderProvider"
    );
  }
  return context;
};

export default PurchaseOrderProvider;
