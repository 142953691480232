import { FunctionComponent } from "react";
import {
  AttachmentModel,
  FileType,
} from "../../../../purchase_order/model/purchase_order_model";
import LoadingWidget from "../../../../../context_providers/modal/loader";

interface InwardAttachmentViewWidgetProps {
  attachments: AttachmentModel[];
  onSelect: (data: AttachmentModel) => void;
}

const InwardAttachmentViewSection: FunctionComponent<
  InwardAttachmentViewWidgetProps
> = ({ attachments = [], onSelect }) => {
  const onSelectAttachment = async (
    attachment: AttachmentModel
  ): Promise<void> => {
    if (!attachment.url) {
      console.error("Attachment URL is missing.");
      return;
    }

    try {
      const response = await fetch(attachment.url);
      if (!response.ok) throw new Error("Failed to fetch the file");

      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = attachment.url.split("/").pop() || "download";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      <div className="p-5 select-none">
        <div className="mt-5">
          <label className="text-lg font-bold mt-5 py-3">
            Purchase Voucher Attachments
          </label>
        </div>

        <div className="grid grid-cols-4 text-sm font-semibold px-2">
          <div className="col-span-2">File</div>
        </div>

        <div
          className="flex flex-col gap-2 overflow-auto w-full"
          style={{ height: "80vh" }}
        >
          {attachments.length > 0 ? (
            attachments.map((att, i) => {
              console.log(att);
              return (
                <div
                  key={i}
                  className="grid grid-cols-4 rounded-lg border overflow-hidden text-xs p-2"
                >
                  <div
                    onClick={() => {
                      if (att.type === FileType.CSV) {
                        onSelectAttachment(att);
                      } else if (
                        att.type === FileType.IMAGE ||
                        att.type === FileType.PDF
                      ) {
                        att.url && window.open(att.url, "_blank");
                      }
                    }}
                    className="rounded-lg h-16 w-16 col-span-2 flex justify-center items-center cursor-pointer border bg-gray-100 hover:bg-gray-200"
                  >
                    {att.type === FileType.IMAGE ? (
                      <img
                        src={att.url}
                        alt="Attachment preview"
                        className="h-full w-full object-cover"
                      />
                    ) : (
                      <div className="text-sm font-semibold text-gray-700">
                        {att.type}
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <p className="font-bold text-green-500 text-sm text-center mt-3">
              No Purchase Voucher Attachments found
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default InwardAttachmentViewSection;
