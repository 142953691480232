import { FunctionComponent } from "react";
import PurchaseOrderProvider from "../contexts/po-creation-context";
import PaymentRequestAdvanceApprovalScreen from "./payment_request_approval_view/advance_payment_request_approval_view";
import { PaymentRequestModel } from "../model/payment_request_model";
import PaymentRequestAdvanceScreen from "./payment_request_view/advance_payment_request_view";
import PaymentRequestInvoiceScreen from "./payment_request_view/invoice_payment_request_view";
import PaymentRequestInvoiceApprovalScreen from "./payment_request_approval_view/invoice_payment_request_approval_view";

interface PRAdvanceApprovePageProps {}

export const PRAdvanceApprovalPageWarraper: FunctionComponent<
  PRAdvanceApprovePageProps
> = () => {
  return (
    <>
      <PurchaseOrderProvider>
        <PaymentRequestAdvanceApprovalScreen />
      </PurchaseOrderProvider>
    </>
  );
};

interface PRInvoiceApprovalPageProps {}

export const PRInvoiceAprovalPageWarraper: FunctionComponent<
  PRInvoiceApprovalPageProps
> = () => {
  return (
    <>
      <PurchaseOrderProvider>
        <PaymentRequestInvoiceApprovalScreen />
      </PurchaseOrderProvider>
    </>
  );
};

interface PRAdvancePageProps {}

export const PRAdvancePageWarraper: FunctionComponent<
  PRAdvancePageProps
> = () => {
  return (
    <>
      <PurchaseOrderProvider>
        <PaymentRequestAdvanceScreen />
      </PurchaseOrderProvider>
    </>
  );
};

interface PRInvoicePageProps {}

export const PRInvoicePageWarraper: FunctionComponent<
  PRInvoicePageProps
> = () => {
  return (
    <>
      <PurchaseOrderProvider>
        <PaymentRequestInvoiceScreen />
      </PurchaseOrderProvider>
    </>
  );
};
