import { fetchGet, fetchPost } from "../../../../../service/api_client";

export const CreateInwardAndVoucher = async (data: FormData) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/voucher/purchase`;
    const res = fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetInwardsFromPO = async (po_no: string) => {
  const inward_url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/voucher/purchase/get-inwards`;

  const res = fetchGet(inward_url + "/" + po_no);
  return res;
};

export const GetPurchaseOrder = async (id: string) => {
  const po_url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order`;

  const res = fetchGet(po_url + "/" + id);
  return res;
};

export const GetVoucherAttachments = async (voucher_id: string) => {
  const po_url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/voucher/purchase/${voucher_id}/attachment`;
  const res = fetchGet(po_url);
  return res;
};
