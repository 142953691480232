import debounce from "lodash.debounce";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { fetchGet, toQueryString } from "../../../../service/api_client";
import AutoCompleteField from "../../../../ui/new_components/common/autocomplete";
import { Project } from "../../../inventory/models/project_model";

interface ProjectSearchFieldPropsV2 {
  onSelect: (data: Project) => void;
  disabled?: boolean;
  placeholder?: string;
  value?: Project | null;
}

const ProjectSearchFieldV2: FunctionComponent<ProjectSearchFieldPropsV2> = (
  props
) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/project/`;
  const { showToast } = useToast();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [addNew, setAddNew] = useState(false);
  const [data, setData] = useState<Project[]>();
  const getData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
  }) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success && Array.isArray(res.data?.data)) {
        const openProjects = res.data.data.filter((project: Project) => project.projectStatus === "Open");
        setData(openProjects); 
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce((query) => {
      if (query) getData({ search: query, page: 1, count: 20, all: true });
      else setData(undefined);
    }, 500),
    []
  );

  useEffect(() => {
    if (search && edit) {
      if (props.value && props.value.name === search) return;
      debouncedHandler(search);
    }
  }, [search]);
  useEffect(() => {
    setSearch(props.value?.name ?? "");
  }, [props.value]);

  useEffect(() => {
    if (props.disabled) {
      setEdit(false);
      setSearch(props.value?.name ?? "");
    }
  }, [props.disabled]);

  return (
    <div className="flex gap-1 items-center    w-full">
      {edit ? (
        <AutoCompleteField
          disabled={props.disabled}
          onAddNew={() => {
            setAddNew(true);
          }}
          loading={loading}
          value={search}
          placeholder={props.placeholder ?? "Select an item "}
          onSelect={(val) => {
            setEdit(false);
            setSearch(val.value.name);
            props.onSelect(val.value);
          }}
          suggestions={data?.map((d) => ({
            label: (
              <div className="text-xs">
                <b>{d.name} </b>
              </div>
            ),

            value: d,
          }))}
          onChange={setSearch}
        />
      ) : (
        <div
          title={search}
          className={`text-xs px-3 py-2  h-8 rounded focus:outline-none w-full border overflow-hidden overflow-ellipsis ${
            search ? "" : " text-gray-400 "
          }`}
          onClick={() => {
            if (!props.disabled) {
              setEdit(true);
              // setSearch(emp?.name ?? "");
            }
          }}
        >
          {search || props.placeholder || "Select a Project "}
        </div>
      )}
      {/* {addNew && (
        <ModalDialog
          onClose={() => {
            setAddNew(false);
          }}
          show={addNew}
          title={"Add Item"}
        >
          <div
            className="overflow-auto"
            style={{ maxHeight: "80vh", maxWidth: "80vw " }}
          >
            <ItemCreateScreen
              onClose={(d) => {
                if (d) {
                  setEdit(false);
                  setSearch(d.name);
                  props.onSelect(d);
                  setAddNew(false);
                }
              }}
            />
          </div>
        </ModalDialog>
      )} */}
    </div>
  );
};

export default ProjectSearchFieldV2;
