import {
  mdiRefresh,
  mdiPlus,
  mdiRestore,
  mdiContentCopy,
  mdiClose,
  mdiArrowTopRight,
  mdiHistory,
} from "@mdi/js";
import Icon from "@mdi/react";
import { debounce } from "lodash";
import moment from "moment";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  useModal,
  ModalType,
  ModalAlignment,
} from "../../../context_providers/modal/modal_context";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../procurement/components/create_view/form_field";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  toQueryString,
} from "../../../service/api_client";
import SearchFieldWidget from "../../../ui/new_components/search_field";
import { UTCToLocalDate } from "../../../utils/date_util";
import { currencyFormat } from "../../../utils/orders_utils";
import { urls } from "../../../utils/urls";
import {
  AttachmentModel,
  PurchaseOrderModel,
  PurchaseOrderModelV2,
  getFileType,
  po_approval_status,
  po_status,
} from "../model/purchase_order_model";
import NewTableView, {
  NewTableRow,
} from "../../../procurement/components/new_table_comp";
import { file_dir } from "../../../utils/enums";
import axios from "axios";
import DrawerWidget from "../../../context_providers/modal/drawer_widget";
import AttachmentSection from "../purchase_order_create/components/attachment";
import PartySearchField from "../../accounts/parties/party_search_field";
import {
  PartyModel,
  defaulPartyValue,
} from "../../accounts/models/party_model";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../../ui/new_components/common/drawer_modal";
import ActionHistory from "../purchase_order_create/components/transfer_logs";
import ModalDialog from "../../../ui/new_components/common/modal";
import ExportPO from "../purchase_order_create/components/POExportWidget";
import { POListFilter } from "../purchase_order_create/models/enums";
import { getPurchaseOrderListDataRepo } from "../purchase_order_create/service/po_service";
import AttachmentSectionV2 from "../purchase_order_create/components/attachment_widget_v2";
import Pagination from "../../../ui/new_components/common/pagination";
import EmployeeSearchWidget from "../../../ui/new_components/emp_search_widget";
import { UserModel } from "../../../context_providers/auth/user_model";
import ProjectSearchFieldV2 from "../purchase_order_create/components/project_search_widget";
import { Project } from "../../inventory/models/project_model";
import FilterDropDown from "../../../ui/new_components/common/filter_drop_down";

const defaultFilter = (): POListFilter => {
  const now = new Date();
  // const { start_date, end_date } = GetStartAndEndDateOfMonth(now);
  const start_date = new Date(new Date(now).setDate(now.getDate() - 30));
  const end_date = now;
  return {
    page: 1,
    count: 100,
    all: false,
    fromDate: start_date,
    toDate: end_date,
  };
};
interface PurchaseOrderListScreenProps {
  location?: any;
}

const PurchaseOrderListScreen: FunctionComponent<
  PurchaseOrderListScreenProps
> = (props) => {
  const query = new URLSearchParams(props.location.search);
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order`;
  const { showModal, onClose } = useModal();
  const [showExport, setShowExport] = useState(false);
  const [ShowHistory, setShowHistory] = useState<any>();
  const [ShowHistory1, setShowHistory1] = useState<any>();
  // const tempFilter = localStorage.getItem("po_list_filter");
  const [selectedPO, setSelectedPO] = useState<PurchaseOrderModelV2 | null>();
  const [filter, setFilter] = useState<POListFilter>(defaultFilter);

  // const [filter, setFilter] = useState<POListFilter>(defaultFilter());
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);

  const [total, setTotal] = useState(0);
  const [data, setData] = useState<PurchaseOrderModelV2[]>([]);

  const handleSelection = (selectedItem: PurchaseOrderModelV2) => {
    if (selectedItem.status === "Saved Draft") {
      return `${urls.purchase_order}/create/${selectedItem.id}`;
    }
    return `${urls.purchase_order}/view-detail/${selectedItem.id}`;
  };

  const fetchData = async (prop: POListFilter) => {
    console.log(prop);
    setLoading(true);
    try {
      const res = await getPurchaseOrderListDataRepo(prop);
      if (res.success) {
        setData(res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandler = useCallback(
    debounce((prop: POListFilter) => {
      if (prop) fetchData(prop);
      else setData([]);
    }, 100),
    []
  );

  // useEffect(() => {
  //   fetchData({ page, count, search });
  // }, [page]);
  const navigation = useHistory();
  // const onSelect = (d: PurchaseOrderModel) => {
  //   navaigation.push(urls.purchase_order + "/update/" + d.id);
  // };
  // const onDelete = async (d: PurchaseOrderModel) => {
  //   const propmt = window.prompt("Type 'delete' to confirm.");
  //   if (!propmt || propmt?.trim() !== "delete") {
  //     return;
  //   }
  //   setLoading(true);
  //   const res = await fetchDelete(url + "/" + d.id);
  //   if (res.success) {
  //     await fetchData(filter);
  //     showToast({ type: ToastType.success, text: res.message });
  //   } else {
  //     showToast({ type: ToastType.error, text: res.error });
  //   }
  //   setLoading(false);
  // };

  const [vendor, setVendor] = useState<PartyModel>();
  const [showAttachment, setShowAttachment] = useState<PurchaseOrderModelV2>();
  const [attachmentKey, setAttachmentKey] = useState(Math.random() * 10000);

  useEffect(() => {
    // if (filter) {
    //   const t: POListFilter = filter;
    //   if (t.party_id) {
    //     const v = defaulPartyValue;
    //     v.id = t.party_id;
    //     v.name = t.party_name ?? "";
    //     console.log({ v });
    //     setVendor(v);
    //   }
    // }
    // localStorage.setItem("po_list_filter", JSON.stringify(filter));
    debouncedHandler(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const uploadFile = async ({
    file,
    category,
    removeSelectedFile,
    setShowUpload,
  }: {
    file: File;
    category: string;
    removeSelectedFile: () => void;
    setShowUpload: (v: boolean) => void;
  }) => {
    try {
      if (!file || !category) {
        showToast({
          type: ToastType.warning,
          text: "Please select file and category",
        });
        return;
      }
      setLoading(true);
      const { name, type } = file;
      const create_url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/${showAttachment?.id}/attachment`;
      const get_signed_urls = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/signed-url`;
      const query = {
        mimetype: type,
        extension: name.split(".").pop(),
        dir: file_dir.pocuremnt_attachments,
      };
      const res = await fetchGet(get_signed_urls + toQueryString(query));
      if (res.success) {
        const { signed_url, url } = res.data;

        //   const options = {
        //     method: "PUT",
        //     body: file,
        //     headers: {
        //       "Content-Type": type,
        //     },
        //   };
        const aws_res = await axios.put(signed_url, file, {
          headers: {
            "Content-Type": type,
          },
        });

        const attachment: AttachmentModel = {
          id: "",
          category,
          type: getFileType(type),
          url,
        };
        const create_res = await fetchPost(create_url, attachment);
        console.log({ create_res });
        if (create_res.success) {
          showToast({
            type: ToastType.success,
            text: create_res.message,
          });
          setData((o) => [
            ...o.map((f) => {
              if (f.id === showAttachment)
                return {
                  ...f,
                  attachments: [...(f.attachments || []), create_res.data],
                };
              return f;
            }),
          ]);

          removeSelectedFile();
          setShowUpload(false);
          setAttachmentKey(Math.random() * 1000);
        }
      }
      setLoading(false);
    } catch (error: any) {
      showToast({
        type: ToastType.error,
        text: error.message,
      });
      setLoading(false);
    }
  };
  const deleteFile = async (att: AttachmentModel) => {
    try {
      if (!window.confirm("Are your sure to delete ?")) return;
      if (!att) {
        showToast({
          type: ToastType.warning,
          text: "Please select file",
        });
        return;
      }
      setLoading(true);
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/${showAttachment?.id}/attachment/${att.id}`;

      const res = await fetchDelete(url);

      if (res.success) {
        showToast({
          type: ToastType.success,
          text: res.message,
        });
        setData((o) => [
          ...o.map((f) => {
            if (f.id === showAttachment) {
              f.attachments = f.attachments.filter((f) => f.id !== att.id);
            }
            return f;
          }),
        ]);

        setAttachmentKey(Math.random() * 1000);
      }
      setLoading(false);
    } catch (error: any) {
      showToast({
        type: ToastType.error,
        text: error.message,
      });
      setLoading(false);
    }
  };

  const onSelectAttchment = (d: AttachmentModel) => {
    window.open(d.url, "_blank");
  };

  return (
    <>
      {showExport && (
        <ModalDialog
          show={showExport}
          title="Export"
          onClose={() => setShowExport(false)}
        >
          <ExportPO filter={filter} />
        </ModalDialog>
      )}
      {ShowHistory && (
        <DrawerModal
          location={DrawerModalLoaction.right}
          show={ShowHistory !== undefined}
          onClose={() => setShowHistory(undefined)}
          title="History"
        >
          {/* {console.log("id of order", ShowHistory)} */}
          <ActionHistory
            po_no={ShowHistory}
            data={ShowHistory1}
            // show={ShowHistory !== undefined}
            // onClose={(v) => setShowHistory(undefined)}
          />
        </DrawerModal>
      )}
      {showAttachment && (
        <DrawerWidget
          data={{
            id: 1,
            title: "Attchments",
            type: ModalType.drawer,
            alignment: ModalAlignment.right,
            container: (
              <AttachmentSectionV2
                key={attachmentKey}
                poNo={showAttachment}
                attachments={showAttachment.attachments}
                onSubmit={uploadFile}
                onDelete={deleteFile}
                onSelect={onSelectAttchment}
              />
            ),
          }}
          onClose={function (): void {
            setShowAttachment(undefined);
          }}
        ></DrawerWidget>
      )}
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section id="top_section" className=" ">
          <div className="flex justify-between items-end my-1 flex-wrap">
            <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
              <p className=" font-bold border-l-4 border-myPrimaryColor pl-2">
                Purchase orders
              </p>
              <button title="Refersh" onClick={() => debouncedHandler(filter)}>
                <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
              </button>
            </h1>
            <div className="flex justify-end   text-sm items-center gap-4 ">
              <div className="text-sm flex items-center gap-4">
                <div className="flex items-center  gap-1">
                  <label htmlFor="fromDate" className="">
                    From
                  </label>
                  <input
                    type="date"
                    name=""
                    id="fromDate"
                    className="p-1 rounded focus:outline-none"
                    value={
                      filter.fromDate
                        ? moment(filter.fromDate).format("YYYY-MM-DD")
                        : ""
                    }
                    onChange={(e) => {
                      const now = UTCToLocalDate(e.target.value)!;
                      const fromDate = new Date(
                        new Date(now).setHours(0, 0, 0, 0)
                      );
                      setFilter((o) => ({ ...o, fromDate }));
                    }}
                  />
                </div>
                <div className="flex items-center  gap-1">
                  <label htmlFor="toDate" className="">
                    To
                  </label>
                  <input
                    type="date"
                    name=""
                    id="toDate"
                    className="p-1 rounded focus:outline-none "
                    value={
                      filter.toDate
                        ? moment(filter.toDate).format("YYYY-MM-DD")
                        : ""
                    }
                    onChange={(e) => {
                      const now = UTCToLocalDate(e.target.value)!;
                      const toDate = new Date(
                        new Date(now).setHours(23, 59, 59, 999)
                      );
                      setFilter((o) => ({ ...o, toDate }));
                    }}
                  />
                </div>
                {(filter.fromDate || filter.toDate) && (
                  <button
                    title="Clear dates"
                    onClick={() =>
                      setFilter((o) => ({
                        ...o,
                        fromDate: undefined,
                        toDate: undefined,
                      }))
                    }
                    className="flex gap-1 items-center cursor-pointer rounded p-1 border  r hover:scale-105 duration-200     transform"
                  >
                    <Icon path={mdiClose} size={0.7}></Icon>
                  </button>
                )}
              </div>
              <button
                onClick={() => {
                  console.log("export");
                  setShowExport(true);
                }}
                className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white flex gap-2 items-center"
              >
                <Icon path={mdiArrowTopRight} className="h-4 w-4"></Icon>Export
                PO
              </button>
              <button
                onClick={() => {
                  if (selectedPO && selectedPO.po_no) {
                    navigation.push(
                      `${urls.purchase_order}/reorder/${selectedPO.po_no}`
                    );
                  } else {
                    showToast({
                      type: ToastType.error,
                      text: "Please Select a PO to Re-Order",
                    });
                  }
                }}
                className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
              >
                <Icon path={mdiPlus} className="h-4 w-4"></Icon>Reorder PO
              </button>
              <button
                onClick={() => {
                  navigation.push(urls.purchase_order + "/create");
                }}
                className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
              >
                <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add PO
              </button>
            </div>
          </div>

          {/* <div className="h-52 w-full bg-green-200 "></div> */}
        </section>
        <section className="flex items-start justify-start m-4">
          <div className="grid grid-cols-2 gap-6 w-1/2">
            <div className="">
              <label htmlFor="app_status" className="">
                PO ID
              </label>
              <SearchFieldWidget
                defaulValue={filter.po_no ?? ""}
                onClear={() => {
                  setFilter((prevData) => ({
                    ...prevData,
                    po_no: "", // Directly updating item_code
                  }));
                }}
                onSubmit={(value: string) =>
                  setFilter((prevData) => ({
                    ...prevData,
                    po_no: value, // Directly updating item_code
                  }))
                }
              />
            </div>
            <div className="">
              <label htmlFor="" className="">
                Vendor Name
              </label>
              <div className="w-full flex ">
                <PartySearchField
                  // show_add={false}
                  placeholder="Select vendor"
                  onSelect={(d) => {
                    setFilter((o) => ({
                      ...o,
                      party_id: d?.id ?? undefined,
                      party_name: d?.name ?? undefined,
                    }));
                    setVendor(d);
                  }}
                  value={vendor}
                />
                {filter.party_id && (
                  <button
                    title="Clear vendor"
                    onClick={() => {
                      setFilter((o) => ({
                        ...o,
                        party_id: undefined,
                        party_name: undefined,
                      }));
                      setVendor(undefined);
                    }}
                    className="flex gap-1 items-center cursor-pointer rounded p-1 border  r hover:scale-105 duration-200     transform bg-white"
                  >
                    <Icon path={mdiClose} size={0.7}></Icon>
                  </button>
                )}
                <button
                  title="Reset filters"
                  onClick={() => setFilter(defaultFilter())}
                  className="flex gap-1 items-center cursor-pointer rounded p-1 border  r hover:scale-105 duration-200     transform bg-white"
                >
                  <Icon path={mdiRestore} size={0.7}></Icon>
                </button>
              </div>
            </div>
            <div>
              <label htmlFor="employee search">Created By</label>
              <SearchFieldWidget
                defaulValue={filter?.created_by_name}
                onClear={() => {
                  setFilter({
                    ...filter,
                    created_by_name: undefined, // Fixed indentation
                  });
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    created_by_name: val, // Fixed indentation
                  });
                }}
              />
              {/* <EmployeeSearchWidget
                placeholder="Search Employee"
                onSelect={function (data: UserModel): void {
                  throw new Error("Function not implemented.");
                }}
              /> */}
            </div>
            <div className="">
              <label htmlFor="app_status" className="">
                Project Name
              </label>
              <SearchFieldWidget
                defaulValue={filter?.project_name}
                onClear={() => {
                  setFilter({
                    ...filter,
                    project_name: undefined, // Fixed indentation
                  });
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    project_name: val, // Fixed indentation
                  });
                }}
              />
              {/* <ProjectSearchFieldV2
                placeholder="Search a Project"
                onSelect={function (data: Project): void {
                  throw new Error("Function not implemented.");
                }}
              /> */}
            </div>
            <div>
              <label htmlFor="po_status" className="">
                PO Status
              </label>
              <FilterDropDown
                placeholder="PO Status"
                onClearAll={() =>
                  setFilter({
                    ...filter,
                    po_status: undefined, // Fixed indentation
                  })
                }
                onDelete={(type) => {
                  setFilter({
                    ...filter,
                    po_status: filter?.po_status?.filter(
                      (f: any) => f !== type
                    ),
                  });
                }}
                onSelect={(type) => {
                  setFilter((old) => ({
                    ...old,
                    po_status: [...(old?.po_status ?? []), type],
                  }));
                }}
                selectedOptions={filter?.po_status ?? []}
                options={Object.values(po_status).map((t) => ({
                  label: t,
                  value: t,
                }))}
              />
            </div>
            <div>
              <label htmlFor="app_status" className="">
                Approval Status
              </label>
              <FilterDropDown
                placeholder="Approval Status"
                onClearAll={() =>
                  setFilter({
                    ...filter,
                    approval_status: undefined, // Fixed indentation
                  })
                }
                onDelete={(type) => {
                  setFilter({
                    ...filter,
                    approval_status: filter?.approval_status?.filter(
                      (f: any) => f !== type
                    ),
                  });
                }}
                onSelect={(type) => {
                  setFilter((old) => ({
                    ...old,
                    approval_status: [...(old?.approval_status ?? []), type],
                  }));
                }}
                selectedOptions={filter?.approval_status ?? []}
                options={Object.values(po_approval_status).map((t) => ({
                  label: t,
                  value: t,
                }))}
              />
            </div>
            <div className="">
              <label htmlFor="fromDate" className="">
                Last Approved Date
              </label>
              <input
                type="date"
                name=""
                id="last_approved_date"
                className="w-full p-1 rounded focus:outline-none bg-white"
                value={
                  filter.last_approved_date
                    ? moment(filter.last_approved_date).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                  const fromDate = selectedDate
                    ? new Date(new Date(selectedDate).setHours(0, 0, 0, 0)) // Convert to a date object
                    : undefined; // Reset if no date is selected

                  // Update the filter
                  setFilter({
                    ...filter,
                    last_approved_date: fromDate,
                  });
                }}
              />
            </div>
          </div>
        </section>
        {/* <NewTableView
          stickyHeader={true}
          show_index={true}
          show_pagination={true}
          headers={[
            {
              key: "po_no",
              value: "PO #",
              type: FieldType.string,
            },
            {
              key: "project_name",
              value: "Project",
              type: FieldType.string,
            },
            {
              key: "date",
              value: "Date",
              type: FieldType.date,
            },
            {
              key: "delivery_date",
              value: "Delivery date",
              type: FieldType.date,
            },
            {
              key: "item",
              value: "Item",
              type: FieldType.string,
            },
            {
              key: "party",
              value: "Party",
              type: FieldType.string,
            },
            {
              key: "",
              value: "Responsible",
              type: FieldType.string,
            },
            {
              key: "warehouse",
              value: "Warehouse",
              type: FieldType.string,
            },

            {
              key: "approval_status",
              value: "Approval status",
              type: FieldType.string,
            },

            {
              key: "status",
              value: "Status",
              type: FieldType.string,
            },
            {
              key: "total",
              value: "Total",
              type: FieldType.currency,
            },
            {
              key: "paid",
              value: "Paid",
              type: FieldType.currency,
            },
            {
              key: "balance",
              value: "Balance",
              type: FieldType.currency,
            },
            {
              key: "",
              value: "Attachment",
              type: FieldType.string,
            },
          ]}
          rows={data.map((data, i) => {
            const row: NewTableRow<PurchaseOrderModelV2> = {
              data: data,

              cells: [
                {
                  value: data.po_no,
                  builder: () => (
                    <div className="flex items-center justify-start gap-1 p-1 h-full sticky-field whitespace-nowrap">
                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(data.po_no);
                          showToast({
                            type: ToastType.info,
                            text: "Copied PO NO to clip board!",
                          });
                        }}
                      >
                        <Icon path={mdiContentCopy} size={0.5}></Icon>
                      </button>
                      #{data.po_no}
                    </div>
                  ),
                },
                { value: data.project },
                { value: data.date },
                { value: data.delivery_date },
                {
                  maxLines: 2,
                  builder: () => (
                    <a
                      href={handleSelection(data)}
                      className="px-2 text-left text-blue-400 hover:text-blue-500  h-full w-full whitespace-pre  "
                    >
                      {data.items?.length > 0
                        ? `${data.items[0]?.item?.name} ${
                            data.items.length > 1
                              ? `... and ${data.items.length - 1} more`
                              : ""
                          }`
                        : "No items"}
                    </a>
                  ),
                  title: data.items?.length
                    ? `${data.items.reduce(
                        (p, v) => p + (v.item?.name || "Unnamed Item") + " | ",
                        ""
                      )}`
                    : "No items",
                },
                { value: data.party?.name, align: "left" },
                { value: data.shipping_profile?.contact_name },
                { value: data.warehouse?.name },
                {
                  value:
                    data.approval_level === data.current_approval_level &&
                    (data.current_approval_count ?? 0) >=
                      (data.current_approval_required_count ?? 0)
                      ? "Approved"
                      : (data.current_approval_required_count ?? 0) > 0 &&
                        (data.current_reject_count ?? 0) -
                          (data.current_approval_count ?? 0) >=
                          (data.current_approval_required_count ?? 0)
                      ? "Rejected"
                      : "Pending",
                },
                { value: data.status },
                {
                  align: "right",
                  value: currencyFormat(
                    data.total,
                    data?.vendor_currency?.short_name
                  ),
                },
                { value: data?.paid },
                { value: data?.total - data?.paid },
                {
                  builder: () => (
                    <button
                      onClick={() => setShowAttachment(data)}
                      className="relative text-sm font-semibold text-blue-400 hover:text-blue-500 whitespace-nowrap sticky-field  flex items-center gap-1 px-2 h-full w-full"
                    >
                      View
                      <div>({data?.attachments?.length}+)</div>
                    </button>
                  ),
                },
              ],
            };
            return row;
          })}
          onShowHistory={(data) => {
            setShowHistory(data.po_no);
            setShowHistory1(data);
          }}
          page={filter.page}
          total={total}
          count={filter.count}
          itemCount={data.length}
          onPageChange={(page) => {
            setFilter((o) => ({ ...o, page }));
          }}
        /> */}
        <div className="my-2 border overflow-auto" style={{ height: "80%" }}>
          <table className="w-full text-xs divide-y divide-gray-200 relative">
            <thead className="text-xs py-2 sticky -top-1 bg-white z-10">
              <tr className="border">
                <th>
                  <div>
                    <input
                      type="checkbox"
                      checked={selectedPO !== null}
                      disabled
                    />
                  </div>
                </th>
                <th className="px-2 py-2">Actions</th>
                <th className="px-2 py-2 text-left">Po No</th>
                <th className="px-2 py-2 text-left">Project Name</th>
                <th className="px-2 py-2 text-left">Vendor Name</th>
                <th className="px-2 py-2 text-left">Bill to</th>
                <th className="px-2 py-2 text-left">Ship to</th>
                <th className="px-2 py-2 text-left">CGST</th>
                <th className="px-2 py-2 text-left">SGST</th>
                <th className="px-2 py-2 text-left">IGST</th>
                <th className="px-2 py-2 text-left">Total Amount</th>
                <th className="px-2 py-2 text-left">Paid</th>
                <th className="px-2 py-2 text-left">Balance</th>
                <th>Created By</th>
                <th>PO Date</th>
                <th>Inward Status</th>
                <th className="px-2 py-2 text-left">Approval Status</th>
                <th className="px-2 py-2 text-left">First Approved By</th>
                <th className="px-2 py-2 text-left">Last Approved By</th>
                <th className="px-2 py-2 text-left">PO Status</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item, i) => (
                  <tr className="border text-center" key={i}>
                    <td>
                      <div className="p-2">
                        <input
                          type="checkbox"
                          name="selectedItem"
                          checked={selectedPO?.po_no === item.po_no}
                          onChange={() => {
                            setSelectedPO((prev) =>
                              prev?.po_no === item.po_no ? null : item
                            );
                          }}
                        />
                      </div>
                    </td>

                    <td>
                      <div className="flex gap-2 justify-center">
                        <div
                          className="cursor-pointer"
                          onClick={() => setShowHistory(item.po_no)}
                        >
                          <Icon path={mdiHistory} size="16" />
                        </div>
                      </div>
                    </td>
                    <td
                      className="px-3 py-2 text-left cursor-pointer text-blue-600 hover:underline"
                      role="button"
                      onClick={() => {
                        const url = handleSelection(item);
                        navigation.push(url);
                      }}
                    >
                      {item.po_no}
                    </td>
                    <td className="px-3 py-2 text-left">
                      {item.items[0]?.project?.name ?? "--"}
                    </td>
                    <td className="px-3 py-2 text-left">
                      {item.party?.name ?? "--"}
                    </td>
                    <td className="px-3 py-2 text-left">
                      {item.bill_to?.gst_number ?? "--"}
                    </td>
                    <td className="px-3 py-2 text-left">
                      {item.warehouse.name ?? "--"}
                    </td>
                    <td className="px-3 py-2 text-left">{item.cgst ?? "--"}</td>
                    <td className="px-3 py-2 text-left">{item.sgst ?? "--"}</td>
                    <td className="px-3 py-2 text-left">{item.igst ?? "--"}</td>
                    <td className="px-3 py-2 text-left">
                      {item.total ?? "--"}
                    </td>
                    <td className="px-3 py-2 text-left">{item.paid}</td>
                    <td className="px-3 py-2 text-left">
                      {item.total - item.paid}
                    </td>
                    <td className="px-3 py-2 text-left">
                      {item.created_by_name ?? "--"}
                    </td>
                    <td className="px-3 py-2 text-left">
                      {moment(item.created_at).format("DD-MM-YYYY") ?? "--"}
                    </td>
                    {/* Inward Status */}
                    <td className="px-3 py-2 text-left">
                      {item.total_received_qty === 0
                        ? "Pending"
                        : (item.total_po_qty ?? 0) >
                          (item.total_received_qty ?? 0)
                        ? "Partial"
                        : "Completed"}
                    </td>

                    {/* Approval Status */}
                    <td className="px-3 py-2 text-left">
                      {item.approval_status ?? "--"}
                    </td>
                    {/* First Approval By Status */}
                    <td className="px-3 py-2 text-left">
                      {item.approvals?.[0]?.approved_by_name ?? "--"}
                    </td>
                    {/* Last Approval By Status */}
                    <td className="px-3 py-2 text-left">
                      {item.approvals?.[item.approvals.length - 1]
                        ?.approved_by_name ?? "--"}
                    </td>
                    {/* Last Approval By Status */}
                    <td className="px-3 py-2 text-left">
                      {item.status ?? "--"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={13}>
                    <div className="h-56 text-center p-5 my-20">
                      <h5 className="text-gray-400 text-2xl">No Data found!</h5>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-3">
          <Pagination
            itemCount={filter.count}
            page={filter.page}
            total={total}
            count={filter.count}
            onChange={
              (page) => setFilter((o) => ({ ...o, page }))
              // () => console.log("Pagination")
            }
          />
        </div>
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default PurchaseOrderListScreen;
