import { mdiCheck, mdiClose, mdiDelete, mdiPencil, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";

import ItemSearchFieldV2 from "../../../../../erp/purchase_order/purchase_order_create/components/item_search_widget";
import {
  defaultItem,
  OrderItemModelV2,
} from "../../../../../service/models/orders/order_item_model";

interface ExtraRowWidgetProps {
  extra?: OrderItemModelV2;
  onSubmit: (data: OrderItemModelV2) => void;
  edit: boolean;
  onDelete?: (i: OrderItemModelV2) => void;
  // form_id?: string;
}

const ExtraRowWidget: FunctionComponent<ExtraRowWidgetProps> = (props) => {
  const { showToast } = useToast();

  const { edit, onSubmit } = props;
  const [extra, setExtra] = useState<OrderItemModelV2>({
    ...(props.extra ?? defaultItem),
  });
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    setEditable(edit);
  }, [edit]);
  const onDone = () => {
    if (!extra.item_id || extra.qty < 1) {
      showToast({
        type: ToastType.warning,
        text: "Please select warehouse, item and qty",
      });
      return;
    }
    // if (!extra.price) {
    //   extra.price = 0;
    // }
    onSubmit(extra);
  };
  return (
    <>
      <form
        // id={props.form_id}
        onSubmit={(e) => {
          e.preventDefault();
          onDone();
        }}
        className="bg-white rounded-md shadow p-5 "
      >
        <div className="grid grid-cols-6 gap-3">
          <input
            value={extra?.item?.item_code}
            disabled={true}
            onChange={(e) => {
              // let v = 0;
              // if (e.target.value && !isNaN(e.target.value as any)) {
              //   v = Number(e.target.value);
              // }
              // setExtra((ol) => ({ ...ol, unit_no: v }));
            }}
            placeholder="Item Code"
            type="text"
            className="text-left  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <div className="">
            <ItemSearchFieldV2
              placeholder="Select Item"
              value={extra.item}
              onSelect={(item) => {
                setExtra((o) => ({
                  ...o,
                  item_id: item.id,
                  item,
                }));
              }}
            />
          </div>
          <input
            value={extra?.item?.description}
            disabled={true}
            onChange={(e) => {
              // let v = 0;
              // if (e.target.value && !isNaN(e.target.value as any)) {
              //   v = Number(e.target.value);
              // }
              // setExtra((ol) => ({ ...ol, unit_no: v }));
            }}
            placeholder="Item Description"
            type="text"
            className="text-left  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <input
            value={extra?.item?.unit?.formal_name ?? "--"}
            disabled={true}
            onChange={(e) => {
              // let v = 0;
              // if (e.target.value && !isNaN(e.target.value as any)) {
              //   v = Number(e.target.value);
              // }
              // setExtra((ol) => ({ ...ol, unit_no: v }));
            }}
            placeholder="Item Unit"
            type="text"
            className="text-left  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <input
            value={extra?.qty}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = Number(e.target.value);
              }
              setExtra((ol) => ({ ...ol, qty: v }));
            }}
            placeholder=""
            type="number"
            className="text-left  p-1 rounded bg-gray-100 focus:outline-none"
          />

          <div className="flex gap-2 items-center justify-center">
            {props.extra ? (
              <>
                <div className="flex justify-center gap-2">
                  {editable ? (
                    <>
                      <div
                        onClick={() => {
                          if (!window.confirm("Are you sure !")) return;
                          onDone();
                          setEditable(false);
                        }}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                      </div>
                      <div
                        onClick={() => {
                          setExtra(props.extra!);
                          setEditable(false);
                        }}
                        className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiClose} className="h-4 w-4"></Icon>
                      </div>
                    </>
                  ) : (
                    <div
                      onClick={() => setEditable(true)}
                      className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                    >
                      <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                    </div>
                  )}
                </div>
                <div
                  onClick={() => {
                    if (!window.confirm("Are you sure !")) return;
                    if (props.onDelete) props.onDelete(extra);
                  }}
                  className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                >
                  <Icon path={mdiDelete} className="h-4 w-4"></Icon>
                </div>
              </>
            ) : (
              <button
                // form="item_form"
                className="flex gap-1 text-white text-sm bg-myPrimaryColor rounded-full px-2 py-1 hover:scale-110 transform duration-300"
              >
                <Icon path={mdiPlus} className="h-5 w-5 "></Icon> Add
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default ExtraRowWidget;
