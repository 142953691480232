import { mdiRefresh, mdiPlus, mdiClose, mdiArrowTopRight } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import { useModal } from "../../../context_providers/modal/modal_context";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { fetchGet, toQueryString } from "../../../service/api_client";
import { urls } from "../../../utils/urls";
import { useAuth } from "../../../context_providers/auth/authProvider";
import { ACTION, MODULE } from "../../../utils/enums";
import Pagination from "../../../ui/new_components/common/pagination";
import { debounce } from "lodash";
import {
  CostCenter,
  CostCenterCloseStatus,
  CostCenterFilterData,
  MasterCostCenterStatus,
} from "../models/cost_center_model";
import moment from "moment";
import SearchFieldWidget from "../../../ui/new_components/search_field";
import FilterDropDown from "../../../ui/new_components/common/filter_drop_down";

interface CostCenterScreenProps {}

const defaultFilter: CostCenterFilterData = {
  page: 1,
  count: 100,
  // id: "",
  // name: "",
  // costCenterStatus: [],
  // approvalStatus: [],
  // fromMonth: undefined,
  // toMonth: undefined,
};

const CostCenterListScreen: FunctionComponent<CostCenterScreenProps> = () => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/cost_center`;
  const { showModal, onClose } = useModal();

  const [filter, setFilter] = useState<CostCenterFilterData>(defaultFilter);

  const { showToast } = useToast();
  const [checker, setChecker] = useState(false);
  const [maker, setMaker] = useState(false);
  const [showHistory, setShowHistory] = useState<any>();
  const [showImport, setShowImport] = useState(false);
  const [selectedItems, setSelectedItems] = useState<CostCenter[]>([]);
  const [showImportErrors, setShowImportErrors] = useState<boolean>(false);
  const [errorObjectList, setErrorObjectList] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<CostCenter[]>([]);

  // const [state, setState] = useState ({
  //   checker:
  // })

  const { user, isAuthorised } = useAuth();
  const fetchData = async (prop: CostCenterFilterData) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
        setCount(res.data.metadata?.count);
        console.log("Res", res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
        setLoading(false);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  useEffect(() => {
    const maker_permission_result = isAuthorised({
      module: MODULE.cost_center,
      action: ACTION.CREATE,
    });
    const checker_permission_result = isAuthorised({
      module: MODULE.cost_center_approval_L1 || MODULE.cost_center_approval_L2,
      action: ACTION.UPDATE,
    });
    setChecker(checker_permission_result);
    setMaker(maker_permission_result);
    console.log("Checker", checker_permission_result);
    console.log("Maker", maker_permission_result);
  }, [isAuthorised]); // Separate call for permission check

  const debouncedHandler = useCallback(
    debounce((prop: CostCenterFilterData) => {
      if (prop) fetchData(prop);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedHandler(filter);
  }, [page, filter, debouncedHandler]);

  const getCostCenterListData = async () => {
    try {
      const a = document.createElement("a");
      a.target = "_blank";
      console.log("filter:", filter);
      a.href = `${
        process.env.REACT_APP_BACKEND_BASE_URL
      }/api/v3/erp/inventory/cost_center/export${toQueryString({
        query: JSON.stringify({
          ...filter,
        }),
      })}`;

      // Delay the trigger to allow UI updates (if necessary)
      setTimeout(() => {
        setLoading(false);
        showToast({ type: ToastType.success, text: "Download started!" });
        a.click(); // Trigger the download
      }, 1000);
    } catch (error) {
      setLoading(false); // Make sure loading is set to false in case of an error
      showToast({ type: ToastType.error, text: "Failed to start download." });
      console.error("Download error:", error);
    }
  };

  const navaigation = useHistory();
  const onSelect = (d: CostCenter) => {
    if (
      checker &&
      (d.costCenterStatus === MasterCostCenterStatus.approved ||
        d.costCenterStatus === MasterCostCenterStatus.rejected)
    ) {
      navaigation.push(
        urls.procurement_masters_cost_center + "/view-cost-center/" + d.id
      );
    } else if (
      maker &&
      d.costCenterStatus === MasterCostCenterStatus.pendingForApproval
    ) {
      navaigation.push(
        urls.procurement_masters_cost_center + "/view-cost-center/" + d!.id
      );
    } else {
      navaigation.push(
        urls.procurement_masters_cost_center + "/view-cost-center/" + d.id
      );
    }
  };

  const handleOnEdit = () => {
    if (selectedItems.length > 1) {
      showToast({
        type: ToastType.error,
        text: "Please select one item to edit",
      });
    } else if (selectedItems.length === 0) {
      showToast({
        type: ToastType.error,
        text: "Please select an item to edit",
      });
    } else {
      navaigation.push(
        urls.procurement_masters_cost_center + "/update/" + selectedItems[0]!.id
      );
    }
  };

  return (
    <>
      {/* {showHistory && (
          <DrawerModal
            location={DrawerModalLoaction.right}
            show={showHistory !== undefined}
            onClose={() => setShowHistory(false)}
            title="History"
          >
            <ItemActionHistory
              item_code={showHistory}
  
              // show={ShowHistory !== undefined}
              // onClose={(v) => setShowHistory(undefined)}
            />
          </DrawerModal>
        )} */}
      {/* {showImport && (
        <ModalWidget
          data={{
            id: 1,
            title: "Import",
            type: ModalType.modal,

            onClose: () => {
              setShowImport(false);
            },
          }}
          onClose={function (): void {
            setShowImport(false);
          }}
        >
          <ImportWizard
            onClose={() => {
              setShowImport(false);
            }}
            onCancel={function () {
              if (cancelTokenSource) {
                console.log("Cancelled by user");
                cancelTokenSource.cancel("cancelled by user");
              }
            }}
            onSubmit={onImport}
            onError={function (error: string): void {
              showToast({ type: ToastType.error, text: error });
            }}
          />
        </ModalWidget>
      )} */}

      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section id="top_section" className=" ">
          <div className="flex justify-between items-end my-1">
            <h1 className="font-bold text-3xl text-gray-500 flex items-center gap-3">
              Cost Center Master
              <button
                title="Refresh"
                onClick={() => fetchData({ page, count })}
              >
                <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
              </button>
            </h1>
            <div className="flex flex-wrap justify-start   text-sm items-center gap-4 ">
              {" "}
              {/* {checker && (
                <div
                  onClick={() => {
                    setShowImport(true);
                  }}
                  className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
                >
                  <Icon path={mdiArrowBottomLeft} size={0.8} />
                  Import
                </div>
              )} */}
              <div className="flex items-center  gap-1">
                <label htmlFor="fromDate" className="">
                  From
                </label>
                <input
                  type="date"
                  id="fromDate"
                  className="p-1 rounded"
                  value={
                    filter.fromMonth
                      ? moment(filter?.fromMonth).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                    const fromDate = selectedDate
                      ? new Date(new Date(selectedDate).setHours(0, 0, 0, 0)) // Convert to a date object
                      : undefined; // Reset if no date is selected

                    // Update the filter
                    setFilter({
                      ...filter,
                      fromMonth: fromDate,
                    });
                  }}
                />
              </div>
              <div className="flex items-center  gap-1">
                <label htmlFor="fromDate" className="">
                  To
                </label>
                <input
                  type="date"
                  id="to_date"
                  className="p-1 rounded"
                  value={
                    filter.toMonth
                      ? moment(filter?.toMonth).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                    const toDate = selectedDate
                      ? new Date(new Date(selectedDate).setHours(0, 0, 0, 0)) // Convert to a date object
                      : undefined; // Reset if no date is selected

                    // Update the filter
                    setFilter({
                      ...filter,
                      toMonth: toDate,
                    });
                  }}
                />
              </div>
              {(filter.fromMonth || filter.toMonth) && (
                <button
                  title="Clear dates"
                  onClick={() =>
                    setFilter((o) => ({
                      ...o,
                      fromMonth: undefined,
                      toMonth: undefined,
                    }))
                  }
                  className="flex gap-1 items-center cursor-pointer rounded p-1 border  r hover:scale-105 duration-200     transform"
                >
                  <Icon path={mdiClose} size={0.7}></Icon>
                </button>
              )}
              <button
                onClick={() => {
                  getCostCenterListData();
                }}
                className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white flex gap-2 items-center"
              >
                <Icon path={mdiArrowTopRight} className="h-4 w-4"></Icon>
                Export
              </button>
              {maker && (
                <>
                  <div
                    onClick={() => {
                      navaigation.push(
                        urls.procurement_masters_cost_center + "/create"
                      );
                    }}
                    className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200 text-white transform"
                  >
                    <Icon path={mdiPlus} className="h-4 w-4"></Icon>Create
                  </div>
                </>
              )}
              {maker && (
                <>
                  <div
                    onClick={handleOnEdit}
                    className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200 text-white transform"
                  >
                    <Icon path={mdiPlus} className="h-4 w-4"></Icon>Update
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
        <div className="grid grid-cols-2 gap-6 w-1/2 m-2">
          <div className="flex flex-col gap-1">
            <label className="text-md pt-1">Cost Center ID</label>
            <SearchFieldWidget
              defaulValue={filter?.id}
              onClear={() => {
                setFilter({
                  ...filter,
                  id: undefined, // Fixed indentation
                });
                setPage(1);
              }}
              onSubmit={(val) => {
                setFilter({
                  ...filter,
                  id: val, // Fixed indentation
                });
                setPage(1);
              }}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-md pt-1">
              Cost Center Name
            </label>
            <SearchFieldWidget
              defaulValue={filter?.name}
              onClear={() => {
                setFilter({
                  ...filter,
                  name: undefined, // Fixed indentation
                });
                setPage(1);
              }}
              onSubmit={(val) => {
                setFilter({
                  ...filter,
                  name: val, // Fixed indentation
                });
                setPage(1);
              }}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-md">
              Approval Status
            </label>
            <FilterDropDown
              placeholder="Approval Status"
              onClearAll={() =>
                setFilter({
                  ...filter,
                  approvalStatus: undefined, // Fixed indentation
                })
              }
              onDelete={(type) => {
                setFilter({
                  ...filter,
                  approvalStatus: filter?.approvalStatus?.filter(
                    (f: any) => f !== type
                  ),
                });
              }}
              onSelect={(type) => {
                setFilter((old) => ({
                  ...old,
                  approvalStatus: [...(old?.approvalStatus ?? []), type],
                }));
              }}
              selectedOptions={filter?.approvalStatus ?? []}
              options={Object.values(MasterCostCenterStatus).map((t) => ({
                label: t,
                value: t,
              }))}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-md">
              Cost Center Status
            </label>
            <FilterDropDown
              placeholder="CC Status"
              onClearAll={() =>
                setFilter({
                  ...filter,
                  costCenterStatus: undefined, // Fixed indentation
                })
              }
              onDelete={(type) => {
                setFilter({
                  ...filter,
                  costCenterStatus: filter?.costCenterStatus?.filter(
                    (f: any) => f !== type
                  ),
                });
              }}
              onSelect={(type) => {
                setFilter((old) => ({
                  ...old,
                  costCenterStatus: [...(old?.costCenterStatus ?? []), type],
                }));
              }}
              selectedOptions={filter?.costCenterStatus ?? []}
              options={Object.values(CostCenterCloseStatus).map((t) => ({
                label: t,
                value: t,
              }))}
            />
          </div>
        </div>
        <div>
          {showImportErrors &&
            errorObjectList.length > 0 &&
            (() => {
              return (
                <div className="">
                  <div className="rounded-lg border border-red-800 m-2 px-4 py-3">
                    <div className="flex justify-between">
                      <div
                        onClick={() => {
                          setShowImportErrors(false);
                        }}
                        className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded"
                      >
                        <Icon path={mdiClose} className="h-4 w-4"></Icon>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })()}
        </div>

        <div className="my-2 border overflow-auto" style={{ height: "80%" }}>
          <table className="w-full     text-xs divide-y divide-gray-200 relative ">
            <thead className="  text-xs  py-2 sticky -top-1 bg-white z-10">
              <tr className="border ">
                <th>
                  <div className="">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      checked={
                        selectedItems.length === data.length ? true : false
                      }
                      disabled={checker && !maker ? false : true}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedItems([...data]);
                        } else {
                          setSelectedItems([]);
                        }
                        console.log(e);
                      }}
                    />
                  </div>
                </th>
                {/* <th className="px-2 py-2">Actions</th> */}
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Cost Center ID
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">Name</th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Created At
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  From Month
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  To Month
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Period
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Budget
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Additional Funds
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Total Budget
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">Spent</th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Balance
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Last Approved Budget
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  {" "}
                  Approved Status
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Cost Center Status
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Created By
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Last Approved By
                </th>
                <th className="px-3 py-3 whitespace-nowrap text-left">
                  Remarks
                </th>
              </tr>
            </thead>
            <tbody className="">
              {data?.length > 0 ? (
                data.map((cost, i) => {
                  const isSelected = selectedItems.includes(cost);
                  return (
                    <tr className="border text-center " key={i}>
                      <td>
                        <div className="p-2">
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            checked={isSelected}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedItems((prev) => [...prev, cost]); // Add the item to selected items
                              } else {
                                setSelectedItems((prev) =>
                                  prev.filter(
                                    (selectedItem) => selectedItem !== cost
                                  )
                                ); // Remove the item from selected items
                              }
                            }}
                          />
                        </div>
                      </td>
                      {/* <td>
                          <div className="flex gap-2 justify-center">
                            {" "}
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                setShowHistory(cost.id);
                              }}
                            >
                              <Icon path={mdiHistory} size="16"></Icon>
                            </div>
                          </div>
                        </td> */}
                      <div
                        className="py-3 items-center cursor-pointer text-blue-600 hover:underline"
                        role="button"
                        onClick={() => onSelect(cost)}
                      >
                        <td className="px-3 py-3 whitespace-nowrap text-left">
                          {cost.id}
                        </td>
                      </div>

                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {cost.name}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {moment(cost.created_at).format("DD-MM-yyyy")}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap  text-left">
                        {cost?.fromMonth
                          ? moment(cost?.fromMonth).format("DD-MM-YYYY")
                          : ""}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {cost?.toMonth
                          ? moment(cost?.toMonth).format("DD-MM-YYYY")
                          : ""}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {cost.period ?? 0}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {cost.budget ?? 0}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {cost.totalAdditionalFunds ?? 0}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {cost.totalBudget ?? 0}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {cost.spent ?? 0}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {cost?.lastApprovedBudget - cost?.spent}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-center">
                        {cost.lastApprovedBudget ?? 0}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {cost.approvalStatus ?? "--"}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {cost.costCenterStatus ?? "--"}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {cost.created_by_name ?? "--"}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {cost.approved_by_name ?? "--"}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap text-left">
                        {cost.remarks ?? "--"}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={13}>
                    <div className="h-56 text-center p-5 my-20">
                      <h5 className="text-gray-400 text-2xl">No Data found!</h5>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-3">
          <Pagination
            itemCount={count}
            page={filter?.page}
            total={total}
            count={filter?.count}
            onChange={
              (page) => setFilter((o) => ({ ...o, page }))
              // () => console.log("Pagination")
            }
          />
        </div>
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default CostCenterListScreen;
