import { mdiArrowLeft, mdiArrowRight, mdiChevronUp } from "@mdi/js";
import Icon from "@mdi/react";
import { ComponentElement, FunctionComponent, useState } from "react";
import OrderCreateContext, {
  orderDetailValidator,
  useOrderCreateCtx,
} from "../../../../context_providers/order_create_context";
import { currencyFormat } from "../../../../utils/orders_utils";
import StepperWidget from "../../../new_components/common/stepper_widget";
import BottomSheetWidget from "./bottom_sheet";
import OrderDetailSection from "./order_detail/detail_section";
import ProductSection from "./product/product_section";
import ConsumptionSectionWidget from "./extra_section/extra_section_widget";
import DiscountSectionWidget from "./dicount_section/discount_section_widget";
import CustomerSectionWidget from "./customer_section/customer_section_widget";
import SubscriptionSection from "./subscription/subscription_section";
import PaymentSectionWidget from "./payments/payment_section_widget";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import AttachmentSection from "./attachment/attachment_section";

interface OrderCreatePageProps {}

const OrderCreatePage: FunctionComponent<OrderCreatePageProps> = () => {
  return (
    <>
      <OrderCreateContext>
        <OrderCreateBody />
      </OrderCreateContext>
    </>
  );
};

export default OrderCreatePage;

interface OrderCreateBodyProps {}

const OrderCreateBody: FunctionComponent<OrderCreateBodyProps> = () => {
  const { order, setStep, step, steps } = useOrderCreateCtx();

  // const order_detail_form = "order_detail";

  const getSection = (s: number) => {
    switch (s) {
      case 0:
        return <CustomerSectionWidget />;
      case 1:
        return <OrderDetailSection />;
      case 2:
        return <ProductSection />;
      case 3:
        return (
          <div className="grid grid-cols-1  gap-5">
            <div className="">
              <ConsumptionSectionWidget />
            </div>
          </div>
        );
      case 4:
        return <SubscriptionSection />;
      case 5:
        return <PaymentSectionWidget />;
      case 6:
        return <AttachmentSection />;
      default:
        return <>Not handled</>;
    }
  };
  return (
    <div className="relative bg-myBgcolor h-screen px-2 md:px-5  overflow-auto">
      <div className="sticky z-10 top-0 p-2  md:px-5 md:pt-3 md:pb-6 rounded-md shadow bg-white mb-3">
        <StepperWidget
          activeStep={step}
          onChange={(s) => {
            setStep(s);
          }}
          steps={steps}
        />
      </div>
      <div className="relative" style={{ minHeight: "78vh" }}>
        {getSection(step)}{" "}
        <div className=" flex items-center justify-between p-5">
          <div
            onClick={() => setStep(step - 1)}
            className={`  ${
              step === 0 && "invisible"
            } p-2   left-0 bg-myPrimaryColor text-white rounded-full cursor-pointer hover:scale-110 transform duration-100 `}
          >
            <Icon path={mdiArrowLeft} className="h-6 w-6"></Icon>
          </div>
          <div
            onClick={() => setStep(step + 1)}
            className={`    ${
              step === 6 && "invisible"
            } p-2 bg-myPrimaryColor text-white rounded-full cursor-pointer hover:scale-110 transform duration-100`}
          >
            <Icon path={mdiArrowRight} className="h-6 w-6"></Icon>
          </div>
        </div>
      </div>
      {/* <div className="sticky  bottom-16 right-0 left-0 flex items-center justify-between p-5">
          <div
            onClick={() => setStep(step - 1)}
            className={`${
              step === 0 && "invisible"
            } p-2   left-0 bg-myPrimaryColor text-white rounded-full cursor-pointer hover:scale-110 transform duration-100 `}
          >
            <Icon path={mdiArrowLeft} className="h-6 w-6"></Icon>
          </div>
          <div
            onClick={() => setStep(step + 1)}
            className={`${
              step === 6 && "invisible"
            } p-2 bg-myPrimaryColor text-white rounded-full cursor-pointer hover:scale-110 transform duration-100`}
          >
            <Icon path={mdiArrowRight} className="h-6 w-6"></Icon>
          </div>
        </div>
      </div> */}

      {(order.products.length > 0 ||
        order.services.length > 0 ||
        order.raw_materials.length > 0 ||
        order.discountData.length > 0 ||
        order.excessData.length > 0) && (
        <div className="sticky bottom-0 right-0 mt-3 w-full rounded-t-md bg-white shadow-sm py-3 px-5">
          <BottomSheetWidget />
        </div>
      )}
    </div>
  );
};
