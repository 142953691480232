import { mdiMenuDown, mdiMenuUp, mdiRefresh } from "@mdi/js";
import Icon from "@mdi/react";
import debounce from "lodash.debounce";
import moment from "moment";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LoadingWidget from "../../../../context_providers/modal/loader";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../../procurement/components/create_view/form_field";
import SearchFieldWidget from "../../../../ui/new_components/search_field";
import { UTCToLocalDate } from "../../../../utils/date_util";
import {
  PurchaseVoucherFilter,
  PurchaseVoucherModelV2,
} from "../../models/purchase_model";
import NewTableView from "../../../../procurement/components/new_table_comp";
import WarehouseMultiSelect from "../../../inventory/journals/stock_journal/stock_transfer_journal_create/components/warehouse_multi_select";
import { WarehouseModel } from "../../../inventory/models/warehouse_model";
import { PartyModel } from "../../models/party_model";
import VendorMultiSelect from "../../../inventory/journals/stock_journal/stock_transfer_journal_create/components/vendor_multi_search";
import { urls } from "../../../../utils/urls";
import { GetPurchaseVoucherList } from "./repo/purchase_voucher_repo";

interface PurchaseVoucherListScreenProps {}

const PurchaseVoucherListScreen: FunctionComponent<
  PurchaseVoucherListScreenProps
> = () => {
  const navigation = useHistory();
  // const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/voucher/purchase`;
  // const { showModal, onClose } = useModal();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [purchaseVoucherData, setPurchaseVoucherData] = useState<
    PurchaseVoucherModelV2[]
  >([]);

  const [filterCollaped, setFilterCollapsed] = useState<boolean>(false);

  const [filter, setFilter] = useState<PurchaseVoucherFilter>({
    all: false,
    count: 50,
    total: 0,
    selectedVendors: [],
    selectedWarehouses: [],
  });

  const [someValue, setSomeValue] = useState<string>("");

  useEffect(() => {
    console.log("Some Value", someValue);
  }, [someValue]);

  const [selectedVendors, setSelectedVendors] = useState<PartyModel[]>([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState<
    WarehouseModel[]
  >([]);

  const fetchData = async (prop: PurchaseVoucherFilter) => {
    setLoading(true);

    let processedProp = {
      ...prop,
      warehouses: prop.selectedWarehouses?.map((w) => w) || [],
      vendors: prop.selectedVendors?.map((v) => v) || [],
    };
    delete processedProp.selectedWarehouses;
    delete processedProp.selectedVendors;
    try {
      // const res = await fetchGet(url + toQueryString(prop));
      const res = await GetPurchaseVoucherList(prop);
      if (res.success) {
        setPurchaseVoucherData(res.data.data);
        if (res.data.metadata) {
          setFilter((prev) => ({
            ...prev,
            total: res.data.metadata.total,
          }));
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce(() => {
      setPurchaseVoucherData([]); // Reset data if no filter
      let prop = { ...filter, page };
      fetchData(prop);
    }, 500),
    [filter, page]
  );

  useEffect(() => {
    console.log("FilterData", filter);
    debouncedHandler();
  }, [filter, page, debouncedHandler]);

  return (
    <>
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section
          id="top_section"
          className="flex flex-wrap justify-between items-end my-1 "
        >
          <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
            <p className="border-l-4 border-myPrimaryColor pl-2">
              Goods Received Note
            </p>
            <button title="Refersh" onClick={() => debouncedHandler()}>
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>

          <div className="flex flex-wrap text-sm items-center gap-1 ">
            <div className="text-sm flex items-center gap-2">
              <div className="flex items-center  gap-1">
                <label htmlFor="fromDate" className="">
                  From
                </label>
                <input
                  type="date"
                  name=""
                  id="fromDate"
                  className="p-1 rounded "
                  value={moment(filter.fromDate).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    const now = UTCToLocalDate(e.target.value)!;
                    const from = new Date(new Date(now).setHours(0, 0, 0, 0));

                    setFilter((prev) => ({
                      ...prev,
                      fromDate: from,
                    }));
                  }}
                />
              </div>
              <div className="flex items-center  gap-1">
                <label htmlFor="toDate" className="">
                  To
                </label>
                <input
                  type="date"
                  name=""
                  id="toDate"
                  className="p-1 rounded "
                  value={moment(filter.toDate).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    const now = UTCToLocalDate(e.target.value)!;
                    const to = new Date(
                      new Date(now).setHours(23, 59, 59, 999)
                    );
                    setFilter((prev) => ({
                      ...prev,
                      toDate: to,
                    }));
                  }}
                />
              </div>
              <div className="flex gap-2">
                <h6 className="font-normal">Filters</h6>
                <div
                  onClick={() => setFilterCollapsed(!filterCollaped)}
                  className="flex   items-center justify-center bg-gray-200 p-1 rounded-full hover:bg-gray-800 hover:text-white"
                >
                  <Icon
                    path={filterCollaped ? mdiMenuDown : mdiMenuUp}
                    className="w-4 h-4"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>{" "}
        {filterCollaped ? (
          <section>
            <div className=" grid grid-cols-2 gap-6 w-1/2 m-2">
              <div className="">
                <label htmlFor="app_status" className="">
                  GRN
                </label>
                <SearchFieldWidget
                  defaulValue={filter?.gr_number ?? ""}
                  onClear={() => {
                    setFilter((prevData) => ({
                      ...prevData,
                      gr_number: "", // Directly updating item_code
                    }));
                  }}
                  onSubmit={(value: string) => {
                    setFilter((prevData) => ({
                      ...prevData,
                      gr_number: value, // Directly updating item_code
                    }));
                  }}
                />
              </div>

              <div className="">
                <label htmlFor="">Warehouse</label>
                <WarehouseMultiSelect
                  onSelect={(selectedWarehouse: WarehouseModel) => {
                    setFilter((prevFilter) => {
                      // Prevent duplicate selections
                      if (
                        prevFilter.selectedWarehouses?.some(
                          (w) => w === selectedWarehouse.id
                        )
                      ) {
                        return prevFilter; // Ignore duplicate entry
                      }

                      return {
                        ...prevFilter,
                        selectedWarehouses: [
                          ...(prevFilter.selectedWarehouses || []),
                          selectedWarehouse.id,
                        ],
                      };
                    });

                    setSelectedWarehouses((prevWarehouses) => {
                      // Prevent duplicate selections
                      if (
                        prevWarehouses.some(
                          (v) => v.id === selectedWarehouse.id
                        )
                      ) {
                        return prevWarehouses; // Ignore duplicate entry
                      }

                      return [...prevWarehouses, selectedWarehouse]; // Append the new vendor
                    });
                  }}
                  onDelete={(id: string) => {
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      selectedWarehouses: (
                        prevFilter.selectedWarehouses || []
                      ).filter((w) => w !== id),
                    }));

                    setSelectedWarehouses((prevWarehouses) =>
                      prevWarehouses.filter((v) => v.id !== id)
                    );
                  }}
                  selectedWarehouses={selectedWarehouses ?? []}
                />
              </div>
              <div className="">
                <label htmlFor="app_status" className="">
                  PO Number
                </label>
                <SearchFieldWidget
                  defaulValue={filter?.po_number ?? ""}
                  onClear={() => {
                    setFilter((prevData) => ({
                      ...prevData,
                      po_number: "",
                    }));
                  }}
                  onSubmit={(value: string) =>
                    setFilter((prevData) => ({
                      ...prevData,
                      po_number: value,
                    }))
                  }
                />
              </div>
              <div className="">
                <label htmlFor="">Vendor Name</label>
                <VendorMultiSelect
                  onSelect={(selectedVendor: PartyModel) => {
                    setFilter((prevFilter) => {
                      // Prevent duplicate selections
                      if (
                        (prevFilter.selectedVendors ?? []).some(
                          (v) => v === selectedVendor.id
                        )
                      ) {
                        return prevFilter; // Ignore duplicate entry
                      }

                      return {
                        ...prevFilter,
                        selectedVendors: [
                          ...(prevFilter.selectedVendors ?? []),
                          selectedVendor.id,
                        ],
                      };
                    });

                    setSelectedVendors((prevVendors) => {
                      // Prevent duplicate selections
                      if (prevVendors.some((v) => v.id === selectedVendor.id)) {
                        return prevVendors; // Ignore duplicate entry
                      }

                      return [...prevVendors, selectedVendor]; // Append the new vendor
                    });
                  }}
                  onDelete={(id: string) => {
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      selectedVendors: (
                        prevFilter.selectedVendors ?? []
                      ).filter((v) => v !== id),
                    }));

                    setSelectedVendors((prevVendors) =>
                      prevVendors.filter((v) => v.id !== id)
                    );
                  }}
                  selectedVendors={selectedVendors ?? []}
                />
              </div>
              <div>
                <div className="flex flex-col gap-1 w-full">
                  <label htmlFor="fromDate" className="">
                    Delivery From
                  </label>
                  <input
                    type="date"
                    name=""
                    id="fromDate"
                    className="p-1 rounded "
                    value={moment(filter.delivery_from).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      const now = UTCToLocalDate(e.target.value)!;
                      const from = new Date(new Date(now).setHours(0, 0, 0, 0));

                      setFilter((prev) => ({
                        ...prev,
                        delivery_from: from,
                      }));
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="">
                  <label htmlFor="toDate" className="">
                    Delivery To
                  </label>
                  <input
                    type="date"
                    name=""
                    id="toDate"
                    className="p-1 rounded flex flex-col gap-1 w-full"
                    value={moment(filter.delivery_to).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      const now = UTCToLocalDate(e.target.value)!;
                      const to = new Date(new Date(now).setHours(0, 0, 0, 0));
                      setFilter((prev) => ({
                        ...prev,
                        delivery_to: to,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
        ) : null}
        <div className="mt-4">
          <NewTableView
            // stickyHeader={true}
            // show_index={true}
            // show_pagination={true}
            headers={[
              {
                key: "po_no",
                value: " ",
                type: FieldType.string,
              },
              {
                key: "id",
                value: "GRN",
                type: FieldType.string,
              },
              {
                key: "po_no",
                value: "PO Number",
                type: FieldType.string,
              },
              {
                key: "party",
                value: "Vendor Name",
                type: FieldType.string,
              },
              {
                key: "warehouse",
                value: "Warehouse",
                type: FieldType.string,
              },
              {
                key: "due_date",
                value: "Delivery Date",
                type: FieldType.date,
              },

              // {
              //   key: "total_po_qty",
              //   value: "Total PO QTY",
              //   type: FieldType.number,
              // },

              {
                key: "top",
                value: "Total PO QTY",
                type: FieldType.number,
              },
              {
                key: "total_received_qty",
                value: "Total Received QTY",
                type: FieldType.number,
              },
              {
                key: "created_on",
                value: "Created On",
                type: FieldType.date,
              },
              {
                key: "",
                value: "Attachment",
                type: FieldType.string,
              },
            ]}
            rows={purchaseVoucherData.map((data, i) => ({
              data: data,
              cells: [
                // { value: "" },
                // { value: data?.id },
                {
                  builder: () => (
                    <button
                      onClick={() => {
                        navigation.push(
                          `${urls.purchase_voucher}/detail-screen/${data.id}` +
                            `?po_no=${data.order_detail?.po_no ?? ""}`
                        );
                      }}
                      className="relative text-sm font-semibold text-blue-400 hover:text-blue-500 whitespace-nowrap sticky-field  flex items-center gap-1 px-2 h-full w-full"
                    >
                      <div>{data.id}</div>
                    </button>
                  ),
                },
                { value: data?.order_detail?.po_no ?? "--" },
                { value: data?.party_detail?.name ?? "--" },
                { value: data?.warehouse?.name ?? "--" },
                { value: data?.due_date },

                { value: data?.total_po_qty ?? 0 },
                { value: data?.total_received_qty ?? 0 },
                { value: data?.created_at },
                {
                  builder: () => (
                    <button
                      onClick={() => {
                        console.log("Test");
                      }}
                      className="relative text-sm font-semibold text-blue-400 hover:text-blue-500 whitespace-nowrap sticky-field  flex items-center gap-1 px-2 h-full w-full"
                    >
                      View
                      <div>({data?.attachments?.length}+)</div>
                    </button>
                  ),
                },
              ],
            }))}
            page={page}
            total={filter.total}
            count={filter.count}
            itemCount={purchaseVoucherData?.length}
            onPageChange={setPage}
            onShowHistory={() => {}}
            // onClick={onSelect}
            // onEdit={onSelect}
            // onDelete={onDelete}
          />
        </div>
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default PurchaseVoucherListScreen;
