import { toQueryString } from "../../../../../service/api_client";
import { fetchGet } from "../../../../../ui/pages/content_panel/services/serviceClient";
import { PurchaseVoucherFilter } from "../../../models/purchase_model";

export const GetPurchaseVoucherList = async (prop: PurchaseVoucherFilter) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/voucher/purchase`;

  const result = await fetchGet(url + toQueryString(prop));
  return result;
};
