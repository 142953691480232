import { useEffect, useState } from "react";
import RequiredMark from "../../../ui/new_components/common/required_mark";
import { dataTypes } from "../../../utils/import_export_parser";
import { defaultKazamGST, KazamGST } from "../models/gst_model";
import { updateObject } from "../../../utils/formDataToJson";
import moment from "moment";
import GstAttachmentSection from "./components/gst_attachment";
import {
  AttachmentModel,
  getFileType,
} from "../../purchase_order/model/purchase_order_model";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { generate_id } from "../../inventory/journals/destination_stock_voucher/components/item_row_status";
import { file_dir, indianStates } from "../../../utils/enums";
import {
  getGstAttachmentSignedURLAPI,
  GSTCreateRepo,
  uploadGstAttachmentFiletoS3API,
} from "./repo/gst_repo";
import { useHistory } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";

const GstCreateScreen = () => {
  const { showToast } = useToast();
  const [kazamGst, setKazamGst] = useState<KazamGST>(defaultKazamGST);
  const [loading, setLoading] = useState(false);
  const [attachmentKey, setAttachmentKey] = useState(Math.random() * 10000);
  const navigation = useHistory();

  useEffect(() => {
    console.log("GST Object", kazamGst);
  }, [kazamGst]);

  const handleChange = (e: any) => {
    const { value, name } = e.target;
    const type = e.target.getAttribute("data-type");

    const keys = name.split(".");
    const updatedValue = updateObject(kazamGst, keys, value, type);
    setKazamGst(updatedValue);
  };

  const uploadFile = async ({
    file,
    category,
  }: {
    file: File;
    category: string;
  }) => {
    console.log("Upload File Called");
    try {
      if (!file) {
        showToast({
          type: ToastType.warning,
          text: "Please select a file",
        });
        return;
      }

      setLoading(true);
      const { name, type } = file;

      const query = {
        mimetype: type,
        extension: name.split(".").pop(),
        dir: file_dir.gst_attachments,
      };

      const res = await getGstAttachmentSignedURLAPI(query);

      if (res.success) {
        const { signed_url, url } = res.data;

        await uploadGstAttachmentFiletoS3API({
          signed_url,
          file,
          type: getFileType(type),
        });

        const newAttachment: AttachmentModel = {
          id: generate_id(),
          category,
          type: getFileType(type),
          url,
        };
        setKazamGst((o) => ({
          ...o,
          attachments: [...(o.attachments || []), newAttachment],
        }));

        showToast({
          type: ToastType.success,
          text: "Upload successfull",
        });

        // Updating KazamGST state properly by adding the new attachment
        setAttachmentKey(Math.random() * 1000);
        setLoading(false);
        return newAttachment;
      }

      setLoading(false);
    } catch (error: any) {
      console.log("Inside Error Block");
      setLoading(false);
      showToast({
        type: ToastType.error,
        text: error.message,
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // ✅ Prevents page reload

    const form = e.target as HTMLFormElement;

    if (!form.checkValidity()) {
      // Find the first invalid input and focus on it
      const firstInvalidField = form.querySelector(":invalid") as HTMLElement;
      firstInvalidField?.focus();
      form.reportValidity();
      return;
    }

    setLoading(true);
    try {
      const res = await GSTCreateRepo(kazamGst);
      if (res.success) {
        showToast({ type: ToastType.success, text: res.message });
        if (!kazamGst.id) {
          setKazamGst(defaultKazamGST);
        }
        setKazamGst((prevData) => ({
          ...prevData,
          ...res.data.value,
        }));
        navigation.goBack();
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    }
    setLoading(false);
  };

  return (
    <div>
      <form
        id="gst_create_form"
        onSubmit={handleSubmit}
        action="submit"
        method="POST"
        className="grid gap-2 p-5 "
      >
        {" "}
        <h1 className="my-4 text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
          Create Kazam GST
        </h1>
        <div className="bg-white rounded-md p-4 w-full">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 w-1/2">
            <div className="grid gap-1">
              <label htmlFor="gst_number" className="text-sm font-semibold">
                GST Number <RequiredMark />
              </label>
              <input
                required
                className="border rounded-md px-3 py-1 w-full"
                type="text"
                data-type={dataTypes.string}
                id="gst_number"
                name="gst_number"
                value={kazamGst.gst_number}
                onChange={handleChange}
              />
            </div>
            <div className="grid gap-1">
              <label htmlFor="name" className="text-sm font-semibold">
                Name <RequiredMark />
              </label>
              <input
                required
                className="border rounded-md px-3 py-1 w-full"
                type="text"
                data-type={dataTypes.string}
                id="name"
                name="name"
                value={kazamGst.name}
                onChange={handleChange}
              />
            </div>
            <div className="grid gap-1">
              <label htmlFor="address.state" className="text-sm font-semibold">
                State <RequiredMark />
              </label>
              {/* <input
              required
              className="border rounded-md px-3 py-1 w-full"
              type="text"
              data-type={dataTypes.string}
              id="address.state"
              name="address.state"
              value={kazamGst.address.state}
              onChange={handleChange}
            /> */}
              <select
                id="address.state"
                name="address.state"
                className="p-1 rounded focus:outline-none w-full border"
                value={kazamGst.address.state || ""} // Make sure it defaults to empty string if undefined
                data-type={dataTypes.string}
                // onChange={(newValue) => {
                //   const selectedStateValue = (
                //     newValue.target as HTMLSelectElement
                //   ).value;

                //   setKazamGst((prev: any) => ({
                //     ...prev,
                //     address: {
                //       ...prev.mail_detail.address,
                //       state: selectedStateValue, // Update the country field in the vendor state
                //     },
                //   }));
                // }}
                onChange={handleChange}
              >
                <option value="">Select State</option>
                {indianStates.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
            <div className="grid gap-1">
              <label htmlFor="address.city" className="text-sm font-semibold">
                City <RequiredMark />
              </label>
              <input
                required
                className="border rounded-md px-3 py-1 w-full"
                type="text"
                data-type={dataTypes.string}
                id="address.city"
                name="address.city"
                value={kazamGst.address.city}
                onChange={handleChange}
              />
            </div>
            {/* Address */}
            <div className="grid grid-cols-1">
              <div className="grid gap-1">
                <label htmlFor="address.line1" className="text-sm font-semibold">
                  Address Line 1 <RequiredMark />
                </label>
                <input
                  required
                  className="border rounded-md px-3 py-1 w-full"
                  type="text"
                  data-type={dataTypes.string}
                  id="address.line1"
                  name="address.line1"
                  value={kazamGst.address.line1}
                  onChange={handleChange}
                />
              </div>
              <div className="grid grid-cols-1">
                <div className="grid gap-1 my-5">
                  <label htmlFor="address.line2" className="text-sm font-semibold">
                    Line 2 <RequiredMark />
                  </label>
                  <input
                    required
                    className="border rounded-md px-3 py-1 w-full"
                    type="text"
                    data-type={dataTypes.string}
                    id="address.line2"
                    name="address.line2"
                    value={kazamGst.address.line2}
                    onChange={handleChange}
                  />
                </div>
                <div className="grid gap-1">
                  <label htmlFor="address.line3" className="text-sm font-semibold">
                    Line 3
                  </label>
                  <input
                    className="border rounded-md px-3 py-1 w-full"
                    type="text"
                    data-type={dataTypes.string}
                    id="address.line3"
                    name="address.line3"
                    value={kazamGst.address.line3}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="grid gap-1 self-start">
              <label htmlFor="address.country" className="text-sm font-semibold">
                Country <RequiredMark />
              </label>
              <input
                required
                disabled
                className="border rounded-md px-3 py-1 w-full"
                type="text"
                data-type={dataTypes.string}
                id="address.country"
                name="address.country"
                value={kazamGst.address.country}
                onChange={handleChange}
              />
            </div>
            <div className="grid gap-1 self-start">
              <label htmlFor="address.pincode" className="text-sm font-semibold">
                Pincode <RequiredMark />
              </label>
              <input
                required
                className="border rounded-md px-3 py-1 w-full"
                type="text"
                data-type={dataTypes.string}
                id="address.pincode"
                name="address.pincode"
                value={kazamGst.address.pincode}
                onChange={handleChange}
              />
            </div>
            <div className="grid gap-1 self-start">
              <label htmlFor="effective_date_of_reg" className="text-sm font-semibold">
                Effective date of registration <RequiredMark />
              </label>
              <input
                required
                className="border rounded-md px-3 py-1 w-full"
                type="date"
                id="effective_date_of_reg"
                name="effective_date_of_reg"
                value={moment(kazamGst.effective_date_of_reg).format(
                  "YYYY-MM-DD"
                )}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="w-1/2 mt-5">
            <label htmlFor="effective_date_of_reg" className="text-sm font-semibold ">
              Attachments
              <RequiredMark />
            </label>
            <GstAttachmentSection
              attachments={kazamGst.attachments}
              onSubmit={async function (data: {
                file: File;
                category: string;
              }): Promise<AttachmentModel> {
                const attachment = await uploadFile({
                  file: data.file,
                  category: data.category,
                });
                if (!attachment) {
                  throw new Error("File upload failed");
                }
                return attachment;
              }}
              category={"gst"}
              onDelete={(data: AttachmentModel): void => {
                console.log("OnDelete GST invoked");
                setKazamGst((prevState) => ({
                  ...prevState,
                  attachments: prevState.attachments.filter(
                    (attachment) => attachment.id !== data.id
                  ),
                }));

                showToast({
                  type: ToastType.success,
                  text: "Attachment removed successfully",
                });
              }}
            />
          </div>
        </div>
        <div className="flex justify-end gap-2 my-4">
          <button
            form="gst_create_form"
            type="submit"
            className="bg-myPrimaryColor text-white rounded-lg px-6 py-1.5 text-sm cursor-pointer duration-300"
          >
            Submit
          </button>
          <button
            onClick={() => {
              navigation.goBack();
            }}
            type="button"
            className="rounded-md border border-green-600 text-sm font-semibold bg-white text-black px-3 py-1"
          >
            Quit
          </button>
        </div>
      </form>
      {loading && <LoadingWidget />}
    </div>
  );
};

export default GstCreateScreen;
