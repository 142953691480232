import { BaseModel } from "../../../service/models/base";
import { MODULE } from "../../../utils/enums";

export interface AdditionalFund extends BaseModel {
  fund_amount: number;
  updated_at?: Date;
  updated_by?: string;
  updated_by_name?: string;
}

export interface ApprovalModel extends BaseModel {
  level: string;
  approval_status: string;
  approved_at?: Date;
  approved_by?: string;
  approved_by_name?: string;
  reason?: string;
}

export interface CostCenter extends BaseModel {
  name: string;
  description: string;
  fromMonth?: Date;
  toMonth?: Date;
  period: number;
  budget: number;
  addAdditionalBudget?: number;
  duplicateAdditionalBudget?: number;
  totalAdditionalFunds?: number;
  additionalFunds?: AdditionalFund[];
  totalBudget: number;
  lastApprovedBudget: number;
  spent: number;
  balance: number;
  approvalStatus: string;
  rejectReason?: string;
  costCenterStatus: string;
  remarks: string;
  approved_at?: Date;
  approved_by?: string;
  approved_by_name?: string;
  approval_level?: string;
  approvals?: ApprovalModel[];
  current_approval_level?: string;
  current_approval_required_count?: number;
  current_approval_count?: number;
  current_reject_count?: number;
}

export const CostCenterCloseStatus = {
  closed: "Closed",
  open: "Open",
}

export const MasterCostCenterStatus = {
  approved: "Approved",
  rejected: "Rejected",
  // savedDraft: "Saved Draft",
  // updatePending: "Update Pending",
  pendingForApproval: "Pending for Approval",
  level2Pendng: "L2 Pending"
}

export const costCenterDefaultValue: CostCenter = {
  name: "",
  description: "",
  period: 0,
  budget: 0,
  totalBudget: 0,
  lastApprovedBudget: 0,
  spent: 0,
  balance: 0,
  approvalStatus: MasterCostCenterStatus.pendingForApproval,
  costCenterStatus: "--",
  remarks: "",
}

export interface CostCenterFilterData {
  id?: string;
  name?: string;
  costCenterStatus?: string[];
  approvalStatus?: string[];
  fromMonth?: Date;
  toMonth?: Date;
  search?: string;
  page: number;
  count: number;
  all?: any;
}

export const getCostCenterApprovedCounts = ({
  approvals = [],
}: {
  approvals?: ApprovalModel[];
}) => {
  let counts: { [key: string]: number } = {};
  for (let i = 0; i < approvals.length; i++) {
    const approval = approvals[i];
    if (approval.approval_status === MasterCostCenterStatus.approved) {
      if (!counts[approval.level]) {
        counts[approval.level] = 0;
      }
      counts[approval.level]++;
    }
  }

  return counts;
};

export const approval_level = {
  level_1: "level_1",//Priya (L1) 
  level_2: "level_2",//Akshay (L2)
};

export const levelModule = {
  [approval_level.level_1]: MODULE.cost_center_approval_L1,
  [approval_level.level_2]: MODULE.cost_center_approval_L2,
};

export const approval_threshold: {
  [k: string]: {
    threshold: {
      min: number | null;
      max: number | null;
    };
    approvals_required: {
      level: string;
      count: number;
    }[];
  };
} = {
  [approval_level.level_1]: {
    threshold: {
      min: null,
      max: null,
    },
    approvals_required: [
      {
        level: approval_level.level_1,
        count: 1,
      },
    ],
  },
  [approval_level.level_2]: {
    threshold: {
      min: null,
      max: null,
    },
    approvals_required: [
      {
        level: approval_level.level_1,
        count: 1,
      },
      {
        level: approval_level.level_2,
        count: 1,
      },
    ],
  },
};