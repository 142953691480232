import { ItemMovementModel } from "../erp/inventory/models/inventory_voucher";
import { DiscountDataModel } from "../service/models/orders/order_discount_model";
import OrderItemModel, {
  OrderItemModelV2,
} from "../service/models/orders/order_item_model";
import { TransactionDataModel } from "../service/models/trannsaction_model";
import {
  PaymentMode,
  PaymentStatus,
  TransactionStatus,
  TransactionType,
} from "./enums";

export const GetSubTotalAmt = (items: OrderItemModelV2[]) => {
  return items.reduce(
    (pv: number, item: OrderItemModelV2) =>
      pv + item.qty * (item.price + (item.extra_amt ? item.extra_amt : 0)),
    0
  );
};

export const GetDiscountAmt = (discounts: DiscountDataModel[]) => {
  return discounts.reduce(
    (pv: number, item: DiscountDataModel) => pv + item.amount,
    0
  );
};

export const GetExcessAmt = (excess: ItemMovementModel[]) => {
  return excess.reduce(
    (pv: number, item: ItemMovementModel) =>
      pv + (item as any).amount ?? item.price * item.unit_no,
    0
  );
};

export const GetPaidAmt = (transactions: TransactionDataModel[]) => {
  return transactions.reduce((pv: number, item: TransactionDataModel) => {
    if (
      item.type === TransactionType.PAYMENT &&
      item.status === TransactionStatus.SUCCESS
    )
      return pv + item.amount;
    if (
      item.type === TransactionType.REFUND &&
      item.status === TransactionStatus.SUCCESS
    )
      return pv - item.amount;
    else return pv;
  }, 0);
};
export const GetTxnAmt = (transactions: TransactionDataModel[]) => {
  return transactions.reduce((pv: number, item: TransactionDataModel) => {
    return pv + item.amount;
  }, 0);
};
export const GetOrderPaymentStatus = (paid: number, total: number) => {
  if (!paid || paid === null) return PaymentStatus.PENDING_PAYMENT;
  else if (paid < total) return PaymentStatus.ADVANCE_PAYMENT;
  else return PaymentStatus.FULL_PAYMENT;
};
export const GetPaytmPayentMode = (mode: string) => {
  switch (mode) {
    case "PPI":
      return PaymentMode.upi;
    case "UPI":
      return PaymentMode.upi;
    case "CC":
      return PaymentMode.credit_card;
    case "DC":
      return PaymentMode.debit_card;
    case "NB":
      return PaymentMode.netbanking;

    default:
      return PaymentMode.upi;
  }
};

export const rupeeFormat = (rs: number) => {
  return rs.toLocaleString("en-IN", {
    maximumFractionDigits: 0,
    style: "currency",
    currency: "INR",
  });
};

export const processItem = ({
  item,
  items,
  excess,
  discount,
  paid,
}: {
  item: OrderItemModelV2;
  items: OrderItemModelV2[];
  discount: number;
  excess: number;
  paid: number;
}) => {
  const preItems = items.filter((it) => it.item_id < item.item_id);
  let prevTotal = GetSubTotalAmt(preItems);
  const allQty = items.reduce((pr, val) => {
    return val.qty + pr;
  }, 0);
  const prevQty = preItems.reduce((p, v) => p + v.qty, 0);
  const prevPercent = allQty === 0 ? 0 : prevQty / allQty;
  const percent = allQty === 0 ? 0 : item.qty / allQty;
  const dis = discount * percent;
  const add = excess * percent;
  const preDis = discount * prevPercent;
  const preAdd = excess * prevPercent;
  prevTotal = prevTotal + preAdd - preDis;
  item.discount_amt = dis;
  item.addition_amt = add;
  item.subtotal = GetSubTotalAmt([item]);
  item.total = item.subtotal + item.addition_amt - item.discount_amt;

  item.paid =
    paid >= prevTotal
      ? prevTotal === 0 && paid > item.total
        ? item.total
        : paid - prevTotal
      : 0;

  return item;
};
