import { mdiDelete, mdiUpload } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  FileType,
  getFileType,
} from "../../../../purchase_order/model/purchase_order_model";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import FileDropZone from "../../../../../ui/new_components/common/file_drop_zone";
import LoadingWidget from "../../../../../context_providers/modal/loader";

interface InwardAttachmentSectionProps {
  attachments: File[];
  voucher_id?: string;
  onSubmit?: (data: {
    file: File;
    category: string;
    removeSelectedFile: () => void;
    setShowUpload: (v: boolean) => void;
  }) => void;
  onDelete?: (file: File) => void;
  onSelect: (file: File) => void;
}

const InwardAttachmentSection: FunctionComponent<
  InwardAttachmentSectionProps
> = (props) => {
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const [file, setFile] = useState<File | null>(null);
  const [fileBlobUrl, setFileBlobUrl] = useState<string | null>(null);
  const [fileType, setFileType] = useState<string | null>(null);
  const [category, setCategory] = useState("");
  const [attachments, setAttachments] = useState<File[]>(props.attachments);

  useEffect(() => {
    if (file) {
      const type = getFileType(file.type);
      setFileType(type);
      if ([FileType.IMAGE, FileType.PDF].includes(type)) {
        setFileBlobUrl(URL.createObjectURL(file));
      }
    }
  }, [file]);

  const removeSelectedFile = () => {
    if (fileBlobUrl) URL.revokeObjectURL(fileBlobUrl);
    setFileBlobUrl(null);
    setFile(null);
  };

  const handleDelete = (fileToDelete: File) => {
    setAttachments((prev) => prev.filter((att) => att !== fileToDelete));
    if (props.onDelete) props.onDelete(fileToDelete);
    showToast({ type: ToastType.success, text: "File deleted successfully!" });
  };

  const openFileInNewTab = (file: File) => {
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
  };

  return (
    <div className="p-5 select-none">
      {/* Always show FileDropZone */}
      <FileDropZone onDrop={(files) => files[0] && setFile(files[0])} />

      {file && (
        <div className="rounded-lg border relative mt-3">
          {fileType === FileType.IMAGE && (
            <img src={fileBlobUrl!} alt="Uploaded" className="w-full" />
          )}
          {fileType === FileType.PDF && (
            <embed
              src={fileBlobUrl!}
              type="application/pdf"
              className="w-full h-64"
            />
          )}
          <button
            onClick={removeSelectedFile}
            className="absolute top-0 right-0 rounded-full p-1"
          >
            <Icon path={mdiDelete} size={1} />
          </button>
        </div>
      )}

      {file && (
        <div className="flex justify-center my-5">
          <button
            onClick={() =>
              props.onSubmit &&
              props.onSubmit({
                file,
                category,
                removeSelectedFile,
                setShowUpload: () => {}, // No need for showUpload anymore
              })
            }
            className="rounded-lg border px-3 py-1 text-sm flex items-center gap-1"
          >
            <Icon path={mdiUpload} size={0.8} /> Upload
          </button>
        </div>
      )}

      <div className="mt-5">
        <label className="text-lg font-bold py-3">Inward Attachments</label>
      </div>
      <div className="grid grid-cols-7 text-sm font-semibold px-2">
        <div className="col-span-2">File</div>
      </div>
      <div
        className="flex flex-col gap-1 overflow-auto w-full"
        style={{ height: "80vh" }}
      >
        {attachments.map((att, i) => (
          <div
            key={i}
            className="grid grid-cols-3 rounded-lg border overflow-hidden text-xs p-2"
          >
            <div
              className="col-span-2 flex items-center gap-2 cursor-pointer"
              onClick={() => openFileInNewTab(att)}
            >
              <span>{att.name}</span>
            </div>

            <div className="col-span-1 flex justify-end">
              <button
                onClick={() => handleDelete(att)}
                className="rounded-full p-1"
              >
                <Icon
                  path={mdiDelete}
                  size={0.8}
                  className="hover:scale-105 transform"
                />
              </button>
            </div>
          </div>
        ))}
      </div>

      {loading && <LoadingWidget />}
    </div>
  );
};

export default InwardAttachmentSection;
