import axios from "axios";
const client = axios.create({
  withCredentials: true,
});
export const toQueryString = (obj: any) => {
  if (!obj) return "";

  obj = JSON.parse(JSON.stringify(obj));

  const query = "?".concat(
    Object.keys(obj)
      .map((e) =>
        obj[e] !== undefined
          ? `${encodeURIComponent(e)}=${encodeURIComponent(obj[e])}`
          : null
      )
      .filter((e) => !!e)
      .join("&")
  );

  return query;
};

export const getHeaders = (tokenReqd = true) => {
  const token = localStorage.getItem("token");

  let referer: string = "";
  if (process.env.VERCEL && process.env.VERCEL === "1") {
    referer = "vercel";
  }

  let headers: { [k: string]: any } = {
    "Content-Type": "application/json",
  };
  if (token && tokenReqd) {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }

  return headers;
};

export const fetchPost = async (
  url: string,
  data: object,
  headers?: object,
  config?: object
) => {
  try {
    const res = await client.post(url, data, {
      ...config,
      headers: { ...getHeaders(), ...headers },
    });

    if (res.status === 401) {
      localStorage.removeItem("token");
      // window.history.pushState({}, "", "/login");
      window.location.reload();
    }

    return res.data;
  } catch (error: any) {
    const { message, response, request } = error;

    if (response && response.status === 401) {
      localStorage.removeItem("token");
      // window.history.pushState({}, "", "/login");
      window.location.reload();
      // window.location.reload();
      return { success: false, error: "Please login" };
    } else if (response && response.status === 400) return response.data;
    else if (response && response.status === 403) return response.data;
    return {
      success: false,
      error: message + (response ? `(${response?.statusText})` : ""),
    };
  }
};
export const fetchPut = async (
  url: string,
  data: object,
  headers?: object,
  config?: object
) => {
  try {
    const res = await client.put(url, data, {
      ...config,
      headers: { ...getHeaders(), ...headers },
    });

    return res.data;
  } catch (error: any) {
    const { message, response, request } = error;

    if (response && response.status === 401) {
      // window.location.reload();
      localStorage.removeItem("token");
      // window.history.pushState({}, "", "/login");
      window.location.reload();
      return { success: false, error: "Please login" };
    } else if (response && response.status === 400) return response.data;
    else if (response && response.status === 403) return response.data;
    return {
      success: false,
      error: message + (response ? `(${response?.statusText})` : ""),
    };
  }
};

export const fetchGet = async (url: string, headers?: object) => {
  try {
    const res = await client.get(url, {
      headers: { ...getHeaders(), ...headers },
    });

    return res.data;
  } catch (error: any) {
    const { message, response, request } = error;

    if (response && response.status === 401) {
      // window.location.reload();
      localStorage.removeItem("token");
      // window.history.pushState({}, "", "/login");
      window.location.reload();
      return { success: false, error: "Please login" };
    } else if (response && response.status === 400) return response.data;
    else if (response && response.status === 403) return response.data;
    return {
      success: false,
      error: message + (response ? `(${response?.statusText})` : ""),
    };
  }
};
export const fetchDelete = async (
  url: string,
  data?: object,
  headers?: object
) => {
  try {
    const res = await client.delete(url, {
      headers: { ...getHeaders(), ...headers },
    });

    return res.data;
  } catch (error: any) {
    const { message, response, request } = error;

    if (response && response.status === 401) {
      // window.location.reload();
      localStorage.removeItem("token");
      // window.history.pushState({}, "", "/login");
      window.location.reload();
      return { success: false, error: "Please login" };
    } else if (response && response.status === 400) return response.data;
    else if (response && response.status === 403) return response.data;
    return {
      success: false,
      error: message + (response ? `(${response?.statusText})` : ""),
    };
  }
};
