import { RouteParam } from "../../../routes";
import { ACTION, MODULE } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import PurchaseVoucherListScreen from "../account_booking/purchase/purchase_voucher_list";
import PurchaseVoucher from "../vouchers/purchase/inward_create_screen";
import PurchaseVoucherDetail from "../vouchers/purchase/inward_detail_screen";

const purchase_voucher_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.purchase_voucher,
    component: PurchaseVoucherListScreen,
    module: MODULE.voucher,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.purchase_voucher + "/create",
    component: PurchaseVoucher,
    module: MODULE.voucher,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.purchase_voucher + "/detail-screen/:id",
    component: PurchaseVoucherDetail,
    module: MODULE.voucher,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
];

export default purchase_voucher_routes;
