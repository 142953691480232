import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import axios from "axios";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import {
  defaultStockOutward,
  OutwardSource,
  OutwardType,
  StockOutwardItemMovementModel,
  StockOutwardModal,
  StockOutwardStatus,
} from "../models/stock_transfer_model";
import { fetchGet, fetchPost, fetchPut } from "../../../service/api_client";
import { urls } from "../../../utils/urls";
import WarehouseSearchField from "../warehouse/warehouse_search_field";
import { UTCToLocalDate } from "../../../utils/date_util";
import ItemRowOutward from "./ItemRowOutward";
import FilterDropDown from "../../../ui/new_components/common/filter_drop_down";
import PartySearchField from "../../accounts/parties/party_search_field";
import EmployeeSearchWidget from "../../../ui/new_components/emp_search_widget";

interface StockJournalProps {}

const StockOutwardCreate: FunctionComponent<StockJournalProps> = () => {
  const { id } = useParams<{ id: string }>();
  // const location = useLocation<LocationState>();
  // const id1 = location.state?.id; //
  // console.log("Fetched ID from params:", id1); // Log the ID
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/journal/stock_outward`;
  const [, setLoading] = useState(false);
  const [editable] = useState(false);
  const [addNewId1, setAddNewId1] = useState(Math.random() * 1000);
  const { showToast } = useToast();

  const [data, setData] = useState<StockOutwardModal>({
    ...defaultStockOutward,
  });
  const existingItems = data?.items || [];
  const navigate = useHistory();
  // const [ShowHistory, setShowHistory] = useState<{ [key: string]: any }>();
  // const handleOptionChange = (value: WarehouseModel["type"]) => {
  //   setData((pv) => ({ ...pv, type: value }));
  // };
  const locate = useLocation();
  // const isCreatePath = locate.pathname.endsWith("/create");

  useEffect(() => {
    if (id) getVoucher(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // useEffect(() => {
  //   if (id1) getDuplicateVoucher(id1);
  //   getUserData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id1]);

  const getVoucher = async (id: string) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + "/" + id);
      if (res.success) {
        setData((prevData) => ({
          ...prevData,
          ...res.data,
        }));
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({ type: ToastType.error, text: "Failed to fetch voucher" });
    }
    setLoading(false);
  };

  const submit = async () => {
    if (!data.items?.length)
      return showToast({
        type: ToastType.error,
        text: "Please add items!!",
      });
    setLoading(true);
    // console.log("ID in submit", id);
    const res = id
      ? await fetchPut(url + "/" + id, data)
      : await fetchPost(url, data);
    if (res.success) {
      data.status = StockOutwardStatus.pending;
      showToast({ type: ToastType.success, text: res.message });
      navigate.push(urls.stock_outward);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  return (
    <>
      <div className="sticky top-0 mt-2 px-6 h-full">
        <div className="flex items-center justify-between mb-2">
          <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
            Stock Outward Entry
          </div>
        </div>
        <div className="bg-white rounded pb-8 flex gap-1 h-auto overflow-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 p-5 text-sm w-3/4 font-semibold rounded-t top-0">
            <div className="">
              <div className="flex flex-col gap-1">
                <label className="text-md pt-1 font-semibold">
                  {" "}
                  Outward Type *
                </label>
                <select
                  value={data.outward_type}
                  onChange={(e) => {
                    setData((old) => {
                      return {
                        ...old,
                        outward_type: e.target.value,
                      };
                    });
                  }}
                  name=""
                  id="tax_type"
                  className="p-1 rounded  focus:outline-none w-full border"
                >
                  <option value="" hidden>
                    Select
                  </option>

                  {Object.values(OutwardType).map((c, i) => (
                    <option value={c} key={i}>
                      {c}
                    </option>
                  ))}
                </select>
              </div>
              <div className="my-2">
                <label htmlFor="" className="text-sm font-semibold">
                  Outward Date *
                </label>{" "}
                <input
                  type="date"
                  className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
                  value={
                    data.outward_date
                      ? moment(data.outward_date).format("YYYY-MM-DD")
                      : ""
                  }
                  //   min={moment().format("YYYY-MM-DD")}
                  onChange={(e) => {
                    const date = UTCToLocalDate(e.target.value)!;
                    setData((o) => ({
                      ...o,
                      outward_date: date,
                    }));
                  }}
                />
              </div>
              {data.source === OutwardSource["In-transit"] && (
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="line1">
                    Stock Transfer ID *
                  </label>
                  <input
                    placeholder="stock_transfer_id"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="stock_transfer_id"
                    name="trstock_transfer_idack_id"
                    value={data.stock_transfer_id}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        stock_transfer_id: e.target.value,
                      }))
                    }
                  />
                </div>
              )}
              <div className="my-2">
                <div className="font-semibold">Responsible Person</div>
                <EmployeeSearchWidget
                  withBtn={false}
                  onSelect={(em) => {
                    setData((c) => ({
                      ...c,
                      uid: em.uid!,
                      responsible_person: em.first_name + " " + em.last_name,
                    }));
                  }}
                  emp={
                    data.responsible_person
                      ? {
                          uid: data.uid,
                          name: data.responsible_person!,
                        }
                      : undefined
                  }
                />
                {/* <input
                  value={`${data.responsible_person?.first_name || ""} ${
                    data.responsible_person?.last_name || ""
                  }`}
                  placeholder="responsible_person"
                  type="text"
                  disabled={editable}
                  className="text-left p-1 border rounded focus:outline-none w-full"
                  onChange={(e) => {
                    setData((prevData) => ({
                      ...prevData,
                      responsible_person: {
                        ...prevData.responsible_person!,
                        first_name: e.target.value,
                        last_name: e.target.value,
                      },
                    }));
                  }}
                /> */}
              </div>
              <div>
                <div className="font-semibold">Vendor</div>
                <PartySearchField
                  // show_add={true}
                  value={data.vendor_name}
                  onSelect={(d) => {
                    setData((o) => ({
                      ...o,
                      vendor_name: d,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="">
              <div className="mr-0">
                <div className="font-semibold">From Warehouse *</div>
                <WarehouseSearchField
                  type={""}
                  disabled={editable}
                  value={data.warehouse}
                  placeholder=""
                  onSelect={(d) => {
                    setData((old) => {
                      return {
                        ...old,
                        warehouse: d,
                      };
                    });
                  }}
                />
              </div>
              <div className="flex flex-col my-2 gap-1">
                <label className="text-md font-semibold"> Source *</label>
                <select
                  value={data.source}
                  onChange={(e) => {
                    setData((old) => {
                      return {
                        ...old,
                        source: e.target.value,
                      };
                    });
                  }}
                  name=""
                  id="tax_type"
                  className="p-1 rounded  focus:outline-none w-full border"
                >
                  <option value="" hidden>
                    Select
                  </option>

                  {Object.values(OutwardSource).map((c, i) => (
                    <option value={c} key={i}>
                      {c}
                    </option>
                  ))}
                </select>
              </div>
              {data.source === OutwardSource["In-transit"] && (
                <div className="">
                  <div className="font-semibold">In transit ID *</div>
                  <input
                    value={data.inTransit_id}
                    name="inTransit_id"
                    id="challinTransit_idan"
                    placeholder="inTransit_id"
                    type="text"
                    className="text-left p-1 focus:outline-none border rounded w-full"
                    onChange={(e) =>
                      setData((d) => ({
                        ...d,
                        inTransit_id: e.target.value,
                      }))
                    }
                  />
                </div>
              )}
              <div className="my-2">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="line1">
                    Responsible Person Address
                  </label>
                  <textarea
                    placeholder="address"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="address"
                    name="address"
                    rows={3}
                    value={data.responsible_person_address}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        responsible_person_address: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-2 mt-5 ">
          <div className="flex flex-col gap-5">
            <div className="">
              <h3 className="text-sm font-bold">Particulars</h3>
              <div className="bg-white rounded flex flex-col gap-1 h-auto overflow-auto pb-20">
                <div className="grid grid-cols-8 gap-1 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t sticky top-0">
                  <div className="text-left">Item Code</div>
                  <div className="text-left col-span-2">Item Name</div>
                  <div className="text-left">Unique IDs</div>
                  <div className="text-left">Qty</div>
                  <div className="text-left">Price</div>
                  <div className="text-left">Amount</div>
                  <div className="text-center">Actions</div>
                </div>
                <div>
                  {data.items.map((comp, i) => {
                    return (
                      <ItemRowOutward
                        component={comp}
                        from_warehouse={data.warehouse}
                        in_transit_id={data?.inTransit_id}
                        source={data.source}
                        edit={false}
                        disabled={false}
                        key={comp.id}
                        onDelete={(d: any) => {
                          setData((o) => {
                            const items = [
                              ...(o.items || []).filter((it) => it.id !== d.id),
                            ];

                            return {
                              ...o,
                              items,
                            };
                          });
                        }}
                        onSubmit={(d: any) => {
                          setData((o) => {
                            const items = [
                              ...(o.items || []).map((it) =>
                                it.id === d.id ? d : it
                              ),
                            ];

                            return {
                              ...o,
                              items,
                            };
                          });
                        }}
                        existingItems={existingItems}
                      />
                    );
                  })}

                  {!data.id && (
                    <ItemRowOutward
                      key={addNewId1}
                      from_warehouse={data?.warehouse}
                      in_transit_id={data?.inTransit_id}
                      source={data.source}
                      onSubmit={function (
                        data: StockOutwardItemMovementModel
                      ): void {
                        data.id = (Math.random() * 1000).toString();
                        setData((o) => {
                          const items = [...(o.items || []), data];

                          return {
                            ...o,
                            items,
                          };
                        });
                        setAddNewId1(Math.random() * 1000);
                      }}
                      // index={i}
                      edit={true}
                      disabled={false}
                      existingItems={existingItems}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex sticky bottom-0 justify-end my-5 gap-4 px-5 py-5">
        {!id && (
          <button
            type="button"
            className="px-10 py-1 rounded-md bg-myPrimaryColor text-white text-sm"
            onClick={submit}
            // disabled={isWithdrawn} // Disable submit if withdrawn or no items
          >
            Outward
          </button>
        )}
        {id && (
          <button
            type="button"
            className="px-10 py-1 rounded-md bg-myPrimaryColor text-white text-sm"
            onClick={submit}
            // disabled={isWithdrawn} // Disable submit if withdrawn or no items
          >
            Update
          </button>
        )}
        <button
          type="button"
          className="px-10 py-1 rounded-md bg-myPrimaryColor text-white text-sm"
          onClick={() => navigate.push(urls.stock_outward)}
        >
          Quit
        </button>
      </div>
      {/* )} */}
    </>
  );
};

export default StockOutwardCreate;
