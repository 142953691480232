import {
  mdiRefresh,
  mdiPlus,
  mdiNoteEdit,
  mdiArrowTopRight,
  mdiClose,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import LoadingWidget from "../../../context_providers/modal/loader";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { fetchGet, toQueryString } from "../../../service/api_client";
import { urls } from "../../../utils/urls";
import { WAREHOUSE_TYPE, WarehouseModel } from "../models/warehouse_model";
import Pagination from "../../../ui/new_components/common/pagination";
import { debounce } from "lodash";
import moment from "moment";
import { ACTION, MODULE } from "../../../utils/enums";
import { useAuth } from "../../../context_providers/auth/authProvider";

interface InventoryBasketListScreenProps {}

export interface WarehouseFilterData {
  name?: string;
  id?: string;
  type?: any;
  description?: string;
  city?: string;
  state?: string;
  pincode?: string;
  spoc?: string;
  from?: Date;
  warehouse_id?: string;
  to?: Date;
  page: number;
  count: number;
  search?: string;
  all?: any;
}

const defaultFilter: WarehouseFilterData = {
  page: 1,
  count: 100,
};

const InventoryBasketListScreen: FunctionComponent<
  InventoryBasketListScreenProps
> = () => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/warehouse_type`;
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<WarehouseFilterData>(defaultFilter);
  const [selectedType, setSelectedType] = useState<string>("");
  const [updateDisabled, setUpdateDisabled] = useState(true);
  const [selected, setSelected] = useState<WarehouseModel[]>([]);
  // const [showExport, setShowExport] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<WarehouseModel[]>([]);
  const [search, setSearch] = useState("");
  const { user, isAuthorised } = useAuth();
  useEffect(() => {
    const disable = selected.length === 0;

    setUpdateDisabled(disable);
  }, [selected]);

  const [maker, setMaker] = useState(false);

  useEffect(() => {
    const authorised = isAuthorised({
      action: [ACTION.UPDATE, ACTION.CREATE, ACTION.READ],
      module: MODULE.warehouse,
    });
    setMaker(authorised);
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(maker,": maker")

  const fetchData = async (prop: WarehouseFilterData) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
        console.log("result:", res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  const getWarehouseExport = async () => {
    try {
      const a = document.createElement("a");
      a.target = "_blank";
      console.log("filter:", filter);
      a.href = `${
        process.env.REACT_APP_BACKEND_BASE_URL
      }/api/v3/erp/inventory/warehouse_type/export${toQueryString({
        query: JSON.stringify({
          ...filter,
        }),
      })}`;

      // Delay the trigger to allow UI updates (if necessary)
      setTimeout(() => {
        setLoading(false);
        showToast({ type: ToastType.success, text: "Download started!" });
        a.click(); // Trigger the download
      }, 1000);
    } catch (error) {
      setLoading(false); // Make sure loading is set to false in case of an error
      showToast({ type: ToastType.error, text: "Failed to start download." });
      console.error("Download error:", error);
    }
  };

  const debouncedHandler = useCallback(
    debounce((prop: WarehouseFilterData) => {
      if (prop) fetchData(prop);
    }, 500),
    []
  );

  useEffect(() => {
    filter.type = selectedType;
    debouncedHandler(filter);
  }, [page, filter, selectedType, debouncedHandler]);

  useEffect(() => {
    if (count) fetchData({ page, count, search });
  }, [count]);
  // return <LedgerCreateScreen />;
  const navaigation = useHistory();

  const handleOnEdit = () => {
    if (selected.length > 1) {
      showToast({
        type: ToastType.error,
        text: "Please select one item to edit",
      });
    } else if (selected.length === 0) {
      showToast({
        type: ToastType.error,
        text: "Please select an item to edit",
      });
    } else {
      navaigation.push(
        urls.procurement_masters_warehouse + "/update/" + selected[0]!.id
      );
    }
  };

  return (
    <>
      {/* {showExport && (
        <ModalDialog
          show={showExport}
          title="Export"
          onClose={() => setShowExport(false)}
        >
          <WarehouseExport filter={filter} />
        </ModalDialog>
      )} */}
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section
          id="top_section"
          className="flex justify-between items-end my-1 "
        >
          <h1 className="font-bold text-2xl text-gray-500 border-l-4 border-myPrimaryColor pl-2   flex items-center gap-3">
            Warehouse Master
            <button
              title="Refersh"
              onClick={() => fetchData({ page, count, search })}
            >
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>
          <div className="flex justify-end   text-sm items-center gap-1 ">
            {/* <SearchFieldWidget
              defaulValue={search}
              onClear={() => {
                setSearch("");
                fetchData({ page: 1, count });
              }}
              onSubmit={(val) => {
                fetchData({ page: 1, count, search: val });
                setPage(1);
                setSearch("");

                // setTimeout(() => {
                //   setSearch(val);
                // }, 10);
              }}
            /> */}
            {/* <SearchFieldWidget
            {/* <SearchFieldWidget
              defaulValue={filter.search}
              onClear={() => {
                setFilter((o) => ({ ...o, search: "", page: 1 }));
              }}
              onSubmit={(val) => {
                setFilter((o) => ({
                  ...o,
                  search: val,
                  page: 1,
                }));
              }}
            /> */}
            <div className="flex items-center  gap-1">
              <label htmlFor="fromDate" className="">
                From
              </label>
              <input
                type="date"
                id="fromDate"
                className="p-1 rounded"
                value={
                  filter.from ? moment(filter?.from).format("YYYY-MM-DD") : ""
                }
                onChange={(e) => {
                  const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                  const fromDate = selectedDate
                    ? new Date(new Date(selectedDate).setHours(0, 0, 0, 0)) // Convert to a date object
                    : undefined; // Reset if no date is selected

                  // Update the filter
                  setFilter({
                    ...filter,
                    from: fromDate,
                  });
                }}
              />
            </div>
            <div className="flex items-center  gap-1">
              <label htmlFor="fromDate" className="">
                To
              </label>
              <input
                type="date"
                id="fromDate"
                className="p-1 rounded"
                value={filter.to ? moment(filter?.to).format("YYYY-MM-DD") : ""}
                onChange={(e) => {
                  const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                  const toDate = selectedDate
                    ? new Date(new Date(selectedDate).setHours(0, 0, 0, 0)) // Convert to a date object
                    : undefined; // Reset if no date is selected

                  // Update the filter
                  setFilter({
                    ...filter,
                    to: toDate,
                  });
                }}
              />
            </div>
            {(filter.from || filter.to) && (
              <button
                title="Clear dates"
                onClick={() =>
                  setFilter((o) => ({
                    ...o,
                    from: undefined,
                    to: undefined,
                  }))
                }
                className="flex gap-1 items-center cursor-pointer rounded p-1 border  r hover:scale-105 duration-200     transform"
              >
                <Icon path={mdiClose} size={0.7}></Icon>
              </button>
            )}
            {maker && (
              <div
                onClick={() => {
                  navaigation.push(
                    urls.procurement_masters_warehouse + "/create"
                  );
                }}
                className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
              >
                <Icon path={mdiPlus} className="h-4 w-4"></Icon>Create
              </div>
            )}
            {/* <div
              onClick={() => {
                navaigation.push(
                  urls.procurement_masters_warehouse + "/create"
                );
              }}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>Export
            </div> */}
            <button
              onClick={() => {
                // setShowExport(true);
                getWarehouseExport();
              }}
              className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white flex gap-2 items-center"
            >
              <Icon path={mdiArrowTopRight} className="h-4 w-4"></Icon>
              Export
            </button>
            {maker && (
              <button
                disabled={updateDisabled}
                onClick={() => {
                  handleOnEdit();
                }}
                className={`${
                  updateDisabled
                    ? "cursor-not-allowed  text-gray-300"
                    : "hover:bg-black hover:text-white"
                } flex gap-2 items-center border rounded-md px-3 py-1`}
              >
                <Icon path={mdiNoteEdit} className="h-4 w-4 "></Icon>Update
              </button>
            )}
          </div>
        </section>
        <section className="flex items-start justify-start m-4">
          <div className="grid grid-cols-5 gap-6 w-full">
            <div className="w-full rounded focus:outline-none">
              <label htmlFor="id" className="">
                Warehouse ID
              </label>
              <input
                type="text"
                id="warehouse_id"
                placeholder="warehouse id"
                className="w-full p-1 bg-white rounded focus:outline-none"
                value={filter?.warehouse_id || ""}
                onChange={(e) => {
                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    warehouse_id: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="w-full rounded focus:outline-none">
              <label htmlFor="app_status" className="">
                Warehouse Name
              </label>
              <input
                type="text"
                id="warehouse"
                placeholder="warehouse"
                className="w-full p-1 bg-white rounded focus:outline-none"
                value={filter?.name || ""}
                onChange={(e) => {
                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    name: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="">
              <label htmlFor="item_type" className="">
                Warehouse Type
              </label>
              <select
                className="w-full p-1 bg-white rounded focus:outline-none"
                name="item_type"
                id="item_type"
                value={selectedType ?? ""}
                onChange={(e) => {
                  setFilter((prevData) => ({
                    ...prevData,
                    type: e.target.value, // Directly updating type
                  }));
                  setSelectedType(e.target.value);
                  setSelected([]);
                }}
              >
                <option value="">select</option>
                {Object.values(WAREHOUSE_TYPE).map((f: string) => (
                  <option key={f} value={f}>
                    {f}
                  </option>
                ))}
              </select>
            </div>
            <div className="">
              <label htmlFor="city" className="">
                City
              </label>
              <input
                type="text"
                id="city"
                placeholder="city"
                className="w-full p-1 bg-white rounded focus:outline-none"
                value={filter?.city || ""}
                onChange={(e) => {
                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    city: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="">
              <label htmlFor="state" className="">
                State
              </label>
              <input
                type="text"
                id="state"
                placeholder="state"
                className="w-full p-1 bg-white rounded focus:outline-none"
                value={filter?.state || ""}
                onChange={(e) => {
                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    state: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="">
              <label htmlFor="state" className="">
                Pincode
              </label>
              <input
                type="pincode"
                id="pincode"
                placeholder="pincode"
                className="w-full p-1 bg-white rounded focus:outline-none"
                value={filter?.pincode || ""}
                onChange={(e) => {
                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    pincode: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="">
              <label htmlFor="state" className="">
                SPOC
              </label>
              <input
                type="text"
                id="spoc"
                placeholder="spoc"
                className="w-full p-1 bg-white rounded focus:outline-none"
                value={filter?.spoc || ""}
                onChange={(e) => {
                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    spoc: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
        </section>

        <div className="my-2 border overflow-auto" style={{ height: "80%" }}>
          <table className="w-full text-xs divide-y divide-gray-200 relative">
            <thead className="text-xs py-2 sticky -top-1 bg-white z-10">
              <tr className="border">
                <th className="p-3">
                  <div className="Select p-1">
                    <input
                      type="checkbox"
                      checked={selected.length === data.length}
                      onChange={(e) => {
                        setSelected(e.target.checked ? [...data] : []);
                      }}
                    />
                  </div>
                </th>
                <th className="px-6 py-3 whitespace-nowrap">Warehouse Id</th>
                <th className="px-6 py-3 whitespace-nowrap">Created At</th>
                <th className="px-6 py-3 whitespace-nowrap">Warehouse Name</th>
                <th className="px-6 py-3 whitespace-nowrap">Warehouse Type</th>
                <th className="px-6 py-3 whitespace-nowrap">Address Line1</th>
                <th className="px-6 py-3 whitespace-nowrap">Address Line2</th>
                <th className="px-6 py-3 whitespace-nowrap">Address Line3</th>
                <th className="px-6 py-3 whitespace-nowrap">City</th>
                <th className="px-6 py-3 whitespace-nowrap">State</th>
                <th className="px-6 py-3 whitespace-nowrap">Country</th>
                <th className="px-6 py-3 whitespace-nowrap">Pincode</th>
                <th className="px-6 py-3 whitespace-nowrap">SPOC</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((d, i) => (
                  <tr key={i} className="border text-center">
                    <td className="p-3">
                      <input
                        type="checkbox"
                        checked={selected.some((v) => v.id === d.id)}
                        onChange={(e) => {
                          setSelected(
                            e.target.checked
                              ? [...selected, d]
                              : selected.filter((w) => w.id !== d.id)
                          );
                        }}
                      />
                    </td>
                    <td className="px-3 py-3 text-blue-500 whitespace-nowrap">
                      <a
                        href={`${urls.procurement_masters_warehouse}/view/${d.id}`}
                      >
                        {d.id}
                      </a>
                    </td>
                    <td className="px-3 py-3 whitespace-nowrap">
                      {moment(d?.created_at).format("YYYY-MM-DD")}
                    </td>
                    <td className="px-3 py-3 whitespace-nowrap">
                      {d?.name ?? "--"}
                    </td>
                    <td className="px-3 py-3 whitespace-nowrap">{d.type}</td>
                    <td className="px-3 py-3 ">{d.address?.line1 || "---"}</td>
                    <td className="px-3 py-3 ">{d.address?.line2 || "---"}</td>
                    <td className="px-3 py-3 ">{d.address?.line3 || "---"}</td>
                    <td className="px-3 py-3 whitespace-nowrap">
                      {d.address?.city || "---"}
                    </td>
                    <td className="px-3 py-3 whitespace-nowrap">
                      {d.address?.state || "---"}
                    </td>
                    <td className="px-3 py-3 whitespace-nowrap">
                      {d.address?.country || "---"}
                    </td>
                    <td className="px-3 py-3 whitespace-nowrap">
                      {d.address?.pincode || "---"}
                    </td>
                    <td className="px-3 py-3 whitespace-nowrap">
                      {d.spoc?.contact_name || "---"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={13} className="h-56 text-center p-5 my-20">
                    <h5 className="text-gray-400 text-2xl">No Data Found!</h5>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="mt-3">
          <Pagination
            itemCount={data.length}
            page={filter.page}
            total={total}
            count={filter.count}
            onChange={(page) => setFilter((o) => ({ ...o, page }))}
          />
        </div>
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default InventoryBasketListScreen;
