import { mdiCheck, mdiClose, mdiDelete, mdiPencil, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { OrderItemModelV2 } from "../../../../../service/models/orders/order_item_model";

import {
  ItemModelV2,
  ItemModelV2Default,
} from "../../../../../erp/inventory/models/item_model";
import ItemSearchFieldV2 from "../../../../../erp/inventory/items/item_search_fieldv2";
const defaultItem: OrderItemModelV2 = {
  item_id: "",
  item: ItemModelV2Default,
  price: 0,
  qty: 0,
  delivered_qty: 0,
  installed_qty: 0,
  returned_qty: 0,
};
interface ProductRowWidgetPropsV2 {
  item?: OrderItemModelV2;
  onSubmit: (data: OrderItemModelV2) => void;
  edit: boolean;
  onDelete?: (i: OrderItemModelV2) => void;
  item_search_type?: string;
  item_search_subcategory?: string;
  // form_id?: string;
}

const ProductRowWidgetV2: FunctionComponent<ProductRowWidgetPropsV2> = (
  props
) => {
  const { showToast } = useToast();

  const { edit, onSubmit } = props;
  const [item, setItem] = useState<OrderItemModelV2>({
    ...(props.item ?? defaultItem),
  });
  const [editable, setEditable] = useState(false);

  const [uniqueKey] = useState(Math.random() * 1000);
  useEffect(() => {
    setEditable(edit);
  }, []);
  const onDone = () => {
    if (!item.item_id || item.qty < 1) {
      showToast({
        type: ToastType.warning,
        text: "Please select product and Qty",
      });
      return;
    }
    onSubmit(item);
  };
  return (
    <>
      <form
        key={uniqueKey}
        id={uniqueKey.toString()}
        onSubmit={(e) => {
          e.preventDefault();
          onDone();
        }}
        className="bg-white rounded-md shadow p-5 "
      >
        <div className="grid grid-cols-6 gap-3">
          <input
            value={item?.item?.item_code}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = parseInt(e.target.value);
              }

              setItem((ol) => ({ ...ol, qty: v }));
            }}
            disabled={!editable}
            placeholder=""
            type="text"
            className="text-right  p-1 rounded bg-gray-100 focus:outline-none"
          />
          {/* <select
            disabled={!editable}
            value={JSON.stringify({
              product_id: item?.product_id,
              name: item?.name,
            })}
            onChange={(e) => {
              const p = JSON.parse(e.target.value);

              setItem((ol) => ({ ...ol, ...p }));
            }}
            id="product"
            name="product_id"
            className=" p-1 rounded bg-gray-100 focus:outline-none    col-span-2"
          >
            <option value="" hidden>
              Select...
            </option>
            {products?.map((item: ProductModel, i: number) => (
              <option
                key={i}
                value={JSON.stringify({ product_id: item.id, name: item.name })}
              >
                {item.name}
              </option>
            ))}
          </select> */}
          <ItemSearchFieldV2
            value={props.item?.item}
            type={props.item_search_type}
            subcategory={props.item_search_subcategory}
            onSelect={function (data: ItemModelV2): void {
              setItem((prev) => ({
                ...prev,
                item_id: data.id,
                item: data,
                created_at: Date.now(),
              }));
            }}
          />

          <input
            value={item?.qty}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = parseInt(e.target.value);
              }

              setItem((ol) => ({ ...ol, qty: v }));
            }}
            disabled={!editable}
            placeholder=""
            type="text"
            className="text-right  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <input
            value={item?.price}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = Number(e.target.value);
              }
              setItem((ol) => ({ ...ol, price: v }));
            }}
            disabled={!editable}
            placeholder=""
            type="number"
            className="text-right  p-1 rounded bg-gray-100 focus:outline-none"
          />

          <input
            value={(item?.qty ?? 0) * (item?.price ?? 0)}
            disabled={true}
            placeholder=""
            type="text"
            className="text-right  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <div className="flex gap-2 items-center justify-evenly">
            {props.item ? (
              <>
                <div className="flex justify-center gap-2">
                  {editable ? (
                    <>
                      <div
                        onClick={() => {
                          if (!window.confirm("Are you sure !")) return;
                          onDone();
                          setEditable(false);
                        }}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                      </div>
                      <div
                        onClick={() => {
                          setItem(props.item!);
                          setEditable(false);
                        }}
                        className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiClose} className="h-4 w-4"></Icon>
                      </div>
                    </>
                  ) : (
                    <div
                      onClick={() => setEditable(true)}
                      className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                    >
                      <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                    </div>
                  )}
                </div>
                <div
                  onClick={() => {
                    if (!window.confirm("Are you sure !")) return;
                    if (props.onDelete) props.onDelete(item);
                  }}
                  className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                >
                  <Icon path={mdiDelete} className="h-4 w-4"></Icon>
                </div>
              </>
            ) : (
              <div className="">
                <button
                  // form="item_form"
                  className="flex gap-1 text-white text-sm bg-myPrimaryColor rounded-full px-2 py-1 hover:scale-110 transform duration-300"
                >
                  <Icon path={mdiPlus} className="h-5 w-5 "></Icon> Add
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default ProductRowWidgetV2;
