import { FunctionComponent, useEffect, useState } from "react";
import {
  accountTxnType,
  LedgerEntryModel,
  voucherEntryType,
} from "../../models/common_model";

import ItemSearchField from "../../../inventory/items/item_search_field";
import { UTCToLocalDate } from "../../../../utils/date_util";
import moment from "moment";
import { ItemModel } from "../../../inventory/models/item_model";
import { ItemMovementModel } from "../../../inventory/models/inventory_voucher";
import {
  ModalAlignment,
  ModalType,
  useModal,
} from "../../../../context_providers/modal/modal_context";
import ItemRow from "./components/item_row";
import LedgerSearchField from "../../ledgers/ledger_search_field";
import {
  account_group,
  account_sub_group,
} from "../../models/ledger_group_model";
import Icon from "@mdi/react";
import { mdiAttachment, mdiChevronRight, mdiPlus } from "@mdi/js";
import {
  ledgerDefaultValue,
  LedgerModel,
  LedgerTaxDetail,
  tax_category,
} from "../../models/ledger_model";
import TaxEntryRow, { getItemTaxAmount } from "./components/tax_entry_row";
import GeneralEntryRow from "./components/general_entry_row";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  toQueryString,
} from "../../../../service/api_client";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { numberRegEx } from "../../../../utils/constant";
import { useParams } from "react-router-dom";
import { defaultSalesValue, SalesVoucherModel } from "../../models/sale_model ";
import {
  ledgerEntryDefaultValue,
  PurchaseVoucherItem,
} from "../../models/purchase_model";
import DrawerWidget from "../../../../context_providers/modal/drawer_widget";
import PartySearchField from "../../parties/party_search_field";
import axios from "axios";
import { file_dir } from "../../../../utils/enums";
import {
  AttachmentModel,
  getFileType,
} from "../../../purchase_order/model/purchase_order_model";
import AttachmentSection from "../../../purchase_order/purchase_order_create/components/attachment";
import DoneWidget from "../../../../ui/new_components/common/done";

interface SalesVoucherProps {
  location?: any;
}

const SalesVoucher: FunctionComponent<SalesVoucherProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const [showOrderDetail, setShowOrderDetail] = useState(false);

  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/voucher/sales`;
  const [showSuccess, setShowSucess] = useState(false);

  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const [addNewItemId, setAddNewItemId] = useState(Math.random() * 1000);
  const [addNewTaxId, setAddNewTaxId] = useState(Math.random() * 1000);
  const [voucherData, setVoucherData] =
    useState<SalesVoucherModel>(defaultSalesValue);
  const submit = async () => {
    if (!voucherData.sales_ledger_entry?.ledger_id)
      return showToast({
        type: ToastType.error,
        text: "Purchase voucher required !",
      });
    if (!voucherData.sales_ledger_entry.ledger_id)
      return showToast({
        type: ToastType.error,
        text: "party ledger required !",
      });
    if (voucherData.entry_type === "Invoice") {
      if (!voucherData.items || voucherData.items.length === 0) {
        return showToast({
          type: ToastType.error,
          text: "Items required for Invoice type entry !",
        });
      }
    }

    // voucherData.party_ledger_entry.amount = voucherData.total;
    // voucherData.sales_ledger_entry.amount =
    //   voucherData.total - (voucherData.tax || 0);
    setLoading(true);
    const res = voucherData.id
      ? await fetchPut(url + "/" + voucherData.id, voucherData)
      : await fetchPost(url, voucherData);
    if (res.success) {
      showToast({ type: ToastType.success, text: res.message });
      if (!voucherData.id) {
        // setVoucherData(defaultSalesValue);
        setVoucherData(res.data);
        setShowSucess(true);
      }
      // props.onClose(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };
  useEffect(() => {
    setVoucherData(props.location.state || defaultSalesValue);
    if (id) getVoucher(id);
  }, []);
  const getVoucher = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      setVoucherData(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  const [showAttachment, setShowAttachment] = useState(false);
  const [attachmentKey, setAttachmentKey] = useState(Math.random() * 10000);

  useEffect(() => {
    voucherData.sub_total = 0;
    voucherData?.items?.forEach((item) => {
      voucherData.sub_total += item.bill_unit_no * item.price;
    });

    const taxes = voucherData.taxes.map((t, i) => {
      // if (voucherData.entry_type === voucherEntryType.Invoice)
      const tax = getItemTaxAmount({
        entry_type: "Invoice",
        items: voucherData.items || [],
        ledgerTaxDetail: t.ledger!.tax_detail!,
        extras: voucherData.extras,
      });
      if (typeof tax == "number") {
        t.amount = tax;
      }

      return { ...t };
    });
    let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
    voucherData.tax = tax;
    voucherData.taxes = taxes;
    voucherData.total =
      voucherData.sub_total +
      (tax ?? 0) +
      (voucherData.extra ?? 0) -
      (voucherData.discount ?? 0);

    setVoucherData({ ...voucherData });
  }, [voucherData.items, voucherData.extras]);

  const uploadFile = async ({
    file,
    category,
    removeSelectedFile,
    setShowUpload,
  }: {
    file: File;
    category: string;
    removeSelectedFile: () => void;
    setShowUpload: (v: boolean) => void;
  }) => {
    try {
      if (!file || !category) {
        showToast({
          type: ToastType.warning,
          text: "Please select file and category",
        });
        return;
      }
      setLoading(true);
      const { name, type } = file;
      const create_url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/voucher/credit-note/${voucherData.id}/attachment`;
      const get_signed_urls = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/signed-url`;
      const query = {
        mimetype: type,
        extension: name.split(".").pop(),
        dir: file_dir.pocuremnt_attachments,
      };
      const res = await fetchGet(get_signed_urls + toQueryString(query));
      if (res.success) {
        const { signed_url, url } = res.data;
        const aws_res = await axios.put(signed_url, file, {
          headers: {
            "Content-Type": type,
          },
        });
        if (aws_res.status === 200) {
          const attachment: AttachmentModel = {
            id: "",
            category,
            type: getFileType(type),
            url,
          };
          const create_res = await fetchPost(create_url, attachment);
          console.log({ create_res });
          if (create_res.success) {
            showToast({
              type: ToastType.success,
              text: create_res.message,
            });
            setVoucherData((o) => ({
              ...o,
              attachments: [...(o.attachments || []), create_res.data],
            }));

            removeSelectedFile();
            setShowUpload(false);
            setAttachmentKey(Math.random() * 1000);
          }
        }
      }
      setLoading(false);
    } catch (error: any) {
      showToast({
        type: ToastType.error,
        text: error.message,
      });
      setLoading(false);
    }
  };
  const deleteFile = async (att: AttachmentModel) => {
    try {
      if (!window.confirm("Are your sure to delete ?")) return;
      if (!att) {
        showToast({
          type: ToastType.warning,
          text: "Please select file",
        });
        return;
      }
      setLoading(true);
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/voucher/debit-note/${voucherData.id}/attachment${att.id}`;

      const res = await fetchDelete(url);

      if (res.success) {
        showToast({
          type: ToastType.success,
          text: res.message,
        });
        setVoucherData((o) => ({
          ...o,
          attachments: [
            ...(o.attachments || []).filter((f) => f.id !== att.id),
          ],
        }));
        setAttachmentKey(Math.random() * 1000);
      }
      setLoading(false);
    } catch (error: any) {
      showToast({
        type: ToastType.error,
        text: error.message,
      });
      setLoading(false);
    }
  };

  const onSelectAttchment = (d: AttachmentModel) => {
    window.open(d.url, "_blank");
  };
  if (showSuccess)
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="w-80 h-80 p-0">
          <DoneWidget />
        </div>
        <div className="flex flex-col gap-2 justify-start items-center">
          <h1 className="text-3xl font-semibold text-gray-400 ">
            Created successfully
          </h1>
          <span className="text-black italic font-semibold">
            # {voucherData.id}
          </span>
          <div className="flex gap-5">
            <button
              onClick={() => setShowSucess(false)}
              className="border rounded-3xl px-5 py-2 text-green-800 border-green-400"
            >
              Continue
            </button>
            <button
              onClick={() => {
                setVoucherData(defaultSalesValue);
                setShowSucess(false);
              }}
              className="border rounded-3xl px-4 py-2 bg-green-400 text-white  "
            >
              Create new
            </button>
          </div>
        </div>
      </div>
    );
  return (
    <>
      {showAttachment && voucherData.id && (
        <DrawerWidget
          data={{
            id: 1,
            title: "Attchments",
            type: ModalType.drawer,
            alignment: ModalAlignment.right,
            container: (
              <AttachmentSection
                key={attachmentKey}
                attachments={voucherData.attachments}
                onSubmit={uploadFile}
                onDelete={deleteFile}
                onSelect={onSelectAttchment}
              />
            ),
          }}
          onClose={function (): void {
            setShowAttachment(false);
          }}
        ></DrawerWidget>
      )}
      {id && (
        <div className="fixed bottom-3 right-3">
          <button
            title="Attchments"
            // to={`${window.document.location.pathname}/comment`}
            onClick={() => setShowAttachment(true)}
            className="  rounded-full p-2  hover:scale-105 transform duration-150 bg-green-400 text-white   shadow cursor-pointer flex items-center justify-center"
          >
            <Icon
              path={mdiAttachment}
              size={1}
              className="hover:scale-105 transform -rotate-45"
            ></Icon>
          </button>
        </div>
      )}
      {showOrderDetail && (
        <DrawerWidget
          data={{
            id: 1,
            title: "Order detail",
            type: ModalType.drawer,
            alignment: ModalAlignment.right,
            container: (
              <>
                <div className="p-5 text-xs">
                  <div className="flex flex-col items-start  w-full">
                    <h2 className="text-sm font-semibold">Party</h2>
                    <PartySearchField
                      // show_add={true}
                      value={voucherData.party_detail}
                      onSelect={(d) => {
                        setVoucherData((o) => ({
                          ...o,
                          party_detail: d,
                        }));
                      }}
                    />
                  </div>

                  <div className="grid grid-cols-2  my-2 gap-1">
                    <div className=" font-semibold text-gray-500">Party</div>
                    <div className="">: {voucherData.party_detail?.name}</div>
                    <div className=" font-semibold text-gray-500">Address</div>
                    <div className="">
                      : {voucherData.party_detail?.mail_detail.address.city},
                      {voucherData.party_detail?.mail_detail.address.state},
                      {voucherData.party_detail?.mail_detail.address.country}-
                      {voucherData.party_detail?.mail_detail.address.pincode},
                    </div>
                    <div className=" font-semibold text-gray-500">
                      Contact name
                    </div>
                    <div className="">
                      : {voucherData.party_detail?.mail_detail.contact_name}
                    </div>
                    <div className=" font-semibold text-gray-500">
                      Contact mobile
                    </div>
                    <div className="">
                      : {voucherData.party_detail?.mail_detail.contact_mobile}
                    </div>
                    <div className=" font-semibold text-gray-500">
                      Contact email
                    </div>
                    <div className="">
                      : {voucherData.party_detail?.mail_detail.contact_email}
                    </div>
                  </div>

                  <hr className="my-5" />
                  <h2 className="text-sm font-semibold">Order details</h2>
                  <div className="grid grid-cols-1  my-2 gap-1">
                    <div className="grid grid-cols-4 items-center">
                      <label
                        htmlFor=""
                        className=" font-semibold text-gray-500"
                      >
                        Order ref
                      </label>{" "}
                      <input
                        type="text"
                        className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                        value={voucherData.order_detail?.order_id}
                        onChange={(e) => {
                          setVoucherData((o) => ({
                            ...o,
                            order_detail: {
                              ...o.order_detail!,
                              order_id: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center">
                      <label
                        htmlFor=""
                        className=" font-semibold text-gray-500"
                      >
                        Delivery terms
                      </label>{" "}
                      <textarea
                        className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                        value={voucherData.order_detail?.delivery_terms}
                        onChange={(e) => {
                          setVoucherData((o) => ({
                            ...o,
                            order_detail: {
                              ...o.order_detail!,
                              delivery_terms: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center">
                      <label
                        htmlFor=""
                        className=" font-semibold text-gray-500"
                      >
                        Payment terms
                      </label>{" "}
                      <textarea
                        className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                        value={voucherData.order_detail?.payment_terms}
                        onChange={(e) => {
                          setVoucherData((o) => ({
                            ...o,
                            order_detail: {
                              ...o.order_detail!,
                              payment_terms: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <hr className="my-5" />
                  <h2 className="text-sm font-semibold">Dispatch details</h2>
                  <div className="grid grid-cols-1  my-2 gap-1">
                    <div className="grid grid-cols-4 items-center">
                      <label
                        htmlFor=""
                        className=" font-semibold text-gray-500"
                      >
                        Dispatch through
                      </label>{" "}
                      <input
                        type="text"
                        className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                        value={voucherData.dispatch_detail?.dispatch_through}
                        onChange={(e) => {
                          setVoucherData((o) => ({
                            ...o,
                            dispatch_detail: {
                              ...o.dispatch_detail!,
                              dispatch_through: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center">
                      <label
                        htmlFor=""
                        className=" font-semibold text-gray-500"
                      >
                        Dispatch doc no
                      </label>{" "}
                      <input
                        type="text"
                        className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                        value={voucherData.dispatch_detail?.dispatch_doc_no}
                        onChange={(e) => {
                          setVoucherData((o) => ({
                            ...o,
                            dispatch_detail: {
                              ...o.dispatch_detail!,
                              dispatch_doc_no: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center">
                      <label
                        htmlFor=""
                        className=" font-semibold text-gray-500"
                      >
                        Destination
                      </label>{" "}
                      <input
                        type="text"
                        className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                        value={voucherData.dispatch_detail?.destination}
                        onChange={(e) => {
                          setVoucherData((o) => ({
                            ...o,
                            dispatch_detail: {
                              ...o.dispatch_detail!,
                              destination: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center">
                      <label
                        htmlFor=""
                        className=" font-semibold text-gray-500"
                      >
                        Vehicle no
                      </label>{" "}
                      <input
                        type="text"
                        className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                        value={voucherData.dispatch_detail?.vehicle_no}
                        onChange={(e) => {
                          setVoucherData((o) => ({
                            ...o,
                            dispatch_detail: {
                              ...o.dispatch_detail!,
                              vehicle_no: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center">
                      <label
                        htmlFor=""
                        className=" font-semibold text-gray-500"
                      >
                        Delivery note
                      </label>{" "}
                      <textarea
                        className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                        value={voucherData.dispatch_detail?.delivery_note}
                        onChange={(e) => {
                          setVoucherData((o) => ({
                            ...o,
                            dispatch_detail: {
                              ...o.dispatch_detail!,
                              delivery_note: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            ),
          }}
          onClose={function (): void {
            setShowOrderDetail(false);
          }}
        ></DrawerWidget>
      )}
      <div className=" mt-2 px-6">
        <div className="flex items-center justify-between mb-2">
          <div className="font-bold text-2xl text-gray-500 border-l-4 border-myPrimaryColor pl-2">
            Sale voucher
          </div>
          <div className="flex gap-2 items-center">
            <label htmlFor="" className="text-sm font-semibold text-gray-500">
              Date
            </label>{" "}
            <input
              type="date"
              className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
              value={
                voucherData.date
                  ? moment(voucherData.date).format("YYYY-MM-DD")
                  : ""
              }
              onChange={(e) => {
                const date = UTCToLocalDate(e.target.value)!;
                setVoucherData((o) => ({
                  ...o,
                  date: date,
                }));
              }}
            />
          </div>
        </div>
        <div className="bg-white rounded-lg p-3">
          <div className="grid grid-cols-3 gap-x-12">
            {/* <div className="grid grid-cols-4 items-center">
              <label
                htmlFor=""
                className="text-sm font-semibold text-gray-500 "
              >
                Entry Mode
              </label>
              <select
                disabled={id !== undefined}
                name=""
                id=""
                className="col-span-3 focus:outline-none focus:ring-1 ring-blue-400 border border-gray-300 rounded px-3 py-1 cursor-pointer mt-1 text-sm font-semibold w-full"
                value={voucherData.entry_type}
                onChange={(e) =>
                  setVoucherData((o: any) => ({
                    ...o,
                    entry_type: e.target.value!,
                  }))
                }
              >
                {Object.values(voucherEntryType).map((el, i) => {
                  return <option value={el}>{el}</option>;
                })}
              </select>
            </div> */}
            {voucherData.entry_type === voucherEntryType.Invoice && (
              <div className="grid grid-cols-2 gap-x-12 col-span-2">
                <div className="grid grid-cols-4 items-center">
                  <label
                    htmlFor="invoice_no"
                    className="text-sm font-semibold text-gray-500"
                  >
                    Invoice no.
                  </label>

                  <input
                    type="text"
                    className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                  />
                </div>
                <div className="grid grid-cols-4 items-center">
                  <label
                    htmlFor=""
                    className="text-sm font-semibold text-gray-500"
                  >
                    Invoice date
                  </label>{" "}
                  <input
                    type="date"
                    className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                    value={
                      voucherData.invoice_date
                        ? moment(voucherData.invoice_date).format("YYYY-MM-DD")
                        : ""
                    }
                    onChange={(e) => {
                      const date = UTCToLocalDate(e.target.value)!;
                      setVoucherData((o) => ({
                        ...o,
                        invoice_date: date,
                      }));
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="grid grid-cols-3 gap-x-12 mt-3">
            <div className="grid grid-cols-4 items-center">
              <label htmlFor="" className="text-sm font-semibold text-gray-500">
                Party ledger
              </label>
              <div className="col-span-3">
                <LedgerSearchField
                  key={Math.random() * 10000}
                  value={
                    voucherData.party_ledger_entry
                      ? voucherData.party_ledger_entry.ledger
                      : undefined
                  }
                  onSelect={(d) => {
                    const party =
                      d.group.name ===
                        account_sub_group.sundry_creditors.name ||
                      d.group.name === account_sub_group.sundry_creditors.name
                        ? d.party
                        : undefined;
                    setVoucherData((o) => ({
                      ...o,
                      party_ledger_entry: {
                        ...(o.party_ledger_entry || ledgerEntryDefaultValue),
                        ledger: d,
                        ledger_id: d.id,
                      },
                      party_detail: party,
                      credit_period_day: party ? party.credit_period_day : 0,
                    }));
                  }}
                />
              </div>
            </div>

            {voucherData.entry_type === voucherEntryType.Invoice && (
              <div className="grid grid-cols-4 items-center">
                <label
                  htmlFor=""
                  className="text-sm font-semibold text-gray-500"
                >
                  Sales ledger
                </label>
                <div className="col-span-3">
                  <LedgerSearchField
                    key={Math.random() * 10000}
                    value={
                      voucherData.sales_ledger_entry
                        ? voucherData.sales_ledger_entry.ledger
                        : undefined
                    }
                    onSelect={(d) =>
                      setVoucherData((o) => ({
                        ...o,
                        sales_ledger_entry: {
                          ...(o.sales_ledger_entry || ledgerEntryDefaultValue),
                          ledger: d,
                          ledger_id: d.id,
                        },
                      }))
                    }
                    group={account_group.sales_ac.name}
                  />
                </div>
              </div>
            )}
            {voucherData.party_ledger_entry.ledger &&
              (voucherData.party_ledger_entry.ledger.group.name ===
                account_sub_group.sundry_creditors.name ||
                voucherData.party_ledger_entry.ledger.group.name ===
                  account_sub_group.sundry_creditors.name) && (
                <div className="grid grid-cols-4 items-center">
                  <label
                    htmlFor=""
                    className="text-sm font-semibold text-gray-500"
                  >
                    Credit period
                  </label>
                  <div className="col-span-3">
                    <input
                      value={voucherData.credit_period_day}
                      onChange={(e) => {
                        const val = e.target.value.trim();
                        if (!val || numberRegEx.test(val)) {
                          const value = Number(val || 0);
                          setVoucherData((ol) => ({
                            ...ol,
                            credit_period_day: value,
                          }));
                        }
                      }}
                      placeholder="In days"
                      type="text"
                      className="text-right  p-1 focus:outline-none border rounded  w-full"
                    />
                  </div>
                </div>
              )}
          </div>
          <div className="flex justify-end">
            <div
              onClick={() => {
                setShowOrderDetail(true);
              }}
              className="flex gap-5 border rounded items-center px-2 py-1  text-sm cursor-pointer"
            >
              <p>Order details</p>
              <Icon path={mdiChevronRight} className="w-4 h-4" />
            </div>
          </div>
        </div>

        {voucherData.entry_type === voucherEntryType.Invoice ? (
          <div className="relative">
            <div className="mt-3 mb-3 border-b border-gray-300 text-sm font-bold">
              Item details
            </div>

            {/* Table grid */}
            <div className="flex flex-col gap-1 bg-white divide-y overflow-auto h-72 rounded">
              <div className="grid grid-cols-9 bg-myPrimaryColor text-white font-semibold text-sm text-center rounded-t">
                <div className="py-1 px-2 col-span-4">Item</div>
                <div className="py-1 px-2 col-span-1">Bill Qty</div>
                <div className="py-1 px-2 col-span-1">Qty</div>
                <div className="py-1 px-2 col-span-1 text-right">Price</div>
                <div className="py-1 px-2 col-span-1 text-right">Amount</div>
                <div className="py-1 px-2 col-span-1">Actions</div>
              </div>

              {voucherData.items?.map((item, i) => (
                <ItemRow
                  key={item.id}
                  onDelete={(d) => {
                    setVoucherData((o) => {
                      const items: PurchaseVoucherItem[] = [
                        ...(o.items || []),
                      ].filter((i) => i.id !== d.id);
                      let sub_total = items.reduce(
                        (pv, val) => pv + val.price * val.bill_unit_no,
                        0
                      );
                      const taxes = o.taxes!.map((t, i) => {
                        t.amount = getItemTaxAmount({
                          entry_type: voucherData.entry_type!,

                          items,
                          ledgerTaxDetail: t.ledger!.tax_detail!,
                        });

                        return t;
                      });
                      let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
                      const total = sub_total + (tax ?? 0) - (o.discount ?? 0);
                      return { ...o, items, taxes, tax, sub_total, total };
                    });
                  }}
                  onSubmit={function (data: PurchaseVoucherItem): void {
                    setVoucherData((o) => {
                      const items: PurchaseVoucherItem[] = [
                        ...(o.items || []).map((it) => {
                          if (it.id === data.id) return data;
                          return it;
                        }),
                      ];

                      let sub_total = items.reduce(
                        (pv, val) => pv + val.price * val.bill_unit_no,
                        0
                      );
                      const taxes = o.taxes!.map((t, i) => {
                        t.amount = getItemTaxAmount({
                          entry_type: voucherData.entry_type!,

                          items,
                          ledgerTaxDetail: t.ledger!.tax_detail!,
                        });

                        return t;
                      });
                      let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
                      const total = sub_total + (tax ?? 0) - (o.discount ?? 0);
                      return { ...o, items, taxes, tax, sub_total, total };
                    });
                  }}
                  item={item}
                  edit={false}
                  disabled={false}
                />
              ))}
              <ItemRow
                key={addNewItemId}
                onSubmit={function (data: PurchaseVoucherItem): void {
                  data.id = (Math.random() * 1000).toString();

                  setVoucherData((o) => {
                    const items = [...(o.items || []), data];
                    let sub_total = items.reduce(
                      (pv, val) => pv + val.price * val.bill_unit_no,
                      0
                    );
                    const taxes = o.taxes.map((t, i) => {
                      t.amount = getItemTaxAmount({
                        entry_type: voucherData.entry_type!,

                        items,
                        ledgerTaxDetail: t.ledger!.tax_detail!,
                      });

                      return t;
                    });
                    let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
                    const total = sub_total + (tax ?? 0) - (o.discount ?? 0);
                    return {
                      ...o,
                      items,
                      taxes,
                      sub_total,
                      tax,
                      total,
                    };
                  });
                  setAddNewItemId(Math.random() * 1000);
                }}
                edit={true}
                disabled={false}
              />
            </div>
          </div>
        ) : (
          <div className="">
            <h3 className="text-sm font-bold">Purchase</h3>
            <div className="bg-white rounded flex flex-col gap-1">
              <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                <div className="col-span-2">Particular</div>
                <div className="text-center">Amount</div>
              </div>
              <div className="grid grid-cols-3 gap-5 p-1  ">
                <div className="col-span-2">
                  <LedgerSearchField
                    key={Math.random() * 10000}
                    value={
                      voucherData.sales_ledger_entry
                        ? voucherData.sales_ledger_entry.ledger
                        : undefined
                    }
                    onSelect={(d) =>
                      setVoucherData((o) => ({
                        ...o,
                        sales_ledger_entry: {
                          ...(o.sales_ledger_entry || ledgerEntryDefaultValue),
                          ledger: d,
                          ledger_id: d.id,
                        },
                      }))
                    }
                    group={account_group.purchase_ac.name}
                  />
                </div>

                <input
                  disabled={!voucherData.sales_ledger_entry}
                  value={voucherData.sales_ledger_entry?.amount}
                  onChange={(e) => {
                    const val = e.target.value.trim();
                    if (!val || numberRegEx.test(val)) {
                      const value = Number(val || 0);
                      setVoucherData((o) => ({
                        ...o,
                        sales_ledger_entry: {
                          ...(o.sales_ledger_entry || ledgerEntryDefaultValue),
                          amount: value,
                          type: accountTxnType.debitor,
                        },
                        sub_total: value,
                        total: value + (o.tax || 0) - (o.discount || 0),
                      }));
                    }
                  }}
                  placeholder=""
                  type="text"
                  className="text-right  p-1 focus:outline-none border rounded  w-full"
                />
              </div>
            </div>
          </div>
        )}
        <div className="grid grid-cols-2 gap-5 mt-2">
          <div className="">
            <h3 className="text-sm font-bold">Taxes</h3>
            <div className="bg-white rounded flex flex-col gap-1">
              <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                <div className="col-span-2">Particular</div>
                <div className="text-center">Amount</div>
              </div>
              {voucherData.taxes.map((tax, i) => {
                return (
                  <TaxEntryRow
                    key={tax.id}
                    entry={tax}
                    items={[...(voucherData.items || [])]}
                    onDelete={(d) => {
                      setVoucherData((o) => {
                        const taxes = [...(o.taxes || [])].filter(
                          (i) => i.id !== d.id
                        );
                        let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
                        const total =
                          o.sub_total + (tax ?? 0) - (o.discount ?? 0);
                        return {
                          ...o,
                          taxes,
                          tax,
                          total,
                        };
                      });
                    }}
                    onSubmit={function (data: LedgerEntryModel): void {
                      setVoucherData((o) => {
                        const taxes = [
                          ...(o.taxes || [[]]).map((t) => {
                            if (t.id === data.id) {
                              return { ...data };
                            }
                            return { ...t };
                          }),
                        ].map((t, i) => {
                          if (
                            voucherData.entry_type === voucherEntryType.Invoice
                          )
                            t.amount = getItemTaxAmount({
                              entry_type: voucherData.entry_type!,
                              items: voucherData.items || [],
                              ledgerTaxDetail: t.ledger!.tax_detail!,
                            });

                          return { ...t };
                        });
                        let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
                        const total =
                          o.sub_total + (tax ?? 0) - (o.discount ?? 0);
                        return {
                          ...o,
                          taxes,
                          tax,
                          total,
                        };
                      });
                    }}
                    edit={false}
                    disabled={false}
                  />
                );
              })}
              <TaxEntryRow
                key={addNewTaxId}
                items={[...(voucherData.items || [])]}
                onSubmit={function (data: LedgerEntryModel): void {
                  data.id = (Math.random() * 1000).toString();
                  setVoucherData((o) => {
                    const taxes = [...(o.taxes || [[]]), data].map((t, i) => {
                      if (voucherData.entry_type === voucherEntryType.Invoice)
                        t.amount = getItemTaxAmount({
                          entry_type: voucherData.entry_type!,

                          items: voucherData.items || [],
                          ledgerTaxDetail: t.ledger!.tax_detail!,
                        });

                      return { ...t };
                    });
                    let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
                    const total = o.sub_total + (tax ?? 0) - (o.discount ?? 0);
                    return {
                      ...o,
                      taxes,
                      tax,
                      total,
                    };
                  });
                  setAddNewTaxId(Math.random() * 1000);
                }}
                edit={true}
                disabled={false}
              />
            </div>
          </div>
          <div className="">
            <h3 className="text-sm font-bold">Discounts</h3>
            <div className="bg-white rounded flex flex-col gap-1">
              <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                <div className="col-span-2">Particular</div>
                <div className="text-center">Amount</div>
              </div>
              <div className="grid grid-cols-3 gap-5 p-1  ">
                <div className="col-span-2">
                  <LedgerSearchField
                    value={
                      voucherData.discounts[0]
                        ? voucherData.discounts[0].ledger
                        : undefined
                    }
                    onSelect={function (data: LedgerModel): void {
                      setVoucherData((ol) => {
                        return {
                          ...ol,

                          discounts: [
                            {
                              ...(ol.discounts[0] || ledgerEntryDefaultValue),
                              ledger: data,
                              ledger_id: data.id,
                              type: accountTxnType.creditor,
                            },
                          ],
                        };
                      });
                    }}
                  />
                </div>
                <div className="text-center">
                  <input
                    disabled={!voucherData.discounts[0]}
                    value={voucherData.discounts[0]?.amount}
                    onChange={(e) => {
                      const val = e.target.value.trim();
                      if (!val || numberRegEx.test(val)) {
                        const value = Number(val || 0);
                        setVoucherData((ol) => ({
                          ...ol,
                          discounts: [
                            {
                              ...(ol.discounts[0] || ledgerEntryDefaultValue),
                              amount: value,
                              type: accountTxnType.creditor,
                            },
                          ],
                          discount: value,
                          total: ol.sub_total + (ol.tax || 0) - value,
                        }));
                      }
                    }}
                    placeholder=""
                    type="text"
                    className="text-right  p-1 focus:outline-none border rounded  w-full"
                  />
                </div>
              </div>
            </div>
            {/* <div className="text-sm flex justify-end">
              <div className="flex flex-col gap-2">
                <div className=" flex gap-5 justify-between">
                  <div className="">Sub total:</div>
                  <div className="">{voucherData.sub_total || 0}</div>
                </div>
                <div className=" flex gap-5 justify-between">
                  <div className="">Tax:</div>
                  <div className="">{voucherData.tax || 0}</div>
                </div>
                <div className=" flex gap-5 justify-between">
                  <div className="">Discount:</div>
                  <div className="">{voucherData.discount || 0}</div>
                </div>
                <div className=" flex gap-5 justify-between font-bold">
                  <div className="">Total:</div>
                  <div className="">{voucherData.total || 0}</div>
                </div>
              </div>
            </div> */}
          </div>{" "}
          <div className="">
            <h3 className="text-sm font-bold">Extra charges</h3>
            <div className="bg-white rounded flex flex-col gap-1">
              <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                <div className="col-span-2">Particular</div>
                <div className="text-center">Amount</div>
              </div>
              <div className="grid grid-cols-3 gap-5 p-1  ">
                <div className="col-span-2">
                  <LedgerSearchField
                    value={
                      voucherData.extras[0]
                        ? voucherData.extras[0].ledger
                        : undefined
                    }
                    onSelect={function (data: LedgerModel): void {
                      setVoucherData((ol) => {
                        return {
                          ...ol,

                          extras: [
                            {
                              ...(ol.extras[0] || ledgerEntryDefaultValue),
                              ledger: data,
                              ledger_id: data.id,
                              type: accountTxnType.debitor,
                            },
                          ],
                        };
                      });
                    }}
                  />
                </div>
                <div className="text-center">
                  <input
                    disabled={!voucherData.extras[0]}
                    value={voucherData.extras[0]?.amount}
                    onChange={(e) => {
                      const val = e.target.value.trim();
                      if (!val || numberRegEx.test(val)) {
                        const value = Number(val || 0);
                        setVoucherData((ol) => ({
                          ...ol,
                          extras: [
                            {
                              ...(ol.extras[0] || ledgerEntryDefaultValue),
                              amount: value,
                              type: accountTxnType.debitor,
                            },
                          ],
                          extra: value,
                          total:
                            ol.sub_total +
                            (ol.tax || 0) +
                            value -
                            (ol.discount || 0),
                        }));
                      }
                    }}
                    placeholder=""
                    type="text"
                    className="text-right  p-1 focus:outline-none border rounded  w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-sm flex justify-end">
          <div className="flex flex-col gap-2">
            <div className=" flex gap-5 justify-between">
              <div className="">Sub total:</div>
              <div className="">{voucherData.sub_total || 0}</div>
            </div>
            <div className=" flex gap-5 justify-between">
              <div className="">Tax:</div>
              <div className="">{voucherData.tax || 0}</div>
            </div>
            <div className=" flex gap-5 justify-between">
              <div className="">Discount:</div>
              <div className="">{voucherData.discount || 0}</div>
            </div>
            <div className=" flex gap-5 justify-between">
              <div className="">Extra:</div>
              <div className="">{voucherData.extra || 0}</div>
            </div>
            <div className=" flex gap-5 justify-between font-bold">
              <div className="">Total:</div>
              <div className="">{voucherData.total || 0}</div>
            </div>
          </div>
        </div>
        {/* {voucherData.entry_type === voucherEntryType.General && (
          <div className=" bg-white rounded-lg mt-2">
            <div className="grid grid-cols-10  bg-myPrimaryColor text-white  text-sm rounded-t-md p-1">
              <div className="">Type</div>
              <div className="col-span-6">Particulars</div>
              <div className="">Debit</div>
              <div className="">Credit</div>
            </div>
            <div
              className="flex flex-col gap-2 p-1 overflow-auto"
              style={{ height: "64vh" }}
            >
              {(voucherData.txns || []).map((txn) => (
                <GeneralEntryRow
                  key={txn.id}
                  value={txn}
                  onSubmit={function (data: LedgerEntryModel): void {
                    setVoucherData((o) => ({
                      ...o,
                      txns: [...(o.txns || [])].map((tx) => {
                        if (tx.id === data.id) return data;
                        return tx;
                      }),
                    }));
                  }}
                  edit={false}
                  disabled={false}
                />
              ))}
              <GeneralEntryRow
                key={(Math.random() * 1000).toString()}
                onSubmit={function (data: LedgerEntryModel): void {
                  data.id = (Math.random() * 1000).toString();
                  setVoucherData((o) => ({
                    ...o,
                    txns: [...(o.txns || []), data],
                  }));
                }}
                edit={true}
                disabled={false}
              />
            </div>
          </div>
        )} */}
      </div>
      <div className=" flex justify-end px-6 py-2">
        <button
          type="button"
          onClick={submit}
          className="px-10 py-1 rounded-md bg-myPrimaryColor text-white text-sm "
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default SalesVoucher;

// onChange={(e) =>
//   setVoucherData((o) => ({
//     ...o,
//     last_name: e.target.value,
//   }))
// }

// const handleChange = (e: any) => {
//   const { value, name } = e.target;
//   if (name.includes(".")) {
//     const fields = name.split(".");

//     if (fields.length === 3) {
//       setVoucherData((prev: any) => {
//         const tempFormData = prev;
//         tempFormData[fields[0]][fields[1]][fields[2]] = value;
//         return { ...tempFormData };
//       });
//     } else if (fields.length === 2) {
//       setVoucherData((prev: any) => {
//         const tempFormData = prev;
//         tempFormData[fields[0]][fields[1]] = value;
//         return { ...tempFormData };
//       });
//     }
//   } else setVoucherData((prev) => ({ ...prev, [name]: value }));
// };
