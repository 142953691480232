import { FunctionComponent, useState } from "react";
import { useOrderCreateCtx } from "../../../../../context_providers/order_create_context";
import OrderItemModel, {
  OrderItemModelV2,
} from "../../../../../service/models/orders/order_item_model";
import { GetSubTotalAmt } from "../../../../../utils/order_util";
import ProductRowWidgetV2 from "./product_rowv2";
import { ItemModelV2Default } from "../../../../../erp/inventory/models/item_model";
import RawMaterialRowWidget from "./product_raw_material_widget";

interface ProductSectionProps {}
const defaultItem: OrderItemModelV2 = {
  item_id: "",
  item: ItemModelV2Default,
  price: 0,
  qty: 0,
  delivered_qty: 0,
  installed_qty: 0,
  returned_qty: 0,
};
const ProductSection: FunctionComponent<ProductSectionProps> = () => {
  const { order, setOrder } = useOrderCreateCtx();
  const [formId, setFormId] = useState(0);

  const calculateTotalSubtotal = ({
    products,
    services,
    raw_materials,
  }: {
    products: OrderItemModelV2[];
    services: OrderItemModelV2[];
    raw_materials: OrderItemModelV2[];
  }) => {
    return (
      GetSubTotalAmt(products) +
      GetSubTotalAmt(services) +
      GetSubTotalAmt(raw_materials)
    );
  };

  return (
    <>
      {/* Product Section */}
      <section>
        <h3 className="font-semibold mt-5">Products</h3>
        <div className="bg-white rounded-md shadow p-3 my-2 text-sm">
          <div className="grid grid-cols-5 gap-3 ">
            <h4 className="font-semibold text-center">
              <span className="text-base text-red-500">*</span> Product/Service
            </h4>
            <h4 className="font-semibold text-center">
              <span className="text-base text-red-500">*</span> Quantity
            </h4>
            <h4 className="font-semibold text-center">Price per unit</h4>
            <h4 className="font-semibold text-center">Amount</h4>
            <h4 className="font-semibold text-center">Action</h4>
          </div>
        </div>
        <div className="flex flex-col gap-2 text-sm">
          {order.products.map((item) => (
            <ProductRowWidgetV2
              item_search_type="Product"
              edit={false}
              key={item.item_id}
              item={item}
              onDelete={(it: OrderItemModelV2) => {
                setOrder((old) => {
                  const products = old.products.filter(
                    (v) => v.item_id !== it.item_id
                  );
                  return {
                    ...old,
                    products,
                    subtotal: calculateTotalSubtotal({
                      products,
                      services: order.services,
                      raw_materials: order.raw_materials,
                    }),
                  };
                });
              }}
              onSubmit={(data) => {
                setOrder((old) => {
                  const products = old.products.map((it) =>
                    it.id === item.id ? data : it
                  );
                  return {
                    ...old,
                    products,
                    subtotal: calculateTotalSubtotal({
                      products,
                      services: order.services,
                      raw_materials: order.raw_materials,
                    }),
                  };
                });
              }}
            />
          ))}
          <ProductRowWidgetV2
            key={formId}
            edit={true}
            item_search_type={"Product"}
            onSubmit={(data) => {
              data.id = Date.now();
              setOrder((old) => {
                const products = [...old.products, data];
                return {
                  ...old,
                  products,
                  subtotal: calculateTotalSubtotal({
                    products,
                    services: order.services,
                    raw_materials: order.raw_materials,
                  }),
                };
              });
              setFormId(Math.random());
            }}
          />
        </div>
      </section>
      {/* Service Section */}
      <section>
        <h3 className="font-semibold mt-5">Services</h3>
        <div className="bg-white rounded-md shadow p-3 my-2 text-sm">
          <div className="grid grid-cols-5 gap-3 ">
            <h4 className="font-semibold text-center">
              <span className="text-base text-red-500">*</span> Product/Service
            </h4>
            <h4 className="font-semibold text-center">
              <span className="text-base text-red-500">*</span> Quantity
            </h4>
            <h4 className="font-semibold text-center">Price per unit</h4>
            <h4 className="font-semibold text-center">Amount</h4>
            <h4 className="font-semibold text-center">Action</h4>
          </div>
        </div>
        <div className="flex flex-col gap-2 text-sm">
          {order.services.map((item) => (
            <ProductRowWidgetV2
              item_search_type="Service"
              edit={false}
              key={item.item_id}
              item={item}
              onDelete={(it: OrderItemModelV2) => {
                setOrder((old) => {
                  const services = old.services.filter(
                    (v) => v.item_id !== it.item_id
                  );
                  return {
                    ...old,
                    services,
                    subtotal: calculateTotalSubtotal({
                      products: order.products,
                      services,
                      raw_materials: order.raw_materials,
                    }),
                  };
                });
              }}
              onSubmit={(data) => {
                setOrder((old) => {
                  const services = old.services.map((it) =>
                    it.id === item.id ? data : it
                  );
                  return {
                    ...old,
                    services,
                    subtotal: calculateTotalSubtotal({
                      products: order.products,
                      services,
                      raw_materials: order.raw_materials,
                    }),
                  };
                });
              }}
            />
          ))}
          <ProductRowWidgetV2
            key={formId}
            edit={true}
            item_search_type={"Service"}
            onSubmit={(data) => {
              data.id = Date.now();
              setOrder((old) => {
                const services = [...old.services, data];
                return {
                  ...old,
                  services,
                  subtotal: calculateTotalSubtotal({
                    products: order.products,
                    services,
                    raw_materials: order.raw_materials,
                  }),
                };
              });
              setFormId(Math.random());
            }}
          />
        </div>
      </section>
      {/* Raw Materials */}
      <section>
        <h3 className="font-semibold mt-5">Raw Materials</h3>
        <div className="bg-white rounded-md shadow p-3 my-2 text-sm">
          <div className="grid grid-cols-6 gap-3 ">
            <h4 className="font-semibold text-center">
              <span className="text-base text-red-500">*</span> Product/Service
            </h4>
            <h4 className="font-semibold text-center">
              <span className="text-base text-red-500">*</span> Unit
            </h4>
            <h4 className="font-semibold text-center">
              <span className="text-base text-red-500">*</span> Quantity
            </h4>
            <h4 className="font-semibold text-center">Price per unit</h4>
            <h4 className="font-semibold text-center">Amount</h4>
            <h4 className="font-semibold text-center">Action</h4>
          </div>
        </div>
        <div className="flex flex-col gap-2 text-sm">
          {order.raw_materials.map((item) => (
            <RawMaterialRowWidget
              edit={false}
              key={item.item_id}
              item={item}
              onDelete={(it: OrderItemModelV2) => {
                setOrder((old) => {
                  const raw_materials = old.raw_materials.filter(
                    (v) => v.id !== it.id
                  );
                  return {
                    ...old,
                    raw_materials,
                    subtotal: calculateTotalSubtotal({
                      products: order.products,
                      services: order.services,
                      raw_materials: raw_materials,
                    }),
                  };
                });
              }}
              onSubmit={(data) => {
                setOrder((old) => {
                  const raw_materials = old.raw_materials.map((it) =>
                    it.id === item.id ? data : it
                  );
                  return {
                    ...old,
                    raw_materials,
                    subtotal: calculateTotalSubtotal({
                      products: order.products,
                      services: order.services,
                      raw_materials: raw_materials,
                    }),
                  };
                });
              }}
            />
          ))}
          <RawMaterialRowWidget
            key={formId}
            edit={true}
            onSubmit={(data) => {
              data.id = Date.now();
              setOrder((old) => {
                const raw_materials = [...old.raw_materials, data];
                return {
                  ...old,
                  raw_materials,
                  subtotal: calculateTotalSubtotal({
                    products: order.products,
                    services: order.services,
                    raw_materials: raw_materials,
                  }),
                };
              });
              setFormId(Math.random());
            }}
          />
        </div>
      </section>
    </>
  );
};

export default ProductSection;
