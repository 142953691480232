import {
  createContext,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { DiscomModel } from "../service/models/discom_model";
import { CustomerDetailModel } from "../service/models/orders/customer_detail_model";
import { DiscountDataModel } from "../service/models/orders/order_discount_model";
import { OrderItemModelV2 } from "../service/models/orders/order_item_model";
import {
  defaultOrderVal,
  OrderModel,
} from "../service/models/orders/order_model";
import {
  SubscriptionPlanModel,
  subscriptionPlanDefault,
  SubscriptionBillModel,
} from "../service/models/orders/subscription_plan_model";
import { NewTransactionModel } from "../service/models/orders/txn_model";
import ProductModel from "../service/models/product_model";
import { TransactionDataModel } from "../service/models/trannsaction_model";
import { GetDiscomApi } from "../service/repos/discom";
import {
  CreateOrderDeviceApi,
  CreateOrderDiscountApi,
  CreateOrderExtraApi,
  CreateOrderItemApi,
  CreateOrderPaymentApi,
  CreateOrderSubscriptionApi,
  DeleteOrderDiscountApi,
  DeleteOrderExtraApi,
  DeleteOrderItemApi,
  DeleteOrderPaymentApi,
  GetOrderApi,
  GetOrderDevicesApi,
  GetOrderSubscriptionApi,
  GetOrderSubscriptionBillsApi,
  ReconcileOrderApi,
  UpdateOrderDetailApi,
  UpdateOrderDeviceApi,
  UpdateOrderDeviceReplaceApi,
  UpdateOrderDiscountApi,
  UpdateOrderExtraApi,
  UpdateOrderItemApi,
  UpdateOrderPaymentApi,
  UpdateOrderSubscriptionApi,
} from "../service/repos/order_repo";
import { GetProductsApi } from "../service/repos/products_repo";
import { GetCustomerTxnBalApi } from "../service/repos/transaction_repo";
import LoadingWidget from "../ui/components/loading_spinner";
import { OrderDetailModel } from "../ui/pages/orders/order_detail/order_detail_section";
import {
  OrderDevice,
  OrderDeviceV2,
} from "../ui/pages/orders/order_detail/order_devices/order_device_row";
import { InventoryStatus, PaymentRefType } from "../utils/enums";
import { GetSubTotalAmt, GetTxnAmt } from "../utils/order_util";
import { useModal } from "./modal/modal_context";

import { ToastType, useToast } from "./toast/toast_ctx";
import { ItemMovementModel } from "../erp/inventory/models/inventory_voucher";
import { WarehouseModel } from "../erp/inventory/models/warehouse_model";

interface OrderDetailCtxModel {
  order: OrderModel;
  setOrder: React.Dispatch<React.SetStateAction<OrderModel>>;
  reconcileOrder: () => void;
  getOrder: () => void;
  getDiscoms: () => void;
  getProducts: () => void;
  discoms: DiscomModel[];
  products: ProductModel[];
  onProductAdded: (item: OrderItemModelV2, category: string) => void;
  onProductUpdated: (item: OrderItemModelV2, category: string) => void;
  onProductDeleted: (item: OrderItemModelV2, category: string) => void;

  onExtraAdded: (item: OrderItemModelV2) => void;
  onExtraUpdated: (item: OrderItemModelV2) => void;
  onExtraDeleted: (item: OrderItemModelV2) => void;
  onDiscountAdded: (item: DiscountDataModel) => void;
  onDiscountUpdated: (item: DiscountDataModel) => void;
  onDiscountDeleted: (item: DiscountDataModel) => void;
  onPaymentAdded: ({
    data,
    use_bal,
  }: {
    data: TransactionDataModel;
    use_bal: boolean;
  }) => Promise<void>;
  onPaymentUpdated: (item: TransactionDataModel) => void;
  onPaymentDeleted: (item: TransactionDataModel) => void;
  onOrderDetailUpdated: (data: OrderDetailModel) => void;
  onCustomerSelect: (data: CustomerDetailModel) => void;
  onDeleveryProfileSelect: (data: CustomerDetailModel) => void;
  useBal: boolean;
  setUseBal: React.Dispatch<React.SetStateAction<boolean>>;
  balTxnAmt: number;
  getBalTxn: () => void;
  setSubscriptions: React.Dispatch<SetStateAction<SubscriptionPlanModel[]>>;
  subscriptions: SubscriptionPlanModel[];
  setSubscription: React.Dispatch<
    SetStateAction<SubscriptionPlanModel | undefined>
  >;
  subscription: SubscriptionPlanModel | undefined;
  namespaces: {
    name: string;
    namespace: string;
  }[];
  setNamespaces: React.Dispatch<
    SetStateAction<
      {
        name: string;
        namespace: string;
      }[]
    >
  >;
  getSubscription: () => Promise<void>;
  onSubmitSubscription: (data?: SubscriptionPlanModel) => Promise<void>;
  subBills: SubscriptionBillModel[];
  getDevices: () => Promise<void>;
  onCreateOrderDevices: (data: {
    device_ids: string[];
    order_id: string;
    status: string;
    org_namespace: string;
    org_name: string;
    date: Date;
    item_id: string;
  }) => Promise<boolean>;
  onUpdateOrderDevice: (data: OrderDevice) => Promise<boolean>;
  onUpdateOrderDeviceReplace: (data: {
    device: OrderDeviceV2;
    return_strategy: boolean;
    replacement_strategy: boolean;
    replaceDevice?: OrderDeviceV2;
    warehouse?: WarehouseModel;
  }) => Promise<boolean>;
  devices: OrderDeviceV2[];
}

const defaultVal: OrderDetailCtxModel = {
  order: defaultOrderVal,
  setOrder: () => {},
  getOrder: () => {},
  reconcileOrder: () => {},
  getDiscoms: () => {},
  getProducts: () => {},
  discoms: [],
  products: [],
  onProductAdded: function (item: OrderItemModelV2, category: string): void {},
  onProductUpdated: function (
    item: OrderItemModelV2,
    category: string
  ): void {},
  onProductDeleted: function (
    item: OrderItemModelV2,
    category: string
  ): void {},
  onOrderDetailUpdated: function (data: OrderDetailModel): void {},
  onExtraAdded: function (item: OrderItemModelV2): void {},
  onExtraUpdated: function (item: OrderItemModelV2): void {},
  onExtraDeleted: function (item: OrderItemModelV2): void {},
  onDiscountAdded: function (item: DiscountDataModel): void {},
  onDiscountUpdated: function (item: DiscountDataModel): void {},
  onDiscountDeleted: function (item: DiscountDataModel): void {},
  useBal: false,
  setUseBal: function (value: SetStateAction<boolean>): void {},
  balTxnAmt: 0,
  getBalTxn: () => {},

  onPaymentUpdated: function (item: TransactionDataModel): void {},
  onPaymentDeleted: function (item: TransactionDataModel): void {},
  onCustomerSelect: function (data: CustomerDetailModel): void {},
  onDeleveryProfileSelect: function (data: CustomerDetailModel): void {},
  setSubscriptions: function (
    value: SetStateAction<SubscriptionPlanModel[]>
  ): void {
    throw new Error("Function not implemented.");
  },
  subscriptions: [],
  setSubscription: function (
    value: SetStateAction<SubscriptionPlanModel | undefined>
  ): void {
    throw new Error("Function not implemented.");
  },
  subscription: subscriptionPlanDefault,
  namespaces: [],
  setNamespaces: function (
    value: SetStateAction<{ name: string; namespace: string }[]>
  ): void {
    throw new Error("Function not implemented.");
  },
  getSubscription: async () => {},
  onSubmitSubscription: async () => {},
  subBills: [],
  getDevices: function (): Promise<void> {
    throw new Error("Function not implemented.");
  },
  onCreateOrderDevices: async (data: {
    device_ids: string[];
    order_id: string;
    status: string;
    org_namespace: string;
    org_name: string;
    date: Date;
    item_id: string;
  }): Promise<boolean> => {
    return false;
  },
  onUpdateOrderDevice: function (data: OrderDevice): Promise<boolean> {
    throw new Error("Function not implemented.");
  },

  devices: [],
  onPaymentAdded: function ({
    data,
    use_bal,
  }: {
    data: TransactionDataModel;
    use_bal: boolean;
  }): Promise<void> {
    throw new Error("Function not implemented.");
  },
  onUpdateOrderDeviceReplace: function (data: {
    device: OrderDeviceV2;
    return_strategy: boolean;
    replacement_strategy: boolean;
    replaceDevice?: OrderDeviceV2;
    warehouse?: WarehouseModel;
  }): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
};

const context = createContext<OrderDetailCtxModel>(defaultVal);
export const useOrderDetailCtx = () => useContext(context);

interface OrderDetailContextProps {}

const OrderDetailContext: FunctionComponent<OrderDetailContextProps> = ({
  children,
}) => {
  const { order_id } = useParams<{ order_id: string }>();
  const { showToast } = useToast();
  const { setLoading } = useModal();
  const [order, setOrder] = useState<OrderModel>(defaultOrderVal);
  const [discoms, setDiscoms] = useState<DiscomModel[]>([]);
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [useBal, setUseBal] = useState(false);
  const [balTxnAmt, setBalTxnAmt] = useState(0);
  const [balTxns, setBalTxns] = useState<TransactionDataModel[]>([]);
  const [balFetched, setBalFetched] = useState(false);
  const [subFetched, setSubFetched] = useState(false);
  const [deviceFetched, setDeviceFetched] = useState(false);

  const [subscriptions, setSubscriptions] = useState<SubscriptionPlanModel[]>(
    []
  );
  const [subBills, setSubBills] = useState<SubscriptionBillModel[]>([]);
  const [namespaces, setNamespaces] = useState<
    {
      name: string;
      namespace: string;
    }[]
  >([]);

  const [subscription, setSubscription] = useState<SubscriptionPlanModel>();
  const [devices, setDevices] = useState<OrderDeviceV2[]>([]);

  const onSubmitSubscription = async (subsData?: SubscriptionPlanModel) => {
    if (!subsData) {
      subsData = subscription;
    }
    if (!subsData) return;
    subsData.order_id = order_id;
    setLoading(true);
    const res = subsData.id
      ? await UpdateOrderSubscriptionApi(subsData)
      : await CreateOrderSubscriptionApi(subsData);
    if (res.success) {
      if (Array.isArray(res.data)) {
        setSubscriptions((o) => [
          ...o.map((s) => {
            if (s.id === res.data[0].id) {
              return res.data[0];
            }
            return s;
          }),
          res.data[1],
        ]);

        setSubscription((o) => ({ ...o, ...res.data[1] }));
      } else {
        if (subsData.id) {
          setSubscriptions((o) => [
            ...o.map((s) => {
              if (s.id === res.data.id) {
                return res.data;
              }
              return s;
            }),
          ]);
        } else {
          setSubscriptions((o) => [...o, res.data]);
        }
        console.log({ res });

        setSubscription((o) => ({ ...o, ...res.data }));
      }
    }
    setLoading(false);
  };

  const getSubs = async () => {
    setLoading(true);
    const res = await GetOrderSubscriptionApi(order_id);
    if (res.success) {
      setSubscriptions(res.data);
    }
    setLoading(false);
    setSubFetched(true);
  };
  const getSubsBills = async () => {
    setLoading(true);
    const res = await GetOrderSubscriptionBillsApi(order_id);
    if (res.success) {
      setSubBills(
        res.data.map((d: SubscriptionBillModel) => {
          d.date = new Date(d.date);
          d.from_date = new Date(d.from_date);
          d.to_date = new Date(d.to_date);
          return d;
        })
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (subscriptions.length) {
      const active = subscriptions.find((f) => f.active);
      if (active) setSubscription(active);
    }
  }, [subscriptions]);
  const getSubscription = async () => {
    if (!subFetched) {
      getSubs();
      getSubsBills();
    }
  };
  const getBalTxn = async () => {
    // if (!balFetched) {
    setLoading(true);
    const res = await GetCustomerTxnBalApi(order.user_id);
    if (res.success) {
      setBalTxns(res.data);
      setBalTxnAmt(GetTxnAmt(res.data));
    }
    setLoading(false);
    setBalFetched(true);
    // }
  };
  const onOrderDetailUpdated = async (data: OrderDetailModel) => {
    setLoading(true);
    data.order_id = order?.order_id;

    const res = await UpdateOrderDetailApi(data);
    if (res.success) {
      setOrder((old) => ({
        ...old,
        ...res.data,
      }));
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  const onCustomerSelect = async (data: CustomerDetailModel) => {
    // if (data.id === order.customer.id) {
    setOrder((old) => ({
      ...old,
      customer: data,
      customer_id: data.id!,
    }));
    return;
    // }
    // setLoading(true);
    // const res = await UpdateOrderDetailApi({
    //   customer_id: data.id,
    //   customer: data,
    //   order_id: order?.order_id,
    // });
    // if (res.success) {
    //   setOrder((old) => ({
    //     ...old,
    //     ...res.data,
    //     customer: data,
    //   }));
    //   showToast({ type: ToastType.success, text: res.message });
    // } else {
    //   showToast({ type: ToastType.error, text: res.error });
    // }
    // setLoading(false);
  };
  const onDeleveryProfileSelect = async (data: CustomerDetailModel) => {
    // if (data.id === order.customer.id) {
    setOrder((old) => ({
      ...old,
      delivery_profile: data,
      delivery_profile_id: data.id!,
    }));
    return;
    // }
    // setLoading(true);
    // const res = await UpdateOrderDetailApi({
    //   customer_id: data.id,
    //   customer: data,
    //   order_id: order?.order_id,
    // });
    // if (res.success) {
    //   setOrder((old) => ({
    //     ...old,
    //     ...res.data,
    //     customer: data,
    //   }));
    //   showToast({ type: ToastType.success, text: res.message });
    // } else {
    //   showToast({ type: ToastType.error, text: res.error });
    // }
    // setLoading(false);
  };
  const onProductAdded = async (data: OrderItemModelV2, category: string) => {
    setLoading(true);
    data.order_id = order?.order_id;

    const res = await CreateOrderItemApi(data, category);
    if (res.success) {
      setOrder((old) => ({
        ...old,
        ...res.data,
      }));
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  const onProductUpdated = async (data: OrderItemModelV2, category: string) => {
    setLoading(true);
    data.order_id = order?.order_id;

    const res = await UpdateOrderItemApi(data, category);
    if (res.success) {
      setOrder((old) => ({
        ...old,
        ...res.data,
      }));
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  const onProductDeleted = async (it: OrderItemModelV2, category: string) => {
    setLoading(true);

    const res = await DeleteOrderItemApi({
      order_id: it.order_id!,
      item_id: it.id!,
      category: category,
    });
    if (res.success) {
      setOrder((old) => ({
        ...old,
        ...res.data,
      }));
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };

  const onExtraAdded = async (data: OrderItemModelV2) => {
    setLoading(true);
    data.order_id = order?.order_id;

    const res = await CreateOrderExtraApi(data);
    if (res.success) {
      setOrder((old) => ({
        ...old,
        ...res.data,
      }));
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  const onExtraUpdated = async (data: OrderItemModelV2) => {
    setLoading(true);
    data.order_id = order.order_id!;

    const res = await UpdateOrderExtraApi(data);
    if (res.success) {
      setOrder((old) => ({
        ...old,
        ...res.data,
      }));
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  const onExtraDeleted = async (it: OrderItemModelV2) => {
    setLoading(true);

    const res = await DeleteOrderExtraApi({
      order_id: it.order_id!,
      item_id: it.id!,
    });
    if (res.success) {
      setOrder((old) => ({
        ...old,
        ...res.data,
      }));
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  const onDiscountAdded = async (data: DiscountDataModel) => {
    setLoading(true);
    data.order_id = order?.order_id;

    const res = await CreateOrderDiscountApi(data);
    if (res.success) {
      setOrder((old) => ({
        ...old,
        ...res.data,
      }));
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  const onDiscountUpdated = async (data: DiscountDataModel) => {
    setLoading(true);
    data.order_id = order?.order_id;

    const res = await UpdateOrderDiscountApi(data);
    if (res.success) {
      setOrder((old) => ({
        ...old,
        ...res.data,
      }));
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  const onDiscountDeleted = async (it: DiscountDataModel) => {
    setLoading(true);

    const res = await DeleteOrderDiscountApi({
      order_id: it.order_id!,
      item_id: it.id!,
    });
    if (res.success) {
      setOrder((old) => ({
        ...old,
        ...res.data,
      }));
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };

  const onPaymentAdded = async ({
    data,
    use_bal,
  }: {
    data: TransactionDataModel;
    use_bal: boolean;
  }) => {
    setLoading(true);
    if (!data.ref_id) {
      data.ref_id = order?.order_id;
      data.ref_type = PaymentRefType.order;
    }

    const form = new FormData();

    if (data.files && data.files.length > 0)
      form.append("txn_attachment", data.files[0]);
    delete data.files;
    form.append("data", JSON.stringify(data));
    form.append("use_bal", JSON.stringify(use_bal));

    const res = await CreateOrderPaymentApi({ form, order_id: order.order_id });
    if (res.success) {
      if (res.data.bill) {
        setSubBills((o) =>
          o.map((b, i) => {
            if (b.id === res.data.bill.id) {
              return res.data.bill;
            }
            return b;
          })
        );
      } else
        setOrder((old) => ({
          ...old,
          ...res.data,
        }));
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  const onPaymentUpdated = async (data: TransactionDataModel) => {
    setLoading(true);

    data.ref_id = order?.order_id;
    data.ref_type = PaymentRefType.order;
    const res = await UpdateOrderPaymentApi(data);
    if (res.success) {
      setOrder((old) => ({
        ...old,
        ...res.data,
      }));
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  const onPaymentDeleted = async (it: TransactionDataModel) => {
    setLoading(true);

    const res = await DeleteOrderPaymentApi({
      order_id: it.ref_id!,
      item_id: it.id!,
    });
    if (res.success) {
      setOrder((old) => ({
        ...old,
        ...res.data,
      }));
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };

  const getDiscoms = async () => {
    if (discoms.length === 0) {
      setLoading(true);
      const discomRes = await GetDiscomApi();
      if (discomRes.success) {
        const t = discomRes.data;
        setDiscoms(
          t.sort((a: any, b: any) => {
            var x = a.state.toLowerCase();
            var y = b.state.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          })
        );
      } else showToast({ type: ToastType.error, text: discomRes.error });
      setLoading(false);
    }
  };
  const getProducts = async () => {
    if (products.length === 0) {
      const productRes = await GetProductsApi();
      if (productRes.success) {
        setProducts(productRes.data);
      }
    }
  };

  const getDevices = async () => {
    if (deviceFetched) return;
    setLoading(true);
    const res = await GetOrderDevicesApi(order_id);
    if (res.success) {
      setDevices(res.result);
      setDeviceFetched(true);
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  const onCreateOrderDevices = async (data: {
    device_ids: string[];
    order_id: string;
    status: string;
    org_namespace: string;
    org_name: string;
    date: Date;
    item_id: string;
  }) => {
    if (!data.device_ids || data.device_ids.length === 0) {
      showToast({ type: ToastType.error, text: "Device id required !" });
      return false;
    }
    if (!data.status) {
      showToast({ type: ToastType.error, text: "status required !" });
      return false;
    }
    if (!data.org_namespace) {
      showToast({ type: ToastType.error, text: "Org namespace required !" });
      return false;
    }
    if (!data.date) {
      showToast({ type: ToastType.error, text: "Date required !" });
      return false;
    }
    if (!data.item_id) {
      showToast({
        type: ToastType.error,
        text: "Please select item required !",
      });
      return false;
    }

    const res = await CreateOrderDeviceApi(data);
    if (res.success) {
      setOrder((o) => ({ ...o!, ...res.data.order }));
      setDevices((o) => [...o, ...res.data.order_devices]);

      showToast({ type: ToastType.success, text: res.message });
      return true;
    } else {
      showToast({ type: ToastType.error, text: res.error });
      return false;
    }
  };

  const onUpdateOrderDevice = async (data: OrderDevice) => {
    if (!data.status) {
      showToast({ type: ToastType.error, text: "Status required !" });
      return false;
    }
    if (data.status === InventoryStatus.delivered && !data.delivery_date) {
      showToast({ type: ToastType.error, text: "Delivery date required !" });
      return false;
    }
    if (data.status === InventoryStatus.installed && !data.installation_date) {
      showToast({
        type: ToastType.error,
        text: "Installation date required !",
      });
      return false;
    }
    if (
      data.status === InventoryStatus.returnedNStocked &&
      !data.return_back_date
    ) {
      showToast({
        type: ToastType.error,
        text: "Returned date required !",
      });
      return false;
    }

    const res = await UpdateOrderDeviceApi(data);
    if (res.success) {
      setOrder((o) => ({
        ...o!,
        products: o.products.map((it, i) => {
          if (it.id === res.data.item.id) return res.data.item;
          return it;
        }),
      }));
      setOrder((o) => ({
        ...o!,
        services: o.services.map((it, i) => {
          if (it.id === res.data.item.id) return res.data.item;
          return it;
        }),
      }));
      setOrder((o) => ({
        ...o!,
        raw_materials: o.raw_materials.map((it, i) => {
          if (it.id === res.data.item.id) return res.data.item;
          return it;
        }),
      }));
      setDevices((o) => [
        ...o.map((d) => {
          if (d.id === res.data.order_device.id) return res.data.order_device;
          return d;
        }),
      ]);

      showToast({ type: ToastType.success, text: res.message });
      return true;
    } else {
      showToast({ type: ToastType.error, text: res.error });
      return false;
    }
  };
  const onUpdateOrderDeviceReplace = async (data: {
    device: OrderDeviceV2;
    replaceDevice?: OrderDeviceV2;
  }) => {
    const res = await UpdateOrderDeviceReplaceApi(data);
    if (res.success) {
      // setOrder((o) => ({
      //   ...o!,
      //   items: o.items.map((it, i) => {
      //     if (it.id === res.data.item.id) return res.data.item;
      //     return it;
      //   }),
      // }));
      // setDevices((o) => [
      //   ...o.map((d) => {
      //     if (d.id === res.data.device.id) return res.data.device;
      //     return d;
      //   }),
      //   ...(res.data.replace_device ? [res.data.replace_device] : []),
      // ]);
      setOrder((o) => ({
        ...o!,
        ...res.data.order,
      }));
      setDevices((o) => [...res.data.devices]);

      showToast({ type: ToastType.success, text: res.message });
      return true;
    } else {
      showToast({ type: ToastType.error, text: res.error });
      return false;
    }
  };

  const getOrder = async () => {
    setLoading(true);

    if (order_id) {
      const res = await GetOrderApi(order_id);

      if (res.success) {
        // getDeliveredDevices();
        setOrder({ ...res.data });
      } else showToast({ type: ToastType.error, text: res.error ?? "" });

      if (subFetched) {
        await getSubs();
        await getSubsBills();
      }

      await getDiscoms();
    }

    setLoading(false);
  };
  const reconcileOrder = async () => {
    setLoading(true);

    if (order_id) {
      const res = await ReconcileOrderApi(order_id);
      if (res.success) {
        // getDeliveredDevices();
        setOrder({ ...res.data });
        showToast({ type: ToastType.success, text: res.message ?? "" });
      } else showToast({ type: ToastType.error, text: res.error ?? "" });
    }

    setLoading(false);
  };
  useEffect(() => {
    getOrder();
  }, []);

  const value: OrderDetailCtxModel = {
    order,
    setOrder,
    reconcileOrder,
    getOrder,
    getDiscoms,
    discoms,
    getProducts,
    products,
    onProductAdded,
    onProductUpdated,
    onProductDeleted,
    onOrderDetailUpdated,
    onExtraAdded,
    onExtraDeleted,
    onExtraUpdated,
    onDiscountAdded,
    onDiscountUpdated,
    onDiscountDeleted,
    useBal,
    setUseBal,
    balTxnAmt,
    getBalTxn,
    onPaymentAdded,
    onPaymentDeleted,
    onPaymentUpdated,
    onCustomerSelect,
    onDeleveryProfileSelect,
    setSubscriptions,
    subscriptions,
    namespaces,
    setNamespaces,
    subscription,
    setSubscription,
    onSubmitSubscription,
    getSubscription,
    subBills,
    getDevices,
    onCreateOrderDevices,
    onUpdateOrderDevice,
    onUpdateOrderDeviceReplace,
    devices,
  };
  return <context.Provider value={value}>{children ?? <></>}</context.Provider>;
};

export default OrderDetailContext;
