import { RouteParam } from "../../../routes";
import { MODULE, ACTION } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import PurchaseOrderApprovalListScreen from "../purchase_order_approval/purchase_order_approval_list_screen";
import PurchaseOrderApprovalScreen from "../purchase_order_approval/purchase_order_approval_screen";
import PurchaseOrderCreateScreen from "../purchase_order_create/purchase_order_create_screen";
import {
  POApprovePageWrapper,
  POCreatePageWrapper,
  PODetailPageWrapper,
  POUpdatePageWrapper,
  PurchaseOrderReorderPageWrapper,
} from "../purchase_order_create/purchase_order_wrapper";
import PurchaseOrderListScreen from "../purchase_order_list/purchase_order_list_screen";
import payment_request_routes from "./payment_request_routes";

const purchase_order_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.purchase_order,
    component: PurchaseOrderListScreen,
    module: MODULE.purchase_order,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.purchase_order + "/view-detail/:id",
    component: PODetailPageWrapper,
    module: MODULE.purchase_order,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.purchase_order + "/reorder/:id",
    component: PurchaseOrderReorderPageWrapper,
    module: MODULE.purchase_order,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.purchase_order + "/create/:id",
    component: POCreatePageWrapper,
    module: MODULE.purchase_order,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.purchase_order + "/create",
    component: POCreatePageWrapper,
    module: MODULE.purchase_order,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.purchase_order + "/update/:id",
    component: POUpdatePageWrapper,
    module: MODULE.purchase_order,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.purchase_order + "/approval",
    component: PurchaseOrderApprovalListScreen,
    module: MODULE.purchase_order_approval,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.purchase_order + "/approval/:id",
    component: POApprovePageWrapper,
    module: MODULE.purchase_order_approval,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  ...payment_request_routes
];

export default purchase_order_routes;
