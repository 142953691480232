import { RouteParam } from "../../../routes";
import { ACTION, MODULE } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import CostCenterViewScreen from "../cost_center/components/cost_center_view";
import CostCenterCreateScreen from "../cost_center/cost_center_create";
import CostCenterListScreen from "../cost_center/cost_center_list_screen";
import ProjectCreateUpdateScreen from "../project/project_create_update";
import ProjectListScreen from "../project/project_list";
import ProjectViewScreen from "../project/project_view";

const cost_center_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_cost_center,
    component: CostCenterListScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_cost_center + "/create",
    component: CostCenterCreateScreen,
    module: MODULE.cost_center,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_cost_center + "/update/:id",
    component: CostCenterCreateScreen,
    module: MODULE.cost_center,
    main_module: MODULE.procurement_panel,
    action: [ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_cost_center + "/view-cost-center/:id",
    component: CostCenterViewScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.READ],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_project,
    component: ProjectListScreen,
    module: MODULE.project_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_project + "/create",
    component: ProjectCreateUpdateScreen,
    module: MODULE.project_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_project + "/update/:id",
    component: ProjectCreateUpdateScreen,
    module: MODULE.project_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_project + "/view-project/:id",
    component: ProjectViewScreen,
    module: MODULE.project_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.READ],
    operator: "or",
  },
];

export default cost_center_routes;
