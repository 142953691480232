import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import axios from "axios";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import {
  defaultStockOutward,
  StockOutwardModal,
  StockOutwardStatus,
} from "../models/stock_transfer_model";
import { fetchGet, fetchPut } from "../../../service/api_client";
import { urls } from "../../../utils/urls";
import WarehouseSearchField from "../warehouse/warehouse_search_field";
import ItemRowOutward from "./ItemRowOutward";
import PartySearchField from "../../accounts/parties/party_search_field";
import EmployeeSearchWidget from "../../../ui/new_components/emp_search_widget";
import ModalDialog from "../../../ui/new_components/common/modal";
import { useAuth } from "../../../context_providers/auth/authProvider";
import { ACTION, MODULE } from "../../../utils/enums";

interface StockJournalProps {}

const StockOutwardView: FunctionComponent<StockJournalProps> = () => {
  const { id } = useParams<{ id: string }>();
  const { user, isAuthorised } = useAuth();
  // const location = useLocation<LocationState>();
  // const id1 = location.state?.id; //
  // console.log("Fetched ID from params:", id1); // Log the ID
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/journal/stock_outward`;
  const [, setLoading] = useState(false);
  const [editable] = useState(false);
  const { showToast } = useToast();
  const [approval, setApproval] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [data, setData] = useState<StockOutwardModal>({
    ...defaultStockOutward,
  });
  const existingItems = data?.items || [];
  const navigate = useHistory();
  const locate = useLocation();
  // const isCreatePath = locate.pathname.endsWith("/create");

  useEffect(() => {
    if (id) getVoucher(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const authorised = isAuthorised({
      action: [ACTION.UPDATE, ACTION.CREATE],
      module: MODULE.stock_outward_approval,
    });
    setApproval(authorised);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVoucher = async (id: string) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + "/" + id);
      if (res.success) {
        setData((prevData) => ({
          ...prevData,
          ...res.data,
        }));
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({ type: ToastType.error, text: "Failed to fetch voucher" });
    }
    setLoading(false);
  };

  const submit = async (status: string) => {
    if (status === StockOutwardStatus.rejected && !data?.reject_reason) {
      showToast({
        type: ToastType.error,
        text: "Need Customer Rejected Reason.",
      });
      return;
    }

    setLoading(true);
    const res = await fetchPut(url + "/approve/" + id, data);
    if (res.success) {
      showToast({ type: ToastType.success, text: res.message });
      navigate.push(urls.stock_outward);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  return (
    <>
      {showRejectModal && (
        <ModalDialog
          show={showRejectModal}
          title={"Stock Outward Reject"}
          onClose={() => {
            setShowRejectModal(false);
            setData({
              ...data!,
              status: StockOutwardStatus.pending,
            });
          }}
        >
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <h2 className="text-md font-semibold mb-4">
              Do you want to change Status to Rejected?
            </h2>
            <div className="flex flex-col gap-1">
              <label className="text-sm text-gray-500">Reason *</label>
              <textarea
                value={data?.reject_reason}
                onChange={(e) => {
                  setData({ ...data!, reject_reason: e.target.value });
                }}
                className="w-full px-3 py-2 border rounded-md"
                rows={4}
              ></textarea>
            </div>
            <div className="flex justify-between mt-4">
              <button
                onClick={() => {
                  setShowRejectModal(false);
                  setData({
                    ...data!,
                    status: StockOutwardStatus.pending,
                  });
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700"
              >
                No
              </button>
              <button
                onClick={() => submit(StockOutwardStatus.rejected)}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Yes
              </button>
            </div>
          </div>
        </ModalDialog>
      )}
      {showApprovalModal && (
        <ModalDialog
          show={showApprovalModal}
          title={"Stock Outward Approval"}
          onClose={() => {
            setShowApprovalModal(false);
            setData({
              ...data!,
              status: StockOutwardStatus?.pending!,
            });
          }}
        >
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <h2 className="text-md font-semibold mb-4">
              Do you want to change Status to Approved?
            </h2>
            <div className="flex justify-between mt-4">
              <button
                onClick={() => {
                  setShowApprovalModal(false);
                  setData({
                    ...data!,
                    status: StockOutwardStatus?.pending,
                  });
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700"
              >
                No
              </button>
              <button
                onClick={() => {
                  submit(StockOutwardStatus.approved);
                }}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Yes
              </button>
            </div>
          </div>
        </ModalDialog>
      )}
      <div className="sticky top-0 mt-2 px-6 h-full">
        <div className="flex items-center justify-between mb-2">
          <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
            Stock Outward Entry
          </div>
        </div>
        <div className="bg-white rounded mb-5 flex gap-1 h-auto overflow-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 p-5 text-sm w-3/4 font-semibold rounded-t top-0">
            <div className="">
              <div className="flex flex-col gap-1">
                <label className="text-md pt-1 font-semibold">
                  {" "}
                  Outward Type
                </label>
                <input
                  value={data.outward_type}
                  name=""
                  readOnly
                  id="tax_type"
                  className="p-1 rounded  focus:outline-none w-full border"
                />
              </div>
              <div className="">
                <label htmlFor="" className="text-sm font-bold">
                  Outward Date
                </label>{" "}
                <input
                  type="date"
                  className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
                  value={
                    data.outward_date
                      ? moment(data.outward_date).format("YYYY-MM-DD")
                      : ""
                  }
                  readOnly
                />
              </div>
              <div className="flex flex-col items-start text-sm w-full">
                <label className="font-semibold w-full" htmlFor="line1">
                  Stock Transfer ID
                </label>
                <input
                  placeholder="stock_transfer_id"
                  className="p-1 rounded focus:outline-none w-full border"
                  id="stock_transfer_id"
                  name="trstock_transfer_idack_id"
                  value={data.stock_transfer_id}
                  readOnly
                />
              </div>

              <div className="">
                <div className="font-bold">Responsible Person</div>
                {/* <EmployeeSearchWidget
                  withBtn={false}
                  disabled={true}
                  onSelect={(em) => {
                    setData((c) => ({
                      ...c,
                      uid: em.uid!,
                      responsible_person: em.first_name + " " + em.last_name,
                    }));
                  }}
                  emp={
                    data.responsible_person
                      ? {
                          uid: data.uid,
                          name: data.responsible_person!,
                        }
                      : undefined
                  }
                /> */}
                <input
                  value={`${data.responsible_person || ""}`}
                  placeholder="responsible_person"
                  type="text"
                  disabled={editable}
                  className="text-left p-1 border rounded focus:outline-none w-full"
                  readOnly
                />
              </div>
              <div>
                <div className="font-bold">Vendor</div>
                <PartySearchField
                  // show_add={true}
                  disabled={true}
                  value={data.vendor_name}
                  onSelect={(d) => {
                    setData((o) => ({
                      ...o,
                      vendor_name: d,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="">
              <div className="mr-0">
                <div className="font-bold">From Warehouse</div>
                <WarehouseSearchField
                  type={""}
                  disabled={true}
                  value={data.warehouse}
                  placeholder=""
                  onSelect={(d) => {
                    setData((old) => {
                      return {
                        ...old,
                        from_warehouse: d,
                      };
                    });
                  }}
                />
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-md pt-1 font-semibold"> Source</label>
                <input
                  value={data.source}
                  readOnly
                  name=""
                  id="tax_type"
                  className="p-1 rounded  focus:outline-none w-full border"
                />
              </div>
              <div className="">
                <div className="font-bold">In transit ID</div>
                <input
                  value={data.inTransit_id}
                  name="inTransit_id"
                  id="challinTransit_idan"
                  placeholder="inTransit_id"
                  type="text"
                  className="text-left p-1 focus:outline-none border rounded w-full"
                  readOnly
                />
              </div>

              <div className="mr-0">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="line1">
                    responsible Person Address
                  </label>
                  <textarea
                    placeholder="address"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="address"
                    name="address"
                    rows={3}
                    value={data.responsible_person_address}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-2 mt-5 ">
          <div className="flex flex-col gap-5">
            <div className="">
              <h3 className="text-sm font-bold">Particulars</h3>
              <div className="bg-white rounded flex flex-col gap-1 h-auto overflow-auto pb-20">
                <div className="grid grid-cols-8 gap-1 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t sticky top-0">
                  <div className="text-left">Item Code</div>
                  <div className="text-left col-span-2">Item Name</div>
                  <div className="text-left">Unique IDs</div>
                  <div className="text-left">Qty</div>
                  <div className="text-left">Price</div>
                  <div className="text-left">Amount</div>
                </div>
                <div>
                  {data.items.map((comp, i) => {
                    return (
                      <ItemRowOutward
                        component={comp}
                        from_warehouse={data.warehouse}
                        in_transit_id={data?.inTransit_id}
                        source={data.source}
                        edit={false}
                        disabled={true}
                        key={comp.id}
                        onDelete={(d: any) => {
                          setData((o) => {
                            const items = [
                              ...(o.items || []).filter((it) => it.id !== d.id),
                            ];

                            return {
                              ...o,
                              items,
                            };
                          });
                        }}
                        onSubmit={(d: any) => {
                          setData((o) => {
                            const items = [
                              ...(o.items || []).map((it) =>
                                it.id === d.id ? d : it
                              ),
                            ];

                            return {
                              ...o,
                              items,
                            };
                          });
                        }}
                        existingItems={existingItems}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex sticky bottom-0 justify-end my-5 gap-4 px-5 py-5">
        {approval && (
          <div className="flex justify-end gap-5">
            <button
              onClick={() => {
                setShowRejectModal(true);
                setData({
                  ...data!,
                  status: StockOutwardStatus.rejected,
                });
              }}
              className="cursor-pointer    text-white bg-red-700 px-3 py-1 rounded font-semibold"
              // className="bg-red-500 hover:bg-red-800 font-semibold text-sm text-white rounded-3xl px-3 py-1 hover:scale-110 transform duration-300"
            >
              Reject
            </button>
            <button
              onClick={() => {
                setShowApprovalModal(true);
                setData({
                  ...data!,
                  status: StockOutwardStatus.approved,
                });
              }}
              className="cursor-pointer    text-white bg-myPrimaryColor px-3 py-1 rounded font-semibold"
              // className="bg-myPrimaryColor font-semibold text-sm text-white rounded-3xl px-3 py-1 hover:scale-110 transform duration-300"
            >
              Approve
            </button>
          </div>
        )}
        <button
          type="button"
          className="cursor-pointer    text-white bg-myPrimaryColor px-3 py-1 rounded font-semibold"
          onClick={() => navigate.push(urls.stock_outward)}
        >
          Quit
        </button>
      </div>
      {/* )} */}
    </>
  );
};

export default StockOutwardView;
