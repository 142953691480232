import {
  fetchGet,
  fetchPost,
  fetchPut,
  toQueryString,
} from "../../../../service/api_client";
import { PurchaseOrderModelV2 } from "../../model/purchase_order_model";
import { POListFilter } from "../models/enums";

export const getPurchaseOrderListDataRepo = async (props: POListFilter) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order`;
  const res = await fetchGet(url + toQueryString(props));
  return res;
};

export const createPurchaseOrderRepo = async (data: PurchaseOrderModelV2) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order`;

  const res = await fetchPost(url, data);
  return res;
};

export const updatePurchaseOrderRepo = async (data: PurchaseOrderModelV2) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order`;

  const res = await fetchPut(url + `/${data.id}`, data);
  return res;
};

export const saveDraftPurchaseOrderRepo = async (
  // data: FormData
  data: Partial<PurchaseOrderModelV2>
) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order`;
  const res = await fetchPost(url + "/save-draft", data);
  return res;
};
