import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import { FunctionComponent, useState, useEffect } from "react";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { UTCToLocalDate } from "../../../../utils/date_util";
import LoadingWidget from "../../../../ui/pages/content_panel/ui/new_components/common/loading_spinner";
import StadiumButton from "../../../../ui/new_components/common/stadium_button";
import { getPurchaseOrderListDataRepo } from "../service/po_service";
import { PurchaseOrderModel } from "../../model/purchase_order_model";
import { currencyFormat } from "../../../../utils/orders_utils";
import { urls } from "../../../../utils/urls";

interface ExportPOProps {
  filter: {
    page: number;
    count: number;
    all: boolean | null;
    search?: string;
    fromDate?: Date;
    toDate?: Date;
    party_id?: string;
    party_name?: string;
    approval_status?: string[];
  };
}

const ExportPO: FunctionComponent<ExportPOProps> = (props) => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<PurchaseOrderModel[]>([]);
  const [fromDate, setFromdate] = useState<Date>();
  const [toDate, setTodate] = useState<Date>();

  const getPOListData = async () => {
    if (!fromDate || !toDate) {
      showToast({
        type: ToastType.warning,
        text: "Please select date",
      });

      return;
    } else if (
      moment(fromDate, "YYYY-MM-DD").isAfter(moment(toDate, "YYYY-MM-DD"))
    ) {
      showToast({
        type: ToastType.warning,
        text: "From Date should not be greater than To Date ",
      });
      return;
    }
    setLoading(true);
    try {
      props.filter.fromDate = fromDate;
      props.filter.toDate = toDate;
      const searchProp = { ...props.filter };
      searchProp.all = true;
      const res = await getPurchaseOrderListDataRepo(searchProp);
      if (res.success) {
        console.log(res.data.data);
        if (res.data.data.length === 0) {
          throw new Error("No data found");
        }
        setData(res.data);
        if (res.data) {
          const options = {
            filename: `PO Data ${moment(fromDate, moment.ISO_8601).format(
              "DD-MM-YYYY"
            )} - ${moment(toDate, moment.ISO_8601).format("DD-MM-YYYY")}`,
            fieldSeparator: ",",
            quoteStrings: '"',
            decimalSeparator: ".",
            showLabels: true,
            showTitle: false,
            title: "PO Data",
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
          };

          const csvExporter = new ExportToCsv(options);

          csvExporter.generateCsv(
            res.data.data.map((data: PurchaseOrderModel, i: number) => {
              return {
                "PO Number": data.po_no,
                Project: data.project ?? "--",
                "PO Date": moment(data.date, moment.ISO_8601).format(
                  "DD-MM-YYYY"
                ),
                Item: `=HYPERLINK("https://${window.location.host}${
                  urls.purchase_order + "/update/" + data.id
                }", "${data.items[0].item.name}${
                  data.items.length > 1
                    ? `... and ${data.items.length - 1} more`
                    : ""
                }")`,
                "Party Name": data.party.name,
                "Responsible Person": data.shipping_profile.contact_name,
                Warehouse: data.warehouse.name,
                "Approval Status":
                  data.approval_level == data.current_approval_level &&
                  (data.current_approval_count ?? 0) >=
                    (data.current_approval_required_count ?? 0)
                    ? "Approved"
                    : (data.current_approval_required_count ?? 0) > 0 &&
                      (data.current_reject_count ?? 0) -
                        (data.current_approval_count ?? 0) >=
                        (data.current_approval_required_count ?? 0)
                    ? "Rejected"
                    : "Pending",
                Status: data.status,
                Total: currencyFormat(
                  data.total,
                  data?.vendor_currency?.short_name
                ),
                Attachment: data.attachments.length,
              };
            })
          );
        }
      } else {
        console.log(res.error);
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };
  useEffect(() => {
    const date = new Date();

    if (props.filter?.fromDate && props.filter.toDate) {
      setFromdate(props.filter.fromDate);
      setTodate(props.filter.toDate);
    } else {
      setFromdate(new Date(date.setHours(0, 0, 0, 0)));
      setTodate(new Date(date.setHours(23, 59, 59, 999)));
    }
  }, []);

  return (
    <div className="p-5">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="">
          <label htmlFor="from-date">From</label>
          <input
            id="from-date"
            className="border rounded p-2 w-full"
            type="date"
            name="fromDate"
            placeholder="From"
            required
            value={moment(fromDate).format("YYYY-MM-DD")}
            onChange={(e) => {
              let date = UTCToLocalDate(e.target.value)!;
              date = new Date(date.setHours(0, 0, 0, 0));
              if (toDate && moment(date).isAfter(moment(toDate))) {
                showToast({
                  type: ToastType.warning,
                  text: "From Date should not be greater than To Date ",
                });
                return;
              }
              setFromdate(date);
            }}
          />
        </div>
        <div className="">
          <label htmlFor="to-date">To</label>
          <input
            id="to-date"
            className="border rounded p-2 w-full"
            type="date"
            name="toDate"
            placeholder="To"
            required
            value={moment(toDate).format("YYYY-MM-DD")}
            min={fromDate ? moment(fromDate).format("YYYY-MM-DD") : ""}
            onChange={(e) => {
              let date = UTCToLocalDate(e.target.value)!;
              date = new Date(date.setHours(23, 59, 59, 999));
              if (fromDate && moment(fromDate).isAfter(moment(date))) {
                showToast({
                  type: ToastType.warning,
                  text: "To Date should  be greater than From Date ",
                });
                return;
              }
              setTodate(date);
            }}
          />
        </div>
      </div>
      <div className="flex justify-end my-2">
        <StadiumButton type="submit" onTap={getPOListData}>
          Export
        </StadiumButton>
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default ExportPO;
