import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../../context_providers/auth/authProvider";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { fetchPut, fetchGet } from "../../../service/api_client";
import { UTCToLocalDate } from "../../../utils/date_util";
import { currencyFormat } from "../../../utils/orders_utils";
import {
  currencies,
} from "../../accounts/models/party_model";
import PartySearchField from "../../accounts/parties/party_search_field";
import WarehouseSearchField from "../../inventory/warehouse/warehouse_search_field";
import {
  po_approval_status,
  PurchaseOrderModelV2,
  PoItemV2,
} from "../model/purchase_order_model";
import { mdiHistory, mdiInformationOutline } from "@mdi/js";
import Icon from "@mdi/react";
import RequiredMark from "../../../ui/new_components/common/required_mark";
import {
  payment_request_default,
  PaymentRequestModel,
} from "../model/payment_request_model";

import {
  useModal,
} from "../../../context_providers/modal/modal_context";
import PoItemRowV2 from "../purchase_order_create/components/item_row_v2";
import { usePurchaseOrder } from "../contexts/po-creation-context";
import KazamGSTSearchField from "../purchase_order_create/components/GSTSearchField";
import { getExchangeRate } from "../purchase_order_create/service/purchase_order_repository";
import ApprovalBottomSheetWidgetV2 from "./components/bottom_sheetv2";

interface PurchaseOrderApprovalScreenProps {}

const PurchaseOrderApprovalScreen: FunctionComponent<
  PurchaseOrderApprovalScreenProps
> = (props) => {
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order`;
  const [, setLoading] = useState(false);
  const { showToast } = useToast();
  const navigation = useHistory();
  const [, setOldTotal] = useState(0);
  const [, setShowCreatePaymentRequestModal] = useState(false);
  // const [project, setProject] = useState<ProcurementProjectModel>();
  const [disabled] = useState();
  const { showModal } = useModal();
  // const [data, setData] = useState<PurchaseOrderModelV2>({
  //   ...po_default_value_v2,
  //   shipping_profile: {
  //     ...default_mail_detail,
  //     contact_name: user?.first_name + " " + (user?.last_name || ""),
  //     contact_mobile: user?.mobile || "",
  //     contact_email: user?.email || "",
  //   },
  // });

  const { purchaseOrder, setPurchaseOrder } = usePurchaseOrder();

  const submit = async ({
    approval_status,
    level,
  }: {
    approval_status: string;
    level: string;
  }) => {
    let reason = "";
    if (
      approval_status === po_approval_status.rejected ||
      approval_status === po_approval_status.pending
    ) {
      reason =
        window.prompt(
          approval_status === po_approval_status.rejected
            ? "What's the reason for Rejection ?"
            : "What's the reason for Pending ?"
        ) || "";
      reason = reason.trim();
      if (!reason) return;
    }
    if (
      !window.confirm(
        `Are you sure to ${approval_status} the ${level} approval ?`
      )
    )
      return;

    setLoading(true);
    const res = await fetchPut(url + "/" + purchaseOrder.id + "/approval", {
      approval_status,
      level,
      id: purchaseOrder.id,
      reason,
      purchaseOrder,
    });

    if (res.success) {
      setPurchaseOrder((o) => ({ ...o, ...res.data }));
      showToast({ type: ToastType.success, text: res.message });
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  const getCurrencyExchangeRate = async () => {
    try {
      setLoading(true);
      const formattedDate = new Date().toISOString().split("T")[0]; // "YYYY-MM-DD"

      const exchangeRate = await getExchangeRate({
        from: "USD",
        to: "INR",
        date: formattedDate,
      });

      if (exchangeRate !== null) {
        console.log(`Exchange Rate: 1 INR = ${exchangeRate} USD`);
      } else {
        console.log("Exchange rate could not be retrieved.");
      }

      setPurchaseOrder((o) => ({
        ...o,
        vendor_currency_to_inr: exchangeRate ?? 0,
      }));
    } catch (error) {
      console.error("Error getting exchange rate:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (purchaseOrder.vendor_currency) {
      getCurrencyExchangeRate();
    }
  }, [purchaseOrder.vendor_currency]);

  useEffect(() => {
    if (id) getData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // We switched to item wise project
  // useEffect(() => {
  //   if (data.id && !project && data.project_id) {
  //     getProjectData(data.project_id);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);

  const getData = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      if (!res.data.vendor_currency) {
        res.data.vendor_currency = currencies.rupee;
        res.data.vendor_currency_to_inr = 1;
      }
      setPurchaseOrder(res.data);
      setOldTotal(res.data.total);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };
  const [, setPaymentRequest] = useState<PaymentRequestModel>({
    ...payment_request_default,
  });

  // const getProjectData = async (id: string) => {
  //   setLoading(true);

  //   const res = await fetchGet(
  //     `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/project/${id}`
  //   );
  //   if (res.success) {
  //     setProject({ ...res.data });
  //   } else showToast({ type: ToastType.error, text: res.error });
  //   setLoading(false);
  // };

  // const showLog = () => {
  //   showModal({
  //     title: "Logs",
  //     type: ModalType.drawer,
  //     alignment: ModalAlignment.right,
  //     container: (id: any) => <ActionHistory po_no={data.po_no} data={data} />,
  //   });
  // };

  return (
    <>
      <div className="relative bg-myBgcolor h-screen px-2 md:px-5 ">
        <div className="flex flex-wrap items-center justify-between py-2 sticky top-0 bg-myBgcolor">
          <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
            Purchase Order{" "}
            <span className="text-sm">#{purchaseOrder.po_no}</span>
          </div>
          <div className="flex flex-row">
            <div className="flex gap-2 items-center">
              <label htmlFor="" className="text-sm font-semibold text-gray-500">
                Date
              </label>
              <input
                disabled={true}
                type="date"
                className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
                value={
                  purchaseOrder.date
                    ? moment(purchaseOrder.date).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  const date = UTCToLocalDate(e.target.value)!;
                  setPurchaseOrder((o) => ({
                    ...o,
                    date: date,
                  }));
                }}
              />
            </div>
            {purchaseOrder.id && (
              <button
                title="Logs"
                // to={`${window.document.location.pathname}/log`}
                // onClick={showLog}
                onClick={() => {
                  console.log("Show Logs");
                }}
                className="mx-2 rounded-full p-2 hover:scale-105 transform duration-150    bg-myPrimaryColor text-white   shadow cursor-pointer flex items-center justify-center"
              >
                <Icon path={mdiHistory} className="h-6 w-6  "></Icon>
              </button>
            )}
          </div>
        </div>
        {purchaseOrder.approval_status === po_approval_status.rejected &&
          purchaseOrder.commets.length > 0 && (
            <p>
              <b>Reason of rejection :</b>
              {purchaseOrder.commets[purchaseOrder.commets.length - 1].comment}
            </p>
          )}
        {/* <div className="bg-white rounded-lg px-3 py-5 my-2 grid grid-cols-1 md:grid-cols-4 ">
          <div className="col-span-2 flex flex-col gap-2">
            <div className="">
              <label htmlFor="" className="text-sm font-semibold  ">
                Project <RequiredMark />
              </label>{" "}
              <ProjectSearchField
                disabled={disabled}
                placeholder="Select Project"
                value={project}
                onSelect={(d) => {
                  setProject(d);
                }}
              />
            </div>

            <div className=" text-sm mt-2">
              <div className="grid grid-cols-4">
                <h5>Project name</h5>
                <p className="col-span-3">:{project?.name}</p>
              </div>
              <div className="grid grid-cols-4">
                <h5>Description</h5>
                <p className="col-span-3">:{project?.description}</p>
              </div>
              <hr />
              <div className="grid grid-cols-4">
                <h5>Amount :</h5>
                <p className="col-span-3  text-right">
                  {currencyFormat(project?.amount || 0)}
                </p>
              </div>
              <div className="grid grid-cols-4">
                <h5>Spent :</h5>
                <p className="col-span-3  text-right">
                  {currencyFormat(project?.spent || 0)}
                </p>
              </div>
              <hr />
              <div className="grid grid-cols-4">
                <h5>Balance : </h5>
                <p className="col-span-3 text-right">
                  {currencyFormat(
                    (project?.amount || 0) - (project?.spent || 0)
                  )}
                </p>
              </div>

              <div className="flex gap-1 text-gray-500 text-xs mt-2 items-center">
                <Icon path={mdiInformationOutline} size={0.8}></Icon>Note : this
                balance is real-time ,Eg: if you create a po on x date and
                balance is y then if you open the same po on z date balance may
                be v.
              </div>
            </div>
          </div>
          {console.log(
            purchaseOrder.po_no,
            " ",
            purchaseOrder.approval_level,
            " ",
            purchaseOrder.current_approval_level,
            " ",
            purchaseOrder.current_approval_count,
            " ",
            purchaseOrder.current_approval_required_count
          )}

          {purchaseOrder.po_no &&
            purchaseOrder.approval_level ===
              purchaseOrder.current_approval_level &&
            (purchaseOrder.current_approval_count ?? 0) >=
              (purchaseOrder.current_approval_required_count ?? 0) && (
              <div className="flex items-start col-span-2">
                <button
                  className="ml-auto bg-myPrimaryColor px-3 py-1 text-sm rounded-md text-white"
                  onClick={() => {
                    setPaymentRequest((o) => ({
                      ...o,
                      amount: purchaseOrder.total - (purchaseOrder?.paid || 0),
                    }));
                    setShowCreatePaymentRequestModal(true);
                  }}
                >
                  Create Payment Request
                </button>
              </div>
            )}
        </div> */}
        <div className="bg-white rounded-lg px-3 py-5 ">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 ">
            {/* <div className="">
              <div className=" grid grid-cols-2 text-sm mt-2 gap-2">
                {" "}
                <label htmlFor="" className="text-sm font-semibold  ">
                  Vendor
                </label>{" "}
                <PartySearchField
                  // show_add={true}
                  disabled={true}
                  placeholder="Vendor"
                  value={purchaseOrder.party ? purchaseOrder.party : undefined}
                  onSelect={(d) => {
                    setPurchaseOrder((o) => ({
                      ...o,
                      party: d,
                      party_mail_detail: d.mail_detail,
                    }));
                  }}
                />
               
                <div className="">Currency</div>
                <div className="">
                  <select
                    disabled
                    required
                    className="p-1 focus:outline-none border rounded  w-full"
                    id="currency"
                    name="currency"
                    value={
                      purchaseOrder.vendor_currency &&
                      JSON.stringify(purchaseOrder.vendor_currency)
                    }
                    onChange={(e) => {
                      setPurchaseOrder((o) => ({
                        ...o,
                        vendor_currency: JSON.parse(e.target.value),
                      }));
                    }}
                  >
                    <option value="" hidden>
                      select
                    </option>
                    {Object.values(currencies).map((currency: any) => (
                      <option value={JSON.stringify(currency)}>
                        {currency.formal_name}({currency.symbol})
                      </option>
                    ))}
                  </select>
                </div>
                <div className="">Currency Exchange rate({"\u20b9"})</div>
                <div className="">
                  <input
                    disabled={true}
                    type="number"
                    placeholder="Exchange rate of inr"
                    value={purchaseOrder.vendor_currency_to_inr}
                    onChange={(e) => {
                      setPurchaseOrder((o) => ({
                        ...o,
                        vendor_currency_to_inr: Number(e.target.value),
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded  w-full"
                  />
                  <div className="flex gap-1 text-gray-500 text-xs mt-2 items-center">
                    <Icon path={mdiInformationOutline} size={0.8}></Icon>
                    INR Per {purchaseOrder.vendor_currency.short_name} Eg: INR
                    70 = USD 1 then enter 70
                  </div>{" "}
                </div>
              </div>
            </div>
            <div className="">
              <div className=" grid grid-cols-2 text-sm mt-2 gap-1">
                {" "}
                <label htmlFor="" className="text-sm font-semibold  ">
                  Shipping profile
                </label>
                <WarehouseSearchField
                  // type={WAREHOUSE_TYPE.internal}
                  disabled={true}
                  placeholder="Warehouse"
                  value={
                    purchaseOrder.warehouse.id
                      ? purchaseOrder.warehouse
                      : undefined
                  }
                  onSelect={(d) => {
                    setPurchaseOrder((o) => ({
                      ...o,
                      warehouse: d,
                      warehouse_id: d.id,
                      shipping_profile: {
                        ...o.shipping_profile,
                        address: d.address!,
                      },
                    }));
                  }}
                />
                <div className="">Address</div>
                <div className="">
                  {purchaseOrder.shipping_profile?.address.city},
                  {purchaseOrder.shipping_profile?.address.country}-
                  {purchaseOrder.shipping_profile?.address.pincode},
                </div>
                <div className="">Contact person</div>
                <div className="">
                  {" "}
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Enter name"
                    value={purchaseOrder.shipping_profile?.contact_name}
                    onChange={(e) => {
                      setPurchaseOrder((o) => ({
                        ...o,
                        shipping_profile: {
                          ...o.shipping_profile,
                          contact_name: e.target.name,
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">Contact phone</div>
                <div className="">
                  {" "}
                  <input
                    disabled={true}
                    type="tel"
                    placeholder="Enter contact number"
                    value={purchaseOrder.shipping_profile?.contact_mobile}
                    onChange={(e) => {
                      setPurchaseOrder((o) => ({
                        ...o,
                        shipping_profile: {
                          ...o.shipping_profile,
                          contact_mobile: e.target.name,
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">Contact email</div>
                <div className="">
                  {" "}
                  <input
                    disabled={true}
                    type="email"
                    placeholder="Enter email"
                    value={purchaseOrder.shipping_profile?.contact_email}
                    onChange={(e) => {
                      setPurchaseOrder((o) => ({
                        ...o,
                        shipping_profile: {
                          ...o.shipping_profile,
                          contact_email: e.target.name,
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded  w-full"
                  />
                </div>
              </div>
            </div> */}

            <div className="">
              <div className="">
                <label htmlFor="" className="text-sm font-semibold  ">
                  Vendor <RequiredMark />
                </label>{" "}
                <PartySearchField
                  // show_add={true}
                  disabled={disabled}
                  placeholder="Vendor"
                  value={purchaseOrder.party ? purchaseOrder.party : undefined}
                  onSelect={(d) => {
                    setPurchaseOrder((o: PurchaseOrderModelV2) => {
                      const t = {
                        ...o,
                        party: d,

                        party_mail_detail: d.mail_detail,
                      };

                      if (d.currency) {
                        t.vendor_currency = d.currency;
                      }
                      return t;
                    });
                  }}
                />
                <div className="">
                  <label className="">Vendor Registered Office</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Enter Registerd Address"
                    value={purchaseOrder.party.mail_detail.address.line1}
                    onChange={(e) => {
                      setPurchaseOrder((o) => ({
                        ...o,
                        party: {
                          ...o.party,
                          mail_detail: {
                            ...o.party.mail_detail,
                            address: {
                              ...o.party.mail_detail.address,
                              line1: e.target.value,
                            },
                          },
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">Line 2</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Enter Line 2"
                    value={purchaseOrder.party.mail_detail.address.line2}
                    onChange={(e) => {
                      setPurchaseOrder((o) => ({
                        ...o,
                        party: {
                          ...o.party,
                          mail_detail: {
                            ...o.party.mail_detail,
                            address: {
                              ...o.party.mail_detail.address,
                              line2: e.target.value,
                            },
                          },
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">Country</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Enter Country"
                    value={purchaseOrder.party.mail_detail.address.country}
                    onChange={(e) => {
                      setPurchaseOrder((o) => ({
                        ...o,
                        party: {
                          ...o.party,
                          mail_detail: {
                            ...o.party.mail_detail,
                            address: {
                              ...o.party.mail_detail.address,
                              country: e.target.value,
                            },
                          },
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="">
                <label className="">State</label>
                <input
                  disabled={true}
                  type="text"
                  placeholder="Enter State"
                  value={purchaseOrder.party.mail_detail.address.state}
                  onChange={(e) => {
                    setPurchaseOrder((o) => ({
                      ...o,
                      party: {
                        ...o.party,
                        mail_detail: {
                          ...o.party.mail_detail,
                          address: {
                            ...o.party.mail_detail.address,
                            state: e.target.value,
                          },
                        },
                      },
                    }));
                  }}
                  className="p-1 focus:outline-none border rounded w-full "
                />
              </div>
              <div className="">
                <label className="">City</label>
                <input
                  disabled={true}
                  type="text"
                  placeholder="Enter City"
                  value={purchaseOrder.party.mail_detail.address.city}
                  onChange={(e) => {
                    setPurchaseOrder((o) => ({
                      ...o,
                      party: {
                        ...o.party,
                        mail_detail: {
                          ...o.party.mail_detail,
                          address: {
                            ...o.party.mail_detail.address,
                            city: e.target.value,
                          },
                        },
                      },
                    }));
                  }}
                  className="p-1 focus:outline-none border rounded w-full "
                />
              </div>
              <div className="">
                <label className="">Pincode</label>
                <input
                  disabled={true}
                  type="text"
                  placeholder="Enter Pincode"
                  value={purchaseOrder.party.mail_detail.address.pincode}
                  onChange={(e) => {
                    setPurchaseOrder((o) => ({
                      ...o,
                      party: {
                        ...o.party,
                        mail_detail: {
                          ...o.party.mail_detail,
                          address: {
                            ...o.party.mail_detail.address,
                            pincode: e.target.value,
                          },
                        },
                      },
                    }));
                  }}
                  className="p-1 focus:outline-none border rounded w-full "
                />
              </div>
            </div>
            <div>
              <div className="">
                <label className="">GST Reg</label>
                <input
                  disabled={true}
                  type="text"
                  placeholder="Enter GST Reg"
                  value={purchaseOrder.party.tax_detail.reg_type ?? "--"}
                  onChange={(e) => {
                    setPurchaseOrder((o) => ({
                      ...o,
                      party: {
                        ...o.party,
                        tax_detail: {
                          ...o.party.tax_detail,
                          // address: {
                          //   ...o.party.mail_detail.address,
                          //   pincode: e.target.value,
                          // },
                          // reg_type: e.target.value as Taxt,
                        },
                      },
                    }));
                  }}
                  className="p-1 focus:outline-none border rounded w-full "
                />
              </div>
              <div className="">
                <label className="">GST No</label>
                <input
                  disabled={true}
                  type="text"
                  placeholder="Enter GST Reg"
                  value={purchaseOrder.party.tax_detail.gst_no[0] ?? "--"}
                  onChange={(e) => {}}
                  className="p-1 focus:outline-none border rounded w-full "
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg px-3 py-5 mt-2">
          <h1>Bill To</h1>
          <div className="grid  grid-cols-1 md:grid-cols-2">
            {" "}
            <div className="grid grid-cols-2 text-sm m-2 gap-1">
              <div>
                <div>
                  <label htmlFor="" className="text-sm font-semibold  ">
                    Name
                  </label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Name"
                    value={purchaseOrder.bill_to?.name ?? "--"}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">Address</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Enter Registerd Address"
                    value={purchaseOrder.bill_to?.address.line1 ?? "--"}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">Line 2</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Enter Line 2"
                    value={purchaseOrder.bill_to?.address.line2 ?? "--"}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">Country</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Enter Country"
                    value={purchaseOrder.bill_to?.address.country ?? "--"}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
              </div>
              <div>
                {" "}
                <div className="">
                  <label className="">GST No</label>
                  <KazamGSTSearchField
                    disabled={disabled}
                    placeholder={"GST Number"}
                    value={
                      purchaseOrder.bill_to?.id
                        ? purchaseOrder.bill_to
                        : undefined
                    }
                    onSelect={(d) => {
                      setPurchaseOrder((prev: PurchaseOrderModelV2) => ({
                        ...prev,
                        bill_to: d,
                      }));
                    }}
                  />
                </div>
                <div className="">
                  <label className="">State</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="State"
                    value={purchaseOrder.bill_to?.address.state ?? "--"}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">City</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="City"
                    value={purchaseOrder.bill_to?.address.city ?? "--"}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">Pincode</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Pincode"
                    value={purchaseOrder.bill_to?.address.pincode ?? "--"}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 text-sm m-2 gap-1">
              <div>
                {" "}
                <label htmlFor="" className="text-sm font-semibold  ">
                  Shipping profile
                </label>
                <WarehouseSearchField
                  // type={WAREHOUSE_TYPE.internal}
                  disabled={disabled}
                  placeholder="Warehouse"
                  value={
                    purchaseOrder.warehouse.id
                      ? purchaseOrder.warehouse
                      : undefined
                  }
                  onSelect={(d) => {
                    setPurchaseOrder((prev: PurchaseOrderModelV2) => ({
                      ...prev,
                      warehouse: d,
                      warehouse_id: d.id,
                      shipping_profile: {
                        ...prev.shipping_profile, // Ensure we keep existing data
                        contact_name: d.spoc?.contact_name ?? "--",
                        contact_mobile: d.spoc?.contact_mobile ?? "--",
                        contact_email: d.spoc?.contact_email ?? "--",

                        address: d.address!,
                      },
                    }));
                  }}
                />
                <div className="">
                  <label className="">Address</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Address"
                    value={purchaseOrder.shipping_profile.address.line1}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">Line 2</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Line 2"
                    value={purchaseOrder.shipping_profile.address.line2}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">Country</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Country"
                    value={purchaseOrder.shipping_profile.address.country}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
              </div>
              <div>
                {" "}
                <div className="">
                  <label className="">State</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="State"
                    value={purchaseOrder.shipping_profile.address.state}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">City</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="City"
                    value={purchaseOrder.shipping_profile.address.city}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">
                  <label className="">Pincode</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Pincode"
                    value={purchaseOrder.shipping_profile.address.pincode}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/3">
          <div className="">Currency</div>
          <div className="">
            <select
              disabled={disabled}
              required
              className="p-1 focus:outline-none border rounded  w-full"
              id="currency"
              name="currency"
              value={
                purchaseOrder.vendor_currency &&
                JSON.stringify(purchaseOrder.vendor_currency)
              }
              onChange={(e) => {
                setPurchaseOrder((o: PurchaseOrderModelV2) => {
                  const updatedOrder = {
                    ...o,
                    vendor_currency: JSON.parse(e.target.value),
                  };
                  return updatedOrder;
                });
              }}
            >
              <option value="" hidden>
                select
              </option>
              {Object.values(currencies).map((currency: any) => (
                <option value={JSON.stringify(currency)}>
                  {currency.formal_name}({currency.symbol})
                </option>
              ))}
            </select>
          </div>
          <div className="">Currency Exchange rate({"\u20b9"})</div>
          <div className="">
            <input
              disabled={true}
              type="number"
              placeholder="Exchange rate of inr"
              value={purchaseOrder.vendor_currency_to_inr}
              onChange={(e) => {
                setPurchaseOrder((o: PurchaseOrderModelV2) => ({
                  ...o,
                  vendor_currency_to_inr: Number(e.target.value),
                }));
              }}
              className="p-1 focus:outline-none border rounded  w-full"
            />
            <div className="flex gap-1 text-gray-500 text-xs mt-2 items-center">
              <Icon path={mdiInformationOutline} size={0.8}></Icon>
              INR Per {purchaseOrder.vendor_currency.short_name} Eg: INR 70 =
              USD 1 then enter 70
            </div>{" "}
          </div>
        </div>
        <div className="my-5">
          {/* <h3 className="text-sm font-bold p-1 bg-myPrimaryColor text-white rounded-t">
            Items
          </h3> */}
          {/* Responsiveness Card */}
          {/* <div className="h-auto overflow-auto">
            <div className="max-h-[90%] overflow-y-auto">
              <PoItemCard
                data={{
                  items: data.items,
                }}
              />
            </div>
          </div> */}

          <div className="bg-white rounded flex flex-col gap-1 border  overflow-auto h-80">
            <div className="grid grid-cols-9 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
              <div className="col-span-2">Particular</div>
              <div className="text-center">HSN/SAC</div>
              <div className="text-center">qty</div>
              <div className="text-center">Rate</div>
              <div className="text-right">Amount</div>
              <div className="text-center" title="Received qty">
                Received qty
              </div>
              <div className="text-center" title="Returned qty">
                Returned qty
              </div>
              <div className="text-right"> </div>
            </div>
            <div className="flex flex-col gap-1 px-1 ">
              {purchaseOrder.items.map((item, i) => {
                return (
                  <PoItemRowV2
                    currency={purchaseOrder.vendor_currency}
                    disabled={true}
                    key={item.id}
                    data={item}
                    onSubmit={function (data: PoItemV2): void {
                      setPurchaseOrder((o) => ({
                        ...o,
                        items: [
                          ...(o.items || []).map((it) => {
                            if (it.id === data.id) return data;
                            return it;
                          }),
                        ],
                      }));
                    }}
                    edit={false}
                  />
                );
              })}
              {/* <PoItemRow
                key={(Math.random() * 1000).toString()}
                onSubmit={function (data: PoItem): void {
                  data.id = (Math.random() * 1000).toString();
                  setData((o) => ({
                    ...o,
                    items: [...(o.items || []), data],
                  }));
                }}
                edit={true}
                disabled={false}
              /> */}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 md:divide-x-2  text-sm  bg-white rounded-lg p-3  ">
          <div className=" flex flex-col gap-1 md:pr-5 ">
            <div className="grid grid-cols-1  md:grid-cols-4   dark: items-center">
              <label htmlFor="delivery_date">Delivery Date *</label>
              <input
                // disabled={disabled}
                value={
                  purchaseOrder.delivery_date
                    ? moment(purchaseOrder.delivery_date).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  setPurchaseOrder((o: any) => ({
                    ...o,
                    delivery_date: UTCToLocalDate(e.target.value)!,
                  }));
                }}
                type="date"
                name=""
                id="delivery_date"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              ></input>
            </div>
            <div className="grid grid-cols-1  md:grid-cols-4  items-start">
              <label htmlFor="ship_term">Delivery terms</label>
              <textarea
                disabled={disabled}
                rows={3}
                value={purchaseOrder.ship_term}
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/[\n\r]/g, ""); // Remove newlines
                  setPurchaseOrder((o: any) => ({
                    ...o,
                    ship_term: sanitizedValue,
                  }));
                }}
                name=""
                id="ship_term"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              ></textarea>
            </div>
            <div className="grid grid-cols-1  md:grid-cols-4  items-start">
              <label htmlFor="payment_term">Payment terms</label>
              <textarea
                // disabled={disabled}
                rows={3}
                value={purchaseOrder.payment_term}
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/[\n\r]/g, ""); // Remove newlines
                  setPurchaseOrder((o: any) => ({
                    ...o,
                    payment_term: sanitizedValue,
                  }));
                }}
                name=""
                id="payment_term"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              ></textarea>
            </div>
          </div>

          <div className=" flex flex-col gap-1 md:pl-5 ">
            <div className="flex justify-between">
              <div className="">Subtotal</div>
              <div className="">
                {currencyFormat(
                  purchaseOrder.subtotal,
                  purchaseOrder.vendor_currency.short_name
                )}
              </div>
            </div>{" "}
            <div className="flex justify-between ">
              <div className="flex  gap-2 items-end ">
                <label htmlFor="tax_desc">CGST </label>{" "}
              </div>

              <div className="">
                +{" "}
                {currencyFormat(
                  purchaseOrder.cgst,
                  purchaseOrder.vendor_currency.short_name
                )}
              </div>
            </div>
            <div className="flex justify-between ">
              <div className="flex  gap-2 items-end ">
                <label htmlFor="tax_desc">SGST </label>{" "}
              </div>

              <div className="">
                +{" "}
                {currencyFormat(
                  purchaseOrder.sgst,
                  purchaseOrder.vendor_currency.short_name
                )}
              </div>
            </div>
            <div className="flex justify-between ">
              <div className="flex  gap-2 items-end ">
                <label htmlFor="tax_desc">IGST</label>{" "}
              </div>

              <div className="">
                +{" "}
                {currencyFormat(
                  purchaseOrder.igst,
                  purchaseOrder.vendor_currency.short_name
                )}
              </div>
            </div>
            <hr />
            <div className="flex justify-between font-bold ">
              <p>Total</p>{" "}
              <div className="">
                {currencyFormat(
                  purchaseOrder.subtotal +
                    (purchaseOrder?.cgst +
                      purchaseOrder?.sgst +
                      purchaseOrder?.igst),
                  purchaseOrder.vendor_currency.short_name
                )}
              </div>
            </div>
            <hr />
            {/* <div className="flex justify-between font-bold ">
                      <p>Paid</p>{" "}
                      <div className="">
                        {currencyFormat(
                          purchaseOrder.paid,
                          purchaseOrder.vendor_currency.short_name
                        )}
                      </div>
                    </div> */}
            <hr />
            {/* <div className="flex justify-between font-bold ">
                      <p>Balance</p>{" "}
                      <div className="">
                        {currencyFormat(
                          purchaseOrder.total,
                          purchaseOrder.vendor_currency.short_name
                        )}
                      </div>
                    </div> */}
            {purchaseOrder.vendor_currency.short_name !==
              currencies.rupee.short_name && (
              <div className="flex justify-between font-bold ">
                <p>Total in INR</p>{" "}
                <div className="">
                  {currencyFormat(
                    purchaseOrder.total * purchaseOrder.vendor_currency_to_inr
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <div className="bottom-20 sticky sm:bottom-0 right-0 mt-3 w-full rounded-t-md bg-white shadow-sm py-3 px-5 border">
          {" "}
          <h3 className="text-sm font-semibold">Approval status</h3>
          <ApprovalBottomSheetWidget data={data} onSubmit={submit} />
        </div> */}
        <div className="pb-40 sm:pb-40 lg:pb-0 sm:sticky bottom-20 sm:bottom-20 lg:bottom-0 right-0 mt-3 w-full rounded-t-md bg-white shadow-sm py-3 px-5 border">
          {" "}
          <h3 className="text-sm font-semibold">Approval status</h3>
          <ApprovalBottomSheetWidgetV2 data={purchaseOrder} onSubmit={submit} />
        </div>
      </div>
    </>
  );
};

export default PurchaseOrderApprovalScreen;
