import { Tooltip } from "@material-ui/core";
import { mdiCheck, mdiChevronLeft, mdiClose, mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { WarehouseModel } from "../../../../service/models/inventory/warehouse_model";
import { OrderModel } from "../../../../service/models/orders/order_model";
import {
  CheckInventoryAvailableApi,
  GetInventoryDeviceForOrderApi,
} from "../../../../service/repos/inventory_repo";
import {
  CreateOpsTicket,
  OpsTicketData,
  OpsTicketDevice,
} from "../../../../service/repos/ops/allowcation";
import {
  GetOrderApi,
  GetOrdersApi,
} from "../../../../service/repos/order_repo";
import { GetExecutivesApi } from "../../../../service/repos/products_repo";
import { GetWareHouseListApi } from "../../../../service/repos/warehouse_repo";
import { currencyFormat } from "../../../../utils/orders_utils";
import LoadingWidget from "../../../components/loading_spinner";
import ModalDialog from "../../../new_components/common/modal";
import AllotAddressEditWidget from "./components/allot_address";

interface DeviceAllocationScreenProps {
  onClose: () => void;
}
const defaultValue: OpsTicketData = {
  order_id: "",
  warehouse_id: 0,
  inventories: [],
};
const DeviceAllocationScreen: FunctionComponent<DeviceAllocationScreenProps> = (
  props
) => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [warehouses, setWareHouse] = useState<WarehouseModel[]>([]);
  const [order, setOrder] = useState<OrderModel>();
  const [opsTickectData, setOpsTicketData] =
    useState<OpsTicketData>(defaultValue);

  const [inventory, setInventory] = useState<OpsTicketDevice[]>([]);

  const [emps, setEmps] = useState<
    {
      id: string;
      name: string;
      dep: string;
      sub_dep: string;
    }[]
  >([]);
  const [exception_devices, setExceptionDevices] = useState<string[]>([]);
  const getEmps = async () => {
    const res = await GetExecutivesApi("Sales");

    if (res.success) {
      setEmps(res.data ?? []);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  const getWarehouse = async () => {
    const res = await GetWareHouseListApi({ page: 0, count: 0, all: true });

    if (res.success) {
      setWareHouse(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };
  const getOrderDetail = async (order_id: string) => {
    const res = await GetOrderApi(order_id);

    if (res.success) {
      setOrder(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };
  const getInventory = async () => {
    setLoading(true);
    const { warehouse_id, order_id } = opsTickectData;
    const res = await GetInventoryDeviceForOrderApi({
      warehouse_id,
      order_id,
    });

    if (res.success) {
      const customer = order?.customer!;
      if (res.message) {
        showToast({ type: ToastType.success, text: res.error });
      }
      let temp = res.data.map(
        (d: {
          device_id: string;
          product_id: number;
          product_name: string;
        }) => {
          return {
            ...d,
            valid: true,
            ...{
              contact_person_mobile: customer.poc_contact ?? customer.mobile,
              contact_person:
                customer.poc ?? customer.firstName + " " + customer.lastName,
              address:
                customer.address.line1 +
                " " +
                customer.address.city +
                " " +
                customer.address.state +
                " ",
              state: customer.address.state,
              city: customer.address.city,
              pincode: customer.address.pincode,
              emp_id: 0,
              emp_name: "",
            },
          };
        }
      );
      setInventory([...temp]);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const checkInventory = async (device_id: string, i: number) => {
    setLoading(true);
    const { warehouse_id } = opsTickectData;

    const res = await CheckInventoryAvailableApi({
      warehouse_id,
      device_id,
    });

    if (res.success) {
      const inv = res.data;
      for (let index = 0; index < inventory.length; index++) {
        const temp = inventory[index];
        if (temp.device_id === inv.device_id) {
          alert("device already exists");
          setLoading(false);
          return;
        }
      }

      setInventory((pre) => {
        return pre.map((temp, j) => {
          if (j === i) {
            temp.valid = true;
            temp.device_id = inv.device_id;
            temp.product_id = inv.product_id;
            temp.product_name = inv.product_name;
          }
          return temp;
        });
      });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const createOps = async () => {
    setLoading(true);
    if (inventory.length === 0) {
      showToast({
        type: ToastType.warning,
        text: "Please validate selected device IDs",
      });
      setLoading(false);
      return;
    }
    for (let index = 0; index < inventory.length; index++) {
      const temp = inventory[index];
      if (!temp.valid) {
        alert("Please validate selected device IDs");
        setLoading(false);
        return;
      }
      if (!temp.emp_id) {
        alert("Please assign responsible person !");
        setLoading(false);
        return;
      }
      delete temp.valid;
    }
    opsTickectData.inventories = inventory;
    const res = await CreateOpsTicket(opsTickectData);

    if (res.success) {
      setOrder(undefined);
      setOpsTicketData(defaultValue);
      setInventory([]);
      props.onClose();
    } else {
      if (
        res.error === "Devices already allotted !" ||
        res.error === "Devices not found !"
      ) {
        setExceptionDevices(res.data);
      }
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    getWarehouse();
    getEmps();
  }, []);
  useEffect(() => {
    if (opsTickectData.warehouse_id && order) {
      getInventory();
    }
  }, [opsTickectData, order]);
  const getPrevData = (inventories: OpsTicketDevice[]) => {
    for (let index = 0; index < inventories.length - 1; index++) {
      const val1 = inventories[index];
      const val2 = inventories[index + 1];
      if (
        val1.contact_person !== val2.contact_person ||
        val1.contact_person_mobile !== val2.contact_person_mobile ||
        val1.address !== val2.address ||
        val1.city !== val2.city ||
        val1.state !== val2.state ||
        val1.pincode !== val2.pincode ||
        val1.emp_id !== val2.emp_id
      ) {
        return;
      }
    }
    const {
      contact_person,
      contact_person_mobile,
      address,
      city,
      state,
      pincode,
      emp_id,
      emp_name,
    } = inventories[0];
    return {
      contact_person,
      contact_person_mobile,
      address,
      city,
      state,
      pincode,
      emp_id,
      emp_name,
      operation: "",
    };
  };
  const addBtnDisable =
    order &&
    inventory.length ===
      order!.products.reduce((p, v) => p + (v.qty - (v.delivered_qty ?? 0)), 0);
  return (
    <>
      {showModel && (
        <ModalDialog
          show={showModel}
          title="Device Allocation"
          onClose={() => {
            setShowModel(false);
          }}
        >
          <AllotAddressEditWidget
            onClose={() => {
              setShowModel(false);
            }}
            emps={emps}
            preData={getPrevData(
              inventory.filter((val) => selected.indexOf(val.device_id) !== -1)
            )}
            onSubmit={(data) => {
              setInventory((invs) => {
                return invs.map((inv) => {
                  if (selected.indexOf(inv.device_id) !== -1)
                    return { ...inv, ...data };
                  else return inv;
                });
              });
              setShowModel(false);
              setSelected([]);
            }}
          />
        </ModalDialog>
      )}
      <div className="p-5 ">
        <div className="flex justify-between sticky top-0 bg-white z-50">
          <button
            className="  flex  items-center p-2  hover:text-gray-800 text-gray-400 font-bold text-lg"
            onClick={() => {
              props.onClose();
              setTimeout(() => setOrder(undefined), 500);
            }}
          >
            <Icon path={mdiChevronLeft} className="h-6 w-6 font-bold"></Icon>{" "}
            Back
          </button>
          {/* <button
            className="  flex  items-center p-2  hover:text-gray-800 text-gray-400 font-bold text-lg gap-2"
            onClick={() => getOrder()}
          >
            Refresh
            <Icon path={mdiReload} className="h-4 w-4 font-bold"></Icon>
          </button> */}
        </div>
        <div className="flex flex-wrap gap-5 text-sm mb-2">
          <div className="flex items-center    ">
            <select
              onChange={(e) => {
                setOpsTicketData((val) => ({
                  ...val,
                  warehouse_id: parseInt(e.target.value),
                }));
              }}
              name="warehouser"
              id="warehouse"
              className="border rounded px-2 py-1"
            >
              <option value="" hidden>
                Select warehouse
              </option>
              {warehouses.map((warehouse) => (
                <option value={warehouse.id}>{warehouse.name}</option>
              ))}
            </select>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const order_id = e.currentTarget.order_id.value;

              getOrderDetail(order_id);
              setOpsTicketData((val) => ({
                ...val,
                order_id,
              }));
            }}
            className="flex items-center  "
          >
            <input
              required
              name="order_id"
              type="text"
              placeholder="Order ID"
              className="focus:outline-none rounded-md border px-2 py-1  w-full md:w-64 "
            />
            <button type="submit" className="  p-2  ">
              <Icon
                path={mdiMagnify}
                className={`  w-6 h-6  text-gray-500 hover:text-gray-800 `}
              ></Icon>
            </button>
          </form>
        </div>

        <div className="   grid grid-cols-1 lg:grid-cols-10 gap-2 items-start  mb-5 ">
          <div className="rounded shadow px-4 py-2 col-span-4 h-full">
            <h6 className="font-semibold mb-3">Customer Details</h6>
            <div className="grid grid-cols-2 text-sm  gap-4 ">
              <div className="flex flex-col  flex-wrap gap-1 justify-start w-full">
                <label className="text-xs  font-bold ">Customer Name</label>
                <p className="rounded-md    w-full">
                  {order?.customer.firstName} {order?.customer.lastName}
                </p>
              </div>
              <div className="flex flex-col  flex-wrap gap-1 ">
                <label className="text-xs  font-bold ">Mobile</label>
                <p className=" w-full  justify-between">
                  {order?.customer.mobile}
                  {order?.customer.alt_mobile ? "/" : ""}
                  {order?.customer.alt_mobile}
                </p>
              </div>
              <div className="flex flex-col  flex-wrap gap-1 justify-start w-full">
                <label className="text-xs  font-bold ">Contact Name</label>
                <p className="rounded-md    w-full">
                  {order?.customer.firstName} {order?.customer.poc}
                </p>
              </div>
              <div className="flex flex-col  flex-wrap gap-1 ">
                <label className="text-xs  font-bold ">Contact Mobile</label>
                <p className=" w-full  justify-between">
                  {order?.customer.poc_contact ?? "--"}
                </p>
              </div>
              <div className=" flex flex-col  col-span-2 flex-wrap gap-1">
                <label className="text-xs  font-bold ">Address</label>
                <p className="   w-full">
                  {order?.customer.address.line1}
                  {order?.customer.address.line2}
                </p>
              </div>
              <div className="flex flex-wrap gap-5 col-span-2">
                {" "}
                <div className=" flex flex-col   flex-wrap gap-1">
                  <label className="text-xs  font-bold ">City</label>
                  <p className="   w-full">{order?.customer.address.city}</p>
                </div>
                <div className=" flex flex-col   flex-wrap gap-1">
                  <label className="text-xs  font-bold ">State</label>
                  <p className="   w-full">{order?.customer.address.state}</p>
                </div>
                <div className=" flex flex-col   flex-wrap gap-1">
                  <label className="text-xs  font-bold ">Pincode</label>
                  <p className="   w-full">{order?.customer.address.pincode}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-6 rounded shadow px-4 py-2 h-full">
            <h6 className="font-semibold mb-3">Order Details</h6>

            <div className="grid gap-2">
              <div className="grid grid-cols-2 text-sm  gap-2 items-start ">
                <div className="flex items-center flex-wrap gap-1 justify-between">
                  <p className="text-xs  font-semibold ">Type</p>
                  <h6 className="w-full justify-between">{order?.type}</h6>
                </div>
                <div className="flex items-center flex-wrap gap-1 justify-between">
                  <p className="text-xs  font-semibold ">Status</p>
                  <h6 className="w-full">{order?.status}</h6>
                </div>
              </div>
              <section className="border rounded">
                <table className="w-full text-xs  ">
                  <thead className="">
                    <tr className="border-b">
                      <th className="p-3" align="left">
                        Product
                      </th>
                      <th className="p-3" align="right">
                        Qty
                      </th>
                      <th className="p-3" align="right">
                        Delivered
                      </th>
                      <th className="p-3" align="right">
                        Installed
                      </th>
                      <th className="p-3" align="right">
                        Outstanding Qty
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {order?.products.map((item) => (
                      <tr className="border-b">
                        <td className="px-3 py-2" align="left">
                          {item.item?.name ?? "--"}
                        </td>
                        <td className="px-3 py-2" align="right">
                          {item.qty}
                        </td>
                        <td className="px-3 py-2" align="right">
                          {item.delivered_qty}
                        </td>
                        <td className="px-3 py-2" align="right">
                          {item.installed_qty}
                        </td>
                        <td className="px-3 py-2" align="right">
                          {item.qty - (item.delivered_qty ?? 0)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>
            </div>
            <hr />
            <div className=" py-2 flex gap-3 text-sm font-semibold">
              <div className="flex ">
                <h6>Total :{currencyFormat(order?.total ?? 0)}</h6>
              </div>
              <div className="flex ">
                <h6>Paid :{currencyFormat(order?.paid ?? 0)}</h6>
              </div>
              <div className="flex ">
                <p>
                  Balance :
                  {currencyFormat((order?.total ?? 0) - (order?.paid ?? 0))}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex my-2 justify-start ">
          <button
            onClick={() => setShowModel(true)}
            className={`border rounded px-2 py-1 ${
              selected.length > 0 ? "visible" : "invisible"
            }`}
          >
            Next
          </button>
        </div>
        <div className="border rounded h-96 overflow-auto">
          <table className="w-full    text-sm">
            <thead className="rounded shadow  sticky top-0 bg-white">
              <tr className="text-xs">
                <th></th>
                <th align="center">
                  <input
                    onClick={() => {
                      if (selected.length === inventory.length)
                        setSelected((pre) => []);
                      else setSelected(inventory.map((d) => d.device_id));
                    }}
                    checked={
                      selected.length > 0 &&
                      selected.length === inventory.length
                    }
                    type="checkbox"
                    name=""
                    id=""
                    className="aligce"
                  />
                </th>
                <th className="px-3 py-2" align="left">
                  Device ID
                </th>
                <th className="px-3 py-2">Product</th>
                <th className="px-3 py-2">Contact Name</th>
                <th className="px-3 py-2">Contact number</th>
                <th className="px-3 py-2">Address</th>
                <th className="px-3 py-2">Operaton</th>
                <th className="px-3 py-2">Responsible</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {inventory.map((d, i) => (
                <tr className="border-b text-xs" key={i}>
                  <td align="right" className="p-1">
                    {i + 1}
                  </td>
                  <td className="px-3 py-2" align="center">
                    {d.valid && (
                      <input
                        onClick={() => {
                          if (selected.indexOf(d.device_id) === -1)
                            setSelected((pre) => [...pre, d.device_id]);
                          else
                            setSelected((pre) =>
                              pre.filter((vl) => vl !== d.device_id)
                            );
                        }}
                        checked={selected.indexOf(d.device_id) !== -1}
                        type="checkbox"
                        name=""
                        id=""
                      />
                    )}
                  </td>
                  <td className="px-3 py-2" align="center">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();

                        const device_id = e.currentTarget.device_id.value;
                        if (device_id && d.device_id !== device_id) {
                          checkInventory(device_id, i);
                        }
                      }}
                      className={`flex gap-2 items-center ${
                        exception_devices.indexOf(d.device_id) !== -1
                          ? "text-red-500"
                          : ""
                      }`}
                    >
                      <input
                        onChange={(e) => {
                          // if (e.target.value !== d.device_id) {

                          setInventory((pre) => {
                            return pre.map((inv, j) => {
                              if (i === j) {
                                if (e.target.value !== d.device_id)
                                  inv.valid = false;
                                else inv.valid = true;
                              }

                              return inv;
                            });
                          });
                          // }
                        }}
                        required
                        className={`border rounded-md px-2 py-1 focus:outline-none ${
                          d.valid ? "border-green-500" : "border-red-500 "
                        }`}
                        type="text"
                        name="device_id"
                        defaultValue={d.device_id}
                      />
                      {!d.valid && (
                        <button type="submit" className="text-blue-400">
                          validate
                        </button>
                      )}
                    </form>
                  </td>
                  <td className="px-3 py-2" align="center">
                    {d.product_name ?? "---"}
                  </td>
                  <td className="px-3 py-2" align="center">
                    {d.contact_person ?? "---"}
                  </td>
                  <td className="px-3 py-2" align="center">
                    {d.contact_person_mobile ?? "---"}
                  </td>
                  <td className="px-3 py-2" align="center">
                    {d.address ?? "---"}
                  </td>
                  <td className="px-3 py-2" align="center">
                    {d.operation ?? "---"}
                  </td>
                  <td className="px-3 py-2" align="center">
                    {d.emp_name ?? "---"}
                  </td>
                  <td align="center" className="p-2">
                    <span
                      onClick={() => {
                        setInventory((pre) => {
                          return pre.filter((p, ind) => ind !== i);
                        });
                      }}
                    >
                      <Icon
                        path={mdiClose}
                        className="w-4 h-4  hover:text-red-500 "
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button
          disabled={addBtnDisable}
          className={`${
            addBtnDisable ? "cursor-not-allowed" : ""
          } border rounded-md px-3 py-1 mt-2`}
          onClick={() => {
            for (let index = 0; index < inventory.length; index++) {
              const inv = inventory[index];
              if (inv.product_id == 0) {
                return;
              }
            }
            const t: OpsTicketDevice = {
              device_id: "",
              product_id: 0,
              product_name: "",
              operation: "",
              contact_person: "",
              contact_person_mobile: "",
              address: "",
              state: "",
              city: "",
              pincode: "",
              emp_id: "",
              emp_name: "",
              valid: false,
            };
            setInventory((pre) => [...pre, t]);
          }}
        >
          Add Device
        </button>
      </div>
      <div className="flex justify-end mx-5">
        <button
          onClick={() => createOps()}
          className="rounded-md px-3 py-1 border hover:bg-black hover:text-white"
        >
          Submit
        </button>
      </div>
      <LoadingWidget loading={loading} />
    </>
  );
};

export default DeviceAllocationScreen;
