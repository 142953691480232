import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import {
  WAREHOUSE_TYPE,
  WarehouseModel,
} from "../../models/warehouse_model";
import { urls } from "../../../../utils/urls";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { fetchGet } from "../../../../service/api_client";
import EmployeeSearchWidget from "../../../../ui/new_components/emp_search_widget";
import PartySearchField from "../../../accounts/parties/party_search_field";
import LoadingWidget from "../../../../context_providers/modal/loader";

interface WarehouseViewScreenProps {
  edit: boolean;
  onClose: (id?: WarehouseModel) => void;
  data?: WarehouseModel;
}

const WarehouseViewScreen: FunctionComponent<WarehouseViewScreenProps> = (
  props
) => {
  const isViewPage = useRouteMatch(
    `${urls.procurement_masters_warehouse}/view/:id`
  );
  const { id } = useParams<{ id: string }>();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/warehouse_type`;
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const { edit } = props;
  const [editable, setEditable] = useState(false);
  const [data, setData] = useState<WarehouseModel>(
    props.data || ({} as WarehouseModel)
  );
  const navaigation = useHistory();

  const handleOptionChange = (value: WarehouseModel["type"]) => {
    setData((pv) => ({ ...pv, type: value }));
  };

  useEffect(() => {
    setEditable(edit);
    if (id && isViewPage) {
      getData(id);
    }
  }, []);

  useEffect(() => {
    // Initialize form with existing data if editing
    if (edit && props.data) {
      setData(props.data);
    }
  }, [edit, props.data]);

  const getData = async (id: string) => {
    try {
      setLoading(true);
      const res = await fetchGet(`${url}/${id}`);
      if (res.success) {
        console.log("result:", res.data);
        const updatedData = { ...res.data, type: res.data.type }; // Update type property
        setData(updatedData); // Update state with merged data
      } else {
        console.log("Invalid");
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({ type: ToastType.error, text: "An error occurred" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 m-5 gap-2 select-none text-sm">
        <h1 className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
          Warehouse {id ? `View: ${data.id}` : ""}
        </h1>
        <div className="grid grid-cols-1 w-full mr-5 justify-center m-5 gap-2 select-none">
          <div className="p-5 md:p-10 flex flex-col mr-10 gap-5 shadow bg-white rounded-lg">
            <div className="flex flex-col text-sm w-1/2">
              <div className="flex flex-col items-start text-sm w-full my-2">
                <label className="font-semibold w-full" htmlFor="name">
                  Warehouse Name*
                </label>
                <input
                  placeholder="Enter Name"
                  className="p-1 rounded focus:outline-none w-full border"
                  id="name"
                  name="name"
                  value={data.name || ""}
                  required
                  type="text"
                  readOnly
                />
              </div>
              {/* <div className="mb-2"> */}
              <div className="grid grid-cols-2 gap-5 items-end my-2">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="line1">
                    Address Line 1
                  </label>
                  <input
                    placeholder="address line 1"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="line1"
                    name="line1"
                    value={data.address?.line1 || ""}
                    readOnly
                  />
                </div>

                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="line2">
                    Address Line 2
                  </label>
                  <input
                    placeholder="address line 2"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="line2"
                    name="line2"
                    value={data.address?.line2 || ""}
                    readOnly
                  />
                </div>
              </div>
              <div className="flex flex-col items-start text-sm w-full my-2">
                <label className="font-semibold w-full" htmlFor="line2">
                  Address Line 3
                </label>
                <input
                  placeholder="address line3"
                  className="p-1 rounded focus:outline-none w-full border"
                  id="line2"
                  name="line2"
                  value={data.address?.line3 || ""}
                  readOnly
                />
              </div>
              <div className="grid grid-cols-2 gap-5 items-end my-2">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="country">
                    Country*
                  </label>
                  <input
                    id="mail_detail.address.country"
                    name="mail_detail.address.country"
                    className="p-1 rounded focus:outline-none w-full border"
                    value={data?.address?.country || ""}
                    readOnly
                  ></input>
                </div>

                <div className="grid gap-1">
                  <label
                    htmlFor="mail_detail.address.state"
                    className="font-semibold text-sm"
                  >
                    State
                  </label>
                  <input
                    type="text"
                    className="border rounded-md px-3 py-1"
                    id="address.state"
                    name="address.state"
                    value={data?.address?.state}
                    readOnly
                    placeholder="State"
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-5 items-end my-2">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="city">
                    City*
                  </label>

                  <input
                    id="city"
                    name="city"
                    className="p-1 rounded focus:outline-none w-full border"
                    value={data?.address?.city || ""} // Make sure it defaults to empty string if undefined
                    readOnly
                  ></input>
                </div>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="pincode">
                    Pincode*
                  </label>
                  <input
                    placeholder="Enter pincode"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="pincode"
                    name="pincode"
                    required
                    value={data.address?.pincode || ""}
                    readOnly
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-5 items-end my-2">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="type">
                    Type*
                  </label>
                  <input
                    value={data.type}
                    readOnly
                    className="p-1 rounded focus:outline-none w-full border"
                  ></input>
                </div>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="status">
                    SPOC Name*
                  </label>
                  <input
                    value={data.spoc?.contact_name}
                    readOnly
                    className="p-1 rounded focus:outline-none w-full border"
                  ></input>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
        <div className="flex justify-end gap-5 mr-5 mt-5 mb-2">
          <button
            onClick={() => {
              navaigation.push(urls.procurement_masters_warehouse);
            }}
            type="button"
            className="rounded-md text-sm font-semibold bg-white border border-green-600 text-green-600 px-3 py-1"
          >
            Quit
          </button>
        </div>
      </div>

      {loading && <LoadingWidget />}
    </>
  );
};

export default WarehouseViewScreen;