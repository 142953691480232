import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
// import { useLocation } from 'react-router-dom';
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { fetchGet, fetchPut } from "../../../../service/api_client";
import { UTCToLocalDate } from "../../../../utils/date_util";
import WarehouseSearchField from "../../warehouse/warehouse_search_field";
import { urls } from "../../../../utils/urls";
import {
  stockJournalDefaultValue,
  StockJournalModel,
  VoucherStatus,
} from "../../models/stock_transfer_model";
import ItemRowStockTransferReceiver from "./componets/ItemRowStockTransferReciever";

interface StockJournalProps {}

const StockJournalUpdate: FunctionComponent<StockJournalProps> = () => {
  const { id } = useParams<{ id: string }>();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/journal/sender_transfer`;
  const [, setLoading] = useState(false);
  const [editable] = useState(false);
  // const [addNewId1, setAddNewId1] = useState(Math.random() * 1000);
  // const [isButtonClicked, setIsButtonClicked] = useState(true);
  const { showToast } = useToast();

  const [data, setData] = useState<StockJournalModel>({
    ...stockJournalDefaultValue,
  });
  const existingItems = data.source || [];
  // const [isWithdrawn, setIsWithdrawn] = useState<boolean>(false);
  const navigate = useHistory();
  // const [ShowHistory, setShowHistory] = useState<{ [key: string]: any }>();
  // const handleOptionChange = (value: WarehouseModel["type"]) => {
  //   setData((pv) => ({ ...pv, type: value }));
  // };
  // const locate = useLocation();
  // const isCreatePath = locate.pathname.endsWith("/create");

  useEffect(() => {
    if (id) getVoucher(id);
  }, [id]);

  const getVoucher = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      const newData = {
        ...res.data,
        destination:
          res.data.destination && res.data.destination.length > 0
            ? res.data.destination
            : res.data.source, // If destination is empty or undefined, use source
      };

      setData((prevData) => ({
        ...prevData,
        ...newData,
      }));
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }

    setLoading(false);
  };

  const submit = async () => {
    if (!data.destination?.length)
      return showToast({
        type: ToastType.error,
        text: "Please add receiver component data",
      });
    // setIsButtonClicked(false);
    setLoading(true);
    // console.log("ID in submit", id);
    const res = await fetchPut(url + "/destination/" + id, data);

    if (res.success) {
      data.status = VoucherStatus.intransit;
      // setIsButtonClicked(false);
      showToast({ type: ToastType.success, text: res.message });
      navigate.push(urls.stock_transfer + "/receiver");
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  return (
    <>
      <div className="sticky top-0 mt-2 px-6 h-full">
        <div className="flex items-center justify-between mb-2">
          <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
            Receiver Stock Transfer
          </div>
        </div>
        <div className="bg-white rounded mb-5 flex gap-1 h-auto overflow-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 p-5 text-sm w-3/4 font-semibold rounded-t top-0">
            <div className="">
              <div className="font-bold text-md mb-3">From</div>
              <div className="">
                <div className="">
                  <div className="font-bold">Sender Name</div>
                  <input
                    value={data.user?.first_name + " " + data.user?.last_name}
                    placeholder="Sender"
                    type="text"
                    disabled={!editable}
                    className="text-left p-1 border rounded-md focus:outline-none w-full"
                  />
                </div>

                <div className="font-bold">Sender Department</div>
                <input
                  value={data.user?.emp_profile?.department}
                  placeholder="Department"
                  disabled={!editable}
                  type="text"
                  className="text-left p-1 border rounded-md focus:outline-none w-full"
                />
              </div>
              <div className="font-bold">From Warehouse</div>
              <WarehouseSearchField
                type={""}
                disabled={true}
                value={data.from_warehouse}
                placeholder="Inventory Basket"
                onSelect={(d) => {
                  setData((old) => {
                    return {
                      ...old,
                      from_warehouse: d,
                    };
                  });
                }}
              />
              <div className="">
                <label htmlFor="" className="text-sm font-bold text-gray-500">
                  Shipment Date
                </label>{" "}
                <input
                  type="date"
                  disabled
                  className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
                  value={
                    data.shipment_date
                      ? moment(data.shipment_date).format("YYYY-MM-DD")
                      : ""
                  }
                  min={moment().format("YYYY-MM-DD")}
                  onChange={(e) => {
                    const date = UTCToLocalDate(e.target.value)!;
                    setData((o) => ({
                      ...o,
                      shipment_date: date,
                    }));
                  }}
                />
              </div>
              <div className="">
                <div className="font-bold">Delivery Challan Number/Invoice</div>
                <input
                  value={data.delivery_challan_number}
                  disabled
                  name="challan"
                  id="challan"
                  placeholder="Delivery Challan"
                  type="text"
                  className="text-left p-1 focus:outline-none border rounded w-full"
                  onChange={(e) =>
                    setData((d) => ({
                      ...d,
                      delivery_challan_number: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="mr-0">
              <div className="font-bold text-md mb-3">To</div>

              <div className="font-bold">Warehouse/office</div>
              <WarehouseSearchField
                type={""}
                disabled={true}
                value={data.receiver_warehouse}
                placeholder="Inventory Basket"
                onSelect={(d) => {
                  setData((old) => {
                    return {
                      ...old,
                      receiver_warehouse: d,
                      //   department:d.spoc?.department,
                      //   contact_name:d.spoc?.contact_name
                    };
                  });
                }}
              />
              <div className="font-bold">Receiver</div>
              <input
                value={data.receiver_warehouse?.spoc?.contact_name}
                placeholder=""
                disabled
                type="text"
                className="text-left bg-gray-200 p-1 focus:outline-none border rounded w-full"
                onChange={(e) => {
                  setData((prevData) => ({
                    ...prevData,
                    receiver_warehouse: {
                      ...prevData.receiver_warehouse!,
                      contact_name: e.target.value,
                    },
                  }));
                }}
                readOnly
              />

              <div className="flex flex-col items-start text-sm w-full">
                <label className="font-semibold w-full" htmlFor="line1">
                  Receiver Department
                </label>
                <input
                  disabled
                  placeholder="dept"
                  className="p-1 rounded focus:outline-none w-full border"
                  id="dept"
                  name="dept"
                  value={data.receiver_warehouse?.spoc?.department}
                  onChange={(e) =>
                    setData((prevData) => ({
                      ...prevData,
                      receiver_warehouse: {
                        ...prevData.receiver_warehouse!,
                        department: e.target.value,
                      },
                    }))
                  }
                />
              </div>
              <div className="flex flex-col items-start text-sm w-full">
                <label className="font-semibold w-full" htmlFor="line1">
                  Tracking ID
                </label>
                <input
                  disabled
                  placeholder="track id"
                  className="p-1 rounded focus:outline-none w-full border"
                  id="track_id"
                  name="track_id"
                  value={data.tracking_id}
                  onChange={(e) =>
                    setData((prevData) => ({
                      ...prevData,
                      tracking_id: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <label htmlFor="" className="text-sm font-bold text-gray-500">
                  Expected Received Date
                </label>{" "}
                <input
                  disabled
                  type="date"
                  required
                  className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
                  value={
                    data?.expected_received_date
                      ? moment(data?.expected_received_date).format(
                          "YYYY-MM-DD"
                        ) // Format to YYYY-MM-DD for input
                      : "" // Empty by default, so no date is selected initially
                  }
                  min={moment().format("YYYY-MM-DD")} // Prevent selection of past dates
                  onChange={(e) => {
                    const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                    const date = selectedDate
                      ? new Date(new Date(selectedDate).setHours(0, 0, 0, 0)) // Set the time to 00:00:00
                      : undefined; // If no date is selected, reset the value

                    // Update the state with the selected date
                    setData((pv) => ({
                      ...pv,
                      expected_received_date: date!, // Save the selected date in state
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-2 mt-5 ">
          <div className="flex flex-col gap-5">
            <div className="">
              <h3 className="text-sm font-bold">Particulars</h3>
              <div className="bg-white rounded flex flex-col gap-1 h-auto overflow-auto pb-20">
                <div className="grid grid-cols-8 gap-1 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t sticky top-0">
                  <div className="text-left">Item Code</div>
                  <div className="text-left col-span-2">Item Name</div>
                  <div className="text-left ">Unit Measure</div>
                  <div className="text-left">Qty</div>
                  <div className="text-left">Received Qty</div>
                  <div className="text-left">Unique IDs</div>
                  <div className="text-center">Action</div>
                </div>
                <div>
                  {data.destination?.map((comp, i) => {
                    return (
                      <ItemRowStockTransferReceiver
                        component={comp}
                        edit={false}
                        disabled={false}
                        key={comp.id}
                        onDelete={(d: any) => {
                          setData((o) => {
                            const destination = [
                              ...(o.destination || []).filter(
                                (it) => it.id !== d.id
                              ),
                            ];

                            return {
                              ...o,
                              destination,
                            };
                          });
                        }}
                        onSubmit={(d: any) => {
                          setData((o) => {
                            const destination = [
                              ...(o.destination || []).map((it) =>
                                it.id === d.id ? d : it
                              ),
                            ];

                            return {
                              ...o,
                              destination,
                            };
                          });
                        }}
                        existingItems={existingItems}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex sticky bottom-0 justify-end my-5 gap-4 px-5 py-5">
        {/* {id && !data.destination?.length ? (
            <button
              type="button"
              className="bg-red-500 px-10 py-1 rounded-md text-white text-sm"
              onClick={withdraw}
              disabled={isWithdrawn} // Disable if already withdrawn
            >
              Withdraw
            </button>
          ) : null}
          {id &&
            !(data.status === voucherStatus.return) &&
            !data.destination?.length && (
              <button
                type="button"
                className="bg-myPrimaryColor px-10 py-1 rounded-md text-white text-sm"
                onClick={submit}
                disabled={isWithdrawn} // Disable if already withdrawn
              >
                Resend Mail
              </button>
            )} */}

        {data.receiver_warehouse?.type === "external" && (
          <button
            type="button"
            className="px-10 py-1 rounded-md bg-myPrimaryColor text-white text-sm"
            onClick={submit}
            // disabled={isWithdrawn} // Disable submit if withdrawn or no items
          >
            Update
          </button>
        )}
        {data.receiver_warehouse?.type === "internal" && (
          <button
            type="button"
            className="px-10 py-1 rounded-md bg-myPrimaryColor text-white text-sm"
            onClick={submit}
            // disabled={isWithdrawn} // Disable submit if withdrawn or no items
          >
            Sender Update
          </button>
        )}
        <button
          type="button"
          className="px-10 py-1 rounded-md bg-myPrimaryColor text-white text-sm"
          onClick={() => navigate.push(urls.stock_transfer + "/receiver")}
        >
          Quit
        </button>
      </div>
      {/* )} */}
    </>
  );
};

export default StockJournalUpdate;
