import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import { debounce } from "lodash";
import {
  mdiArrowBottomLeft,
  mdiArrowTopRight,
  mdiClose,
  mdiMenuDown,
  mdiMenuUp,
  mdiPlus,
  mdiRefresh,
} from "@mdi/js";
import moment from "moment";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  toQueryString,
} from "../../../../service/api_client";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../../procurement/components/create_view/form_field";
import LoadingWidget from "../../../../context_providers/modal/loader";
import SearchFieldWidget from "../../../../ui/new_components/search_field";
import { UTCToLocalDate } from "../../../../utils/date_util";
import { urls } from "../../../../utils/urls";
import ModalDialog from "../../../../ui/new_components/common/modal";
import FileDropZone from "../../../../ui/new_components/common/file_drop_zone";
import { parse } from "papaparse";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../../../ui/new_components/common/drawer_modal";
import {
  intransitDefaultValue,
  IntransitStockJournalModel,
} from "../../models/stock_transfer_model";
import TableView from "../../../../procurement/components/table_comp";

interface StockTransferJournalListScreenProps {
  records: IntransitStockJournalModel[];
  onClick: (id: string) => void;
  sort_by: string;
  sort_order: number;
  pages: number;
  options: []; // Replace with actual type
  handleSort: (data: { sort: string; sort_order: number }) => void;
  handlePageChange: (page: number) => void;
  // handleStatusFilterChange: (selectedOptions: string[]) => void;
}

interface StockListFilter {
  stock_transfer_id?: string;
  from_warehouse?: string;
  to_warehouse?: string;
  sender_name?: string;
  sender_department?: string;
  receiver_department?: string;
  receiver_name?: string;
  search?: string;
  page: number;
  count: number;
  all?: any;
  fromDate?: Date;
  toDate?: Date;
}

const defaultFilter = (): StockListFilter => {
  return {
    page: 1,
    count: 100,
  };
};

const IntransitStockTransferListScreen: FunctionComponent<
  StockTransferJournalListScreenProps
> = () => {
  const navigation = useHistory();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/journal/sender_transfer/in-transit`;
  const { showToast } = useToast();
  const [page, setPage] = useState(1);
  const [count] = useState(50);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<IntransitStockJournalModel[]>([]);
  const [filter, setFilter] = useState<StockListFilter>(defaultFilter());
  const [showUniqueIds, setShowUniqueIds] = useState(false);
  const [ShowHistory, setShowHistory] = useState<any>();
  const [filterCollaped, setFilterCollapsed] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandler = useCallback(
    debounce((prop: StockListFilter) => {
      if (prop) fetchData(prop);
    }, 300),
    []
  );
  useEffect(() => {
    debouncedHandler(filter);
  }, [filter, debouncedHandler]);

  const [selectedRows, setSelectedRows] = useState<boolean[]>(
    data.flatMap((item) => new Array(item?.uniqueIds?.length).fill(false))
  );

  useEffect(() => {
    if (!data.length) return;

    const updatedRows = data.map((entry) =>
      Array.from({ length: entry?.unit_no || 0 }, (_, index) => ({
        unique_id: entry?.uniqueIds?.[index]?.unique_id || "", // Get existing unique_id if present
        item_name: entry?.item_name || "",
        status: entry?.uniqueIds?.[index]?.status || "",
      }))
    );

    // Flatten the array if needed
    const flattenedRows = updatedRows.flat();

    // setInitialRows(flattenedRows);
    setSelectedRows(flattenedRows.map((item) => item.status === "Confirm"));

    setData((prevData) => {
      if (!prevData.length) return prevData;

      return prevData.map((entry, index) => ({
        ...entry,
        uniqueIds: updatedRows[index],
      }));
    });
  }, [data]); // Depend on entire data array

  const fetchData = async (prop: StockListFilter) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    } finally {
      setLoading(false);
    }
  };

  const getIntransitExport = async () => {
    try {
      const a = document.createElement("a");
      a.target = "_blank";
      a.href = `${
        process.env.REACT_APP_BACKEND_BASE_URL
      }/api/v3/erp/inventory/journal/sender_transfer/in-transit/export${toQueryString(
        {
          query: JSON.stringify({
            ...filter,
          }),
        }
      )}`;

      // Delay the trigger to allow UI updates (if necessary)
      setTimeout(() => {
        setLoading(false);
        showToast({ type: ToastType.success, text: "Download started!" });
        a.click(); // Trigger the download
      }, 1000);
    } catch (error) {
      setLoading(false); // Make sure loading is set to false in case of an error
      showToast({ type: ToastType.error, text: "Failed to start download." });
      console.error("Download error:", error);
    }
  };

  return (
    <>
      {setShowUniqueIds && (
        <ModalDialog
          show={showUniqueIds}
          title={"Product Details"}
          onClose={() => {
            setShowUniqueIds(false);
          }}
        >
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <div className="flex justify-between">
              <h2 className="text-md font-semibold mb-4">Product List</h2>
              <div className="">
                <button className="bg-green-600 text-white p-1 rounded-md">
                  Export
                </button>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full border border-gray-300">
                {/* Table Head */}
                <thead className="bg-gray-200">
                  <tr>
                    <th className="p-2 border">
                      <input type="checkbox" checked={false} />
                    </th>
                    <th className="p-2 border text-left">Unique Id</th>
                    <th className="p-2 border text-left">Item Name</th>
                  </tr>
                </thead>

                {/* Table Body */}
                <tbody>
                  {data
                    .map((entry) => entry.uniqueIds || [])
                    .flat()
                    .map((row: any, index: number) => (
                      <tr key={row.id} className="text-center">
                        <td className="p-2 border">
                          <input
                            type="checkbox"
                            checked={selectedRows[index]}
                          />
                          <span className="ml-2">Confirm</span>
                        </td>
                        <td className="p-2 border">
                          <input
                            type="text"
                            value={row.unique_id || ""}
                            className="p-1 border rounded w-full focus:outline-none"
                            placeholder="Enter Unique Id"
                          />
                        </td>
                        <td className="p-2 border">{row.item_name}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </ModalDialog>
      )}
      {ShowHistory && (
        <DrawerModal
          location={DrawerModalLoaction.right}
          show={ShowHistory !== undefined}
          onClose={() => setShowHistory(undefined)}
          title="History"
        >
          {/* {console.log("id of order", ShowHistory)} */}
          {/* <ActionHistory
              voucher_id={ShowHistory}
              // show={ShowHistory !== undefined}
              // onClose={(v) => setShowHistory(undefined)}
            /> */}
        </DrawerModal>
      )}

      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section
          id="top_section"
          className="flex justify-between items-end my-1"
        >
          <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
            In-Transit Page
            <button
              title="Refersh"
              onClick={() => debouncedHandler({ ...filter })}
            >
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>

          <div className="text-sm flex flex-wrap items-center gap-2 m-3">
            <div className="flex items-center  gap-1">
              <label htmlFor="fromDate" className="">
                From
              </label>
              <input
                type="date"
                id="fromDate"
                className="p-1 rounded focus:outline-none"
                value={
                  filter.fromDate
                    ? moment(filter.fromDate).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  const selectedDate = UTCToLocalDate(e.target.value);
                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    fromDate: selectedDate,
                  }));
                }}
              />
            </div>
            <div className="flex items-center gap-1">
              <label htmlFor="toDate" className="">
                To
              </label>
              <input
                type="date"
                id="toDate"
                className="p-1 rounded focus:outline-none "
                value={
                  filter.toDate
                    ? moment(filter.toDate).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  const selectedDate = UTCToLocalDate(e.target.value);
                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    toDate: selectedDate,
                  }));
                }}
              />
              {(filter.fromDate || filter.toDate) && (
                <button
                  title="Clear dates"
                  onClick={() =>
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      fromDate: undefined,
                      toDate: undefined,
                    }))
                  }
                  className="flex gap-1 items-center cursor-pointer rounded p-1 border r hover:scale-105 duration-200     transform"
                >
                  <Icon path={mdiClose} size={0.7}></Icon>
                </button>
              )}
            </div>
            <div className="flex flex-wrap text-sm items-center gap-2 ">
              <button
                onClick={() => getIntransitExport()}
                className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white flex gap-2 items-center"
              >
                <Icon path={mdiArrowTopRight} size="15"></Icon> Export
              </button>
            </div>
            <div className="flex gap-2">
              <h6 className="font-normal">Filters</h6>
              <div
                onClick={() => setFilterCollapsed(!filterCollaped)}
                className="flex   items-center justify-center bg-gray-200 p-1 rounded-full hover:bg-gray-800 hover:text-white"
              >
                <Icon
                  path={filterCollaped ? mdiMenuDown : mdiMenuUp}
                  className="w-4 h-4"
                />
              </div>
            </div>
          </div>
        </section>{" "}
        {!filterCollaped && (
          <div className="grid grid-cols-2 gap-6 w-1/2 m-2">
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">
                Stock Transfer ID
              </label>
              <SearchFieldWidget
                defaulValue={filter?.stock_transfer_id}
                onClear={() => {
                  setFilter({
                    ...filter,
                    stock_transfer_id: undefined, // Fixed indentation
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    stock_transfer_id: val, // Fixed indentation
                  });
                  setPage(1);
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">
                From Warehouse
              </label>
              <SearchFieldWidget
                defaulValue={filter?.from_warehouse}
                onClear={() => {
                  setFilter({
                    ...filter,
                    from_warehouse: undefined, // Fixed indentation
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    from_warehouse: val, // Fixed indentation
                  });
                  setPage(1);
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">To Warehouse</label>
              <SearchFieldWidget
                defaulValue={filter?.to_warehouse}
                onClear={() => {
                  setFilter({
                    ...filter,
                    to_warehouse: undefined, // Fixed indentation
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    to_warehouse: val, // Fixed indentation
                  });
                  setPage(1);
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">Sender Name</label>
              <SearchFieldWidget
                defaulValue={filter?.sender_name}
                onClear={() => {
                  setFilter({
                    ...filter,
                    sender_name: undefined, // Fixed indentation
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    sender_name: val, // Fixed indentation
                  });
                  setPage(1);
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">
                Sender Department
              </label>
              <SearchFieldWidget
                defaulValue={filter?.sender_department}
                onClear={() => {
                  setFilter({
                    ...filter,
                    sender_department: undefined, // Fixed indentation
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    sender_department: val, // Fixed indentation
                  });
                  setPage(1);
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">
                Receiver Name
              </label>
              <SearchFieldWidget
                defaulValue={filter?.receiver_name}
                onClear={() => {
                  setFilter({
                    ...filter,
                    receiver_name: undefined, // Fixed indentation
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    receiver_name: val, // Fixed indentation
                  });
                  setPage(1);
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">
                Receiver Department
              </label>
              <SearchFieldWidget
                defaulValue={filter?.receiver_department}
                onClear={() => {
                  setFilter({
                    ...filter,
                    receiver_department: undefined, // Fixed indentation
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    receiver_department: val, // Fixed indentation
                  });
                  setPage(1);
                }}
              />
            </div>
          </div>
        )}
        <TableView
          headers={[
            {
              key: "id",
              title: "ID",
              type: FieldType.string,
            },
            {
              key: "stock_transfer_id",
              title: "Stock transfer ID",
              type: FieldType.string,
            },
            {
              key: "date",
              title: "Created Date",
              type: FieldType.date,
            },
            {
              key: "sender_name",
              title: "Sender Name",
              type: FieldType.string,
            },
            {
              key: "sender_dept",
              title: "Sender Dept",
              type: FieldType.string,
            },
            {
              key: "from_warehouse",
              title: "From Warehouse",
              type: FieldType.string,
            },
            {
              key: "to_warehouse",
              title: "To Warehouse",
              type: FieldType.string,
            },
            {
              key: "item_code",
              title: "Item Code",
              type: FieldType.string,
            },
            {
              key: "item",
              title: "Item Name",
              type: FieldType.string,
            },
            {
              key: "unique_ids",
              title: "Unique IDs",
              type: FieldType.string,
            },
            {
              key: "in_transit_qty",
              title: "In-transit Qty",
              type: FieldType.number,
            },
            {
              key: "units",
              title: "Total Qty",
              type: FieldType.number,
            },
            {
              key: "received_units",
              title: "Received Qty",
              type: FieldType.number,
            },
            {
              key: "receiver_name",
              title: "Receiver Name",
              type: FieldType.string,
            },
            {
              key: "receiver_dept",
              title: "Receiver Dept",
              type: FieldType.string,
            },
            {
              key: "status",
              title: "Status",
              type: FieldType.string,
            },
          ]}
          rows={data.map((data, i) => ({
            data: data,
            values: [
              // formatISODate(data.date),
              data.id,
              data.stock_transfer_id,
              moment(data?.created_at).format("DD-MM-YYYY"),
              data.sender_name,
              data.sender_department,
              data.from_warehouse,
              data.to_warehouse,
              data.item_code,
              data.item_name,
              // data.uniqueIds?.length + " Unique Ids",
              <span
                className="text-blue-500 cursor-pointer hover:underline"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering row click
                  if (data.uniqueIds && data.uniqueIds.length > 0) {
                    setShowUniqueIds(true);
                  }
                }}
              >
                {data.uniqueIds?.length} Unique Ids
              </span>,
              data.in_transit_units,
              data?.unit_no,
              data?.received_units,
              data.receiver_name,
              data.receiver_department,
              data.status,
            ],
          }))}
          page={page}
          total={total}
          count={count}
          itemCount={data.length}
          onPageChange={setPage}
          // onClick={onSelect}
          // onShowHistory={(data) => {
          //   setShowHistory(data);
          //   // console.log(data);
          // }}
          // onDelete={onDelete}
        />
      </div>

      {loading && <LoadingWidget />}
    </>
  );
};

export default IntransitStockTransferListScreen;
