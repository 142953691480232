import {
  mdiCheck,
  mdiChevronDown,
  mdiChevronUp,
  mdiClose,
  mdiDelete,
  mdiPencil,
  mdiPlus,
} from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import OrderItemModel from "../../../../../service/models/orders/order_item_model";
import ProductModel from "../../../../../service/models/product_model";
import { numberRegEx } from "../../../../../utils/constant";
import { currencyFormat } from "../../../../../utils/orders_utils";
import {
  itemMovementDefaultValue,
  ItemMovementModel,
} from "../../../../inventory/models/inventory_voucher";
import { ItemModel } from "../../../../inventory/models/item_model";
import WarehouseSearchField from "../../../../inventory/warehouse/warehouse_search_field";
import { WAREHOUSE_TYPE } from "../../../../inventory/models/warehouse_model";

interface ItemSplitRowProps {
  item: ItemModel;
  price: number;
  item_split?: ItemMovementModel;
  onSubmit: (data: ItemMovementModel) => void;
  edit: boolean;
  disabled: boolean;
  onDelete?: (i: ItemMovementModel) => void;
}

const ItemSplitRow: FunctionComponent<ItemSplitRowProps> = (props) => {
  const [data, setData] = useState<ItemMovementModel>(
    props.item_split || {
      ...itemMovementDefaultValue,
      price: props.price,
      item: props.item,
    }
  );
  const { showToast } = useToast();
  const [showMore, setShowMore] = useState(false);
  const { edit, onSubmit } = props;

  const [editable, setEditable] = useState(false);
  useEffect(() => {
    setEditable(edit);
  }, []);
  const onDone = () => {
    if (!data.warehouse || !data.bill_unit_no || !data.unit_no) {
      showToast({
        type: ToastType.warning,
        text: "Please select Warehouse and Qty",
      });
      return;
    }
    onSubmit(data);
  };
  useEffect(() => {
    setData((o) => ({ ...o, price: props.price }));
  }, [props.price]);

  return (
    <>
      <form
        // id={props.form_id}
        onSubmit={(e) => {
          e.preventDefault();
          onDone();
        }}
        className="bg-white     p-1 "
      >
        <div className="grid grid-cols-8 gap-x-1">
          <div className="col-span-4">
            <WarehouseSearchField
              disabled={!editable || props.disabled}
              value={data.warehouse}
              onSelect={(d) => {
                setData((old) => {
                  return { ...old, warehouse: d, warehouse_id: d.id };
                });
              }}
            />
          </div>
          <div className="grid grid-cols-4 gap-1 items-center">
            <input
              value={data?.bill_unit_no}
              onChange={(e) => {
                const val = e.target.value.trim();
                if (!val || numberRegEx.test(val)) {
                  const value = Number(val || 0);
                  setData((ol) => ({ ...ol, bill_unit_no: value }));
                }
              }}
              disabled={!editable || props.disabled}
              placeholder=""
              type="text"
              className="text-right  p-1 focus:outline-none border rounded col-span-3"
            />
            <div className="">{data.item?.unit.symbol}</div>
          </div>
          <div className="grid grid-cols-4 gap-1 items-center">
            <input
              value={data?.unit_no}
              onChange={(e) => {
                const val = e.target.value.trim();
                if (!val || numberRegEx.test(val)) {
                  const value = Number(val || 0);
                  setData((ol) => ({ ...ol, unit_no: value }));
                }
              }}
              disabled={!editable || props.disabled}
              placeholder=""
              type="text"
              className="text-right  p-1 focus:outline-none border rounded col-span-3"
            />
            <div className="">{data.item?.unit.symbol}</div>
          </div>
          {/* <input
            value={data?.price}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = Number(e.target.value);
              }
              setData((ol) => ({ ...ol, price: v }));
            }}
            disabled={true}
            placeholder=""
            type="text"
            className="text-right  p-1 focus:outline-none border rounded"
          /> */}

          <input
            value={(data?.bill_unit_no ?? 0) * (data?.price ?? 0)}
            disabled={true}
            placeholder=""
            type="text"
            className="text-right  p-1 focus:outline-none border rounded"
          />
          <div className="flex gap-2 items-center justify-end">
            {props.item_split ? (
              !props.disabled && (
                <>
                  <div className="flex justify-center gap-2">
                    {editable ? (
                      <>
                        <div
                          onClick={() => {
                            if (!window.confirm("Are you sure !")) return;
                            onDone();
                            setEditable(false);
                          }}
                          className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                        </div>
                        <div
                          onClick={() => {
                            setData(props.item_split!);
                            setEditable(false);
                          }}
                          className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiClose} className="h-4 w-4"></Icon>
                        </div>
                      </>
                    ) : (
                      <div
                        onClick={() => setEditable(true)}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                      </div>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      if (!window.confirm("Are you sure !")) return;
                      if (props.onDelete) props.onDelete(data);
                    }}
                    className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                  >
                    <Icon path={mdiDelete} className="h-4 w-4"></Icon>
                  </div>
                </>
              )
            ) : (
              <button
                // form="item_form"
                className="flex gap-1 text-white text-sm bg-myPrimaryColor rounded-full px-2 py-1  "
              >
                <Icon path={mdiPlus} className="h-5 w-5 "></Icon> Add
              </button>
            )}
            {/* {props.item_split && (
              <div
                onClick={() => setShowMore((o) => !o)}
                className="p-1 bg-gray-100 rounded cursor-pointer hover:bg-gray-200"
              >
                <Icon
                  path={showMore ? mdiChevronUp : mdiChevronDown}
                  className="h-4 w-4"
                ></Icon>
              </div>
            )} */}
          </div>
        </div>
        {/* {showMore && props.item && (
          <div className="px-2 py-1">
            <hr className=" " />
            <div className="text-xs text-gray-400">
              <p>Id : {props.item.id}</p>
              <p>Extra : {rupeeFormat(props.item.addition_amt ?? 0)}</p>
              <p>Discount : {rupeeFormat(props.item.discount_amt ?? 0)}</p>
              <p>Paid : {rupeeFormat(props.item.paid ?? 0)}</p>
            </div>
            <div className="flex   justify-between gap-2 my-1">
              <p className="text-xs ">
                Created by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.item.created_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.item.created_at
                      ? moment(props.item.created_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
              <p className="text-xs ">
                Updated by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.item.updated_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.item.updated_at
                      ? moment(props.item.updated_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
            </div>
          </div>
        )} */}
      </form>
    </>
  );
  // return (
  //   <div className=" text-sm  grid grid-cols-10 gap-1 bg-white shadow py-1">
  //     <div className="  col-span-1"></div>
  //     <div className={`  col-span-4 `}>
  //       <div className="">
  //            <WarehouseSearchField type={WAREHOUSE_TYPE.warehouse}
  //           value={data.warehouse}
  //           onSelect={(d) => {
  //             setData((old) => {
  //               return { ...old, warehouse: d, warehouse_id: d.id };
  //             });
  //           }}
  //         />
  //       </div>
  //     </div>
  //     <div className="   ">
  //       <input
  //         type="number"
  //         className=" focus:outline-none border rounded py-1 px-2 col-span-1 w-full text-right"
  //         value={data?.bill_unit_no}
  //         onChange={(e) => {
  //           setData((old) => {
  //             return {
  //               ...old,
  //               bill_unit_no: Number(e.target.value),
  //             };
  //           });
  //         }}
  //       />
  //     </div>
  //     <div className="">
  //       <input
  //         type="number"
  //         className=" focus:outline-none border rounded py-1 px-2 col-span-1 w-full text-right"
  //         value={data?.unit_no}
  //         onChange={(e) => {
  //           setData((old) => {
  //             return {
  //               ...old,
  //               unit_no: Number(e.target.value),
  //             };
  //           });
  //         }}
  //       />
  //     </div>
  //     <div className="">
  //       <input
  //         type="number"
  //         className=" focus:outline-none border rounded py-1 px-2 col-span-1 w-full text-right"
  //         value={data?.price}
  //         onChange={(e) => {
  //           setData((old) => {
  //             return {
  //               ...old,
  //               price: Number(e.target.value),
  //             };
  //           });
  //         }}
  //       />
  //     </div>
  //     <div className="">
  //       <input
  //         disabled
  //         type="text"
  //         className=" focus:outline-none border rounded py-1 px-2 col-span-1 w-full text-right"
  //         value={data?.price * data.bill_unit_no}
  //       />
  //     </div>
  //     <div className="  ">
  //       <button className="py-1 px-2 rounded bg-teal   shadow text-white  col-span-1 w-full ">
  //         +
  //       </button>
  //     </div>
  //   </div>
  // );
};

export default ItemSplitRow;
