import { mdiCheck, mdiClose, mdiPencil, mdiDelete, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import {
  defaultPoItemValueV2,
  PoItemV2,
} from "../../model/purchase_order_model";
import { Currency } from "../../../accounts/models/party_model";
import ItemSearchFieldV2 from "./item_search_widget";
import { numberRegEx } from "../../../../utils/constant";
import { ItemModelV2 } from "../../../inventory/models/item_model";
import ProjectSearchFieldV2 from "./project_search_widget";
import { usePurchaseOrder } from "../../contexts/po-creation-context";
import { Project } from "../../../inventory/models/project_model";

interface PoItemRowPropsV2 {
  data?: PoItemV2;
  currency: Currency;
  onSubmit: (data: PoItemV2) => void;
  edit: boolean;
  disabled: boolean;
  onDelete?: (i: PoItemV2) => void;
  existingItems?: PoItemV2[];
}

const PoItemRowV2: FunctionComponent<PoItemRowPropsV2> = (props) => {
  const [data, setData] = useState<PoItemV2>({
    ...defaultPoItemValueV2,
  });

  const { defaultProject, setDefaultProject, purchaseOrder } =
    usePurchaseOrder();

  const { showToast } = useToast();
  const [showMore, setShowMore] = useState(false);
  const { edit, onSubmit, existingItems } = props;
  // const [check, setMdiCheck] = useState(true);
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setEditable(edit);
  }, []);

  const onDone = () => {
    if (!data.item || data.price < 0 || !data.unit_no) {
      showToast({
        type: ToastType.warning,
        text: "Please select Item, Price and Qty",
      });
      return;
    }

    if (!defaultProject) {
      showToast({
        type: ToastType.warning,
        text: "Please select a Project",
      });
      return;
    }

    if (
      data.project === undefined ||
      (data.project === null && defaultProject)
    ) {
      data.project_id = defaultProject.id;
      data.project = defaultProject;
    }
    console.log("Data", data);
    onSubmit(data);
  };

  const isDuplicate = (item: ItemModelV2) => {
    return existingItems?.some((existing) => existing.item.id === item.id);
  };

  const [canAdd, setCanAdd] = useState(true);

  const handleProjectSelection = async (selectedProject: Project) => {
    if (!selectedProject) {
      showToast({
        type: ToastType.error,
        text: "Please Select Project",
      });
      return;
    }

    // Set default project only if it's the first row
    if (!defaultProject && existingItems?.length === 0) {
      console.log("Default Item Changed");
      setDefaultProject(selectedProject);
    }

    // Update the project's selection in the current row only
    console.log("Current Project Selection", selectedProject);
    setData((prevData) => ({
      ...prevData,
      project_id: selectedProject.id,
      project: selectedProject,
    }));
  };

  useEffect(() => {
    if (props.data) {
      setData({ ...props.data });
    } else if (!props.data && existingItems?.length === 0 && defaultProject) {
      // Only set the default project for the first row when there's no prior data

      console.log("Default Project Override");
      setData((prevData) => ({
        ...prevData,
        project_id: defaultProject.id,
        project: defaultProject,
      }));
    }
  }, [props.data, defaultProject, existingItems]);

  const handleItemSelect = async (selectedItem: ItemModelV2) => {
    if (isDuplicate(selectedItem)) {
      showToast({
        type: ToastType.warning,
        text: "This item already exists for the selected Item.",
      });
      setCanAdd(false); // Disable the add button
      return;
    }
    setCanAdd(true); // Item is not a duplicate

    // handle the tax information in here additional layer of tax

    setData((prevData) => ({
      ...prevData,
      item: selectedItem,
      item_id: selectedItem.id,
    }));
  };

  // const getStockValue = async (item: ItemModel) => {
  //   const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/items/${item.id}/value`;
  //   setLoading(true);
  //   const res = await fetchGet(url);
  //   if (res.success) {
  //     setData((o) => ({
  //       ...o,
  //       item: item,
  //       item_id: item.id,
  //       price: res.data.price,
  //     }));
  //   } else showToast({ type: ToastType.error, text: res.error });
  //   setLoading(false);
  // };

  useEffect(() => {
    if (props.data) setData({ ...props.data });
  }, [props.data]);

  const billToState = purchaseOrder?.bill_to?.address?.state || "";
  const partyState = purchaseOrder?.party?.mail_detail?.address?.state || "";

  const isInterStateTransaction =
    billToState !== "" &&
    partyState !== "" &&
    billToState.toLowerCase() !== partyState.toLowerCase();

  return (
    <>
      <form
        // id={props.form_id}
        onSubmit={(e) => {
          e.preventDefault();
          onDone();
        }}
        className="bg-white   text-sm  "
      >
        <div className="grid grid-cols-12 gap-2">
          <input
            value={data?.item?.item_code ?? "--"}
            disabled={true}
            placeholder=""
            type="text"
            className="text-center  p-1 focus:outline-none border rounded"
          />
          <ItemSearchFieldV2
            disabled={!editable || props.disabled}
            value={data.item}
            // onSelect={getStockValue}
            onSelect={handleItemSelect}
            // onSelect={(d) => {
            //   setData((old) => {
            //     return { ...old, item: d, item_id: d.id };
            //   });
            // }}
          />
          {/* Project Name */}

          <ProjectSearchFieldV2
            disabled={!editable || props.disabled}
            value={data?.project ?? defaultProject ?? null}
            onSelect={handleProjectSelection}
          />

          {/* HSN SAC */}
          <input
            value={data?.item?.tax_detail?.hsn_sac || "--"}
            disabled={true}
            placeholder=""
            type="text"
            className="text-center  p-1 focus:outline-none border rounded"
          />
          {/* Unit */}
          <input
            value={data?.item?.unit?.formal_name || "--"}
            disabled={true}
            placeholder=""
            type="text"
            className="text-center  p-1 focus:outline-none border rounded"
          />
          {/* QTY */}
          <input
            value={data.unit_no ?? 0}
            disabled={false}
            placeholder=""
            type="number"
            className="text-center p-1 focus:outline-none border rounded"
            onChange={(e) => {
              const val = e.target.value.trim(); // Trim whitespace
              if (val === "" || !numberRegEx.test(e.target.value)) return; // Prevent invalid input

              setData((prevData) => ({
                ...prevData,
                unit_no: Number(val), // Convert input to a number
              }));
            }}
          />

          {/* Price */}
          <input
            value={data.price ?? 0}
            disabled={false}
            placeholder=""
            type="number"
            className="text-center  p-1 focus:outline-none border rounded"
            onChange={(e) => {
              const val = e.target.value.trim(); // Trim whitespace

              if (val === "" || !numberRegEx.test(e.target.value)) return; // Prevent invalid input
              console.log(val);
              setData((prevData) => ({
                ...prevData,
                price: Number(val), // Convert input to a number
              }));
            }}
          />
          {/* CGST */}
          <input
            value={
              !isInterStateTransaction &&
              data?.item?.tax_applicable &&
              data?.item?.tax_detail?.gst_rate
                ? data?.item?.tax_detail?.gst_rate / 2.0
                : "--"
            }
            disabled={true}
            placeholder=""
            type="text"
            className="text-center  p-1 focus:outline-none border rounded"
          />
          {/* SGST */}
          <input
            value={
              !isInterStateTransaction &&
              data?.item?.tax_applicable &&
              data?.item?.tax_detail?.gst_rate
                ? data?.item?.tax_detail?.gst_rate / 2.0
                : "--"
            }
            disabled={true}
            placeholder=""
            type="text"
            className="text-center  p-1 focus:outline-none border rounded"
          />
          {/* IGST */}
          <input
            value={
              isInterStateTransaction &&
              data?.item?.tax_applicable &&
              data?.item?.tax_detail?.gst_rate
                ? data?.item?.tax_detail?.gst_rate
                : "--"
            }
            disabled={true}
            placeholder=""
            type="text"
            className="text-center  p-1 focus:outline-none border rounded"
          />
          {/* Amount */}
          <input
            value={data.unit_no * data.price || "--"}
            disabled={true}
            placeholder=""
            type="text"
            className="text-center  p-1 focus:outline-none border rounded"
          />
          {/* Actions */}
          <div className="flex gap-2 items-center justify-end">
            {props.data ? (
              !props.disabled && (
                <>
                  <div className="flex justify-center gap-2">
                    {editable ? (
                      <>
                        <div
                          onClick={() => {
                            if (!window.confirm("Are you sure !")) return;
                            onDone();
                            setEditable(false);
                          }}
                          className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                        </div>
                        <div
                          onClick={() => {
                            setData(props.data!);
                            setEditable(false);
                          }}
                          className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiClose} className="h-4 w-4"></Icon>
                        </div>
                      </>
                    ) : (
                      <div
                        onClick={() => setEditable(true)}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                      </div>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      if (!window.confirm("Are you sure !")) return;
                      if (props.onDelete) props.onDelete(data);
                    }}
                    className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                  >
                    <Icon path={mdiDelete} className="h-4 w-4"></Icon>
                  </div>
                </>
              )
            ) : (
              <button
                // form="item_form"
                disabled={!canAdd}
                className="flex gap-1 text-white text-sm bg-myPrimaryColor rounded-full px-2 py-1  "
              >
                <Icon path={mdiPlus} className="h-5 w-5"></Icon> Add
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default PoItemRowV2;
