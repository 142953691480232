import { FunctionComponent } from "react";
import ProtocolLayout from "../components/ProtocolLayout";

interface SafetySegmentProps {}

const ComplaintSegment: FunctionComponent<SafetySegmentProps> = () => {
  return (
    <ProtocolLayout>
      <div className="flex flex-col items-center py-12">
        <div className="mb-12 text-3xl font-bold underline">
          Safety Protocols
        </div>
        <div className="grid lg:grid-cols-3 justify-items-center gap-y-5 lg:gap-y-0 w-4/5 mt-6 lg:mt-20">
          <a
            href="https://drive.google.com/file/d/18_aWyVBnqqZV0Wzj5JD8ycvMla2ex7Zi/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <div className="relative bg-gray-100 hover:bg-gray-200 duration-300 w-72 h-64 flex flex-col items-center justify-center rounded-xl shadow-lg py-12 px-4 ">
              <div className="">
                <img src="/docs/gmc1.png" alt="" className="w-32" />
              </div>
              <div className="mt-12 text-black text-opacity-40 font-bold text-xl">
                SOP for EHS Generic Requirements
              </div>
            </div>
          </a>
          <a
            href="https://drive.google.com/file/d/19IBNVLYDOrnJh07NjdTseuijaOnjGh1d/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <div className="relative bg-gray-100 hover:bg-gray-200 duration-300 w-72 h-64 flex flex-col items-center justify-center rounded-xl shadow-lg py-12 px-4 ">
              <div className="">
                <img src="/docs/gmc1.png" alt="" className="w-32" />
              </div>
              <div className="mt-12 text-black text-opacity-40 font-bold text-xl">
                Hazard Identification Risk Assessment
              </div>
            </div>
          </a>
          <a
            href="https://drive.google.com/file/d/1u8_57tK10ilPOnmbCtB6FAtE5GjAScUp/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <div className="relative bg-gray-100 hover:bg-gray-200 duration-300 w-72 h-64 flex flex-col items-center justify-center rounded-xl shadow-lg py-12 px-4 ">
              <div className="">
                <img src="/docs/gmc1.png" alt="" className="w-32" />
              </div>
              <div className="mt-12 text-black text-opacity-40 font-bold text-xl">
                Anti-Bribery and Corruption Policy
              </div>
            </div>
          </a>
        </div>
      </div>
    </ProtocolLayout>
  );
};

export default ComplaintSegment;
