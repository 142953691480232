import { mdiCheck, mdiClose, mdiPencil, mdiDelete, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  itemMovementDefaultValue,
  StockItemMovementModel,
  UniqueProductModel,
} from "../../../models/stock_transfer_model";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { ItemModelV2 } from "../../../models/item_model";
import { fetchGet } from "../../../../../service/api_client";
import WarehouseSearchField from "../../../warehouse/warehouse_search_field";
import {
  WAREHOUSE_TYPE,
  WarehouseModel,
} from "../../../models/warehouse_model";
import ItemSearchField from "../../../items/item_search_field";
import NewItemSearchField from "../../../items/item_search_new";
import ModalDialog from "../../../../../ui/new_components/common/modal";
import { uniqueId } from "lodash";

interface ItemRowProps {
  component?: StockItemMovementModel;
  onSubmit: (data: StockItemMovementModel) => void;
  edit: boolean;
  disabled: boolean;
  onDelete?: (i: StockItemMovementModel) => void;
  existingItems: StockItemMovementModel[];
}

const ItemRowStockTransferReceiver: FunctionComponent<ItemRowProps> = (
  props
) => {
  const [data, setData] = useState<StockItemMovementModel>({
    ...itemMovementDefaultValue,
  });
  // const [selectedItem, setSelectedItem] = useState<string>();
  const { showToast } = useToast();
  const { edit, onSubmit, existingItems } = props;
  const [editable, setEditable] = useState(false);
  const [addProduct, setAddProduct] = useState(false);
  const [check, setMdiCheck] = useState(true);

  const location = useLocation();
  const isCreatePath = location.pathname.endsWith("/view");
  console.log("isCreatePath:", isCreatePath);
  useEffect(() => {
    setEditable(edit);
    if (props.component) {
      setData(props.component);
    } else {
      setData({ ...itemMovementDefaultValue });
    }
  }, [edit, props.component]);

  useEffect(() => {
    if (props.component) {
      setData((prevData) => ({
        ...prevData,
        ...props.component,
      }));
    }
  }, [props.component]);

  const [initialRows, setInitialRows] = useState<
    { unique_id: string; item_name: string; status: string }[]
  >([]);

  const [selectedRows, setSelectedRows] = useState<boolean[]>(
    new Array(data.uniqueIds?.length).fill(false)
  );
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (!data.unit_no) return; // Ensure unit_no is valid
    if (
      data.item?.subcategory !== "Finished Goods" &&
      data.item?.subcategory !== "IT and Electronic Hardware"
    ) {
      return;
    }
    if (!data.unit_no) return; // Ensure unit_no is valid

    const rows = Array.from({ length: data.unit_no }, (_, index) => {
      const existingUniqueId = data.uniqueIds?.[index]?.unique_id || ""; // Get existing unique_id if present
      const existingUniqueStatus = data.uniqueIds?.[index]?.status || "";
      return {
        unique_id: existingUniqueId,
        item_name: data.item?.name || "",
        status: existingUniqueStatus,
      };
    });

    setInitialRows(rows);
    const newSelectedRows = rows.map((item) => item.status === "Confirm");
    setSelectedRows(newSelectedRows);
    setData((prevData) => ({ ...prevData, uniqueIds: rows }));
  }, [data.unit_no]); // Depend on data.unit_no to update rows dynamically
  // console.log("data after status:", data);

  const handleRowSelect = (index: number) => {
    const newSelectedRows = [...selectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);

    // Count selected checkboxes
    const selectedCount = newSelectedRows.filter((val) => val).length;

    // Update received_unit_no immediately
    setData((prevData) => {
      const updatedUniqueIds = prevData.uniqueIds?.map((item, i) => ({
        ...item,
        status: newSelectedRows[i] ? "Confirm" : "", // Set status when checked
      }));

      return {
        ...prevData,
        uniqueIds: updatedUniqueIds,
        received_unit_no: selectedCount,
      };
    });

    // If all checkboxes are selected, update selectAll state
    setSelectAll(newSelectedRows.every((val) => val));
  };

  const handleSelectAll = () => {
    const newValue = !selectAll;
    setSelectAll(newValue);
    const newSelectedRows = new Array(data.uniqueIds?.length).fill(newValue);
    setSelectedRows(newSelectedRows);

    // Update uniqueIds status
    setData((prevData) => {
      const updatedUniqueIds = prevData.uniqueIds?.map((item) => ({
        ...item,
        status: newValue ? "Confirm" : "", // Set status for all
      }));

      return {
        ...prevData,
        uniqueIds: updatedUniqueIds,
        received_unit_no: newValue ? data.uniqueIds?.length || 0 : 0,
      };
    });
  };

  console.log("dataa after selection:", data);

  const onDone = () => {
    if (
      data.item?.subcategory !== "Finished Goods" &&
      data.item?.subcategory !== "IT and Electronic Hardware"
    ) {
      delete data.uniqueIds;
    } else if (data.uniqueIds) {
      data.uniqueIds.forEach((object) => {
        if (object.unique_id === "" || object.unique_id === undefined) {
          showToast({
            type: ToastType.error,
            text: "Please enter a valid Item ID",
          });
          return;
        }
      });
    }

    if (!data.item || !data.unit_no) {
      showToast({
        type: ToastType.warning,
        text: "Please select Item, Warehouse, and Quantity",
      });
      return;
    }
    console.log("data getting :", data);
    onSubmit(data);
  };

  return (
    <div>
      {setAddProduct && (
        <ModalDialog
          show={addProduct}
          title={"Product Details"}
          onClose={() => {
            setAddProduct(false);
            setEditable(true);
            setData((prevData) => ({ ...prevData, uniqueIds: initialRows }));
          }}
        >
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <div className="flex justify-between">
              <h2 className="text-md font-semibold mb-4">Product List</h2>
              <div className="">
                <button className="bg-green-600 text-white p-1 rounded-md">
                  Export
                </button>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full border border-gray-300">
                {/* Table Head */}
                <thead className="bg-gray-200">
                  <tr>
                    <th className="p-2 border">
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th className="p-2 border">Unique Id</th>
                    <th className="p-2 border">Item Name</th>
                  </tr>
                </thead>

                {/* Table Body */}
                <tbody>
                  {(data.uniqueIds || []).map((row: any, index: number) => (
                    <tr key={row.id} className="text-center">
                      <td className="p-2 border">
                        <input
                          type="checkbox"
                          checked={selectedRows[index]}
                          onChange={() => handleRowSelect(index)}
                        />
                        <span className="ml-2">Confirm</span>
                      </td>
                      <td className="p-2 border">
                        <input
                          type="text"
                          value={row.unique_id || ""}
                          className="p-1 border rounded w-full focus:outline-none"
                          placeholder="Enter Unique Id"
                        />
                      </td>
                      <td className="p-2 border">{row.item_name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex m-3 justify-end ">
              <button
                onClick={() => {
                  console.log("Done button clicked"); // Debugging log
                  onDone(); // Ensure onDone is defined
                  setAddProduct(false);
                }}
                className="bg-green-600 text-white p-1 rounded-md"
              >
                Done
              </button>
            </div>
          </div>
        </ModalDialog>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          // onDone();
        }}
        className="bg-white p-1"
      >
        <div className="grid grid-cols-8 gap-1 items-center">
          <div>
            <input
              className="text-left p-1 focus:outline-none border rounded w-full"
              placeholder="item-code"
              disabled
              value={data.item_code}
              readOnly
            />
          </div>
          <div className="col-span-2">
            <NewItemSearchField
              disabled={true}
              value={data.item}
              onSelect={(d: ItemModelV2) => {
                // setSelectedItem(data.item);
                setData((o) => ({
                  ...o,
                  item: d,
                  item_id: d.id,
                  item_code: d.item_code,
                }));
              }}
            />
          </div>
          <div>
            <input
              className="text-left p-1 focus:outline-none border rounded w-full"
              placeholder="unit"
              disabled
              value={data.unit_measure}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  unit_measure: e.target.value,
                }));
              }}
            />
          </div>
          <div>
            <input
              value={data.unit_no || ""}
              disabled
              onChange={(e) => {
                const unitNo = Number(e.target.value) || 0;
                setData((prevData) => ({
                  ...prevData,
                  unit_no: unitNo,
                }));
              }}
              // disabled={!editable || props.disabled}
              type="text"
              className="text-left p-1 focus:outline-none border rounded w-full"
            />
          </div>
          <div>
            <input
              value={data.received_unit_no || ""}
              disabled={!editable || props.disabled}
              onChange={(e) => {
                const unitNo = Number(e.target.value) || 0;

                // Validate that received_unit_no does not exceed unit_no
                if (unitNo > (data.unit_no ?? 0)) {
                  showToast({
                    type: ToastType.error,
                    text: "Received unit count cannot exceed total unit number.",
                  });
                  return; // Prevent update
                }

                setData((prevData) => ({
                  ...prevData,
                  received_unit_no: unitNo,
                }));
              }}
              type="text"
              className="text-left p-1 focus:outline-none border rounded w-full"
            />
          </div>
          <div>
            {data.item?.type === "Product" &&
            (data.item?.subcategory === "Finished Goods" ||
              data.item?.subcategory === "IT and Electronic Hardware") ? (
              <div
                onClick={() => {
                  setAddProduct(true);
                  setEditable(true);
                }}
                className="text-left p-1 focus:outline-none border rounded text-blue-800 w-full cursor-pointer"
              >
                <div>
                  <button
                    className="flex items-center space-x-2 rounded  disabled:opacity-50"
                    disabled={!editable || props.disabled}
                  >
                    <Icon path={mdiPlus} className="h-6 w-4" />
                    <span>
                      {(data.uniqueIds?.length ?? 0) > 0
                        ? `${data.uniqueIds?.length ?? 0} items added`
                        : "Add/Upload"}
                    </span>
                  </button>
                </div>
              </div>
            ) : (
              <input
                className="text-left p-1 focus:outline-none border rounded w-full"
                placeholder="unique"
                disabled
                // value={}
                onClick={() => {}}
              />
            )}
          </div>

          <div className="flex gap-2 items-center justify-center">
            {props.component ? (
              !props.disabled && (
                <div className="flex justify-center gap-2">
                  {editable ? (
                    <>
                      <div
                        onClick={() => {
                          if (!window.confirm("Are you sure!")) return;
                          onDone();
                          setEditable(false);
                        }}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiCheck} className="h-4 w-4" />
                      </div>
                      <div
                        onClick={() => {
                          setData(props.component || itemMovementDefaultValue);
                          setEditable(false);
                        }}
                        className="bg-gray-100 p-1 rounded text-gray-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiClose} className="h-4 w-4" />
                      </div>
                    </>
                  ) : (
                    data.voucher_id && (
                      <div
                        onClick={() => {
                          setEditable(true);
                          setMdiCheck(false);
                        }}
                        className="bg-blue-100 p-1 rounded text-blue-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiPencil} className="h-4 w-4" />
                      </div>
                    )
                  )}
                  {data.voucher_id && (
                    <div
                      onClick={() =>
                        props.onDelete && props.onDelete(props.component!)
                      }
                      className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                    >
                      <Icon path={mdiDelete} className="h-4 w-4" />
                    </div>
                  )}
                </div>
              )
            ) : (
              <div
                onClick={() => {
                  setEditable(false);
                  onDone();
                }}
                className="bg-blue-100 p-1 rounded text-blue-800 hover:scale-110 transform duration-200"
              >
                <Icon path={mdiPlus} className="h-4 w-4" />
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ItemRowStockTransferReceiver;
