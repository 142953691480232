import { BaseModel } from "../../../service/models/base";
import { MasterItemStatus } from "../../../utils/enums";
import { tax_type } from "../../accounts/models/ledger_model";
import { TaxDetail } from "../../accounts/models/party_model";
import { itemGroupDefaultValue, ItemGroupModel } from "./item_group_model";
import { unitMeasureDefaultValue, UnitMeasureModel } from "./item_unit_model";
import { WarehouseModel } from "./warehouse_model";

export interface ItemTaxDetailV2 {
  type: string;
  gst_rate?: number;
  hsn_sac?: string;
}

export interface ItemTaxDetail {
  type: string;
  hsn_sac: string;
  integreted: number;
  central: number;
  state: number;
  cess: number;
}
export const ItemType = {
  goods: "Goods",
  service: "Service",
};

export interface OpeningStockModel {
  id?: any;
  warehouse_id?: string;
  warehouse?: WarehouseModel;
  date: Date;
  unit_no: number;
  price: number;
}

export interface ItemModelV2 extends BaseModel {
  item_code: string; // Unique identifier for the item, clickable to open details screen
  name: string; // Name of the item (mandatory)
  description: string; // Brief description of the item (mandatory)
  status?: MasterItemStatus;
  barcode?: string; // Optional barcode for the item
  type: "Product" | "Service";
  category?: "Electrical" | "Electronics" | "Others" | undefined; // Applicable for Products
  subcategory: string; // Mandatory for both Products and Services
  manufacturer?: string; // Applicable for Products (Electrical/Electronics Raw Material)
  mpn?: string; // Manufacturer Part Number, applicable for Products
  unit: UnitMeasureModel; // Mandatory for both
  remarks?: string; // Optional remarks for the item
  // Electronics Raw Material Specific Fields
  components?: string; // Applicable for Electronics Raw Material
  component_sub_type?: string; // Applicable for Electronics Raw Material
  mounting_style?: "SMT" | "THT" | ""; // Applicable for Electronics Raw Material
  value?: string; // Applicable for Electronics Raw Material
  footprint?: string; // Applicable for Electronics Raw Material
  tax_applicable: boolean; // Mandatory for both
  tax_detail: ItemTaxDetailV2;
}

export interface ItemModel extends BaseModel {
  type: string;
  item_code: string;
  name: string;
  description?: string;
  category?: string;
  subcategory?: string;
  manufacturer?: string;
  group: ItemGroupModel;
  unit: UnitMeasureModel;
  tax_applicable: boolean;
  tax_detail: ItemTaxDetail;
  image?: string;
  bom_applicable: boolean;
  bom: ItemBom;
  opening_stock: OpeningStockModel[];
}

export interface ItemModelV2 extends BaseModel {
  item_code: string; // Unique identifier for the item, clickable to open details screen
  name: string; // Name of the item (mandatory)
  description: string; // Brief description of the item (mandatory)
  status?: MasterItemStatus;
  barcode?: string; // Optional barcode for the item
  type: "Product" | "Service";
  category?: "Electrical" | "Electronics" | "Others" | undefined; // Applicable for Products
  subcategory: string; // Mandatory for both Products and Services
  manufacturer?: string; // Applicable for Products (Electrical/Electronics Raw Material)
  mpn?: string; // Manufacturer Part Number, applicable for Products
  unit: UnitMeasureModel; // Mandatory for both
  remarks?: string; // Optional remarks for the item
  // Electronics Raw Material Specific Fields
  components?: string; // Applicable for Electronics Raw Material
  component_sub_type?: string; // Applicable for Electronics Raw Material
  mounting_style?: "SMT" | "THT" | ""; // Applicable for Electronics Raw Material
  value?: string; // Applicable for Electronics Raw Material
  footprint?: string; // Applicable for Electronics Raw Material
  tax_applicable: boolean; // Mandatory for both
  tax_detail: ItemTaxDetailV2;
}

export interface ItemFilterData {
  item_code?: string;
  item_name?: string;
  description?: string;
  manufacturer?: string;
  mpn?: string;
  type?: string;
  category?: string;
  status?: string;
  sub_category?: string;
  page: number;
  count: number;
  all?: any;
  uid?: string;
}

export interface ItemLogsModel {
  id?: string;
  operation?: string;
  user_id: any;
  item_code: string;
  created_at?: Date;
  created_by?: string;
  user?: string;
  params?: any;
}

//all params except item_id,price and qty should be deleted from ItemBom.items as it will be lookup from item masters
export interface BomItem {
  id: string;
  item_id: string;
  unit_no: number;
  price?: number;
  closing_qty?: number;
  item?: ItemModel;
}
export interface ItemBom extends BaseModel {
  name: string;
  item_id: string;
  item_name: string;
  unit_no: number;
  unit_measure: UnitMeasureModel;
  items: BomItem[];
}
export const itemTaxDetailDefault: ItemTaxDetail = {
  type: "",
  hsn_sac: "",
  integreted: 0,
  central: 0,
  state: 0,
  cess: 0,
};
export const bomDefaultValue: ItemBom = {
  name: "",
  item_id: "",
  item_name: "",
  unit_no: 0,
  unit_measure: unitMeasureDefaultValue,
  items: [],
};

export const opening_stock_default: OpeningStockModel = {
  warehouse_id: "",
  date: new Date(),
  unit_no: 0,
  price: 0,
};
export const itemDefaultValue: ItemModel = {
  item_code: "",
  name: "",
  group: itemGroupDefaultValue,
  bom_applicable: false,
  unit: unitMeasureDefaultValue,
  tax_applicable: false,
  tax_detail: itemTaxDetailDefault,
  type: ItemType.goods,
  opening_stock: [],
  bom: bomDefaultValue,
};

export const ItemModelV2Default: ItemModelV2 = {
  item_code: "", // Default empty string for unique identifier
  name: "", // Default empty string for item name
  description: "", // Default empty string for description
  barcode: "", // Default empty string for optional barcode
  type: "Product", // Default to 'Product'
  category: undefined, // Default undefined for category
  subcategory: "", // Default empty string for mandatory subcategory
  manufacturer: "", // Default empty string for manufacturer
  mpn: "", // Default empty string for Manufacturer Part Number
  unit: unitMeasureDefaultValue, // Default values for UnitMeasureModel

  // Electronics Raw Material Specific Fields
  components: "", // Default empty string
  component_sub_type: "", // Default empty string
  mounting_style: "", // Default empty string
  value: "", // Default empty string
  footprint: "", // Default empty string
  tax_applicable: false, // Default false for tax_applicable;
  // Tax Details
  tax_detail: {
    type: tax_type.gst, // Default to 'GST' for tax type
    hsn_sac: "", // Default empty string for HSN/SAC Code
  },
};
