import axios from "axios";
import {
  fetchGet,
  fetchPost,
  fetchPut,
  toQueryString,
} from "../../../../service/api_client";
import { KazamGST, KazamGSTFilter } from "../../models/gst_model";
import Success from "../../../../ui/pages/kazam_academy/videos/pages/success";

export const getGstAttachmentSignedURLAPI = async (query: {
  mimetype: string;
  extension: string | undefined;
  dir: string;
}) => {
  const get_signed_urls = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/signed-url`;
  const res = await fetchGet(get_signed_urls + toQueryString(query));
  return res;
};

export const uploadGstAttachmentFiletoS3API = async ({
  signed_url,
  file,
  type,
}: {
  signed_url: string;
  file: File;
  type: string;
}) => {
  if (type === "PDF") {
    type = "application/pdf";
  }
  const res = await axios.put(signed_url, file, {
    headers: {
      "Content-Type": type,
    },
  });
  return res;
};

export const GetGSTRepoByID = async (gst_number: string) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/gst`;
  const res = await fetchGet(url + "/" + gst_number);
  return res;
};

export const GSTUpdateRepo = async (data: KazamGST) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/gst`;
  const res = await fetchPut(url + "/", data);
  return res;
};

export const GSTCreateRepo = async (data: KazamGST) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/gst`;
  const res = await fetchPost(url + "/", data);
  return res;
};

export const GSTGetRepo = async (prop: KazamGSTFilter) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/gst`;
  const res = await fetchGet(url + "/" + toQueryString(prop));
  return res;
};
