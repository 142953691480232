import { UserModel } from "../../../context_providers/auth/user_model";
import { BaseModel } from "../../../service/models/base";
import { WarehouseModel } from "./warehouse_model";
import { ItemModelV2 } from "./item_model";
import { AttachmentModel } from "../../purchase_order/model/purchase_order_model";
import { PartyModel } from "../../accounts/models/party_model";

export interface UniqueProductModel extends BaseModel {
    unique_id?: string;
    item_name?: string;
    status?: string;
}

export interface StockItemMovementModel extends BaseModel {
    item: ItemModelV2;
    // item_id: string;
    item_code: string;
    unit_measure: string;
    unit_no: number;
    received_unit_no: number;
    movement_type: string;
    voucher_type: string;
    voucher_id: string;
    status: string;//intransit,received
    warehouse_id: string;
    uniqueIds?: UniqueProductModel[];
}

export const itemMovementDefaultValue: StockItemMovementModel = {
    item: {} as ItemModelV2,
    item_code: "",
    unit_measure: "",
    unit_no: 0,
    received_unit_no: 0,
    movement_type: "",
    voucher_type: "",
    voucher_id: "",
    status: "",
    warehouse_id: "",
    // item_id: ""
}

export interface StockJournalModel extends BaseModel {
    voucher_type: string;
    source?: StockItemMovementModel[];
    destination?: StockItemMovementModel[];
    delivery_challan_number: string;
    tracking_id?: string;
    shipment_date?: Date;
    expected_received_date?: Date;
    user?: UserModel;//sender
    from_warehouse: WarehouseModel;
    receiver_warehouse?: WarehouseModel;
    status: string;//completed,pending
    attachments: AttachmentModel[];
}

export const ItemStatus = {
    in_transit: "In Transit",
    received: "Received",
    // scrapped: "Scrapped",
    // lost: "Lost",
    // damaged: "Damaged",
    // partialReceipt: "Partial Receipt",
    // failed: "Failed",
};

export const VoucherStatus = {
    received: "Received",
    intransit: "In Transit",
    // failed: "Failed",
    // return: "Returned"
}

export const stockJournalDefaultValue: StockJournalModel = {
    voucher_type: "",
    delivery_challan_number: "",
    status: VoucherStatus.intransit,
    from_warehouse: {} as WarehouseModel,
    attachments: []
}

export interface IntransitStockJournalModel extends BaseModel {
    stock_transfer_id: string;
    sender_name: string;
    sender_department: string;
    receiver_department: string;
    receiver_name: string;
    from_warehouse: string;
    to_warehouse?: string;
    item_code: string;
    item_name: string;
    in_transit_units: number;
    received_units: number;
    unit_no: number;
    status: string;
    uniqueIds?: UniqueProductModel[];
}

export const intransitDefaultValue: IntransitStockJournalModel = {
    stock_transfer_id: "",
    sender_name: "",
    sender_department: "",
    receiver_department: "",
    receiver_name: "",
    from_warehouse: "",
    item_code: "",
    item_name: "",
    in_transit_units: 0,
    received_units: 0,
    unit_no: 0,
    status: ""
}


export const OutwardType = {
    Sale: "Sale",//for-order
    Opex: "Opex",
    Return: "Return",
    Replacement: "Replacement",
    Consumption: "Consumption",
    Lost: "Lost",//for in-transit
    Scrap: "Scrap"
}

export const OutwardSource = {
    "Stock inventory": "Stock inventory",
    "In-transit": "In-transit",
}

export interface StockOutwardItemMovementModel extends BaseModel {
    item: ItemModelV2;
    // item_id: string;
    item_code: string;
    unit_no: number;
    price: number;
    movement_type: string;
    voucher_type: string;
    voucher_id: string;
    status: string;//what status need for outwarding.
    warehouse_id: string;
    uniqueIds?: UniqueProductModel[];
}

export const itemStockOutwardDefaultValue: StockOutwardItemMovementModel = {
    item: {} as ItemModelV2,
    item_code: "",
    unit_no: 0,
    price: 0,
    movement_type: "",
    voucher_type: "",
    voucher_id: "",
    status: "",
    warehouse_id: ""
}

export const StockOutwardStatus = {
    pending: "Pending",
    approved: "Approved",
    rejected: "Rejected",
}
export interface StockOutwardModal extends BaseModel {
    outward_type: string;
    outward_date?: Date;
    warehouse: WarehouseModel;
    items: StockOutwardItemMovementModel[];
    order_id?: string;// for order
    source: string;
    inTransit_id?: string;
    stock_transfer_id?: string;
    voucher_type: string;
    status?: string;
    reject_reason?: string;
    uid: string;
    responsible_person?: string;
    responsible_person_address?: string;
    vendor_name?: PartyModel;
}

export const defaultStockOutward: StockOutwardModal = {
    outward_type: "",
    warehouse: {} as WarehouseModel,
    items: [],
    source: "",
    inTransit_id: "",
    stock_transfer_id: "",
    status: StockOutwardStatus.pending,
    responsible_person_address: "",
    vendor_name: {} as PartyModel,
    uid: "",
    voucher_type: ""
}