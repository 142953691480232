import debounce from "lodash.debounce";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useModal } from "../../../context_providers/modal/modal_context";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { fetchGet, toQueryString } from "../../../service/api_client";
import AutoCompleteField from "../../../ui/new_components/common/autocomplete";

import ModalDialog from "../../../ui/pages/content_panel/ui/new_components/common/modal";
import { ItemFilterData, ItemModelV2 } from "../models/item_model";
import ItemCreateScreen from "./item_create/item_create_screen";
import { getItemsRepo } from "./repository/items_repository";

interface ItemSearchFieldProps {
  onSelect: (data: ItemModelV2) => void;
  disabled?: boolean;
  placeholder?: string;
  value?: ItemModelV2;
  type?: string;
  category?: string;
  subcategory?: string;
}

const ItemSearchFieldV2: FunctionComponent<ItemSearchFieldProps> = (props) => {
  const { showToast } = useToast();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [addNew, setAddNew] = useState(false);
  const [data, setData] = useState<ItemModelV2[]>();
  const [uniqueKey] = useState(Math.random() * 10000);
  const getData = async (prop: ItemFilterData) => {
    setLoading(true);
    try {
      const res = await getItemsRepo(prop);
      if (res.success) {
        setData(res.data.data);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce((query) => {
      let searchProp: ItemFilterData = {
        item_name: query,
        page: 1,
        count: 1,
        all: true,
      };
      if (props.type && props.type !== undefined) {
        searchProp = { ...searchProp, type: props.type };
      }

      if (props.subcategory && props.subcategory !== undefined) {
        searchProp = { ...searchProp, sub_category: props.subcategory };
      }

      if (query) getData(searchProp);
      else setData(undefined);
    }, 500),
    []
  );

  useEffect(() => {
    if (search && edit) {
      if (props.value && props.value.name === search) return;
      debouncedHandler(search);
    }
  }, [search]);
  useEffect(() => {
    setSearch(props.value?.name ?? "");
  }, [props.value]);

  useEffect(() => {
    if (props.disabled) {
      setEdit(false);
      setSearch(props.value?.name ?? "");
    }
  }, [props.disabled]);

  return (
    <div key={uniqueKey} className="flex gap-1 items-center    w-full">
      {edit ? (
        <AutoCompleteField
          disabled={props.disabled}
          onAddNew={() => {
            setAddNew(true);
          }}
          loading={loading}
          value={search}
          placeholder={props.placeholder ?? "Select an item "}
          onSelect={(val) => {
            setEdit(false);
            setSearch(val.value.name);
            props.onSelect(val.value);
          }}
          suggestions={data?.map((d) => ({
            label: (
              <div className="text-xs">
                <b>{d.name} </b>
              </div>
            ),

            value: d,
          }))}
          onChange={setSearch}
        />
      ) : (
        <div
          title={search}
          className={`text-xs px-3 py-2  h-8 rounded focus:outline-none w-full border overflow-hidden overflow-ellipsis ${
            search ? "" : " text-gray-400 "
          }`}
          onClick={() => {
            if (!props.disabled) {
              setEdit(true);
              // setSearch(emp?.name ?? "");
            }
          }}
        >
          {search || props.placeholder || "Select an item "}
        </div>
      )}
      {addNew && (
        <ModalDialog
          onClose={() => {
            setAddNew(false);
          }}
          show={addNew}
          title={"Add Item"}
        >
          <div
            className="overflow-auto"
            style={{ maxHeight: "80vh", maxWidth: "80vw " }}
          >
            <ItemCreateScreen
              onClose={(d) => {
                if (d) {
                  setEdit(false);
                  setSearch(d.name);
                  props.onSelect(d);
                  setAddNew(false);
                }
              }}
            />
          </div>
        </ModalDialog>
      )}
    </div>
  );
};

export default ItemSearchFieldV2;
