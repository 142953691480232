import { FunctionComponent } from "react";
import { PurchaseOrderProvider } from "../contexts/po-creation-context";
import PurchaseOrderCreateScreen from "./purchase_order_create_screen";
import PurchaseOrderUpdateScreen from "./purchase_order_update_screen";
import PurchaseOrderDetailScreen from "./purchase_order_detail_screen";
import PurchaseOrderApprovalScreen from "../purchase_order_approval/purchase_order_approval_screen";
import { PurchaseOrderModelV2 } from "../model/purchase_order_model";
import PurchaseOrderReorderScreen from "./purchase_order_reorder";

interface POCreatePageProps {}

export const POCreatePageWrapper: FunctionComponent<POCreatePageProps> = () => {
  return (
    <>
      <PurchaseOrderProvider>
        <PurchaseOrderCreateScreen />
      </PurchaseOrderProvider>
    </>
  );
};

interface POUpdatePageProps {}

export const POUpdatePageWrapper: FunctionComponent<POUpdatePageProps> = () => {
  return (
    <>
      <PurchaseOrderProvider>
        <PurchaseOrderUpdateScreen />
      </PurchaseOrderProvider>
    </>
  );
};

interface PODetailPageProps {}

export const PODetailPageWrapper: FunctionComponent<PODetailPageProps> = () => {
  return (
    <>
      <PurchaseOrderProvider>
        <PurchaseOrderDetailScreen />
      </PurchaseOrderProvider>
    </>
  );
};

interface POApprovePageProps {}

export const POApprovePageWrapper: FunctionComponent<
  POApprovePageProps
> = () => {
  return (
    <>
      <PurchaseOrderProvider>
        <PurchaseOrderApprovalScreen />
      </PurchaseOrderProvider>
    </>
  );
};

interface ReorderPageProps {
  purchaseOrder: PurchaseOrderModelV2;
}

export const PurchaseOrderReorderPageWrapper: FunctionComponent<
  ReorderPageProps
> = ({ purchaseOrder }) => {
  return (
    <PurchaseOrderProvider>
      <PurchaseOrderReorderScreen />
    </PurchaseOrderProvider>
  );
};
