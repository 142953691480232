import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
// import { useLocation } from 'react-router-dom';
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import {
  fetchGet,
  fetchPut,
  fetchPost,
  getHeaders,
} from "../../../../../service/api_client";
import { UTCToLocalDate } from "../../../../../utils/date_util";
import WarehouseSearchField from "../../../warehouse/warehouse_search_field";
import axios from "axios";
import { useGoogleLogout } from "react-google-login";
import EmployeeSearchWidget from "../../../../../ui/new_components/emp_search_widget";
import { urls } from "../../../../../utils/urls";
import {
  StockItemMovementModel,
  stockJournalDefaultValue,
  StockJournalModel,
  VoucherStatus,
} from "../../../models/stock_transfer_model";
import ItemRowStockTransfer from "./ItemRowStockTransfer";

interface StockJournalProps {}

// interface LocationState {
//   id: string; // or whatever type the ID is
// }

const StockJournalCreate: FunctionComponent<StockJournalProps> = () => {
  const { id } = useParams<{ id: string }>();
  // const location = useLocation<LocationState>();
  // const id1 = location.state?.id; //
  // console.log("Fetched ID from params:", id1); // Log the ID
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/journal/sender_transfer`;
  const [, setLoading] = useState(false);
  const [editable] = useState(false);
  const [addNewId1, setAddNewId1] = useState(Math.random() * 1000);
  const [addNewTaxId1, setAddNewTaxId1] = useState(Math.random() * 1000);
  const [addNewTaxId, setAddNewTaxId] = useState<number>();
  const [isButtonClicked, setIsButtonClicked] = useState(true);
  const { showToast } = useToast();
  const google_props = {
    clientId:
      "225306128297-9b0pj2cq68voiqk87qpod3fpuevghoje.apps.googleusercontent.com",
  };
  const { signOut } = useGoogleLogout(google_props);
  const [data, setData] = useState<StockJournalModel>({
    ...stockJournalDefaultValue,
  });
  const existingItems = data.source || [];
  const [isWithdrawn, setIsWithdrawn] = useState<boolean>(false);
  const navigate = useHistory();
  // const [ShowHistory, setShowHistory] = useState<{ [key: string]: any }>();
  // const handleOptionChange = (value: WarehouseModel["type"]) => {
  //   setData((pv) => ({ ...pv, type: value }));
  // };
  const locate = useLocation();
  // const isCreatePath = locate.pathname.endsWith("/create");

  useEffect(() => {
    if (id) getVoucher(id);
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // useEffect(() => {
  //   if (id1) getDuplicateVoucher(id1);
  //   getUserData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id1]);

  const getVoucher = async (id: string) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + "/" + id);
      if (res.success) {
        setData((prevData) => ({
          ...prevData,
          ...res.data,
          user: prevData.user,
        }));
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({ type: ToastType.error, text: "Failed to fetch voucher" });
    }
    setLoading(false);
  };

  // const getDuplicateVoucher = async (id: string) => {
  //   setLoading(true);
  //   try {
  //     const res = await fetchGet(url + "/" + id);
  //     if (res.success) {
  //       // Update the source items' unit_no to 0 and warehouse to an empty object
  //       const updatedSource = res.data.source.map((item: any) => ({
  //         ...item,
  //         unit_no: 0,
  //         warehouse: {
  //           _id: "", // or null, depending on your preference
  //           id: null,
  //           name: "",
  //         }, // Set warehouse to an empty structure
  //       }));

  //       // Set the data with updated source items
  //       setData((prevData) => ({
  //         ...prevData,
  //         ...res.data,
  //         date: new Date(),
  //         source: updatedSource, // Use the updated source here
  //         destination: [],
  //         destination_tax: 0,
  //         destination_taxes: [],
  //         disposal_ids: [],
  //         destination_ids: [],
  //         destination_tax_ids: [],
  //         destination_sub_total: 0,
  //         destination_total: 0,
  //         disposalItems: [],
  //         user: prevData.user,
  //       }));
  //     } else {
  //       showToast({ type: ToastType.error, text: res.error });
  //     }
  //   } catch (error) {
  //     showToast({ type: ToastType.error, text: "Failed to fetch voucher" });
  //   }
  //   setLoading(false);
  // };

  async function getUserData() {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/auth/data`;
      const res = (
        await axios.get(url, {
          withCredentials: true,
          headers: { ...getHeaders() },
        })
      ).data;

      if (res.success) {
        setData((prevData) => ({
          ...prevData,
          user: res.data || prevData.user, // Ensure existing data is preserved
        }));
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      const { response } = error;
      if (response && response.status === 401) {
        signOut();
        window.localStorage.removeItem("token");
      }
    }
    setLoading(false);
  }

  const submit = async () => {
    if (!data.source?.length)
      return showToast({
        type: ToastType.warning,
        text: "Please add Items!!!!",
      });
    setIsButtonClicked(false);
    setLoading(true);
    // console.log("ID in submit", id);
    const res = id
      ? await fetchPut(url + "/" + id, data)
      : await fetchPost(url, data);
    if (res.success) {
      data.status = VoucherStatus.intransit;
      setIsButtonClicked(false);
      showToast({ type: ToastType.success, text: res.message });
      navigate.push(urls.stock_transfer);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  // const withdraw = async () => {
  //   if (!id) return;

  //   setLoading(true);

  //   try {
  //     data.status = VoucherStatus.return;
  //     const res = await fetchPut(url + "/" + id, data);
  //     if (res.success) {
  //       setIsWithdrawn(true); // Update state to reflect withdrawal
  //       showToast({
  //         type: ToastType.success,
  //         text: "Voucher withdrawn successfully",
  //       });
  //       navigate.push(urls.stock_transfer);
  //     } else {
  //       showToast({ type: ToastType.error, text: res.error });
  //     }
  //   } catch (error) {
  //     showToast({ type: ToastType.error, text: "Failed to withdraw voucher" });
  //   }
  //   setLoading(false);
  // };

  return (
    <>
      <div className="sticky top-0 mt-2 px-6 h-full">
        <div className="flex items-center justify-between mb-2">
          <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
            Stock Transfer
          </div>
        </div>
        <div className="bg-white rounded mb-5 flex gap-1 h-auto overflow-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 p-5 text-sm w-3/4 font-semibold rounded-t top-0">
            <div className="">
              <div className="font-bold text-md mb-3">From</div>
              <div className="">
                <div className="">
                  <div className="font-bold">Sender Name</div>
                  <input
                    value={`${data.user?.first_name || ""} ${
                      data.user?.last_name || ""
                    }`}
                    placeholder="Sender"
                    type="text"
                    disabled={!editable}
                    className="text-left p-1 focus:outline-none w-full"
                    onChange={(e) => {
                      setData((prevData) => ({
                        ...prevData,
                        user: {
                          ...prevData.user!,
                          first_name: e.target.value,
                          last_name: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>

                <div className="font-bold">Sender Department</div>
                <input
                  value={data.user?.emp_profile?.department}
                  placeholder="Department"
                  disabled={!editable}
                  type="text"
                  className="text-left p-1 focus:outline-none w-full"
                  onChange={(e) =>
                    setData((d) => ({
                      ...d,
                      department: d.user?.emp_profile?.department,
                    }))
                  }
                />
              </div>
              <div className="font-bold">From Warehouse *</div>
              <WarehouseSearchField
                type={""}
                disabled={editable}
                value={data.from_warehouse}
                placeholder="Inventory Basket"
                onSelect={(d) => {
                  setData((old) => {
                    return {
                      ...old,
                      from_warehouse: d,
                    };
                  });
                }}
              />
              <div className="">
                <label htmlFor="" className="text-sm font-bold text-gray-500">
                  Shipment Date
                </label>{" "}
                <input
                  type="date"
                  className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
                  value={
                    data.shipment_date
                      ? moment(data.shipment_date).format("YYYY-MM-DD")
                      : ""
                  }
                  // min={moment().format("YYYY-MM-DD")}
                  onChange={(e) => {
                    const date = UTCToLocalDate(e.target.value)!;
                    setData((o) => ({
                      ...o,
                      shipment_date: date,
                    }));
                  }}
                />
              </div>
              <div className="">
                <div className="font-bold">Delivery Challan Number/Invoice</div>
                <input
                  value={data.delivery_challan_number}
                  name="challan"
                  id="challan"
                  placeholder="Delivery Challan"
                  type="text"
                  className="text-left p-1 focus:outline-none border rounded w-full"
                  onChange={(e) =>
                    setData((d) => ({
                      ...d,
                      delivery_challan_number: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="mr-0">
              <div className="font-bold text-md mb-3">To</div>

              <div className="font-bold">Warehouse/office *</div>
              <WarehouseSearchField
                type={""}
                disabled={editable}
                value={data.receiver_warehouse}
                placeholder="Inventory Basket"
                onSelect={(d) => {
                  setData((old) => {
                    return {
                      ...old,
                      receiver_warehouse: d,
                      //   department:d.spoc?.department,
                      //   contact_name:d.spoc?.contact_name
                    };
                  });
                }}
              />
              <div className="font-bold">Receiver</div>
              <input
                value={data.receiver_warehouse?.spoc?.contact_name}
                placeholder=""
                type="text"
                className="text-left bg-gray-200 p-1 focus:outline-none border rounded w-full"
                onChange={(e) => {
                  setData((prevData) => ({
                    ...prevData,
                    receiver_warehouse: {
                      ...prevData.receiver_warehouse!,
                      contact_name: e.target.value,
                    },
                  }));
                }}
                readOnly
              />

              <div className="flex flex-col items-start text-sm w-full">
                <label className="font-semibold w-full" htmlFor="line1">
                  Receiver Department
                </label>
                <input
                  placeholder="dept"
                  className="p-1 rounded focus:outline-none w-full border"
                  id="dept"
                  name="dept"
                  value={data.receiver_warehouse?.spoc?.department}
                  onChange={(e) =>
                    setData((prevData) => ({
                      ...prevData,
                      receiver_warehouse: {
                        ...prevData.receiver_warehouse!,
                        department: e.target.value,
                      },
                    }))
                  }
                />
              </div>
              <div className="flex flex-col items-start text-sm w-full">
                <label className="font-semibold w-full" htmlFor="line1">
                  Tracking ID
                </label>
                <input
                  placeholder="track id"
                  className="p-1 rounded focus:outline-none w-full border"
                  id="track_id"
                  name="track_id"
                  value={data.tracking_id}
                  onChange={(e) =>
                    setData((prevData) => ({
                      ...prevData,
                      tracking_id: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <label htmlFor="" className="text-sm font-bold text-gray-500">
                  Expected Received Date
                </label>{" "}
                <input
                  type="date"
                  className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
                  value={
                    data?.expected_received_date
                      ? moment(data?.expected_received_date).format(
                          "YYYY-MM-DD"
                        ) // Format to YYYY-MM-DD for input
                      : "" // Empty by default, so no date is selected initially
                  }
                  min={moment().format("YYYY-MM-DD")} // Prevent selection of past dates
                  onChange={(e) => {
                    const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                    const date = selectedDate
                      ? new Date(new Date(selectedDate).setHours(0, 0, 0, 0)) // Set the time to 00:00:00
                      : undefined; // If no date is selected, reset the value

                    // Update the state with the selected date
                    setData((pv) => ({
                      ...pv,
                      expected_received_date: date!, // Save the selected date in state
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-2 mt-5 ">
          <div className="flex flex-col gap-5">
            <div className="">
              <h3 className="text-sm font-bold">Particulars</h3>
              <div className="bg-white rounded flex flex-col gap-1 h-auto overflow-auto pb-20">
                <div className="grid grid-cols-7 gap-1 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t sticky top-0">
                  <div className="text-left">Item Code</div>
                  <div className="text-left col-span-2">Item Name</div>
                  <div className="text-left ">Unit Measure</div>
                  {/* <div className="text-left">From Warehouse</div> */}
                  <div className="text-left">Qty</div>
                  <div className="text-left">Unique IDs</div>
                  <div className="text-center">Action</div>
                </div>
                <div>
                  {data.source?.map((comp, i) => {
                    return (
                      <ItemRowStockTransfer
                        component={comp}
                        from_warehouse={data.from_warehouse}
                        edit={false}
                        disabled={false}
                        key={comp.id}
                        onDelete={(d: any) => {
                          setData((o) => {
                            const source = [
                              ...(o.source || []).filter(
                                (it) => it.id !== d.id
                              ),
                            ];

                            return {
                              ...o,
                              source,
                            };
                          });
                        }}
                        onSubmit={(d: any) => {
                          setData((o) => {
                            const source = [
                              ...(o.source || []).map((it) =>
                                it.id === d.id ? d : it
                              ),
                            ];

                            return {
                              ...o,
                              source,
                            };
                          });
                        }}
                        existingItems={existingItems}
                      />
                    );
                  })}

                  {!data.id && (
                    <ItemRowStockTransfer
                      key={addNewId1}
                      from_warehouse={data?.from_warehouse}
                      onSubmit={function (data: StockItemMovementModel): void {
                        data.id = (Math.random() * 1000).toString();
                        setData((o) => {
                          const source = [...(o.source || []), data];

                          return {
                            ...o,
                            source,
                          };
                        });
                        setAddNewId1(Math.random() * 1000);
                      }}
                      // index={i}
                      edit={true}
                      disabled={false}
                      existingItems={existingItems}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex sticky bottom-0 justify-end my-5 gap-4 px-5 py-5">
        {/* {id && !data.destination?.length ? (
            <button
              type="button"
              className="bg-red-500 px-10 py-1 rounded-md text-white text-sm"
              onClick={withdraw}
              disabled={isWithdrawn} // Disable if already withdrawn
            >
              Withdraw
            </button>
          ) : null}
          {id &&
            !(data.status === voucherStatus.return) &&
            !data.destination?.length && (
              <button
                type="button"
                className="bg-myPrimaryColor px-10 py-1 rounded-md text-white text-sm"
                onClick={submit}
                disabled={isWithdrawn} // Disable if already withdrawn
              >
                Resend Mail
              </button>
            )} */}

        {/* {!id && isButtonClicked && ( */}

        {!id && (
          <button
            type="button"
            className="px-10 py-1 rounded-md bg-myPrimaryColor text-white text-sm"
            onClick={submit}
            // disabled={isWithdrawn} // Disable submit if withdrawn or no items
          >
            Send
          </button>
        )}
        {id &&
          !data.destination?.length &&
          !(data.status === VoucherStatus.received) && (
            <button
              type="button"
              className="px-10 py-1 rounded-md bg-myPrimaryColor text-white text-sm"
              onClick={submit}
              // disabled={isWithdrawn} // Disable submit if withdrawn or no items
            >
              Update
            </button>
          )}
        <button
          type="button"
          className="px-10 py-1 rounded-md bg-myPrimaryColor text-white text-sm"
          onClick={() => navigate.push(urls.stock_transfer)}
        >
          Quit
        </button>
      </div>
      {/* )} */}
    </>
  );
};

export default StockJournalCreate;
