import debounce from "lodash.debounce";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import AutoCompleteField from "../../../new_components/common/autocomplete";
import { InventoryModel } from "../../../../service/models/inventory/inventory_model";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { GetAvailableDevicesAPI } from "../../../../service/repos/order_repo";
import { AvailableDevices } from "../../../../erp/inventory/models/inventory_voucher";

interface InventorySearchFieldProps {
  status?: string[];
  item_code: string;
  onSelect: (data: AvailableDevices) => void;
  disabled?: boolean;
  placeholder?: string;
  value?: { device_id: string; name: string };
}

const InventorySearchFieldV2: FunctionComponent<InventorySearchFieldProps> = (
  props
) => {
  const { showToast } = useToast();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [addNew, setAddNew] = useState(false);

  const [selectedDevice, setSelectedDevice] = useState<AvailableDevices>();

  const [inventories, setInventories] = useState<AvailableDevices[]>();

  const searchInventory = async (search: string) => {
    console.log("Search", props.item_code);

    setLoading(true);

    const res = await GetAvailableDevicesAPI({
      unique_id: search,
      item_code: props.item_code,
    });

    if (res.success) {
      setInventories(res.data);
    } else {
      setInventories([]);
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce((query) => {
      if (query) {
        console.log("Just vefore debounce");
        searchInventory(query);
        // debounce(() => searchInventory(query));
      } else setInventories([]);
    }, 500),
    []
  );

  useEffect(() => {
    if (search && edit) {
      if (props.value && props.value.device_id === search) return;
      debouncedHandler(search);
    }
  }, [search]);
  useEffect(() => {
    setSearch(props.value?.device_id ?? "");
  }, [props.value]);

  return (
    <div className="flex gap-1 items-center    w-full">
      {edit ? (
        <AutoCompleteField
          loading={loading}
          value={search}
          placeholder={props.placeholder ?? "Select a device "}
          onSelect={(val) => {
            setEdit(false);
            setSearch(val.value.uniqueIds.unique_id ?? "--");
            props.onSelect(val.value);
          }}
          suggestions={inventories?.map((d) => ({
            label: (
              <div className="text-xs">
                <b>
                  {d.item?.name}({d.item?.item_code}){" "}
                </b>
              </div>
            ),

            value: d,
          }))}
          onChange={setSearch}
        />
      ) : (
        <div
          className={`text-sm px-3 py-2  h-8 rounded focus:outline-none w-full border  overflow-hidden overflow-ellipsis${
            search ? "" : " text-gray-400 "
          }`}
          onClick={() => {
            if (!props.disabled) {
              setEdit(true);
              // setSearch(emp?.name ?? "");
            }
          }}
        >
          {search || props.placeholder || "Select a device "}
        </div>
      )}
    </div>
  );
};

export default InventorySearchFieldV2;
