import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import { debounce } from "lodash";
import {
  mdiArrowBottomLeft,
  mdiArrowTopRight,
  mdiClose,
  mdiMenuDown,
  mdiMenuUp,
  mdiPlus,
  mdiRefresh,
} from "@mdi/js";
import moment from "moment";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  toQueryString,
} from "../../../../service/api_client";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../../procurement/components/create_view/form_field";
import LoadingWidget from "../../../../context_providers/modal/loader";
import SearchFieldWidget from "../../../../ui/new_components/search_field";
import { UTCToLocalDate } from "../../../../utils/date_util";
import { urls } from "../../../../utils/urls";
import ModalDialog from "../../../../ui/new_components/common/modal";
import FileDropZone from "../../../../ui/new_components/common/file_drop_zone";
import { parse } from "papaparse";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../../../ui/new_components/common/drawer_modal";
import {
  StockJournalModel,
  VoucherStatus,
} from "../../models/stock_transfer_model";
import TableView from "../../../../procurement/components/table_comp";
import FilterDropDown from "../../../../ui/new_components/common/filter_drop_down";
import Pagination from "../../../../ui/new_components/common/pagination";
import { AttachmentModel } from "../../../purchase_order/model/purchase_order_model";
import DrawerWidget from "../../../../context_providers/modal/drawer_widget";
import {
  ModalAlignment,
  ModalType,
} from "../../../../context_providers/modal/modal_context";
import AttachmentBar from "../../../purchase_order/payment_request/components/attachmentBar";

interface StockTransferJournalListScreenProps {
  records: StockJournalModel[];
  onClick: (id: string) => void;
  sort_by: string;
  sort_order: number;
  pages: number;
  options: []; // Replace with actual type
  handleSort: (data: { sort: string; sort_order: number }) => void;
  handlePageChange: (page: number) => void;
  // handleStatusFilterChange: (selectedOptions: string[]) => void;
}

interface StockListFilter {
  stock_transfer_id?: string;
  from_warehouse?: string;
  to_warehouse?: string;
  sender_name?: string;
  receiver_name?: string;
  sender_dept?: string;
  received_date?: Date;
  receiver_dept?: string;
  status?: string[];
  search?: string;
  page: number;
  count: number;
  sort_order: string;
  all?: any;
  fromDate?: Date;
  toDate?: Date;
}

const defaultFilter = (): StockListFilter => {
  // const now = new Date();
  // const start_date = new Date(new Date(now).setDate(now.getDate() - 30));
  // const end_date = now;
  return {
    page: 1,
    count: 100,
    sort_order: "desc",
    // fromDate: start_date,
    // toDate: end_date,
  };
};

const SenderStockTransferJournalListScreen: FunctionComponent<
  StockTransferJournalListScreenProps
> = () => {
  const navigation = useHistory();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/journal/sender_transfer`;
  const { showToast } = useToast();
  const [page, setPage] = useState(1);
  const [count] = useState(50);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<StockJournalModel[]>([]);
  // const [fromDate, setFromDate] = useState(new Date());
  // const [toDate, setToDate] = useState(new Date());
  const [filter, setFilter] = useState<StockListFilter>(defaultFilter());
  const [showImport, setShowImport] = useState(false);
  const [showAttachment, setShowAttachment] = useState<StockJournalModel>();
  const [attachmentKey, setAttachmentKey] = useState(Math.random() * 10000);
  const [selectedItems, setSelectedItems] = useState<StockJournalModel[]>([]);
  const [showExport, setShowExport] = useState(false);
  const [ShowHistory, setShowHistory] = useState<any>();
  const [filterCollaped, setFilterCollapsed] = useState(true);

  // useEffect(() => {
  //   const now = new Date();
  //   let from = new Date(new Date(now).setHours(0, 0, 0, 0));
  //   from = new Date(from.setDate(1));
  //   let to = new Date(new Date(now).setHours(23, 59, 59, 999));
  //   to = new Date(to.setMonth(now.getMonth() + 1));
  //   to = new Date(to.setDate(0));
  //   setFromDate(from);
  //   setToDate(to);
  //   fetchData({ ...filter });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // Format ISO date to YYYY-MM-DD
  const formatISODate = (isoDateString: any): any => {
    const dateObject = new Date(isoDateString);
    return dateObject.toISOString().slice(0, 10);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandler = useCallback(
    debounce((prop: StockListFilter) => {
      if (prop) fetchData(prop);
      else setData([]);
    }, 300),
    []
  );
  useEffect(() => {
    debouncedHandler(filter);
  }, [filter, debouncedHandler]);
  console.log("filter:", filter);

  const fetchData = async (prop: StockListFilter) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    } finally {
      setLoading(false);
    }
  };
  const onSelectAttchment = (d: AttachmentModel) => {
    window.open(d.url, "_blank");
  };

  const onSubmit_Bulk = async (data: StockJournalModel[]) => {
    setLoading(true);

    try {
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/journal/erp/bulk_upload`;
      const res = await fetchPost(url, data);

      return res;
    } catch (error: any) {
      return { success: false, error: error.message };
    }
  };

  const ExportData = async () => {
    try {
      const a = document.createElement("a");
      a.target = "_blank";
      a.href = `${
        process.env.REACT_APP_BACKEND_BASE_URL
      }/api/v3/erp/inventory/journal/sender_transfer/export${toQueryString({
        query: JSON.stringify({
          ...filter,
        }),
      })}`;

      // Delay the trigger to allow UI updates (if necessary)
      setTimeout(() => {
        setLoading(false);
        showToast({ type: ToastType.success, text: "Download started!" });
        a.click(); // Trigger the download
      }, 1000);
    } catch (error) {
      setLoading(false); // Make sure loading is set to false in case of an error
      showToast({ type: ToastType.error, text: "Failed to start download." });
      console.error("Download error:", error);
    }
  };

  const handleOnEdit = () => {
    if (selectedItems.length > 1) {
      showToast({
        type: ToastType.error,
        text: "Please select one item to edit",
      });
    } else if (selectedItems.length === 0) {
      showToast({
        type: ToastType.error,
        text: "Please select an item to edit",
      });
    } else {
      navigation.push(urls.stock_transfer + "/update/" + selectedItems[0]!.id);
    }
  };

  const onSelect = (d: StockJournalModel) => {
    // if (d.status !== "Returned") {
    //   navigation.push(urls.stock_transfer_voucher + "/update/" + d.id);
    // } else {
    //   showToast({
    //     type: ToastType.error,
    //     text: "Return Voucher can't be editable.",
    //   });
    // }
    navigation.push(urls.stock_transfer + "/sender/view/" + d.id);
  };

  const onDelete = async (d: StockJournalModel) => {
    const promptText = window.prompt("Type 'delete' to confirm.");
    if (!promptText || promptText.trim() !== "delete") {
      return;
    }
    setLoading(true);
    const res = await fetchDelete(url + "/" + d.id);
    if (res.success) {
      await fetchData({ ...filter });
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  return (
    <>
      {showAttachment && (
        <DrawerWidget
          data={{
            id: 1,
            title: "Attchments",
            type: ModalType.drawer,
            alignment: ModalAlignment.right,
            container: (
              <AttachmentBar
                key={attachmentKey}
                attachments={showAttachment.attachments}
                // onSubmit={uploadFile}
                // onDelete={deleteFile}
                onSelect={onSelectAttchment}
              />
            ),
          }}
          onClose={function (): void {
            setShowAttachment(undefined);
          }}
        ></DrawerWidget>
      )}
      {ShowHistory && (
        <DrawerModal
          location={DrawerModalLoaction.right}
          show={ShowHistory !== undefined}
          onClose={() => setShowHistory(undefined)}
          title="History"
        >
          {/* {console.log("id of order", ShowHistory)} */}
          {/* <ActionHistory
            voucher_id={ShowHistory}
            // show={ShowHistory !== undefined}
            // onClose={(v) => setShowHistory(undefined)}
          /> */}
        </DrawerModal>
      )}
      {/* {showImport && (
        <ModalDialog
          show={showImport}
          onClose={() => setShowImport(false)}
          title="Import Inventory"
        >
          <div className="text-xs w-96">
            <h2 className="font-semibold">Please note :</h2>
            <p>sheet should have header as</p>
            <p className="font-semibold text-purple-500">
              device_id,product_id,product_name,warehouse_id,mobile_number,
              module_imei,sim_imsi,plan_name,sim_no,sim_status,
              status,responsible_email
            </p>
            <p>In the first row of csv.</p>
          </div>
          <div className="h-96 w-96 mt-5">
            <FileDropZone
              accept=".csv"
              onDrop={(files) => {
                parse<StockJournalModel>(files[0], {
                  transformHeader(header, index) {
                    return header.trim();
                  },
                  header: true,
                  skipEmptyLines: true,
                  complete: (data) => {
                    const res = data.data;
                    // console.log({ res });

                    if (res.length === 0) {
                      showToast({
                        type: ToastType.warning,
                        text: "No record found !",
                      });
                      return;
                    }
                    const keys = Object.keys(res[0]);
                    if (
                      keys.indexOf("device_id") === -1 ||
                      keys.indexOf("product_id") === -1 ||
                      keys.indexOf("warehouse_id") === -1
                    ) {
                      showToast({
                        type: ToastType.warning,
                        text: "Required fields are [device_id,product_id,product_name,warehouse_id]",
                      });
                      return;
                    }
                    const filteredData: StockJournalModel[] = [];
                    for (let index = 0; index < res.length; index++) {
                      const {
                        receiver,
                        delivery_challan_number,
                        user,
                        id,
                        source,
                      } = res[index];
                      if (
                        !id &&
                        !source &&
                        !user &&
                        !delivery_challan_number &&
                        !receiver
                      )
                        continue;
                      if (
                        !id &&
                        !source &&
                        !user &&
                        !delivery_challan_number &&
                        !receiver
                      ) {
                        console.log({
                          id,
                          source,
                          user,
                          delivery_challan_number,
                          receiver,
                        });

                        showToast({
                          type: ToastType.warning,
                          text:
                            `Either Device id, product_id, warehouse_id, responsible_email missing at row ` +
                            (index + 2),
                        });
                        return;
                      }

                      filteredData.push({
                        id,
                        voucher_type: "",
                        voucher_no: "",
                        source_taxes: [],
                        source_tax_ids: [],
                        source_tax: 0,
                        destination_taxes: [],
                        destination_tax_ids: [],
                        destination_tax: 0,
                        source_sub_total: 0,
                        destination_sub_total: 0,
                        source_total: 0,
                        destination_total: 0,
                        delivery_challan_number: "",
                        status: "",
                      });
                    }

                    // Bulk upload
                    setShowImport(false);
                    onSubmit_Bulk(filteredData);
                  },
                });
              }}
            />
          </div>
        </ModalDialog>
      )} */}
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section
          id="top_section"
          className="flex justify-between items-end my-1"
        >
          <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
            Stock transfer journal
            <button
              title="Refersh"
              onClick={() => debouncedHandler({ ...filter })}
            >
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>

          <div className="flex flex-wrap text-sm items-center gap-2 ">
            <div className="text-sm flex flex-wrap items-center gap-2 m-3">
              <div className="flex items-center  gap-1">
                <label htmlFor="fromDate" className="">
                  From
                </label>
                <input
                  type="date"
                  id="fromDate"
                  className="p-1 rounded focus:outline-none"
                  value={
                    filter.fromDate
                      ? moment(filter.fromDate).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const selectedDate = UTCToLocalDate(e.target.value);
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      fromDate: selectedDate,
                    }));
                  }}
                />
              </div>
              <div className="flex items-center gap-1">
                <label htmlFor="toDate" className="">
                  To
                </label>
                <input
                  type="date"
                  id="toDate"
                  className="p-1 rounded focus:outline-none "
                  value={
                    filter.toDate
                      ? moment(filter.toDate).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const selectedDate = UTCToLocalDate(e.target.value);
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      toDate: selectedDate,
                    }));
                  }}
                />
                {(filter.fromDate || filter.toDate) && (
                  <button
                    title="Clear dates"
                    onClick={() =>
                      setFilter((prevFilter) => ({
                        ...prevFilter,
                        fromDate: undefined,
                        toDate: undefined,
                      }))
                    }
                    className="flex gap-1 items-center cursor-pointer rounded p-1 border r hover:scale-105 duration-200     transform"
                  >
                    <Icon path={mdiClose} size={0.7}></Icon>
                  </button>
                )}
              </div>
            </div>
            <div
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
              onClick={() => navigation.push(urls.stock_transfer + "/create")}
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>Create
            </div>
            <div
              onClick={handleOnEdit}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200 text-white transform"
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>Update
            </div>
            <div>
              <button
                onClick={() => ExportData()}
                className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white flex gap-2 items-center"
              >
                <Icon path={mdiArrowTopRight} size="15"></Icon> Export
              </button>
            </div>

            <div className="flex gap-2">
              <h6 className="font-normal">Filters</h6>
              <div
                onClick={() => setFilterCollapsed(!filterCollaped)}
                className="flex   items-center justify-center bg-gray-200 p-1 rounded-full hover:bg-gray-800 hover:text-white"
              >
                <Icon
                  path={filterCollaped ? mdiMenuDown : mdiMenuUp}
                  className="w-4 h-4"
                />
              </div>
            </div>
          </div>
        </section>{" "}
        {!filterCollaped && (
          <div className="grid grid-cols-2 gap-6 w-1/2 m-2">
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">
                Stock Transfer ID
              </label>
              <SearchFieldWidget
                defaulValue={filter?.stock_transfer_id}
                onClear={() => {
                  setFilter({
                    ...filter,
                    stock_transfer_id: undefined, // Fixed indentation
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    stock_transfer_id: val, // Fixed indentation
                  });
                  setPage(1);
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">
                From Warehouse
              </label>
              <SearchFieldWidget
                defaulValue={filter?.from_warehouse}
                onClear={() => {
                  setFilter({
                    ...filter,
                    from_warehouse: undefined, // Fixed indentation
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    from_warehouse: val, // Fixed indentation
                  });
                  setPage(1);
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">To Warehouse</label>
              <SearchFieldWidget
                defaulValue={filter?.to_warehouse}
                onClear={() => {
                  setFilter({
                    ...filter,
                    to_warehouse: undefined, // Fixed indentation
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    to_warehouse: val, // Fixed indentation
                  });
                  setPage(1);
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">Sender Name</label>
              <SearchFieldWidget
                defaulValue={filter?.sender_name}
                onClear={() => {
                  setFilter({
                    ...filter,
                    sender_name: undefined, // Fixed indentation
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    sender_name: val, // Fixed indentation
                  });
                  setPage(1);
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">
                Sender Department
              </label>
              <SearchFieldWidget
                defaulValue={filter?.sender_dept}
                onClear={() => {
                  setFilter({
                    ...filter,
                    sender_dept: undefined, // Fixed indentation
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    sender_dept: val, // Fixed indentation
                  });
                  setPage(1);
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">
                Receiver Name
              </label>
              <SearchFieldWidget
                defaulValue={filter?.receiver_name}
                onClear={() => {
                  setFilter({
                    ...filter,
                    receiver_name: undefined, // Fixed indentation
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    receiver_name: val, // Fixed indentation
                  });
                  setPage(1);
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold">
                Receiver Department
              </label>
              <SearchFieldWidget
                defaulValue={filter?.receiver_dept}
                onClear={() => {
                  setFilter({
                    ...filter,
                    receiver_dept: undefined, // Fixed indentation
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    receiver_dept: val, // Fixed indentation
                  });
                  setPage(1);
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-md pt-1 font-semibold"> Status</label>
              <FilterDropDown
                placeholder="Status"
                onClearAll={() =>
                  setFilter({
                    ...filter,
                    status: undefined, // Fixed indentation
                  })
                }
                onDelete={(type) => {
                  setFilter({
                    ...filter,
                    status: filter?.status?.filter((f: any) => f !== type),
                  });
                }}
                onSelect={(type) => {
                  setFilter((old) => ({
                    ...old,
                    status: [...(old?.status ?? []), type],
                  }));
                }}
                selectedOptions={filter?.status ?? []}
                options={Object.values(VoucherStatus).map((t) => ({
                  label: t,
                  value: t,
                }))}
              />
            </div>
          </div>
        )}
        <div className="my-2 border overflow-auto" style={{ height: "80%" }}>
          <table className="w-full     text-xs divide-y divide-gray-200 relative ">
            <thead className="  text-xs   py-2 sticky -top-1 bg-white z-10">
              <tr className="border ">
                <th>
                  <div className="">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      checked={
                        selectedItems.length === data.length ? true : false
                      }
                      // disabled={checker && !maker ? false : true}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedItems([...data]);
                        } else {
                          setSelectedItems([]);
                        }
                        console.log(e);
                      }}
                    />
                  </div>
                </th>
                {/* <th className="px-2 py-2">Actions</th> */}
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Stock transfer ID
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Created Date
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Shipment Date
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  DC No./Inv No.
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Tracking ID
                </th>
                {/* <th className="px-6 py-3 whitespace-nowrap text-left">
                  Received Date
                </th> */}
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Sender Name
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Sender Dept
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  From Warehouse
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  To Warehouse
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Receiver Name
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Receiver Dept
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Status
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Attachments
                </th>
              </tr>
            </thead>
            <tbody className="">
              {data?.length > 0 ? (
                data.map((data, i) => {
                  const isSelected = selectedItems.includes(data);
                  return (
                    <tr className="border text-center " key={i}>
                      <td>
                        <div className="p-2">
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            checked={isSelected}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedItems((prev) => [...prev, data]); // Add the item to selected items
                              } else {
                                setSelectedItems((prev) =>
                                  prev.filter(
                                    (selectedItem) => selectedItem !== data
                                  )
                                ); // Remove the item from selected items
                              }
                            }}
                          />
                        </div>
                      </td>
                      {/* <td>
                                  <div className="flex gap-2 justify-center">
                                    {" "}
                                    <div
                                      className="cursor-pointer"
                                      onClick={() => {
                                        setShowHistory(cost.id);
                                      }}
                                    >
                                      <Icon path={mdiHistory} size="16"></Icon>
                                    </div>
                                  </div>
                                </td> */}
                      <div
                        className="py-3 items-center cursor-pointer text-blue-600 hover:underline"
                        role="button"
                        onClick={() => onSelect(data)}
                      >
                        <td className="px-6 py-3 whitespace-nowrap text-left">
                          {data.id}
                        </td>
                      </div>

                      <td className="px-6 py-3 whitespace-nowrap text-left">
                        {moment(data.created_at).format("DD-MM-YYYY")}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap  text-left">
                        {moment(data.shipment_date).format("DD-MM-YYYY")}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-left">
                        {data.delivery_challan_number}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-left">
                        {data.tracking_id}
                      </td>
                      {/* <td className="px-6 py-3 whitespace-nowrap  text-left">
                        {moment(data.expected_received_date).format(
                          "DD-MM-YYYY"
                        )}
                      </td> */}
                      <td className="px-6 py-3 whitespace-nowrap text-left">
                        {`${data.user?.first_name ?? ""} ${
                          data.user?.last_name ?? ""
                        }`.trim()}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-left">
                        {data.user?.emp_profile?.department}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-left">
                        {data.from_warehouse?.name}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-left">
                        {data.receiver_warehouse?.name}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-left">
                        {data.receiver_warehouse?.spoc?.contact_name}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-left">
                        {data.receiver_warehouse?.spoc?.department}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-left">
                        {data.status ?? " "}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-left">
                        <button
                          onClick={() => setShowAttachment(data)}
                          className="relative text-sm font-semibold text-blue-400 hover:text-blue-500 whitespace-nowrap   flex items-center gap-1 px-2 h-full w-full"
                        >
                          View
                          <div>
                            (
                            {data?.attachments?.length
                              ? data?.attachments?.length
                              : 0}
                            )
                          </div>
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={13}>
                    <div className="h-56 text-center p-5 my-20">
                      <h5 className="text-gray-400 text-2xl">No Data found!</h5>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-3">
          <Pagination
            itemCount={count}
            page={filter.page}
            total={total}
            count={filter.count}
            onChange={(page) => setFilter((o) => ({ ...o, page }))}
          />
        </div>
        {/* <TableView
          headers={[
            {
              key: "id",
              title: "Stock transfer ID",
              type: FieldType.string,
            },
            {
              key: "date",
              title: "Created Date",
              type: FieldType.date,
            },
            {
              key: "shipment_date",
              title: "Shipment Date",
              type: FieldType.date,
            },
            {
              key: "DC_no",
              title: "DC No./Inv No.",
              type: FieldType.string,
            },
            {
              key: "track",
              title: "Tracking ID",
              type: FieldType.string,
            },
            {
              key: "sender_name",
              title: "Sender Name",
              type: FieldType.string,
            },
            {
              key: "sender_dept",
              title: "Sender Dept",
              type: FieldType.string,
            },
            {
              key: "from_warehouse",
              title: "From Warehouse",
              type: FieldType.string,
            },
            {
              key: "to_warehouse",
              title: "To Warehouse",
              type: FieldType.string,
            },
            {
              key: "receiver_name",
              title: "Receiver Name",
              type: FieldType.string,
            },
            {
              key: "receiver_dept",
              title: "Receiver Dept",
              type: FieldType.string,
            },
            {
              key: "voucherStatus",
              title: "Status",
              type: FieldType.string,
            },
            {
              key: "attachmnets",
              title: "Attachments",
              type: FieldType.number,
            },
          ]}
          rows={data.map((data, i) => ({
            data: data,
            values: [
              // formatISODate(data.date),
              data.id,
              moment(data.created_at).format("DD-MM-YYYY"),
              moment(data.shipment_date).format("DD-MM-YYYY"),
              data.delivery_challan_number,
              data.tracking_id,
              `${data.user?.first_name ?? ""} ${
                data.user?.last_name ?? ""
              }`.trim(),
              data.user?.emp_profile?.department,
              data.from_warehouse?.name,
              data.receiver_warehouse?.name,
              data.receiver_warehouse?.spoc?.contact_name,
              data.receiver_warehouse?.spoc?.department,
              data.status,
              data.attachments,
            ],
            disabled: data.status === "Returned",
          }))}
          page={page}
          total={total}
          count={count}
          itemCount={data.length}
          onPageChange={setPage}
          onClick={onSelect}
          onShowHistory={(data) => {
            setShowHistory(data);
            // console.log(data);
          }}
          onDelete={onDelete}
        /> */}
      </div>

      {loading && <LoadingWidget />}
    </>
  );
};

export default SenderStockTransferJournalListScreen;
