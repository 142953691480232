import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { fetchGet, fetchPost, fetchPut } from "../../../service/api_client";

import {
  WAREHOUSE_TYPE,
  warehouseDefaultValue,
  WarehouseModel,
} from "../models/warehouse_model";
import { urls } from "../../../utils/urls";
import EmployeeSearchWidget from "../../../ui/new_components/emp_search_widget";
import PartySearchField from "../../accounts/parties/party_search_field";
import { Country } from "country-state-city";
import RequiredMark from "../../../ui/new_components/common/required_mark";
import { stateData } from "../../../utils/states_data";
import { dataTypes } from "../../../utils/import_export_parser";
// Define types for state and city
type CountryType = {
  isoCode: string;
  name: string;
};

interface InventoryBasketCreateScreenProps {
  edit: boolean;
  onClose: (id?: WarehouseModel) => void;
  data?: WarehouseModel;
}

const InventoryBasketCreateScreen: FunctionComponent<
  InventoryBasketCreateScreenProps
> = (props) => {
  const isUpdatePage = useRouteMatch(
    `${urls.procurement_masters_warehouse}/update/:id`
  );
  const { id } = useParams<{ id: string }>();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/warehouse_type`;
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const { edit } = props;
  const [editable, setEditable] = useState(false);
  const [data, setData] = useState<WarehouseModel>(
    props.data || ({} as WarehouseModel)
  );
  const navaigation = useHistory();
  const defaultWarehouseModel: WarehouseModel = warehouseDefaultValue;

  const handleOptionChange = (value: WarehouseModel["type"]) => {
    setData((pv) => ({ ...pv, type: value }));
  };

  // States for countries, states, and cities
  const [countries, setCountries] = useState<CountryType[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  console.log("selectedCountry:", selectedCountry);
  // Load countries on component mount
  useEffect(() => {
    const allCountries = Country.getAllCountries();
    setCountries(allCountries);
  }, []);

  const submit = async () => {
    try {
      if (!data.name || !data.type) {
        showToast({
          type: ToastType.error,
          text: "Please fill all the required details",
        });
        return;
      }

      setLoading(true);

      const res = data.id
        ? await fetchPut(`${url}/${data.id}`, data)
        : await fetchPost(url, data);
      if (res.success) {
        showToast({ type: ToastType.success, text: res.message });
        if (!data.id) {
          setData(defaultWarehouseModel);
          // setShowSucess(true);
        }
        setData((prevData) => ({
          ...prevData,
          ...res.data, // Update with the response data
        }));
        if (props.onClose) props.onClose(res.data);
        navaigation.push(urls.procurement_masters_warehouse);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      showToast({ type: ToastType.error, text: "An error occurred" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setEditable(edit);
    if (id && isUpdatePage) {
      getData(id);
    }
  }, []);

  useEffect(() => {
    // Initialize form with existing data if editing
    if (edit && props.data) {
      setData(props.data);
    }
  }, [edit, props.data]);

  const getData = async (id: string) => {
    try {
      setLoading(true);
      const res = await fetchGet(`${url}/${id}`);
      if (res.success) {
        console.log(res.data);
        const updatedData = { ...res.data, type: res.data.type }; // Update type property
        setData(updatedData); // Update state with merged data
      } else {
        console.log("Invalid");
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({ type: ToastType.error, text: "An error occurred" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 m-5 gap-2 select-none text-sm">
        <h1 className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
          Warehouse {id ? `Update: ${data.id}` : "create"}
        </h1>
        <div className="grid grid-cols-1 w-full mr-5 justify-center m-5 gap-2 select-none">
          <div className="p-5 md:p-10 flex flex-col mr-10 gap-5 shadow bg-white rounded-lg">
            <div className="flex flex-col text-sm w-1/2">
              <div className="flex flex-col items-start text-sm w-full my-2">
                <label className="font-semibold w-full" htmlFor="name">
                  Warehouse Name*
                </label>
                <input
                  placeholder="Enter Name"
                  className="p-1 rounded focus:outline-none w-full border"
                  id="name"
                  name="name"
                  value={data.name || ""}
                  required
                  type="text"
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
              </div>
              {/* <div className="mb-2"> */}
              <div className="grid grid-cols-2 gap-5 items-end my-2">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="line1">
                    Address Line 1
                  </label>
                  <input
                    placeholder="address line 1"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="line1"
                    name="line1"
                    value={data.address?.line1 || ""}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        address: {
                          ...prevData.address!,
                          line1: e.target.value,
                        },
                      }))
                    }
                  />
                </div>

                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="line2">
                    Address Line 2
                  </label>
                  <input
                    placeholder="address line 2"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="line2"
                    name="line2"
                    value={data.address?.line2 || ""}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        address: {
                          ...prevData.address!,
                          line2: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col items-start text-sm w-full my-2">
                <label className="font-semibold w-full" htmlFor="line2">
                  Address Line 3
                </label>
                <input
                  placeholder="address line3"
                  className="p-1 rounded focus:outline-none w-full border"
                  id="line2"
                  name="line2"
                  value={data.address?.line3 || ""}
                  onChange={(e) =>
                    setData((prevData) => ({
                      ...prevData,
                      address: {
                        ...prevData.address!,
                        line3: e.target.value,
                      },
                    }))
                  }
                />
              </div>
              <div className="grid grid-cols-2 gap-5 items-end my-2">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="country">
                    Country*
                  </label>
                  <select
                    id="mail_detail.address.country"
                    name="mail_detail.address.country"
                    className="p-1 rounded focus:outline-none w-full border"
                    value={data?.address?.country || ""}
                    onChange={(event) => {
                      const selectedCountryName = event.target.value;

                      if (selectedCountryName) {
                        setSelectedCountry(selectedCountryName);
                      }
                      console.log("selectedCountry:", selectedCountryName);
                      setData((prev: any) => ({
                        ...prev,
                        address: {
                          ...prev.address,
                          country: selectedCountryName, // Store the country name in state
                        },
                      }));
                    }}
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country.isoCode} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                {selectedCountry === "India" ? (
                  <div className="grid gap-1">
                    <label
                      htmlFor="mail_detail.address.state"
                      className="font-semibold text-sm"
                    >
                      State <RequiredMark />
                    </label>
                    <select
                      id="mail_detail.address.state"
                      name="mail_detail.address.state"
                      className="p-1 rounded focus:outline-none w-full border"
                      value={data?.address?.state} // Make sure it defaults to empty string if undefined
                      onChange={(newValue) => {
                        const selectedStateValue = (
                          newValue.target as HTMLSelectElement
                        ).value;

                        setData((prev: any) => ({
                          ...prev,

                          address: {
                            ...prev.address,
                            state: selectedStateValue ? selectedStateValue : "", // Update the country field in the vendor state
                          },
                        }));
                      }}
                    >
                      <option value="">Select State</option>
                      {stateData.map((state) => (
                        <option key={state.id} value={state.name}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div className="grid gap-1">
                    <label
                      htmlFor="mail_detail.address.state"
                      className="font-semibold text-sm"
                    >
                      State
                    </label>
                    <input
                      type="text"
                      data-type={dataTypes.string}
                      className="border rounded-md px-3 py-1"
                      id="address.state"
                      name="address.state"
                      value={data?.address?.state}
                      onChange={(e) => {
                        setData((prev: any) => ({
                          ...prev,
                          address: {
                            ...prev.address,
                            state: e.target.value ?? "", // Update the country field in the vendor state
                          },
                        }));
                      }}
                      placeholder="State"
                    />
                  </div>
                )}
              </div>

              <div className="grid grid-cols-2 gap-5 items-end my-2">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="city">
                    City*
                  </label>

                  <input
                    id="city"
                    name="city"
                    className="p-1 rounded focus:outline-none w-full border"
                    value={data?.address?.city || ""} // Make sure it defaults to empty string if undefined
                    onChange={(e) => {
                      setData((prev: any) => ({
                        ...prev,
                        address: {
                          ...prev.address,
                          city: e.target.value, // Update the country field in the vendor state
                        },
                      }));
                    }}
                  ></input>
                </div>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="pincode">
                    Pincode*
                  </label>
                  <input
                    placeholder="Enter pincode"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="pincode"
                    name="pincode"
                    required
                    value={data.address?.pincode || ""}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        address: {
                          ...prevData.address!,
                          pincode: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-5 items-end my-2">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="type">
                    Type*
                  </label>
                  <select
                    value={data.type}
                    onChange={(e) => {
                      handleOptionChange(
                        e.target.value as WarehouseModel["type"]
                      );
                      setData({
                        ...data,
                        type: e.target.value as WarehouseModel["type"],
                      });
                    }}
                    className="p-1 rounded focus:outline-none w-full border"
                  >
                    <option value="">select</option>
                    {Object.values(WAREHOUSE_TYPE).map((f: string) => (
                      <option key={f} value={f}>
                        {f}
                      </option>
                    ))}
                  </select>
                </div>
                {data.type === "internal" && (
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="line1">
                      User Name/SPOC *
                    </label>
                    <EmployeeSearchWidget
                      withBtn={false}
                      onSelect={(emp) => {
                        setData((pv) => {
                          return {
                            ...pv!,
                            spoc: {
                              ...pv.spoc,
                              uid: emp.uid,
                              contact_name:
                                emp.first_name + " " + (emp.last_name ?? ""),
                              contact_mobile: emp.mobile,
                              contact_email: emp.email,
                              department: emp.emp_profile?.department,
                            },
                          };
                        });
                      }}
                      emp={
                        data.spoc?.uid
                          ? {
                              uid: data.spoc.uid!,
                              name: data.spoc.contact_name,
                            }
                          : undefined
                      }
                    />
                  </div>
                )}
                {data.type === "external" && (
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="line1">
                      User Name/SPOC *
                    </label>
                    <PartySearchField
                      // show_add={true}
                      placeholder={data.spoc?.contact_name}
                      onSelect={(e) => {
                        setData((prevData) => ({
                          ...prevData,
                          spoc: {
                            ...prevData.spoc!,
                            contact_name: e.name,
                            contact_email: e.mail_detail.contact_email,
                            contact_mobile: e.mail_detail.contact_mobile,
                          },
                        }));
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
        {!id && (
          <div className="flex justify-end gap-5 mt-5 mb-2 mr-5">
            <button
              onClick={submit}
              type="button"
              className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
            >
              Create
            </button>
            <button
              onClick={() => {
                navaigation.push(urls.procurement_masters_warehouse);
              }}
              type="button"
              className="rounded-md text-sm font-semibold bg-white border border-green-600 text-green-600 px-3 py-1"
            >
              Quit
            </button>
          </div>
        )}
        {id && (
          <div className="flex justify-end gap-5 mt-5 mb-2 mr-5">
            <button
              onClick={submit}
              type="button"
              className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
            >
              Update
            </button>
            <button
              onClick={() => {
                navaigation.push(urls.procurement_masters_warehouse);
              }}
              type="button"
              className="rounded-md text-sm font-semibold bg-white border border-green-600 text-green-600 px-3 py-1"
            >
              Quit
            </button>
          </div>
        )}
      </div>

      {loading && <LoadingWidget />}
    </>
  );
};

export default InventoryBasketCreateScreen;
