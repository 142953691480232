import { RouteParam } from "../../../routes";
import { MODULE, ACTION } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import PaymentRequestListForAdvanceAndInvoice from "../payment_request/payment_requests_list";
import PaymentRequestApprovalListForAdvanceAndInvoice from "../payment_request/payment_request_approvals_list";
import {
  PRAdvanceApprovalPageWarraper,
  PRAdvancePageWarraper,
  PRInvoiceAprovalPageWarraper,
  PRInvoicePageWarraper,
} from "../payment_request/payment_request_wrapper";

const payment_request_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.po_advance_payment_request,
    component: PaymentRequestListForAdvanceAndInvoice,
    module: MODULE.payment_request_maker,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.po_payment_request_approval,
    component: PaymentRequestApprovalListForAdvanceAndInvoice,
    module: MODULE.payment_request_checker,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.po_advance_payment_request + "/updateAdvance/:id",
    component: PRAdvancePageWarraper,
    module: MODULE.payment_request_maker,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.po_advance_payment_request + "/updateInvoice/:id",
    component: PRInvoicePageWarraper,
    module: MODULE.payment_request_maker,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.po_payment_request_approval + "/approveAdvance/:id",
    component: PRAdvanceApprovalPageWarraper,
    module: MODULE.payment_request_checker,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.po_payment_request_approval + "/approveInvoice/:id",
    component: PRInvoiceAprovalPageWarraper,
    module: MODULE.payment_request_checker,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
];

export default payment_request_routes;
