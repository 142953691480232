import { OrderDevice } from "../../../ui/pages/orders/order_detail/order_devices/order_device_row";
import { BaseModel } from "../base";
import { WarehouseModel } from "./warehouse_model";
export interface InventoryImportData {
  id?: string;
  device_id: string;
  product_id: number;
  product_name: string;
  warehouse_id: string;
  warehouse_name?: string;
  mobile_number?: string;
  module_imei?: string;
  sim_imsi?: string;
  plan_name?: string;
  sim_no?: string;
  sim_status?: string;
  status: string;
  order_id: string;

  org_namespace: string;
  org_name: string;

  installation_date?: Date | null;
  return_back_date?: Date | null;
  delivery_date?: Date | null;

  responsible_id?: string;
  responsible_name?: string;
  responsible_email?: string;
}

export interface DeviceModel {
  device_id: string;
  product_id: number;
  product_name: string;
  mobile_number?: string;
  sim_no?: string;
  sim_imsi?: string;
  sim_status?: string;
  plan_name?: string;
  module_imei?: string;
}

export const deviceDefaultValue: DeviceModel = {
  device_id: "",
  product_id: 0,
  product_name: "",
};



export interface InventoryModel extends BaseModel {
  device_id: string;
  warehouse_id?: any;
  warehouse?: WarehouseModel;
  status: string;
  device: DeviceModel;
  allocations: OrderDevice[];
  responsible_id?: string;
  responsible_name?: string;
  responsible_email?: string;
}

export const inventoryDefaultValue: InventoryModel = {
  device_id: "",
  status: "",
  device: deviceDefaultValue,
  allocations: [],
};
export interface InventoryCommentModel {
  id?: number;
  uid?: number;
  inventory_id: number;
  created_at?: string;
  comment: string;
  user?: string;
}

export interface InventoryLogModel {
  id?: number;
  inventory_id: number;
  created_at?: string;
  log: string;
}
