import { RouteParam } from "../../../routes";
import { ACTION, MODULE } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import IntransitStockTransferListScreen from "../stock_transfer/in_transit_page/InTransit_list";
import StockTransferViewScreen from "../stock_transfer/receiver_stock_transfer/componets/receiver_stock_transfer_view";
import ReceiverStockTransferJournalListScreen from "../stock_transfer/receiver_stock_transfer/receiver_stock_transfer_list";
import StockJournalUpdate from "../stock_transfer/receiver_stock_transfer/receiver_stock_transfer_update_screen";
import StockJournalCreate from "../stock_transfer/sender_stock_transfer/components/sender_stock_transfer_create";
import SenderStockTransferViewScreen from "../stock_transfer/sender_stock_transfer/components/sender_stock_transfer_view";
import SenderStockTransferJournalListScreen from "../stock_transfer/sender_stock_transfer/sender_stock_transfer_list";

const stock_journal_transfer_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_transfer,
    component: SenderStockTransferJournalListScreen,
    module: MODULE.stock_transfer_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_transfer + "/create",
    component: StockJournalCreate,
    module: MODULE.stock_transfer_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_transfer + "/sender/view/:id",
    component: SenderStockTransferViewScreen,
    module: MODULE.stock_transfer_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.READ],
    operator: "or"
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_transfer + "/update/:id",
    component: StockJournalCreate,
    module: MODULE.stock_transfer_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_transfer + "/in-transit",
    component: IntransitStockTransferListScreen,
    module: MODULE.stock_transfer_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_transfer + "/receiver",
    component: ReceiverStockTransferJournalListScreen,
    module: MODULE.stock_transfer_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_transfer + "/receiver/update/:id",
    component: StockJournalUpdate,
    module: MODULE.stock_transfer_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_transfer + "/receiver/view/:id",
    component: StockTransferViewScreen,
    module: MODULE.stock_transfer_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.READ],
    operator: "or"
  },
];

export default stock_journal_transfer_routes;
