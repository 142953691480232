import { BaseModel } from "../../../service/models/base";
import { AttachmentModel } from "../../purchase_order/model/purchase_order_model";
import { AddressModel } from "./party_model";

export interface KazamGSTFilter {
  from?: Date;
  to?: Date;
  page: number;
  count: number;
  gst_number?: string;
  name?: string;
  state?: string[];
}

export const defaultKazamGSTFilter: KazamGSTFilter = {
  page: 1,
  count: 100,
  state: [],
};

export interface KazamGST extends BaseModel {
  gst_number: string;
  name: string;
  address: AddressModel;
  effective_date_of_reg: Date;
  attachments: AttachmentModel[];
}

export const defaultKazamGST: KazamGST = {
  gst_number: "",
  name: "",
  address: {
    line1: "",
    city: "",
    state: "",
    pincode: "",
    country: "India",
    id: "",
    type: "",
  },
  attachments: [],
  effective_date_of_reg: new Date(),
};
