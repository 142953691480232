import { debounce } from "lodash";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../../../context_providers/toast/toast_ctx";
import AutoCompleteField from "../../../../../../ui/new_components/common/autocomplete";
import { GetWareHouseListApi } from "../../../../inventory_basket/components/warehouse_repo";
import { WarehouseModel } from "../../../../models/warehouse_model";

interface WarehouseMultiSelectProps {
  onSelect: (data: WarehouseModel) => void;
  onDelete: (uid: string) => void;
  selectedWarehouses: WarehouseModel[];
  placeholder?: string;
  disabled?: boolean;
  withBtn?: boolean;
}

const WarehouseMultiSelect: FunctionComponent<WarehouseMultiSelectProps> = ({
  onSelect,
  onDelete,
  selectedWarehouses,
  placeholder,
  disabled = false,
}) => {
  const { showToast } = useToast();
  const [search, setSearch] = useState("");
  const [warehouse, setWarehouses] = useState<WarehouseModel[]>([]);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit) setSearch(""); // Clear search when entering edit mode
  }, [edit]);

  const getWarehouses = async (prop: {
    type?: string;
    search?: string;
    page: number;
    count: number;
    all?: boolean;
  }) => {
    setLoading(true);
    try {
      prop.all = true;

      const res = await GetWareHouseListApi(prop);
      if (res.success) {
        setWarehouses(res.data.data);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce((query) => {
      console.log("Query", query);
      let prop = {
        search: query,
        page: 1,
        count: 20,
        all: true,
      };
      if (query) getWarehouses(prop);
    }, 500),
    []
  );

  useEffect(() => {
    if (search && edit) {
      debouncedHandler(search);
    }
  }, [search, edit, debouncedHandler]);

  return (
    <div className="flex flex-col gap-1 w-full">
      <div className="flex gap-1 items-center">
        {edit ? (
          <AutoCompleteField
            loading={loading}
            value={search}
            placeholder={placeholder ?? "Warehouse"}
            onSelect={(val) => {
              setEdit(false);
              setSearch("");
              onSelect(val.value);
            }}
            suggestions={warehouse.map((e) => ({
              label: (
                <div className="text-xs">
                  <b>{e.name}</b>
                </div>
              ),
              value: e,
            }))}
            onChange={(str) => {
              setSearch(str);
            }}
          />
        ) : (
          <div
            className="text-sm px-7 py-2 h-8 rounded text-left items-start bg-white focus:outline-none w-full border"
            onClick={() => {
              if (!disabled) {
                setEdit(true);
              }
            }}
          >
            {placeholder}
          </div>
        )}
      </div>

      {/* Render selected employees as pills */}
      <div className="flex flex-wrap gap-1 mt-2">
        {selectedWarehouses.map((warehouse) => (
          <span
            key={warehouse.id}
            className="bg-gray-200 text-xs py-1 px-2 rounded flex items-center"
          >
            {warehouse.name}
            <button
              onClick={() => onDelete(warehouse.id)}
              className="ml-1 text-red-500"
            >
              x
            </button>
          </span>
        ))}
      </div>
    </div>
  );
};

export default WarehouseMultiSelect;
