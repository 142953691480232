import { FunctionComponent } from "react";
import { InwardModel } from "../../../../inventory/models/inventory_voucher";
import { QRCodeSVG } from "qrcode.react";

interface PurchaseVoucherQRCodeProps {
  data: InwardModel[];
}

const PurchaseVoucherQRCodeModal: FunctionComponent<
  PurchaseVoucherQRCodeProps
> = ({ data }) => {
  const printCard = (index: number) => {
    const cardElement = document.getElementById(`print-card-${index}`);
    if (!cardElement) return;

    const printWindow = window.open("", "_blank");
    if (!printWindow) return;

    printWindow.document.write(`
      <html>
        <head>
          <title>Print QR Code</title>
          <style>
            body { font-family: Arial, sans-serif; text-align: center; padding: 20px; }
            .print-container { border: 1px solid #ddd; padding: 20px; border-radius: 8px; display: inline-block; }
            input { border: none; background: none; text-align: center; font-size: 16px; width: 100%; }
            img { width: 150px; height: 150px; }
          </style>
        </head>
        <body>
          <div class="print-container">${cardElement.innerHTML}</div>
          <script>
            window.onload = function () {
              window.print();
              window.onafterprint = function() { window.close(); };
            };
          </script>
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  return (
    <>
      <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 gap-3 p-5 m-5">
        {data.map((inward_item, index) => {
          const qrCodeData = JSON.stringify({
            item_code: inward_item.item?.item_code || "N/A",
            item_name: inward_item.item?.name || "N/A",
          });

          return (
            <div
              key={index}
              id={`print-card-${index}`}
              className="gap-4 rounded shadow p-4 border bg-white"
            >
              <div className="flex flex-row text-sm w-full mb-4">
                <label className="font-semibold w-full">Item Code</label>
                <input
                  className="p-1 rounded focus:outline-none w-full border"
                  value={inward_item.item?.item_code || ""}
                  disabled
                />
              </div>
              <div className="flex flex-row text-sm w-full">
                <label className="font-semibold w-full">Item Name</label>
                <input
                  className="p-1 rounded focus:outline-none w-full border"
                  value={inward_item.item?.name || ""}
                  disabled
                />
              </div>
              <div className="flex justify-center my-4">
                <QRCodeSVG value={qrCodeData} size={128} />
              </div>
              <div className="flex justify-center">
                <button
                  onClick={() => printCard(index)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Print
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PurchaseVoucherQRCodeModal;
