import {
  ItemModelV2,
  ItemModelV2Default,
} from "../../../erp/inventory/models/item_model";

export interface OrderItemModelV2 {
  id?: any;
  order_id?: any;
  item_id: string;
  item?: ItemModelV2;
  price: number;
  qty: number;
  delivered_qty: number;
  installed_qty: number;
  returned_qty: number;
  extra_amt?: number;
  extra_des?: string;
  created_at?: number;
  updated_at?: string;
  addition_amt?: number;
  discount_amt?: number;
  paid?: number;
  subtotal?: number;
  total?: number;
  created_by?: number;
  updated_by?: number;
  created_by_name?: string;
  updated_by_name?: string;
  deleted?: boolean;
}

export const defaultItem: OrderItemModelV2 = {
  item_id: "",
  item: ItemModelV2Default,
  price: 0,
  qty: 0,
  delivered_qty: 0,
  installed_qty: 0,
  returned_qty: 0,
};

interface OrderItemModel {
  id?: any;
  order_id?: any;
  product_id: number;
  name: string;
  price: number;
  qty: number;
  delivered_qty: number;
  installed_qty: number;
  returned_qty: number;
  extra_amt?: number;
  extra_des?: string;
  created_at?: string;
  updated_at?: string;
  addition_amt?: number;
  discount_amt?: number;
  paid?: number;
  subtotal?: number;
  total?: number;
  created_by?: number;
  updated_by?: number;
  created_by_name?: string;
  updated_by_name?: string;
  deleted?: boolean;
}

export default OrderItemModel;
