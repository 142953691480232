import { mdiClose, mdiRefresh } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import { useOrderDetailCtx } from "../../../../../context_providers/order_detail_context";
import { useToast } from "../../../../../context_providers/toast/toast_ctx";

import { ACTION, InventoryStatus, MODULE } from "../../../../../utils/enums";
import ModalDialog from "../../../../new_components/common/modal";
import SearchFieldWidget from "../../../../new_components/search_field";
import OrderDeviceCreateWidget from "./order_device_create";

import OrderDeviceRow, { OrderDevice, OrderDeviceV2 } from "./order_device_row";

import DeviceUpdateWidgets from "./device_update";
import {
  ModalAlignment,
  ModalType,
  useModal,
} from "../../../../../context_providers/modal/modal_context";
import { WarehouseModel } from "../../../../../erp/inventory/models/warehouse_model";

interface OrderDeviceSectionProps {}

const OrderDeviceSection: FunctionComponent<OrderDeviceSectionProps> = () => {
  const { showToast } = useToast();
  const { setLoading, showModal, onClose } = useModal();

  const {
    order,
    setOrder,
    devices,
    getDevices,
    onCreateOrderDevices,
    onUpdateOrderDevice,
    onUpdateOrderDeviceReplace,
  } = useOrderDetailCtx();
  const [activeDevices, setActiveDevices] = useState<OrderDeviceV2[]>([]);
  const [returnedDevice, setReturnedDevices] = useState<OrderDeviceV2[]>([]);
  const [addNew, setAddNew] = useState(false);
  // const [selectDevice, setSelectedDevice] = useState<OrderDevice>();

  useEffect(() => {
    getDevices();
  }, [getDevices]);
  useEffect(() => {
    setActiveDevices(
      devices.filter((f) =>
        [InventoryStatus.installed, InventoryStatus.delivered].includes(
          f.status
        )
      )
    );
    setReturnedDevices(
      devices.filter((f) =>
        [InventoryStatus.returnedNStocked].includes(f.status)
      )
    );
  }, [devices]);

  const createOrderDevices = async (data: {
    device_ids: string[];
    order_id: string;
    status: string;
    org_namespace: string;
    org_name: string;
    date: Date;
    item_id: string;
  }) => {
    if (await onCreateOrderDevices(data)) setAddNew(false);
  };

  const searchDevice = (search: string) => {
    if (search) {
      setActiveDevices(
        devices.filter(
          (f) =>
            [InventoryStatus.installed, InventoryStatus.delivered].includes(
              f.status
            ) &&
            f.device_id.trim().toLowerCase().match(search.trim().toLowerCase())
        )
      );
      setReturnedDevices(
        devices.filter(
          (f) =>
            [InventoryStatus.returnedNStocked].includes(f.status) &&
            f.device_id.trim().toLowerCase().match(search.trim().toLowerCase())
        )
      );
    } else {
      setActiveDevices(
        devices.filter((f) =>
          [InventoryStatus.installed, InventoryStatus.delivered].includes(
            f.status
          )
        )
      );
      setReturnedDevices(
        devices.filter((f) =>
          [InventoryStatus.returnedNStocked].includes(f.status)
        )
      );
    }
  };

  const { user, isAuthorised } = useAuth();
  const [disabled, setDisabled] = useState(false);

  const [itemId, setItemId] = useState<any>();

  useEffect(() => {
    if (itemId) {
      setActiveDevices(
        devices.filter(
          (f) =>
            [InventoryStatus.installed, InventoryStatus.delivered].includes(
              f.status
            ) && f.item_id == itemId
        )
      );
      setReturnedDevices(
        devices.filter(
          (f) =>
            [InventoryStatus.returnedNStocked].includes(f.status) &&
            f.item_id == itemId
        )
      );
    } else {
      setActiveDevices(
        devices.filter((f) =>
          [InventoryStatus.installed, InventoryStatus.delivered].includes(
            f.status
          )
        )
      );
      setReturnedDevices(
        devices.filter((f) =>
          [InventoryStatus.returnedNStocked].includes(f.status)
        )
      );
    }
  }, [itemId, devices]);
  useEffect(() => {
    setDisabled(
      !isAuthorised({ module: MODULE.order, action: [ACTION.UPDATE] })
    );
  }, []);
  const onUpdateDevice = async (data: {
    device: OrderDeviceV2;
    return_strategy: boolean;
    replacement_strategy: boolean;
    replaceDevice?: OrderDeviceV2;
    warehouse?: WarehouseModel;
  }) => {
    setLoading(true);
    await onUpdateOrderDeviceReplace(data);
    setLoading(false);
  };
  return (
    <>
      <div
        className={`  mt-10 mx-5 right-0
                transform duration-500 ease-in-out   slide_drawer_rtl `}
      >
        <div className="flex justify-between items-center mb-2 gap-2">
          <div className="flex items-center gap-1">
            <h3 className="font-semibold text-gray-500">Delivered Devices</h3>
            {/* <button onClick={() => getDevices()} className="">
              <Icon path={mdiRefresh} className="h-5 w-5"></Icon>
            </button> */}

            <div className="flex">
              <div className="">
                Delivered{" "}
                <span>
                  {
                    activeDevices.filter(
                      (f) => f.status === InventoryStatus.delivered
                    ).length
                  }
                </span>
              </div>
              <div className="">
                Installed{" "}
                <span>
                  {
                    activeDevices.filter(
                      (f) => f.status === InventoryStatus.installed
                    ).length
                  }
                </span>
              </div>
              <div className="">
                Delivered{" "}
                <span>
                  {
                    activeDevices.filter(
                      (f) => f.status === InventoryStatus.delivered
                    ).length
                  }
                </span>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-end mb-2 gap-2">
            <div className="flex flex-col">
              <label htmlFor="item">Item</label>
              <div className="flex bg-white">
                <select
                  onChange={(e) => {
                    const t = JSON.parse(e.target.value);
                    setItemId(t.item_id);
                  }}
                  value={JSON.stringify({ item_id: itemId })}
                  name=""
                  id="item"
                  className="text-sm focus:outline-none px-2 py-1 rounded"
                >
                  <option value="" hidden>
                    Select
                  </option>

                  {order.products.map((item) => (
                    <option value={JSON.stringify({ item_id: item.id })}>
                      {item.item?.item_code ?? "--"}
                    </option>
                  ))}
                </select>
                {itemId && (
                  <button
                    className="p-1"
                    onClick={() => {
                      setItemId(null);
                    }}
                  >
                    <Icon path={mdiClose} size={0.8}></Icon>
                  </button>
                )}
              </div>
            </div>
            <div className="bg-white rounded">
              <SearchFieldWidget
                placeholder="Device Id"
                onClear={() => searchDevice("")}
                onSubmit={(search) => {
                  searchDevice(search);
                }}
              />
            </div>
            <button
              onClick={() => setAddNew(true)}
              disabled={disabled}
              className="rounded bg-myPrimaryColor text-white px-2 py-1 text-sm"
            >
              Add
            </button>
          </div>
        </div>

        <div className="  grid grid-cols-12 bg-white font-semibold  rounded-md px-3 py-2 text-sm">
          <div>SL.NO</div>
          <div>Row ID | Item Code</div>

          <div>Device ID</div>
          <div>Replaced By</div>
          <div>Replaced To</div>
          <div>Org namespace</div>
          <div>Product</div>
          <div>Delivery Date</div>
          <div>Installation Date</div>
          <div>Returned Date</div>
          <div>Status</div>
        </div>
        <div
          className="flex flex-col gap-2 mt-1 overflow-auto "
          style={{ maxHeight: "67vh", minHeight: "37vh" }}
        >
          {activeDevices.length > 0 ? (
            activeDevices.map((device, i) => (
              <OrderDeviceRow
                device={device}
                sl_no={i + 1}
                key={device.device_id + i}
                onUpdate={(orderDevice) => {
                  showModal({
                    type: ModalType.drawer,
                    title: "Address Picker",
                    alignment: ModalAlignment.right,
                    container(id) {
                      return (
                        <DeviceUpdateWidgets
                          data={orderDevice}
                          onSubmit={onUpdateDevice}
                          onCancel={function (): void {
                            onClose(id);
                          }}
                        />
                      );
                    },
                  });
                }}
              />
            ))
          ) : (
            <div className="my-10 flex flex-col gap-5 items-center justify-center text-2xl font-semibold text-gray-400">
              <p> No Record Found !</p>
            </div>
          )}
        </div>
        <div className="flex items-center gap-1">
          <h3 className="font-semibold text-gray-500">Returned Devices</h3>
          <button onClick={() => getDevices()} className="">
            <Icon path={mdiRefresh} className="h-5 w-5"></Icon>
          </button>
        </div>
        <div className="  grid grid-cols-12 bg-white font-semibold  rounded-md px-3 py-2 text-sm">
          <div>SL.NO</div>
          <div>Row Id | Item Code</div>
          <div>Device ID</div>
          <div>Replaced By</div>
          <div>Replaced To</div>
          <div>Org namespace</div>
          <div>Product</div>
          <div>Delivery Date</div>
          <div>Installation Date</div>
          <div>Returned Date</div>
          <div>Status</div>
        </div>
        <div
          className="flex flex-col gap-2 mt-1 overflow-auto "
          style={{ maxHeight: "67vh", minHeight: "37vh" }}
        >
          {returnedDevice.length > 0 ? (
            returnedDevice.map((device, i) => (
              <OrderDeviceRow
                disabled={false}
                device={device}
                sl_no={i + 1}
                key={device.device_id + i}
                onUpdate={function (data: OrderDeviceV2): void {}}
              />
            ))
          ) : (
            <div className="my-10 flex flex-col gap-5 items-center justify-center text-2xl font-semibold text-gray-400">
              <p> No Record Found !</p>
            </div>
          )}
        </div>
      </div>
      {addNew && (
        <ModalDialog
          show={addNew}
          title="Device allocation"
          onClose={() => {
            setAddNew(false);
          }}
        >
          <div className="p-5">
            <OrderDeviceCreateWidget
              order={order!}
              onCreateOrderDevices={createOrderDevices}
            />
          </div>
        </ModalDialog>
      )}
    </>
  );
};

export default OrderDeviceSection;
